import { ViewportRuler } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rims-table-scroll-indicator',
  templateUrl: './rims-table-scroll-indicator.component.html',
  styleUrls: ['./rims-table-scroll-indicator.component.scss']
})
export class RimsTableScrollIndicatorComponent implements OnInit, OnDestroy {
  @Input()
  table: MatTable<any>;

  @Input()
  set columns(_: string[]) {
    this.updateDisplay();
  }

  show = false;
  private sub: Subscription;

  constructor(private readonly ruler: ViewportRuler, private readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.sub = this.ruler
      .change(500)
      .pipe(tap(_ => this.updateDisplay()))
      .subscribe();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  private updateDisplay() {
    requestAnimationFrame(() => {
      const { width } = this.ruler.getViewportSize();
      const tableEl = this.table['_elementRef'] as ElementRef<HTMLTableElement>;
      const tableWidth = tableEl.nativeElement.scrollWidth;
      this.show = tableWidth * 1.1 > width;
      this.changeDetector.detectChanges();
    });
  }
}
