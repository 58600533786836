import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user/user.model';

@Pipe({
  name: 'userEmail'
})
export class UserEmailPipe implements PipeTransform {
  transform(user: User): string | undefined {
    const name = `${user.firstName} ${user.lastName}`;
    const mail = user.contact?.eMail;

    if (!mail) {
      return undefined;
    }

    return `${name} <${mail}>`;
  }
}
