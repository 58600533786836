import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nomenclatureOptionFormat'
})
export class NomenclatureOptionFormatPipe implements PipeTransform {
  transform(nomenclature: any): string {
    return `${('' + nomenclature.number).trim()} - ${nomenclature.term} (${nomenclature.nomenclatureType2.name})`;
  }
}
