import { Inject, Injectable } from '@angular/core';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AppConfig, APP_CONFIG } from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class NumberValidator {
  constructor(
    @Inject(APP_CONFIG)
    private readonly config: AppConfig
  ) {}

  /**
   *
   * Checks whether the control value is a valid positive number.
   *
   * @param regex If the control value matches this pattern it automatically passes the validator (optional).
   * @param maxValue Can be used to overwrite the default maximum integer value (optional).
   */
  positiveNumber = (
    control: FormControl,
    regex: RegExp = null,
    maxValue = this.config.maxIntegerValue
  ): ValidationErrors | null => {
    const number = control?.value;

    if (regex && regex.test(number?.toString())) {
      return null;
    }

    if (number === '' || number == null) {
      return null;
    }

    if (Number.isNaN(+number)) {
      return { invalidNumber: 'Please enter a number' };
    }

    if (+number < 1) {
      return { invalidNumber: 'Please enter a positive number' };
    }

    if (+number > maxValue) {
      return { invalidNumber: 'The provided number is too big' };
    }

    return null;
  };

  /**
   *
   * @returns a validator that calls `positiveNumber` with the provided regex
   */
  positiveNumberWithRegex(regex: RegExp): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      return this.positiveNumber(control, regex);
    };
  }

  /**
   * @returns a validator that calls `positiveNumber` with the maximum allowed number for `numeric(10, 2)` fields
   */
  positiveNumberWithTwoDecimalPlaces() {
    // A field with precision 10, scale 2 must round to an absolute value less than 10^8.
    const precisionMax = 99999999.99;

    return (control: FormControl): ValidationErrors | null => {
      return this.positiveNumber(control, null, precisionMax);
    };
  }
}
