import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ItemNotDeleted, ProductGroupBudiInfo, ProductGroupType } from '@rims/database';
import { EqualsFilter } from '@rims/lib';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from '../../shared/services/request-builder/request-builder.service';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupValidator {
  constructor(private readonly data: DataService, private readonly requestBuilder: RequestBuilder) {}

  productGroupNameValidator(groupType: ProductGroupType, name: string) {
    return this.validatePattern(groupType, 'name', name).pipe(
      switchMap(errors => {
        if (errors === null) {
          return this.validateUniqueness(groupType, name);
        }

        return of(errors);
      })
    );
  }

  validatePattern(
    groupType: ProductGroupType,
    mode: 'number' | 'name',
    value: string,
    delayMillis = 500
  ): Observable<ValidationErrors | null> {
    if (!(value && groupType?.id)) {
      return of({
        canNotDetermineValidity: true
      });
    }

    return timer(delayMillis).pipe(
      switchMap(() => {
        return this.requestBuilder
          .request(environment.backendUrl, 'productgrouptypes', `${groupType.id}`, 'validate')
          .param(mode, value)
          .get<{ valid: boolean; pattern: string }>()
          .pipe(
            map(({ valid, pattern }) => {
              if (valid) {
                return null;
              }

              pattern =
                mode === 'name'
                  ? `Name does not match the expected pattern: ${pattern}`
                  : `${groupType.name}s must match the pattern: ${pattern}`;

              return {
                pattern
              };
            })
          );
      })
    );
  }

  validateUniqueness(groupType: ProductGroupType, name: string): Observable<ValidationErrors | null> {
    if (!(name && groupType)) {
      return of({
        canNotDetermineUniqueness: true
      });
    }

    return this.data
      .getAll('productgroups', undefined, undefined, undefined, undefined, undefined, [
        new EqualsFilter('groupType', groupType.id),
        new EqualsFilter('name', name)
      ])
      .pipe(
        map(result => {
          if (result.resultsSize > 0) {
            return {
              duplicate: `${groupType.shortName} with the name already exists`
            };
          }

          return null;
        })
      );
  }

  /**
   * Checks whether the BUDI info properties of the items are the same as the BUDI's
   */
  propertyMismatchValidator(
    budiInfo: ProductGroupBudiInfo,
    items: ItemNotDeleted[],
    overwriteItemsPermission = false,
    isEbudi = false
  ) {
    const invalidItems = items
      .map(item => {
        const mismatch: { [key: string]: { property: string; value: boolean } } = {};
        if (item.itemNumber2.materialStatus === 'M4' && !isEbudi) {
          return { id: item.itemNumber, message: 'Item has material status M4 (end-of-life)' };
        }

        if (item.isSterile !== budiInfo.isSterile) {
          mismatch['isSterile'] = { property: 'sterile', value: item.isSterile };
        }

        if (item.active !== budiInfo.active) {
          mismatch['active'] = { property: 'active', value: item.active };
        }

        if (item.administeringMedicine !== budiInfo.administeringMedicine) {
          mismatch['administeringMedicine'] = {
            property: 'admin. medicine',
            value: item.administeringMedicine
          };
        }

        if (item.implantable !== budiInfo.implantable) {
          mismatch['implantable'] = { property: 'implantable', value: item.implantable };
        }

        if (item.measuringFunction !== budiInfo.measuringFunction) {
          mismatch['measuringFunction'] = { property: 'measuring func.', value: item.measuringFunction };
        }

        if (item.reusable !== budiInfo.reusable) {
          mismatch['reusable'] = { property: 'reusable', value: item.reusable };
        }

        if (Object.values(mismatch).length) {
          let message = 'Insufficient rights to add items with discrepancy between (E)BUDI properties';
          if (overwriteItemsPermission) {
            message = `Mismatch between (E)BUDI and item properties (${Object.values(mismatch)
              .map(m => m.property)
              .join(', ')})`;
          }
          return {
            id: item.itemNumber,
            message,
            mismatch
          };
        }
      })
      .filter(Boolean); // filter out any null/undefined values from this array of objects

    return invalidItems.length ? invalidItems : [];
  }

  /**
   * Based on a given intended purpose text, this function returns
   * the id of an existing intended purpose in our database or
   * `undefined` if none can be found.
   *
   * @param intendedPurpose the text received from SAP
   */
  getMatchingIntendedPurpose(intendedPurpose: string): Observable<number | undefined> {
    return this.data
      .getAll('intendedpurposes', undefined, 0, 1, undefined, undefined, [
        new EqualsFilter('intendedPurpose', intendedPurpose)
      ])
      .pipe(map(page => page.results[0]?.id));
  }
}
