import { transferArrayItem } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';
import { Filter } from '@rims/lib';
import { appConfig } from 'src/app/app.constants';
import { difference } from '../../utils/array-utils';
import * as MetadataActions from './metadata.actions';
import { MetadataState, METADATA_INITIAL_STATE } from './metadata.state';

export const metadataReducer = createReducer(
  METADATA_INITIAL_STATE,
  on(MetadataActions.startLoading, state => ({
    ...state,
    loading: true
  })),
  on(MetadataActions.stopLoading, state => ({
    ...state,
    loading: false
  })),
  on(MetadataActions.getViews, state => ({
    ...state,
    loading: true
  })),
  on(MetadataActions.getViewsSuccess, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      loading: false,
      views: {}
    };
    payload.results.forEach(view => {
      newState.views[view.id] = view;
    });
    return newState;
  }),
  on(MetadataActions.getViewConfig, state => ({
    ...state,
    loading: true
  })),
  on(MetadataActions.getViewConfigSuccess, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      views: {
        ...state.views,
        [payload.view.id]: {
          ...state.views[payload.view.id],
          ...payload.view,
          filters: Array.isArray(payload.filters)
            ? [...payload.filters]
            : Array.isArray(state.views[payload.view.id].filters)
            ? [...state.views[payload.view.id].filters]
            : undefined
        }
      },
      loading: false
    };
    return newState;
  }),
  on(MetadataActions.setDraggedColumn, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      draggedColumn: payload
    };
    return newState;
  }),
  on(MetadataActions.clearDraggedColumn, state => {
    const newState: MetadataState = {
      ...state,
      draggedColumn: null
    };
    return newState;
  }),
  on(MetadataActions.resetColumnPreferences, state => {
    const newState: MetadataState = {
      ...state,
      loading: true
    };
    return newState;
  }),
  on(MetadataActions.resetColumnPreferencesSuccess, state => {
    const newState: MetadataState = {
      ...state,
      loading: false
    };
    return newState;
  }),
  on(MetadataActions.openColumnPreferencesDialog, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      columnPreferencesDialog: {
        viewId: payload.viewId,
        available: difference(
          [...state.views[payload.viewId].columns],
          [...state.views[payload.viewId].displayedColumns]
        ),
        selected: [...state.views[payload.viewId].displayedColumns]
      }
    };
    return newState;
  }),
  on(MetadataActions.moveColumn, (state, payload) => {
    const available = [...state.columnPreferencesDialog.available];
    const selected = [...state.columnPreferencesDialog.selected];
    transferArrayItem(
      payload.source === 'available' ? available : selected,
      payload.target === 'available' ? available : selected,
      payload.sourceIndex,
      payload.targetIndex
    );

    if (payload.target === 'selected') {
      const targetCol = { ...selected[payload.targetIndex] };
      targetCol.hide = false;

      const adjacentCols = selected.filter((_, index) => Math.abs(index - payload.targetIndex) <= 1);
      // Depending on the position, change the order of the column
      switch (adjacentCols.length) {
        case 2: {
          switch (payload.targetIndex) {
            case 0:
              targetCol.defaultOrder = adjacentCols[1].defaultOrder - 1;
              break;
            case 1:
              targetCol.defaultOrder = adjacentCols[0].defaultOrder + 1;
              break;
          }
          break;
        }
        case 3: {
          targetCol.defaultOrder = (adjacentCols[0].defaultOrder + adjacentCols[2].defaultOrder) / 2;
        }
      }

      selected[payload.targetIndex] = targetCol;
    }
    if (payload.target === 'available') {
      const targetCol = { ...available[payload.targetIndex] };
      targetCol.hide = true;
      available[payload.targetIndex] = targetCol;
    }

    const newState: MetadataState = {
      ...state,
      columnPreferencesDialog: {
        ...state.columnPreferencesDialog,
        available,
        selected
      }
    };
    return newState;
  }),
  on(MetadataActions.addSelection, (state, payload) => {
    const old = state?.rowSelection[payload.viewId] || [];
    const newSelection = [...new Set([...old, ...payload.selection])];
    const newState: MetadataState = {
      ...state,
      rowSelection: {
        ...state.rowSelection,
        [payload.viewId]: newSelection
      }
    };
    return newState;
  }),
  on(MetadataActions.removeSelection, (state, payload) => {
    const old = state?.rowSelection[payload.viewId] || [];
    const newSelection = old.filter(obj => !payload.selection.includes(obj));
    const newState: MetadataState = {
      ...state,
      rowSelection: {
        ...state.rowSelection,
        [payload.viewId]: newSelection
      }
    };
    return newState;
  }),
  on(MetadataActions.clearSelection, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      rowSelection: {
        ...state.rowSelection,
        [payload.viewId]: []
      }
    };
    window.dispatchEvent(
      new CustomEvent(appConfig.eventNames.RIMS_TABLE_CLEAR_SELECTION, {
        detail: payload
      })
    );
    return newState;
  }),
  on(MetadataActions.getPermissions, state => ({
    ...state,
    loading: true
  })),
  on(MetadataActions.getPermissionsSuccess, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      entities: {},
      loading: false
    };
    payload.results.forEach(permission => {
      newState.entities[permission.entity] = {
        ...state.entities[permission.entity],
        permissions: {
          ...permission
        }
      };
    });
    return newState;
  }),
  on(MetadataActions.getEntities, state => ({
    ...state,
    loading: true
  })),
  on(MetadataActions.getEntitiesSuccess, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      loading: false,
      entities: {}
    };
    payload.results.forEach(entity => {
      newState.entities[entity.id] = entity;
    });
    return newState;
  }),
  on(MetadataActions.getFieldsForEntity, (state, payload) => ({
    ...state,
    entities: {
      ...state.entities,
      [payload.entityId]: {
        ...state.entities[payload.entityId],
        fieldDataRequested: true
      }
    },
    loading: true
  })),
  on(MetadataActions.getFieldsForEntitySuccess, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      loading: false,
      entities: {
        ...state.entities,
        [payload.entityId]: {
          ...state.entities[payload.entityId],
          fields: {}
        }
      }
    };
    payload.page.results.forEach(field => {
      newState.entities[payload.entityId].fields[field.field] = field;
    });
    return newState;
  }),
  on(MetadataActions.getViewGroups, state => ({
    ...state,
    loading: true
  })),
  on(MetadataActions.getViewGroupsSuccess, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      loading: false,
      viewGroups: {}
    };
    payload.results.forEach(group => {
      newState.viewGroups[group.id] = group;
    });
    return newState;
  }),
  on(MetadataActions.addFilters, (state, payload) => {
    const newFilters = [...(state.views[payload.viewId].filters || []), ...payload.filters];
    const uniqueFilters = newFilters.filter((filt, index, self) => {
      const selfIndex = self.findIndex(
        f => f.value == filt.value && f.operator === filt.operator && f.fieldName === filt.fieldName
      );
      return index === selfIndex;
    });

    const newState: MetadataState = {
      ...state,
      views: {
        ...state.views,
        [payload.viewId]: {
          ...state.views[payload.viewId],
          filters: uniqueFilters
        }
      }
    };
    return newState;
  }),
  on(MetadataActions.saveFilter, (state, { viewId, filter }) => {
    let alreadyExists = false;
    const view = state.views[viewId];
    const filters = view.filters.map(f => {
      if (f.fieldName === filter.fieldName) {
        alreadyExists = true;
        return new Filter({
          ...f,
          ...filter
        });
      }

      return f;
    });

    // Check if the filter was updated; if not, add it to the end
    const updatedFilters = alreadyExists ? filters : [...filters, new Filter(filter)];

    return {
      ...state,
      views: {
        ...state.views,
        [viewId]: {
          ...view,
          filters: updatedFilters
        }
      }
    };
  }),
  on(MetadataActions.setFilters, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      views: {
        ...state.views,
        [payload.viewId]: {
          ...state.views[payload.viewId],
          filters: [...(payload.filters || [])]
        }
      }
    };

    return newState;
  }),
  on(MetadataActions.removeFilters, (state, { viewId, filters }) => {
    const newFilters = state.views[viewId].filters.filter(
      f =>
        !filters.some(filt => f.operator === filt.operator && f.value == filt.value && f.fieldName === filt.fieldName)
    );

    const newState: MetadataState = {
      ...state,
      views: {
        ...state.views,
        [viewId]: {
          ...state.views[viewId],
          filters: newFilters
        }
      }
    };
    return newState;
  }),
  on(MetadataActions.resetFilters, (state, payload) => {
    // keep readOnly filters
    const newFilters = state.views[payload.viewId].filters.filter(f => f.readOnly);

    const newState: MetadataState = {
      ...state,
      views: {
        ...state.views,
        [payload.viewId]: {
          ...state.views[payload.viewId],
          filters: newFilters
        }
      }
    };
    return newState;
  }),
  on(MetadataActions.removeColumnSettings, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      views: {
        ...state.views,
        [payload.viewId]: {
          ...state.views[payload.viewId],
          columnIds: null
        }
      }
    };
    return newState;
  }),
  on(MetadataActions.setCheckboxColor, (state, payload) => {
    const newState: MetadataState = {
      ...state,
      checkboxColor: {
        ...state.checkboxColor,
        [payload.viewId]: payload.checkboxColor
      }
    };
    return newState;
  }),
  on(MetadataActions.clearCheckboxColor, state => {
    const newState: MetadataState = {
      ...state,
      checkboxColor: {}
    };
    return newState;
  })
);
