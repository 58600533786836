<rims-header>
  <rims-header-title>Create Container</rims-header-title>
</rims-header>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container my-5">
    <div class="row mb-5">
      <div class="col-12">
        <h2>Basic Information</h2>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Is Advanced?</mat-label>
          <mat-select formControlName="isAdvanced" required>
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['isAdvanced'].hasError('required')">Please specify if this container has an advanced configuration.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Container Type</mat-label>
          <mat-select formControlName="type" required>
            <mat-option *ngFor="let type of containerTypes | async" [value]="type?.id">{{ type?.name }} ({{ type?.shortName }})</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['type'].hasError('required')">Container Type is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 my-1 mb-5 mb-sm-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Size</mat-label>
          <input matInput formControlName="size" type="number" autocomplete="off" required />
          <span matSuffix>{{ selectedUnitSuffix | async }}</span>
          <mat-error *ngIf="form.controls['size'].hasError('required')">Container Size is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Unit</mat-label>
          <mat-select formControlName="substanceUnit" required>
            <mat-option *ngFor="let unit of units | async" [value]="unit?.id">{{ unit?.name }} ({{ unit?.shortName }})</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['substanceUnit'].hasError('required')">The unit is required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" [class.d-none]="!form.get('isAdvanced').value">
      <div class="col-12">
        <h2>Advanced Properties</h2>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Shape</mat-label>
          <mat-select formControlName="shape">
            <mat-option *ngFor="let shape of shapes | async" [value]="shape?.id">{{ shape?.name }} ({{ shape?.shortName }})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Color</mat-label>
          <mat-select formControlName="color">
            <mat-option *ngFor="let color of colors | async" [value]="color?.id">{{ color?.name }} ({{ color?.shortName }})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Cap</mat-label>
          <mat-select formControlName="cap">
            <mat-option *ngFor="let cap of caps | async" [value]="cap?.id">{{ cap?.name }} ({{ cap?.shortName }})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button mat-button type="submit" [disabled]="form.invalid">Create</button>
      </div>
    </div>
  </div>
</form>
