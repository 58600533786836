import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { ChangeContainerTypeDialogComponent } from './components/change-container-type-dialog/change-container-type-dialog.component';
import { ContainersRoutingModule } from './containers-routing.module';
import { ContainerSizePipe } from './pipes/container-size.pipe';
import { ContainerTypeFormatPipe } from './pipes/container-type-format.pipe';
import { ContainerDetailComponent } from './views/container-detail/container-detail.component';
import { CreateContainerComponent } from './views/create-container/create-container.component';

@NgModule({
  declarations: [
    ContainerDetailComponent,
    CreateContainerComponent,
    ChangeContainerTypeDialogComponent,
    ContainerSizePipe,
    ContainerTypeFormatPipe
  ],
  providers: [ContainerSizePipe],
  imports: [CommonModule, ContainersRoutingModule, SharedModule, MaterialModule, ReactiveFormsModule],
  exports: [ContainerTypeFormatPipe]
})
export class ContainersModule {}
