import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../store/store.state';
import { TableColumn } from '../../models/table/table-column.model';
import {
  closeColumnPreferencesDialog,
  moveColumn,
  resetColumnPreferences,
  saveColumns
} from '../../store/metadata/metadata.actions';
import { ColumnPreferencesDialogState } from '../../store/metadata/metadata.state';
import { getQueryParamKey, ViewQueryParam } from '../rims-table/rims-table-query-params';
import { updateBrowserURL } from '../rims-table/rims-table-utils';

export interface RimsTableColumnSortDialogInput {
  viewId: number;
  fixedColumnIds?: number[];
}

@Component({
  selector: 'rims-table-column-sort-dialog',
  templateUrl: './rims-table-column-sort-dialog.component.html',
  styleUrls: ['./rims-table-column-sort-dialog.component.scss']
})
export class RimsTableColumnSortDialogComponent implements OnInit {
  config: Observable<ColumnPreferencesDialogState>;

  constructor(
    private readonly store: Store<AppState>,
    public readonly dialogRef: MatDialogRef<RimsTableColumnSortDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: RimsTableColumnSortDialogInput,
    private readonly route: ActivatedRoute,
    // Router is used by imported function `updateBrowserURL`
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.config = this.store.pipe(select(state => state.metadata.columnPreferencesDialog));
  }

  reset() {
    this.store.dispatch(resetColumnPreferences({ viewId: this.data.viewId }));
    this.dialogRef.close();
  }

  save() {
    this.store.dispatch(saveColumns());
    this.dialogRef.close();
  }

  close() {
    this.store.dispatch(closeColumnPreferencesDialog());
    this.dialogRef.close();
  }

  enterPredicate(drag: CdkDrag, drop: CdkDropList): boolean {
    // We only want to allow columns to be hidden with showalways === false
    if (drop.id.includes('available')) {
      const showAlways = drag.element.nativeElement.getAttribute('rims-column-show-always') as 'true' | 'false';
      return showAlways === 'false';
    }

    return true;
  }

  drop(event: CdkDragDrop<TableColumn>) {
    this.store.dispatch(
      moveColumn({
        source: event.previousContainer.id.includes('available') ? 'available' : 'selected',
        target: event.container.id.includes('available') ? 'available' : 'selected',
        sourceIndex: event.previousIndex,
        targetIndex: event.currentIndex
      })
    );
  }

  removeColumnConfigFromQueryParams() {
    const rawParam = this.route.snapshot.queryParamMap.get(getQueryParamKey(this.data.viewId));
    const param = ViewQueryParam.decode(rawParam);
    param.c = undefined;

    updateBrowserURL.call(this, {
      [getQueryParamKey(this.data.viewId)]: param.encode()
    });
    this.close();
  }
}
