import { Component, Input } from '@angular/core';

@Component({
  selector: 'rims-header-announcement',
  templateUrl: './header-announcement.component.html',
  styleUrls: ['./header-announcement.component.scss']
})
export class HeaderAnnouncementComponent {
  @Input()
  backgroundColor = '#ffe4c1';
}
