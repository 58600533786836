<div class="bg-green header sticky-top" style="top: -5.5rem">
  <div class="container d-flex flex-column py-2">
    <div class="row" *ngIf="(breadcrumb.breadcrumbs | async).length > 1">
      <div class="col d-flex">
        <ng-container *ngFor="let crumb of breadcrumb.breadcrumbs | async; last as isLast">
          <ng-container [ngSwitch]="isLast">
            <a
              *ngSwitchCase="false"
              class="unstyled"
              [routerLink]="crumb | makeAbsoluteLink : (breadcrumb.breadcrumbs | async)"
              (click)="breadcrumb.breakdownUntil(crumb.routerLink)"
            >
              {{ crumb?.label }}
            </a>
            <span *ngSwitchCase="true">{{ crumb?.label }}</span>
          </ng-container>
          <mat-icon *ngIf="!isLast" class="mx-2 breadcrumb-separator">keyboard_arrow_right</mat-icon>
        </ng-container>
      </div>
    </div>
    <!-- Header Title -->
    <div class="row">
      <div class="col">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <ng-content select="rims-header-title"></ng-content>
          <rims-view-menu></rims-view-menu>
        </div>
      </div>
    </div>
  </div>
  <mat-progress-bar *ngIf="loading | async" mode="indeterminate" class="position-absolute"></mat-progress-bar>
</div>
<ng-content select="rims-header-announcement"></ng-content>
