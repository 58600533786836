import { Pipe, PipeTransform } from '@angular/core';
import { getProperty } from '../utils/object-utils';

/**
 * TODO(volkende): Refactor this pipe and `packages/frontend/src/app/modules/shared/utils/object-utils.ts#getProperty`
 */
@Pipe({
  name: 'propertyAccess'
})
export class PropertyAccessPipe implements PipeTransform {
  transform(value: Record<string | number, any>, key: string | number, defaultValue = null): any {
    return PropertyAccessPipe.transform(value, key, defaultValue);
  }

  static transform(value: Record<string | number, any>, key: string | number, defaultValue = null): any {
    if (value) {
      if (typeof key === 'string') {
        return getProperty(key, value, defaultValue);
      }
      return value[key] || defaultValue;
    }
    return defaultValue;
  }
}
