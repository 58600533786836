import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { EntityNameToNewRecordLinkPipe } from './pipes/entity-name-to-new-record-link.pipe';
import { ViewComponent } from './views/view/view.component';

@NgModule({
  declarations: [ViewComponent, EntityNameToNewRecordLinkPipe],
  imports: [CommonModule, RouterModule, SharedModule, MaterialModule, ReactiveFormsModule]
})
export class ViewModule {}
