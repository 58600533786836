import { Component, Input } from '@angular/core';
import { AppEntity } from '../../models/utils/entity.model';

/**
 * This is a wrapper around `rims-label-help-icon`
 * to be able to pass an `AppField` object.
 */
@Component({
  selector: 'rims-field-help-texts',
  templateUrl: './field-help-texts.component.html',
  styleUrls: ['./field-help-texts.component.scss']
})
export class FieldHelpTextsComponent {
  @Input()
  entity: AppEntity;

  @Input()
  field: string;
}
