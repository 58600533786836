import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { View } from '../../shared/models/view/view.model';
import { getOne } from '../../shared/store/data/data.actions';
import { getFieldsForEntityCheck } from '../../shared/store/metadata/metadata.actions';
import { MetadataState } from '../../shared/store/metadata/metadata.state';
import { viewNotFound } from '../../shared/store/router/router.actions';
import { AppState } from '../../store/store.state';
import { DetailViewResolverConfig, ViewPayloads } from '../models/detail-resolver-config';
import { DetailResolverResult } from '../models/detail-resolver-result';

/**
 * This class shall be used to implement/configure resolvers for detail views
 * (e.g. `/views/productgroups/187`).
 *
 * @see src/app/modules/product-groups/resolvers/product-group-detail.resolver.ts
 */
export abstract class DetailViewResolver implements Resolve<DetailResolverResult> {
  /**
     * Constructor.
     *
     * @param store An instance of the store
     * @param config Config object which defines the resolved data
     *
     * @example ```
     *
                constructor(
                    readonly store: Store<AppState>,
                ) {
                    super(store, {
                        baseEntityName: 'PRODUCTGROUPS',
                        expand: [
                            'coe',
                            'producttype',
                            'intendedpurpose',
                            'specialdevicetype',
                            'systemprocedurepacktype',
                            'multicomponenttype',
                            'grouptype'
                        ],
                        viewPayloads: (id, metadata) => {
                            const views = Object.keys(metadata.views).map(key => metadata.views[key]);
                            const entities = Object.keys(metadata.entities).map(key => metadata.entities[key]);
                            const itemsEntity = entities.find(e => e.name === 'ITEMS');
                            const itemView = views.find(view => (view.entity as number) === itemsEntity.id);
                            return {
                                'item': {
                                  viewId: itemView.id
                                }
                              };
                        }
                    })
                }
     * ```
     */
  constructor(
    protected readonly store: Store<AppState>,
    protected readonly router: Router,
    protected readonly config: DetailViewResolverConfig
  ) {}

  resolve(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<DetailResolverResult> {
    return this.store.pipe(
      select(state => state.metadata),
      filter(metadata => !metadata.loading && !!metadata.views && !!metadata.entities),
      take(1),
      switchMap(metadata => {
        const id = route.paramMap.get('id');

        let viewPayloads: ViewPayloads = {};
        if (this.config.viewPayloads) {
          viewPayloads = this.config.viewPayloads(id, metadata);
        }

        const entityId = this.findEntityId(metadata);
        this.store.dispatch(getFieldsForEntityCheck({ entityId }));

        const dataKey = entityId + '_' + id;
        this.store.dispatch(
          getOne({
            entityId,
            recordId: id,
            expand: this.config.expand
          })
        );

        return this.store.pipe(
          filter(state => {
            let done = true;
            done = done && !!state.data.entries[dataKey] && !state.data.entries[dataKey].loading;
            return done;
          }),
          take(1),
          tap(state => {
            if (state.data.entries[dataKey].page.resultsSize === 0) {
              this.store.dispatch(
                viewNotFound({
                  view: routerState.url,
                  redirectUrl: `view/${route.parent.url[0].path}`
                })
              );
              return of(undefined);
            }
          }),
          switchMap(state => {
            Object.keys(viewPayloads).forEach(key => {
              viewPayloads[key]['browserRefresh'] = !this.router.navigated;
            });

            const result: DetailResolverResult = {
              entityId,
              dataKey,
              expand: this.config.expand,
              viewPayloads
            };

            if (this.config.interceptor) {
              return this.config.interceptor(
                this.config.baseEntityName,
                this.config.expand || [],
                id,
                result,
                state.data
              );
            }
            return of(result);
          })
        );
      })
    );
  }

  /**
   * Returns the entity id associated with a view.
   *
   * @param view a view object
   */
  protected getEntityId(view: View): number {
    if (typeof view.entity === 'number') {
      return view.entity;
    } else {
      return view.entity.id;
    }
  }

  /**
   * Tries to find the entity id based on the given metadata state and the resolver config.
   *
   * @param metadata the current metadata state
   */
  private findEntityId(metadata: MetadataState): number {
    try {
      return Object.keys(metadata.entities)
        .map(key => metadata.entities[key])
        .find(entity => entity.name === this.config.baseEntityName).id;
    } catch (e) {
      throw new Error(
        `[DetailViewResolver] Entity ID was not found by entity name "${this.config.baseEntityName}".
                Please check your config! You can find the available entity names in Store > Metadata > Entities`
      );
    }
  }
}
