import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intendedPurpose'
})
export class IntendedPurposePipe implements PipeTransform {
  transform(purpose: any): string {
    return `${purpose.id} - ${purpose.intendedPurpose}`;
  }
}
