import { createAction, props } from '@ngrx/store';

const PREFIX = '[Companies]';

interface CompanyPayload {
  name: string;
  vatCode?: string;
}

interface AddressPayload {
  streetName: string;
  streetNumber?: string;
  poBox?: string;
  zipCode?: string;
  state?: string;
  country: number;
  city: string;
}

export interface CreateAddressAndCompanyPayload {
  address: AddressPayload;
  company: CompanyPayload;
}

export interface CreateCompanyPayload {
  address: number;
  company: CompanyPayload;
}

export const createAddressAndCompany = createAction(
  `${PREFIX} Create Address and Company`,
  props<CreateAddressAndCompanyPayload>()
);
export const createCompany = createAction(`${PREFIX} Create Company`, props<CreateCompanyPayload>());
