import { createAction, props } from '@ngrx/store';

const PREFIX = '[Items]';

export interface OpenChangeItemLifecycleDialogPayload {
  itemBudiInfoId: number;
}
export interface OpenAddRiskClassToItemDialogPayload {
  item: string;
}

export const openChangeItemLifecycleDialog = createAction(
  `${PREFIX} Open Change Item Lifecycle Dialog Dialog`,
  props<OpenChangeItemLifecycleDialogPayload>()
);
export const closeChangeItemLifecycleDialog = createAction(
  `${PREFIX} Close Change item Lifecycle Dialog Dialog`,
  props<OpenChangeItemLifecycleDialogPayload>()
);

export const openAddRiskClassToItemDialog = createAction(
  `${PREFIX} Open Add Risk Class to Item Dialog`,
  props<OpenAddRiskClassToItemDialogPayload>()
);
