import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productGroupOptionFormat'
})
export class ProductGroupOptionFormatPipe implements PipeTransform {
  transform(group: any): string {
    return `${group.number} - ${group.name} (${group.groupType2.shortName})`;
  }
}
