import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { getData } from '../../shared/store/data/data.actions';
import { DataStateEntry } from '../../shared/store/data/data.state';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class CreateContainerResolver implements Resolve<Record<string, DataStateEntry>> {
  private readonly containerTypeKey = 'container_type';
  private readonly containerShapeKey = 'container_shape';
  private readonly containerColorKey = 'container_color';
  private readonly containerCapKey = 'container_cap';
  private readonly unitKey = 'unit';

  constructor(readonly store: Store<AppState>) {}

  resolve(): Observable<Record<string, DataStateEntry>> {
    return this.store.pipe(
      select(state => state.data.entries),
      take(1),
      switchMap(data => {
        if (!data[this.containerTypeKey]) {
          this.store.dispatch(
            getData({
              url: 'containertypes',
              key: this.containerTypeKey,
              pageSize: 100
            })
          );
        }
        if (!data[this.containerShapeKey]) {
          this.store.dispatch(
            getData({
              url: 'containershapes',
              key: this.containerShapeKey,
              pageSize: 100
            })
          );
        }
        if (!data[this.containerColorKey]) {
          this.store.dispatch(
            getData({
              url: 'containercolors',
              key: this.containerColorKey,
              pageSize: 100
            })
          );
        }
        if (!data[this.containerCapKey]) {
          this.store.dispatch(
            getData({
              url: 'containercaps',
              key: this.containerCapKey,
              pageSize: 100
            })
          );
        }
        if (!data[this.unitKey]) {
          this.store.dispatch(
            getData({
              url: 'units',
              key: this.unitKey,
              pageSize: 100
            })
          );
        }
        return this.store.pipe(
          select(state => state.data.entries),
          filter(entries => {
            let done = true;
            done =
              done &&
              !!entries[this.containerTypeKey] &&
              !entries[this.containerTypeKey].loading &&
              !!entries[this.containerColorKey] &&
              !entries[this.containerColorKey].loading &&
              !!entries[this.containerCapKey] &&
              !entries[this.containerCapKey].loading &&
              !!entries[this.unitKey] &&
              !entries[this.unitKey].loading &&
              !!entries[this.containerShapeKey] &&
              !entries[this.containerShapeKey].loading;
            return done;
          }),
          take(1)
        );
      })
    );
  }
}
