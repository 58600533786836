import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { capitalize } from '@rims/lib';
import * as pluralize from 'pluralize';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import { PropertyAccessPipe } from '../../pipes/property-access.pipe';
import { closeRemoveDialog, OpenRemoveDialogPayload } from '../../store/shared/shared.actions';

enum FieldNames {
  /**
   * The user must confirm the change.
   */
  CONFIRM = 'CONFIRM',
  /**
   * The user might need to confirm additional side effects that come with this change
   */
  ADDITIONAL_CONFIRM = 'ADDITIONAL_CONFIRM'
}

@Component({
  selector: 'rims-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent implements OnInit {
  readonly fieldNames = FieldNames;
  readonly name = this.data.displayName ?? this.data.entityName;
  readonly relation = this.data.relation ?? this.data.entityName + '2';
  readonly identifier = this.data.dataId ?? 'id';
  readonly actionTxt = this.data.actionTxt ?? 'remove';

  form = new UntypedFormGroup({
    [FieldNames.CONFIRM]: new UntypedFormControl(false, Validators.requiredTrue)
  });

  remaining = 0;
  entities: Observable<any>;

  constructor(
    public readonly dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: OpenRemoveDialogPayload,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (this.data.additionalConfirm) {
      this.form.addControl(FieldNames.ADDITIONAL_CONFIRM, new UntypedFormControl(false, Validators.requiredTrue));
    }

    this.entities = this.store.pipe(
      select(state => state.data.entries[this.data.key].page.results),
      map(elements => {
        return elements.filter(element =>
          this.data.entityIds.includes(PropertyAccessPipe.transform(element, this.identifier))
        );
      }),
      map(elements => elements.slice(0, 10)),
      tap(elements => {
        this.remaining = this.data.entityIds.length - elements.length;
      })
    );
  }

  getTitle() {
    const name = this.data.entityIds.length > 1 ? pluralize(this.name) : pluralize.singular(this.name);
    const action = this.getActionTxt(true);
    return `${action} ${capitalize(name)}`;
  }

  getRemoveFromTxt() {
    const amount = this.data.entityIds.length;
    const amountTxt = amount > 1 ? `These ${pluralize(this.name)}` : `This ${pluralize.singular(this.name)}`;

    return `${amountTxt} will be ${this.getActionTxt()}d${this.getFromTxt()}.`;
  }

  getIgnoreTxt() {
    const referenceAmount = this.data.referenceIds?.length;
    const referenceTxt =
      referenceAmount === 1
        ? `this ${pluralize.singular(this.data.referenceName)} is`
        : `some of these ${pluralize(this.data.referenceName)} are`;
    const pronoun = referenceAmount === 1 ? 'it' : 'they';
    const article = this.data.entityIds.length === 1 ? 'this' : 'a';

    return `If ${referenceTxt} not linked to ${article} ${this.name}, ${pronoun} will be ignored.`;
  }

  getConfirmTxt() {
    const amount = this.data.entityIds.length;
    const amountTxt =
      amount > 1 ? `these <b>${amount}</b> ${pluralize(this.name)}` : `this ${pluralize.singular(this.name)}`;

    return `I confirm that I want to ${this.getActionTxt()} ${amountTxt}${this.getFromTxt()}.`;
  }

  getActionTxt(capital = false) {
    if (capital) return capitalize(this.actionTxt);
    return this.actionTxt;
  }

  private getFromTxt() {
    if (!this.data.fromName) return '';
    let fromTxt = ` from the ${this.data.fromName}`;
    if (this.data.referenceName) {
      const referenceAmount = this.data.referenceIds?.length;
      const referenceName =
        referenceAmount === 1 ? pluralize.singular(this.data.referenceName) : pluralize(this.data.referenceName);
      fromTxt = referenceAmount
        ? ` from ${referenceAmount} selected ${referenceName} in this ${this.data.fromName}`
        : ` from all ${referenceName} in this ${this.data.fromName}`;
    }

    return fromTxt;
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.store.dispatch(closeRemoveDialog({ ...this.data }));
    this.dialogRef.close();
  }
}
