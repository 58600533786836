import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { select } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DetailViewComponent } from 'src/app/modules/detail-view/views/detail-view/detail-view.component';
import { PropertyEditMenuComponent } from 'src/app/modules/shared/components/rims-property-edit-menu/property-edit-menu.component';
import { openAddDialog, openRemoveDialog } from '../../../shared/store/shared/shared.actions';
import { NumberValidator } from '../../../shared/validators/number.validator';
import {
  ChangeContainerTypeDialogComponent,
  ChangeContainerTypeDialogConfig
} from '../../components/change-container-type-dialog/change-container-type-dialog.component';

@Component({
  selector: 'rims-container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss']
})
export class ContainerDetailComponent extends DetailViewComponent<any> implements OnInit, AfterViewInit {
  @ViewChild(PropertyEditMenuComponent, { static: false })
  editPropertyMenu: PropertyEditMenuComponent;

  @ViewChild('containerTypeSelect')
  containerTypeSelect: MatSelect;

  readonly hasProductOwnerPermission = this.store.pipe(
    select(state => state.user.productOwnerPermissions?.container),
    switchMap(containerIds => {
      if (!Array.isArray(containerIds)) {
        return of(false);
      }

      return this.result.pipe(map(res => containerIds.includes(`${res.id}`)));
    })
  );

  readonly containerTypeFilters = this.result.pipe(
    map(container => {
      const isAdvanced = container.type.isAdvanced;
      return [new EqualsFilter('isAdvanced', isAdvanced)];
    })
  );

  containerSizeValidators = [Validators.required, this.validator.positiveNumberWithTwoDecimalPlaces()];

  selectedContainerProductionSites: Observable<number[]>;

  constructor(injector: Injector, private readonly dialog: MatDialog, private validator: NumberValidator) {
    super(injector);
  }

  ngOnInit() {
    this.selectedContainerProductionSites = combineLatest([
      this.result,
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(([res, resolveResult, selection]) => {
        return selection[resolveResult?.viewPayloads['container_production_site']?.viewId] as number[];
      })
    );
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.handleTypeChange();
  }

  private handleTypeChange() {
    this.containerTypeSelect.valueChange
      .asObservable()
      .pipe(
        withLatestFrom(this.storeData.pipe(select(state => state.container_type)), this.entity, this.result),
        switchMap(([isAdvanced, types, entity, result]) => {
          return this.dialog
            .open<ChangeContainerTypeDialogComponent, ChangeContainerTypeDialogConfig>(
              ChangeContainerTypeDialogComponent,
              {
                panelClass: 'custom-dialog-width',
                data: {
                  newIsAdvancedValue: isAdvanced,
                  availableTypes: types.page.results.filter(type => type.isAdvanced === isAdvanced),
                  containerEntityId: entity.id,
                  containerId: result.id
                },
                disableClose: true
              }
            )
            .afterClosed();
        }),
        tap(_ => location.reload()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  openAddProductionSiteDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openAddDialog({
              key: 'container_production_site',
              entityName: 'company',
              displayName: 'production site',
              toName: 'container',
              toId: res.id,
              searchLabel: 'Search by Company Name or Address',
              notFoundHint: 'No company found. Try again with another query.',
              duplicateHint: 'This production site is already set for this container.',
              expand: ['address.country'],
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveProductionSite() {
    combineLatest([this.result, this.resolveResult, this.selectedContainerProductionSites])
      .pipe(
        take(1),
        tap(([res, resolveResult, containerProductionSiteIds]) => {
          const viewId = resolveResult?.viewPayloads['container_production_site']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'container_production_site',
              entityName: 'company',
              displayName: 'production site',
              entityIds: containerProductionSiteIds,
              fromName: 'container',
              fromId: res.id
            })
          );
        })
      )
      .subscribe();
  }
}
