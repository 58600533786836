<rims-header>
  <rims-header-title>Create Product Group</rims-header-title>
</rims-header>

<form [formGroup]="form">
  <div class="container my-5">
    <div class="row mb-4">
      <div class="col-12">
        <h2>Group Type and Number</h2>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Product Group Type</mat-label>
          <mat-select formControlName="groupType" required>
            <mat-option *ngFor="let type of groupTypes" [value]="type?.id">{{ type?.name }} ({{ type?.shortName }})</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['groupType'].invalid">Group Type is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-progress-bar mode="indeterminate" class="position-absolute" style="width: calc(100% - 30px)" *ngIf="form.controls[formFieldName.NUMBER].pending"></mat-progress-bar>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Number</mat-label>
          <input matInput formControlName="number" type="text" autocomplete="off" required />
          <mat-icon
            [ngSwitch]="form.controls[formFieldName.PRODUCT_GROUP_ALREADY_EXISTS].value"
            [color]="form.controls[formFieldName.PRODUCT_GROUP_ALREADY_EXISTS].value === true ? 'warn' : 'primary'"
            matSuffix
          >
            <ng-container *ngSwitchCase="true">warning</ng-container>
            <ng-container *ngSwitchCase="false">done</ng-container>
          </mat-icon>
          <mat-hint *ngIf="form.controls[formFieldName.PRODUCT_GROUP_ALREADY_EXISTS].value === true">
            This group already exists:
            <a class="unstyled d-inline-flex align-items-end" target="_blank" [href]="'/view/productgroups/' + (existingProductGroupsResult | async)?.results[0]?.id">
              {{ (existingProductGroupsResult | async)?.results[0]?.name }}
              <mat-icon class="ml-1">open_in_new</mat-icon>
            </a>
          </mat-hint>
          <mat-error *ngIf="form.controls['number'].hasError('canNotDetermineValidity')">Please select a group type to validate the group number.</mat-error>
          <mat-error *ngIf="form.controls['number'].hasError('required')">Please enter a valid {{ selectedGroupType | propertyAccess : 'shortName' : 'group' }} number</mat-error>
          <mat-error *ngIf="form.controls['number'].hasError('pattern')">{{ form.controls['number'].errors.pattern }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4" *ngIf="form.controls[formFieldName.SECTION_ONE_FINISHED].value && isBudi">
      <div class="col-12">
        <h2>SAP Material Information</h2>
      </div>
      <div class="col-12 col-md-6 d-flex flex-column">
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI_NOT_FOUND].value">
          <p class="text-danger">The BUDI could not be found in SAP. Please check the source system and try again.</p>
        </ng-container>
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR].value">
          <p class="text-danger">An error occurred while communicating with SAP.</p>
          <small *ngIf="form.value[formFieldName.EXT_SAP_BASIC_UDI_ERROR_MESSAGE]">{{ form.value[formFieldName.EXT_SAP_BASIC_UDI_ERROR_MESSAGE] }}</small>
        </ng-container>
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI].value">
          <p>
            <ng-container
              >The following information has been fetched from SAP. Please make sure that they are correct before continuing. If they are not correct, please correct them in SAP
              first and create the BUDI afterwards.</ng-container
            >
          </p>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Basic UDI DI</mat-label>
            <input matInput disabled [value]="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.basicUdiDi" />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Basic UDI Name</mat-label>
            <input matInput disabled [value]="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.basicUdiName" />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Division</mat-label>
            <input matInput disabled [value]="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.division | sapDivisionToRimsDivision | async | propertyAccess : 'name'" />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Status</mat-label>
            <input
              matInput
              disabled
              [value]="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.materialStatus + ' / ' + form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.distributionStatus"
            />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-form-field
            *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.classValues?.lifecycle"
            class="w-100"
            [class.mb-3]="form.controls[formFieldName.EXT_SAP_BASIC_UDI].hasError('lifecycle')"
            appearance="fill"
          >
            <mat-label>Lifecycle</mat-label>
            <input matInput disabled [value]="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.classValues.lifecycle | sapLifecycleToRimsLifecycle" />
            <mat-icon
              [color]="form.controls[formFieldName.EXT_SAP_BASIC_UDI].hasError('lifecycle') ? 'warn' : 'primary'"
              [style.opacity]="form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value || form.controls[formFieldName.EXT_SAP_BASIC_UDI].hasError('lifecycle') ? '1' : '0'"
              matSuffix
            >
              {{ form.controls[formFieldName.EXT_SAP_BASIC_UDI].hasError('lifecycle') ? 'error' : 'done' }}
            </mat-icon>
            <mat-hint *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI].hasError('lifecycle')" class="color-dark-red">
              Only BUDIs with a lifecycle of
              <span>{{ '01' | sapLifecycleToRimsLifecycle }}</span>
              can be added.
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="fill" *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.intendedPurpose">
            <mat-label>Intended Purpose</mat-label>
            <textarea rows="6" matInput disabled [value]="form.controls[formFieldName.EXT_SAP_BASIC_UDI]?.value?.intendedPurpose"></textarea>
            <mat-icon
              color="primary"
              *ngIf="form.controls[formFieldName.INTENDED_PURPOSE].value"
              matSuffix
              [matTooltip]="
                'This Text exactly matched one of the Intended Purposes in our database (#' +
                form.controls[formFieldName.INTENDED_PURPOSE].value +
                '). We can therefore use it as is.'
              "
            >
              done
            </mat-icon>
            <mat-icon
              color="primary"
              *ngIf="!form.controls[formFieldName.INTENDED_PURPOSE].value && form.controls[formFieldName.INTENDED_PURPOSE_RESOLVED].value"
              matSuffix
              [matTooltip]="'All issues regarding the intended purpose have been resolved.'"
            >
              done
            </mat-icon>
            <mat-icon
              color="warn"
              *ngIf="
                !form.controls[formFieldName.INTENDED_PURPOSE_RESOLVED].value &&
                !form.controls[formFieldName.INTENDED_PURPOSE].value &&
                form.controls[formFieldName.INTENDED_PURPOSE_CHECKED].value
              "
              matSuffix
            >
              warning
            </mat-icon>
          </mat-form-field>
          <div *ngIf="!form.controls[formFieldName.INTENDED_PURPOSE].value && form.controls[formFieldName.INTENDED_PURPOSE_CHECKED].value" class="mb-5">
            <p class="color-dark-red">
              This group already has an Intended Purpose set in SAP, but an exact match could not be found in RIMS. Please choose how you want to proceed.
            </p>
            <p>
              <strong>Discard the Intended Purpose and maintain it later in RIMS</strong>
              <br />
              Choose
              <strong>Discard</strong>
              to ignore the Intended Purpose from SAP.
              <br />
              Click
              <a href="/view/intendedpurposes" target="_blank" class="unstyled d-inline-flex">
                here
                <mat-icon style="font-size: 100%; line-height: 100%; height: 1rem; width: 1rem" class="align-self-center ml-1">open_in_new</mat-icon>
              </a>
              to view all texts which are available in RIMS.
              <br />
              After the group has been created, you can choose the desired text.
            </p>
            <button class="mb-4" mat-button [disabled]="form.get(formFieldName.INTENDED_PURPOSE_RESOLVED).value" (click)="discardIntendedPurpose()">Discard</button>
            <p>
              <strong>Create a new Intended Purpose</strong>
              <br />
              Choose
              <strong>Create new Intended Purpose</strong>
              to create a new record based on this text.
              <br />
              Afterwards, restart the BUDI creation to make the new text available to the process.
            </p>
            <a class="mb-4" mat-button [disabled]="form.get(formFieldName.INTENDED_PURPOSE_RESOLVED).value" [href]="createNewIntendedPurposeLink" target="_blank">
              Create new Intended Purpose
            </a>
          </div>
          <ng-container *ngIf="desiredExtSapItemCount">
            <div class="d-flex justify-content-between pb-2">
              <mat-form-field style="width: 90%" appearance="fill">
                <mat-label>Items</mat-label>
                <input matInput disabled [value]="'This BUDI contains ' + desiredExtSapItemCount + ' item' + (desiredExtSapItemCount === 1 ? '' : 's') + '.'" />
                <mat-hint>Since e.g. end-of-life items are excluded, the final amount may be smaller.</mat-hint>
                <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
              </mat-form-field>
              <button style="margin-top: 0.5rem" mat-icon-button (click)="showBudiMaterialsDialog()" matTooltip="View Items">
                <mat-icon>list</mat-icon>
              </button>
            </div>
          </ng-container>
          <mat-checkbox *ngIf="!form.value[formFieldName.SECTION_TWO_FINISHED]" [formControlName]="formFieldName.EXT_SAP_BASIC_UDI_CHECKED">
            The information is correct and I want to sync this BUDI to RIMS
          </mat-checkbox>
        </ng-container>
      </div>
    </div>
    <div class="row mb-4" *ngIf="form.controls[formFieldName.SECTION_TWO_FINISHED].value">
      <div class="col-12">
        <h2>Additional Information</h2>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Product Type</mat-label>
          <mat-select formControlName="productType" required>
            <mat-option *ngFor="let type of productTypes | async" [value]="type?.id">
              {{ type?.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['productType'].invalid">Product Type is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-progress-bar mode="indeterminate" class="position-absolute" style="width: calc(100% - 30px)" *ngIf="form.pending"></mat-progress-bar>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Group Name</mat-label>
          <input matInput formControlName="name" type="text" autocomplete="off" required />
          <mat-hint *ngIf="form.controls['name'].hasError('duplicate')">{{ form.controls['name'].errors.duplicate }}</mat-hint>
          <mat-error *ngIf="form.controls['name'].hasError('required')">Name is required</mat-error>
          <mat-error *ngIf="form.controls['name'].hasError('pattern')">{{ form.controls['name'].errors.pattern }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 my-1">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>CoE</mat-label>
          <mat-select formControlName="coe" required>
            <mat-option *ngFor="let coe of coes | async" [value]="coe?.id">
              {{ coe | coeOptionFormat }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['coe'].invalid">CoE is required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-4 pb-5">
      <div class="col">
        <button
          data-submit-button="1"
          mat-button
          type="button"
          *ngIf="!form.controls[formFieldName.SECTION_ONE_FINISHED].value"
          [disabled]="!form.controls[formFieldName.SECTION_ONE_VALID].value"
          (click)="nextStage()"
        >
          Next
        </button>
        <button
          mat-button
          *ngIf="form.controls[formFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR].value || form.controls[formFieldName.EXT_SAP_BASIC_UDI_NOT_FOUND].value"
          (click)="retry()"
        >
          Retry
        </button>
        <button
          data-submit-button="3"
          mat-button
          type="button"
          *ngIf="
            !form.controls[formFieldName.SECTION_TWO_FINISHED].value &&
            form.controls[formFieldName.SECTION_ONE_FINISHED].value &&
            form.controls[formFieldName.EXT_SAP_BASIC_UDI].value
          "
          [disabled]="
            !form.controls[formFieldName.EXT_SAP_BASIC_UDI_CHECKED].value ||
            form.controls[formFieldName.EXT_SAP_BASIC_UDI].invalid ||
            !form.controls[formFieldName.INTENDED_PURPOSE_RESOLVED].value
          "
          (click)="nextStage()"
        >
          Next
        </button>
        <button
          data-submit-button="4"
          mat-button
          type="button"
          *ngIf="form.controls[formFieldName.SECTION_TWO_FINISHED].value"
          [disabled]="form.invalid || form.pending"
          (click)="nextStage()"
        >
          Create Product Group
        </button>
      </div>
    </div>
  </div>
</form>
