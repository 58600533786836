import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from 'src/app/app.constants';
import { BreadcrumbService } from '../breadcrumb/services/breadcrumb.service';
import { User } from '../shared/models/user/user.model';
import { AppViewGroup } from '../shared/models/view/view-group.model';
import { View } from '../shared/models/view/view.model';
import { startLoading, stopLoading } from '../shared/store/metadata/metadata.actions';
import { logout } from '../shared/store/user/user.actions';
import { loggedInUser } from '../shared/store/user/user.state';
import { AppState } from '../store/store.state';

@Component({
  selector: 'rims-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private highlightViewPaths = [
    {
      route: '/view/productgroups',
      description: 'RMFs, BUDIs and Solutions'
    },
    {
      route: '/view/items',
      description: 'Individual materials and products'
    },
    {
      route: '/view/containers',
      description: 'Containers used in Solutions'
    },
    {
      route: '/view/documents',
      description: 'Certificates and other documents'
    },
    {
      route: '/view/actors',
      description: 'Companies and their legal roles'
    }
  ];

  loggedInUser: Observable<User>;
  views: Observable<View[]>;
  viewGroups: Observable<Record<string, AppViewGroup>>;
  highlightViews: Observable<View[]>;

  @ViewChildren('menu')
  menus: QueryList<MatMenu>;
  menusArr: MatMenu[];
  private menusSub: Subscription;

  isRedirecting = false;

  constructor(
    private readonly store: Store<AppState>,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig,
    private readonly breadcrumb: BreadcrumbService
  ) {}

  ngOnInit() {
    this.loggedInUser = this.store.pipe(select(loggedInUser));
    this.views = this.store.pipe(
      select(state => state.metadata.views),
      withLatestFrom(this.loggedInUser),
      filter(([view, user]) => !!view && !!user),
      map(([views, user]) => {
        const viewsArray = [];
        for (const key in views) {
          viewsArray.push(views[key]);
        }
        return viewsArray.filter(view => user.role.permissionLevel >= view.role.permissionLevel);
      })
    );
    this.viewGroups = this.store.pipe(select(state => state.metadata.viewGroups));
    this.highlightViews = this.views.pipe(
      map(views => {
        const highLightViews = this.highlightViewPaths.map(path => {
          return {
            ...views.find(view => view.route === path.route),
            description: path.description
          };
        });
        return highLightViews;
      })
    );

    if (sessionStorage.getItem(this.config.storageKeys.REDIRECT_PATH)) {
      this.store.dispatch(startLoading());
      this.isRedirecting = true;
    }

    this.breadcrumb.clear();
  }

  ngAfterViewInit() {
    this.initViewMenus();
  }

  ngOnDestroy() {
    this.store.dispatch(stopLoading());
    this.menusSub.unsubscribe();
  }

  logout() {
    this.store.dispatch(logout());
  }

  private initViewMenus() {
    this.menusSub = this.menus.changes
      .pipe(
        // When navigating to the view for the 2nd, 3rd, ... time, there will be no change,
        // so we manually create the event
        startWith(undefined)
      )
      .subscribe(_ => {
        const menus = this.menus.toArray();
        if (Array.isArray(menus)) {
          requestAnimationFrame(() => {
            this.menusArr = menus;
          });
        }
      });
  }

  public greet() {
    const hourOfDay = new Date().getHours();
    if (hourOfDay >= 5 && hourOfDay < 12) {
      return 'Good morning';
    } else if (hourOfDay >= 12 && hourOfDay < 18) {
      return 'Good afternoon';
    } else if (hourOfDay >= 18 && hourOfDay < 23) {
      return 'Good evening';
    } else {
      return 'Hello';
    }
  }
}
