import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Filter, FilterOperator } from '@rims/lib';
import { DetailViewResolver } from '../../detail-view/resolvers/detail-view.resolver';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class RoleDetailResolver extends DetailViewResolver {
  constructor(store: Store<AppState>, router: Router) {
    super(store, router, {
      baseEntityName: 'app_role',
      viewPayloads: (id, metadata) => {
        const views = Object.keys(metadata.views).map(key => metadata.views[key]);
        const usersView = views.find(view => view.route.endsWith('/users'));
        return {
          user: {
            viewId: usersView.id,
            filters: [
              new Filter({
                fieldName: 'role',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          }
        };
      }
    });
  }
}
