<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title>Export Data: {{ (view | async)?.displayName }}</h1>
    </div>
  </div>
  <div class="row pt-2 mb-4">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Format</mat-label>
          <mat-select [formControlName]="fieldNames.FORMAT">
            <mat-option value="csv">CSV</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Which records to export?</mat-label>
          <mat-select [formControlName]="fieldNames.MODE">
            <mat-option [value]="exportMode.EXPORT_ALL" *ngIf="data.allowExportAll">Every available record</mat-option>
            <mat-option [value]="exportMode.CURRENT_PAGE">Currently visible records</mat-option>
            <mat-option [value]="exportMode.IGNORE_PAGING" [disabled]="!(data.viewParam.f || data.viewParam.q)">Every record that matches filters</mat-option>
          </mat-select>
        </mat-form-field>
        <div [class.mt-3]="form.get(fieldNames.MODE).value !== exportMode.EXPORT_ALL">
          <div class="row" @openClose *ngIf="data.viewParam.o">
            <div class="col-5 col-md-3 font-weight-bold">Order by</div>
            <div class="col">{{ data.viewParam | viewParam : data.viewId : 'orderBy' | async }}</div>
          </div>
          <div class="row" @openClose *ngIf="form.get(fieldNames.MODE).value === exportMode.CURRENT_PAGE">
            <div class="col-5 col-md-3 font-weight-bold">Page</div>
            <div class="col-7 col-md-9">{{ data.viewParam | viewParam : data.viewId : 'page' | async }}</div>
            <div class="col-5 col-md-3 font-weight-bold">Items per Page</div>
            <div class="col">{{ data.viewParam | viewParam : data.viewId : 'pageSize' | async }}</div>
          </div>
          <ng-container *ngIf="form.get(fieldNames.MODE).value !== exportMode.EXPORT_ALL">
            <div class="row" @openClose *ngIf="data.viewParam.f">
              <div class="col-5 col-md-3 font-weight-bold">Filter</div>
              <div class="col">{{ data.viewParam | viewParam : data.viewId : 'filter' | async }}</div>
            </div>
            <div class="row" @openClose *ngIf="data.viewParam.q">
              <div class="col-5 col-md-3 font-weight-bold">Query</div>
              <div class="col">{{ data.viewParam | viewParam : data.viewId : 'query' | async }}</div>
            </div>
          </ng-container>
        </div>
        <button type="submit" hidden #button></button>
      </form>
    </div>
  </div>

  <mat-dialog-actions class="p-0" align="end">
    <button mat-button [attr.e2e-id]="'submit-button'" (click)="button.click()" [disabled]="form.invalid">Export</button>
    <button mat-button [attr.e2e-id]="'close-button'" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
