import { Pipe, PipeTransform } from '@angular/core';
import { SelectExpression } from './property-edit-menu-select-value.pipe';

export const coeAndDivision: SelectExpression = coe => {
  let str = `${coe.name}`;
  const division = coe.division?.shortName;
  if (division) {
    str += ` (${division})`;
  }
  return str;
};

@Pipe({
  name: 'coeOptionFormat'
})
export class CoeOptionFormatPipe implements PipeTransform {
  transform(coe: any): string {
    return coeAndDivision(coe);
  }
}
