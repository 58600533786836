import { Pipe, PipeTransform } from '@angular/core';
import { AppViewGroup } from '../models/view/view-group.model';
import { View } from '../models/view/view.model';

@Pipe({
  name: 'filterViews'
})
export class FilterViewsPipe implements PipeTransform {
  transform(views: View[], group: AppViewGroup, returnViewsWithNoGroup = false): View[] {
    if (returnViewsWithNoGroup) {
      return views?.filter(view => !view.hide).filter(view => !view.group);
    }

    return views
      ?.filter(view => !view.hide)
      .filter(view => {
        if (!view.group) {
          return false;
        }
        if (typeof view.group === 'number') {
          return view.group === group.id;
        } else {
          return view.group.id === group.id;
        }
      });
  }
}
