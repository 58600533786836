<div class="container-fluid mb-3 drag-boundary" [class.fixed-columns]="data.fixedColumnIds">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title>Configure columns</h1>
    </div>
  </div>
  <div class="row pt-2 mb-4" *ngIf="data.fixedColumnIds">
    <div class="col">
      <p>
        The column settings for this table have been provided via URL.
        <br />
        You can enable manual column controls to define your own preferences for this table.
      </p>
      <button mat-button (click)="removeColumnConfigFromQueryParams()">Enable manual column control</button>
    </div>
  </div>
  <div class="row pt-2 mb-4" [class.d-none]="data.fixedColumnIds">
    <div class="col-12 col-md-6">
      <h2 class="columns-headline mb-2">Available Columns</h2>

      <div
        cdkDropList
        #availableList="cdkDropList"
        id="cdk-drop-list-available"
        [attr.e2e-id]="'Available Columns'"
        [cdkDropListData]="(config | async).available"
        [cdkDropListConnectedTo]="[selectedList]"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="enterPredicate"
      >
        <div
          *ngFor="let item of (config | async).available"
          cdkDrag
          [cdkDragDisabled]="data.fixedColumnIds"
          cdkDragBoundary=".drag-boundary"
          class="drag-container d-flex align-items-center"
          [attr.rims-column-show-always]="item?.showAlways"
          [attr.e2e-id]="item?.field2.displayName + ' - Dialog'"
        >
          <ng-container [ngSwitch]="item?.showAlways">
            <div *ngSwitchCase="false" class="d-flex py-2 cursor-drag">
              <mat-icon class="lock-icon color-grey">drag_handle</mat-icon>
              <span class="column-name ml-3">{{ item?.field2.displayName }}</span>
            </div>
            <div *ngSwitchCase="true" class="d-flex py-2 cursor-drag">
              <mat-icon class="lock-icon color-grey">lock</mat-icon>
              <span class="column-name">{{ item?.field2.displayName }}</span>
            </div>
          </ng-container>
        </div>
        <span *ngIf="(config | async).available.length === 0" class="font-italic">No columns available</span>
      </div>
    </div>

    <div class="col-12 col-md-6 mt-3 mt-md-0">
      <h2 class="columns-headline mb-2">Selected Columns</h2>

      <div
        cdkDropList
        #selectedList="cdkDropList"
        id="cdk-drop-list-selected"
        [attr.e2e-id]="'Selected Columns'"
        [cdkDropListData]="(config | async).selected"
        [cdkDropListConnectedTo]="[availableList]"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let item of (config | async).selected"
          cdkDrag
          [cdkDragDisabled]="data.fixedColumnIds"
          cdkDragBoundary=".drag-boundary"
          [attr.rims-column-show-always]="item?.showAlways"
          [attr.e2e-id]="item?.field2.displayName + ' - Dialog'"
        >
          <ng-container [ngSwitch]="item?.showAlways">
            <div *ngSwitchCase="false" class="d-flex py-2 cursor-drag">
              <mat-icon class="lock-icon color-grey">drag_handle</mat-icon>
              <span class="column-name ml-3">{{ item?.field2.displayName }}</span>
            </div>
            <div *ngSwitchCase="true" class="d-flex py-2 cursor-drag">
              <mat-icon class="lock-icon color-grey">lock</mat-icon>
              <span class="column-name ml-3">{{ item?.field2.displayName }}</span>
            </div>
          </ng-container>
        </div>
        <span *ngIf="(config | async).selected.length === 0" class="font-italic">No columns selected</span>
      </div>
    </div>
  </div>

  <mat-dialog-actions class="row">
    <div class="col d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex justify-content-end">
        <button mat-button [attr.e2e-id]="'reset-button'" (click)="reset()" *ngIf="!data.fixedColumnIds">Restore Defaults</button>
      </div>
      <div class="mt-3 mt-md-0 d-flex justify-content-end">
        <button mat-button [attr.e2e-id]="'save-button'" (click)="save()" *ngIf="!data.fixedColumnIds">Save columns</button>
        <button mat-button [attr.e2e-id]="'close-button'" (click)="close()">Cancel</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
