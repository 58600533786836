<mat-toolbar color="primary">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>RIMS</h1>
      </div>
    </div>
  </div>
</mat-toolbar>
<div class="container py-5">
  <div class="row py-5">
    <div class="col py-5">
      <h1 class="color-green">Invalid Login Attempt</h1>
      <p class="color-grey">The login token you have provided is not compatible with our login mechanism. Please contact your administrator to get assistance.</p>
    </div>
  </div>
</div>
