import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forceProductOwnerPermission'
})
export class ForceProductOwnerPermissionPipe implements PipeTransform {
  /**
   * If the user is considiered a product owner, than we positively
   * force the permission in the `rimsRequirePermission` directive.
   *
   * Otherwise, we leave it unset to use the default directive behaviour.
   */
  transform(hasProductOwnerPermission: boolean): undefined | true {
    return hasProductOwnerPermission ? true : undefined;
  }
}
