import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoutes } from '../../app-routing.module';
import { NumericalIdGuard } from '../shared/guards/numerical-id.guard';
import { ViewByUrlParamResolver } from '../view/resolvers/view-by-url-param.resolver';
import { ViewComponent } from '../view/views/view/view.component';
import { ContainerDetailResolver } from './resolvers/container-detail.resolver';
import { CreateContainerResolver } from './resolvers/create-container.resolver';
import { ContainerDetailComponent } from './views/container-detail/container-detail.component';
import { CreateContainerComponent } from './views/create-container/create-container.component';

const routes: CustomRoutes = [
  {
    path: '',
    component: ViewComponent,
    resolve: {
      result: ViewByUrlParamResolver
    },
    data: {
      shouldDisplayDetailLink: true,
      shouldDisplayExportDialog: true,
      shouldDisplayDeleteAction: true,
      shouldDisplayCheckboxes: true,
      checkBoxesProperty: 'id',
      createNewRecordLink: {
        entityName: 'container'
      },
      filterMenus: [
        { fieldName: 'type.name', displayName: 'Type' },
        { fieldName: 'size', displayName: 'Size' },
        { fieldName: 'substanceUnit.name', displayName: 'Unit' },
        {
          name: 'Advanced',
          isGroup: true,
          filterDefinitions: [
            { fieldName: 'shape.name', displayName: 'Shape' },
            { fieldName: 'color.name', displayName: 'Color' },
            { fieldName: 'cap.name', displayName: 'Cap' }
          ]
        }
      ]
    }
  },
  {
    path: 'new',
    component: CreateContainerComponent,
    resolve: {
      result: CreateContainerResolver
    },
    data: {
      meta: {
        title: 'Create Container'
      }
    }
  },
  {
    path: ':id',
    component: ContainerDetailComponent,
    canActivate: [NumericalIdGuard],
    resolve: {
      result: ContainerDetailResolver,
      _: CreateContainerResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContainersRoutingModule {}
