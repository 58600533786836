import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';
import { getData, GetDataPayload } from '../../shared/store/data/data.actions';
import { noop } from '../../shared/store/shared/shared.actions';
import { getEntityUrlAndView } from '../../shared/utils/entity-utils';
import { AppState } from '../../store/store.state';
import { AddIdentificationNumberDialogComponent } from '../components/add-identification-number-dialog/add-identification-number-dialog.component';
import {
  closeAddIdentificationNumberDialog,
  openAddIdentificationNumberDialog,
  OpenAddIdentificationNumberDialogPayload
} from './actors.actions';

@Injectable()
export class ActorsEffects {
  constructor(
    private readonly actions: Actions,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>
  ) {}

  openAddIdentificationNumberDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openAddIdentificationNumberDialog),
      switchMap(payload => {
        this.dialog.open<AddIdentificationNumberDialogComponent, OpenAddIdentificationNumberDialogPayload>(
          AddIdentificationNumberDialogComponent,
          {
            panelClass: 'custom-dialog-width',
            data: payload
          }
        );
        return of(noop());
      })
    )
  );

  closeAddIdentificationNumberDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeAddIdentificationNumberDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, 'actor_identification_number');
        return this.dataService
          .create(url, {
            actor: payload.actor,
            type: payload.identificationNumberType,
            number: payload.identificationNumber
          })
          .pipe(
            map(() =>
              getData(
                GetDataPayload.fromView({
                  ...view
                })
              )
            )
          );
      })
    )
  );
}
