import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { appConfig } from 'src/app/app.constants';
import { ChangeTrackedEntity } from '../../models/utils/change-tracked-entity.model';
import { AppEntity } from '../../models/utils/entity.model';
import { ManagedPipeToken } from '../../pipes/dynamic.pipe';
import { getProperty } from '../../utils/object-utils';
import { OnMenuOpenData, PropertyEditMenuComponent } from '../rims-property-edit-menu/property-edit-menu.component';

export interface FieldEditEvent {
  currentValue?: string;
}

@Component({
  selector: 'rims-field',
  templateUrl: './rims-field.component.html',
  styleUrls: ['./rims-field.component.scss']
})
export class RimsFieldComponent {
  readonly getProp = getProperty;

  @ViewChild('trigger')
  editTrigger: ElementRef<MatMenuTrigger>;

  @Input()
  obj: ChangeTrackedEntity;

  @Input()
  entity: AppEntity;

  @Input()
  field: string;

  @Input()
  editMenu: PropertyEditMenuComponent;

  /**
   * In case we want to display additional text to the displayName (e.g. 'YYYY-MM-DD' format for a date) once,
   * but not on all occurrences of 'displayName'
   */
  @Input()
  displayNameSuffix = '';

  /**
   * Name of the entity which is used to check if
   * the user has permission to edit this field.
   */
  @Input()
  permissionBaseEntity: string;

  /**
   * Use this field name to check permission instead of
   * `field`.
   *
   * @optional
   */
  @Input()
  permissionField: string;

  /**
   * @optional
   */
  @Input()
  menuConfig: {
    onMenuOpen?: OnMenuOpenData;
    [key: string]: any;
  };

  /**
   * If false, the user has to handle the `edit` output.
   */
  @Input()
  useDefaultPropertyEditMenu = true;

  /**
   * Set this to `true` to use a `<textarea>` instead of
   * an `<input>`.
   *
   * @optional
   */
  @Input()
  useTextArea = false;

  /**
   * When using a `<textarea>`, you can provide a function
   * which defines the number of rows.
   *
   * @optional
   */
  @Input()
  textAreaRows?: (obj: RimsFieldComponent['obj']) => number;

  /**
   * When using a `<textarea>`, you can provide a function
   * which sets the `resize` style property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/resize
   *
   * @optional
   */
  @Input()
  textAreaResize?: (obj: RimsFieldComponent['obj']) => 'vertical' | 'none';

  /**
   * Set this to true when the field is of type boolean.
   */
  @Input()
  isBoolean = false;

  /**
   * Override the label for boolean `true` values.
   */
  @Input()
  trueLabel: string;

  /**
   * Override the label for boolean `false` values.
   */
  @Input()
  falseLabel: string;

  /**
   * Override the label for `undefined` values.
   */
  @Input()
  undefinedLabel = 'Not defined';

  /**
   * Override the default icon for the edit menu.
   */
  @Input()
  menuIcon = 'edit';

  /**
   * Places a link icon on the input that links
   * to the URL returned by this function.
   *
   * Should be an internal link, since `routerLink` is used.
   */
  @Input()
  forwardLink?: (obj: RimsFieldComponent['obj']) => string;

  /**
   * Optional tooltip placed on the link.
   */
  @Input()
  forwardLinkTooltip?: string;

  /**
   * Instad of `field`, use a pipe to display
   * the value.
   *
   * @see DynamicPipe
   */
  @Input()
  useCustomPipe?: {
    token: ManagedPipeToken;
    prePipeProperty?: string;
    debug?: boolean;
  };

  /**
   * this must be true to display the edit menu
   */
  @Input()
  editMenuCondition = true;

  @Input()
  highlightIfNull = appConfig.shouldShowBorderForNullFields;

  /**
   * Will show the highlight border even if the underlying value
   * is not null or the `highlightIfNull` setting is not true.
   */
  @Input()
  forceShowBorder: boolean;

  /**
   * Sets the `force` parameter of `rimsRequirePermissionDirective`
   */
  @Input()
  forcePermission: boolean;

  /**
   * Event that is emitted when `useDefaultPropertyEditMenu` is `false`
   * and the user clicks the edit icon. The host component has to handle
   * this to facilitate the editing process.
   */
  @Output()
  edit = new EventEmitter<FieldEditEvent>();

  emitCustomEditEvent(trigger: MatIcon) {
    const el = trigger._elementRef.nativeElement;
    const currentValue = el.getAttribute('data-current-value');
    this.edit.emit({
      currentValue: currentValue === 'null' ? null : currentValue
    });
  }

  getDisplay() {
    const displayName = this.entity?.fields?.[this.field]?.displayName;
    const suffix = this.displayNameSuffix;

    return suffix ? `${displayName} ${suffix}` : displayName;
  }
}
