<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12 mb-2">
      <h1 mat-dialog-title class="m-0">Add Identification Number</h1>
      <span class="color-grey">Each Actor / Type / ID Number combination must be unique.</span>
    </div>
  </div>
  <div class="row pt-2 mb-4">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="mb-2 w-100" appearance="fill">
          <mat-label>Type</mat-label>
          <mat-select [formControlName]="fieldNames.TYPE">
            <mat-option *ngFor="let type of (identificationNumberTypes | async)?.results" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
          <mat-hint class="warn" *ngIf="form.get(fieldNames.TYPE).hasError('duplicate')">A number of this type already exists</mat-hint>
        </mat-form-field>
        <mat-progress-bar mode="indeterminate" class="position-absolute" style="width: calc(100% - 30px)" *ngIf="form.pending"></mat-progress-bar>
        <mat-form-field class="mb-2 w-100" appearance="fill">
          <mat-label>Identification Number</mat-label>
          <input type="text" matInput [formControlName]="fieldNames.NUMBER" autocomplete="off" />
          <mat-hint class="warn" *ngIf="form.hasError('duplicate')">This combination is already used by another actor</mat-hint>
        </mat-form-field>
        <mat-checkbox [formControlName]="fieldNames.CONFIRM">
          I understand that adding this identification number will trigger a data synchronisation for all product groups that are related to this actor.
        </mat-checkbox>
        <button type="submit" hidden #button></button>
      </form>
    </div>
  </div>

  <mat-dialog-actions class="p-0" align="end">
    <button mat-button [attr.e2e-id]="'submit-button'" color="primary" (click)="button.click()" [disabled]="form.invalid || form.pending">Add Identification Number</button>
    <button mat-button [attr.e2e-id]="'close-button'" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
