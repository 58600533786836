import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Filter, FilterOperator } from '@rims/lib';
import { mapTo } from 'rxjs/operators';
import { DetailViewResolver } from '../../detail-view/resolvers/detail-view.resolver';
import { MetadataService } from '../../shared/services/metadata/metadata.service';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class ItemDetailResolver extends DetailViewResolver {
  constructor(private readonly meta: MetadataService, store: Store<AppState>, router: Router) {
    super(store, router, {
      baseEntityName: 'item',
      expand: ['itemNumber2', 'product', 'budiInfo2.lifecycle'],
      viewPayloads: (id, metadata) => {
        const views = Object.keys(metadata.views).map(key => metadata.views[key]);
        const itemProductionSitesView = views.find(view => view.route.endsWith('/itemproductionsites'));
        const itemDocumentsView = views.find(view => view.route.endsWith('/itemdocuments'));
        const itemNomenclaturesView = views.find(view => view.route.endsWith('/itemnomenclatures'));
        const itemRiskClassesView = views.find(view => view.route.endsWith('/itemriskclasses'));
        const itemProductGroupsView = views.find(view => view.route.endsWith('/itemproductgroups'));
        return {
          item_risk_class: {
            viewId: itemRiskClassesView.id,
            filters: [
              new Filter({
                fieldName: 'item',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          },
          product_group_item: {
            viewId: itemProductGroupsView.id,
            filters: [
              new Filter({
                fieldName: 'item',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          },
          item_nomenclature: {
            viewId: itemNomenclaturesView.id,
            filters: [
              new Filter({
                fieldName: 'item',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          },
          item_document: {
            viewId: itemDocumentsView.id,
            filters: [
              new Filter({
                fieldName: 'item',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          },
          item_production_site: {
            viewId: itemProductionSitesView.id,
            filters: [
              new Filter({
                fieldName: 'item',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          }
        };
      },
      interceptor: (baseEntityName, expand, id, result, state) => {
        return this.meta.hasProductOwnerPermissions(baseEntityName, id, 'string').pipe(mapTo(result));
      }
    });
  }
}
