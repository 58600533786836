import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: new URL(environment.graphqlPath || '/graphql', environment.backendUrl).toString()
          })
        };
      },
      deps: [HttpLink]
    }
  ]
})
export class GraphqlModule {}
