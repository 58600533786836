import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPastDate'
})
export class IsPastDatePipe implements PipeTransform {
  transform(date?: string | Date): boolean | undefined {
    if (!date) {
      return undefined;
    }
    const dat = typeof date === 'string' ? new Date(date) : date;
    return dat.getTime() < Date.now();
  }
}
