import { Action, createAction, props } from '@ngrx/store';
import { Direction, Filter, isPopulatedArray, RecordHistory } from '@rims/lib';
import { Mutable, Omit } from 'utility-types';
import { ViewQueryParam } from '../../components/rims-table/rims-table-query-params';
import { Page } from '../../models/page/page.model';
import { View } from '../../models/view/view.model';
import { ColumnByIdPipe } from '../../pipes/column-by-id.pipe';
import { OnlyNumericalStringsPipe } from '../../pipes/only-numerical-strings.pipe';
import { viewToDataKey } from '../../pipes/view-to-data-key.pipe';
import { ExportDataPayload, SortExt } from '../../services/data/data.service';
import { columnsToExpandQuery } from '../../utils/columns';
import { filtersToExpandQuery } from '../../utils/expand';

export type GetDataPayloadFromViewOptions = Mutable<Omit<GetDataPayload, 'key' | 'expand' | 'viewId' | 'filter'>>;

export class GetDataPayload {
  readonly key: string;
  readonly url: string;
  readonly expand?: string[];
  readonly viewId?: number;
  readonly filter?: Filter[];
  readonly query?: string;
  readonly page?: number;
  readonly pageSize?: number;
  readonly sort?: SortExt;

  constructor(init?: Partial<GetDataPayload>) {
    Object.assign(this, init);
  }

  static fromView(view: View, options?: GetDataPayloadFromViewOptions, columnIds?: string[]): GetDataPayload {
    // use 'begins-with' sort when searching
    if (options?.query) {
      options.sort = {
        direction: options.sort?.direction === Direction.DESC ? Direction.BEGINS_WITH_DESC : Direction.BEGINS_WITH_ASC,
        active: options.sort?.active
      };
    }

    let expandBaseColumns = view.displayedColumns;

    if (columnIds) {
      const onlyNumericalStringsPipe = new OnlyNumericalStringsPipe();
      const columnByIdPipe = new ColumnByIdPipe();
      const filteredColumnIds = onlyNumericalStringsPipe.transform(columnIds);
      expandBaseColumns = filteredColumnIds.map(col => columnByIdPipe.transform(col, view.columns));
    }

    let expand: string[] = undefined;
    if (Array.isArray(view.displayedColumns)) {
      expand = columnsToExpandQuery(expandBaseColumns);
    }

    if (isPopulatedArray(view.filters)) {
      expand = filtersToExpandQuery(expand, view.filters);
    }

    const key = viewToDataKey(view);

    // gets the current page size from the url
    const currentPageSize = +window.location.href.split(`view_${view.id}=`)[1]?.split(`s:`)[1]?.split(',')[0] || 10;

    return new GetDataPayload({
      key,
      expand,
      filter: view.filters,
      viewId: view.id,
      ...options,
      url: options?.url || key.toLowerCase(),
      pageSize: options?.pageSize ?? currentPageSize
    });
  }
}

export interface GetDataSuccessPayload<T = any> {
  key: string;
  page: Page<T>;
}

/**
 * You have to provide either a `viewId` or an `entityId`.
 *
 * If both are supplied, the `viewId` is ignored.
 *
 * @see DataEffects
 */
export interface UpdateDataPayload<T = any> {
  viewId?: number;
  entityName?: string;
  entityId?: number;
  id: string;
  value: T;
  expand?: string[];
  /**
   * You can pass additional actions which are executed in
   * case of a successful data update.
   */
  afterSuccessActions?: Action[];
  /**
   * By default, after an update actions, the corresponding
   * `GetOne` action will be executed. For complex situations
   * in which `entityName` or `entityId` is used, this might
   * not be what you want.
   */
  skipDefaultAfterSuccessAction?: boolean;
}

export interface UpdateDataSuccessPayload {
  entityId: number;
  recordId: number | string;
  expand?: string[];
  /**
   * @see UpdateDataPayload#afterSuccessActions
   */
  afterSuccessActions?: Action[];
  /**
   * @see UpdateDataPayload#afterSuccessActions
   */
  skipDefaultAfterSuccessAction?: boolean;
}

export interface GetHistoryPayload {
  entityIdOrName: number | string;
  recordId: number;
  hideCreatedRecordEntry?: boolean;
}

export interface GetHistorySuccessPayload<T = any> {
  history: RecordHistory;
  request: GetHistoryPayload;
}

export interface RunAsProductOwnerFor {
  productGroup?: number;
  item?: string;
  container?: number;
}

export interface CreateRecordOptions {
  /**
   * Only one of the record ids shall be set.
   *
   * @see RimsQueryParam
   */
  runAsProductOwnerFor?: RunAsProductOwnerFor;
}

export interface DeleteRecordOptions {
  /**
   * Only one of the record ids shall be set.
   *
   * @see RimsQueryParam
   */
  runAsProductOwnerFor?: RunAsProductOwnerFor;
}

export interface CreateRecordPayload<T = any> {
  payload: T;
  url: string;
  options?: CreateRecordOptions;
  navigateTo?: (result: any) => string;
}

export interface CreateRecordSuccessPayload {
  result: any;
  navigateTo?: (result: any) => string;
}

export interface GetOnePayload {
  entityId: number;
  recordId: number | string;
  expand?: string[];
}

export interface GetOneByNamePayload {
  entityName: string;
  recordId: number | string;
  expand?: string[];
}

export interface GetOneSuccessPayload<T = any> {
  entityId: number;
  recordId: number | string;
  page: Page<T>;
}

export interface OpenExportDialogPayload {
  viewId: number;
  viewParam: ViewQueryParam;
  allowExportAll?: boolean;
}

export interface DeleteRecordPayload {
  entityId: number;
  recordIds: (number | string)[];
}

export interface ClearDataPayload {
  viewId: number;
}

export interface ClearDataSuccessPayload {
  key: string;
}

const PREFIX = '[Data]';

export const getOne = createAction(`${PREFIX} Get One`, props<GetOnePayload>());
export const getOneByName = createAction(`${PREFIX} Get One By Url`, props<GetOneByNamePayload>());
export const getOneSuccess = createAction(`${PREFIX} Get One Success`, props<GetOneSuccessPayload>());

export const getData = createAction(`${PREFIX} Get Data`, props<GetDataPayload>());
export const getDataSuccess = createAction(`${PREFIX} Get Data Success`, props<GetDataSuccessPayload>());

export const createRecord = createAction(`${PREFIX} Create Record`, props<CreateRecordPayload>());
export const createRecordSuccess = createAction(`${PREFIX} Create Record Success`, props<CreateRecordSuccessPayload>());
export const createRecordError = createAction(`${PREFIX} Create Record Error`, props<Error | undefined>());

export const deleteRecord = createAction(`${PREFIX} Delete Record`, props<DeleteRecordPayload>());

export const updateData = createAction(`${PREFIX} Update Data`, props<UpdateDataPayload>());
export const updateDataSuccess = createAction(`${PREFIX} Update Data Success`, props<UpdateDataSuccessPayload>());

export const reloadView = createAction(`${PREFIX} Reload View`);

export const openExportDialog = createAction(`${PREFIX} Open Export Dialog`, props<OpenExportDialogPayload>());
export const closeExportDialog = createAction(`${PREFIX} Close Export Dialog`, props<ExportDataPayload>());

export const clearData = createAction(`${PREFIX} Clear Data`, props<ClearDataPayload>());
export const clearDataSuccess = createAction(`${PREFIX} Clear Data Success`, props<ClearDataSuccessPayload>());
