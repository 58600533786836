import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { RimsBudiLifecycle } from '@rims/sap-interface';
import { of } from 'rxjs';
import { mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';
import { noop } from '../../shared/store/shared/shared.actions';
import { getEntity } from '../../shared/utils/entity-utils';
import { AppState } from '../../store/store.state';
import { AddRiskClassToItemDialogComponent } from '../components/add-risk-class-to-item-dialog/add-risk-class-to-item-dialog.component';
import { ChangeItemLifecycleDialogComponent } from '../components/change-item-lifecycle-dialog/change-item-lifecycle-dialog.component';
import {
  closeChangeItemLifecycleDialog,
  openAddRiskClassToItemDialog,
  OpenAddRiskClassToItemDialogPayload,
  openChangeItemLifecycleDialog,
  OpenChangeItemLifecycleDialogPayload
} from './item.actions';

@Injectable()
export class ItemEffects {
  constructor(
    private readonly actions: Actions,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  openChangeItemLifecycleDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openChangeItemLifecycleDialog),
      switchMap(payload => {
        this.dialog.open<ChangeItemLifecycleDialogComponent, OpenChangeItemLifecycleDialogPayload>(
          ChangeItemLifecycleDialogComponent,
          {
            panelClass: 'custom-dialog-width',
            data: payload
          }
        );
        return of(noop());
      })
    )
  );

  closeChangeItemLifecycleDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeChangeItemLifecycleDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const entity = getEntity(metadata, 'item_budi_info');
        return this.dataService
          .getAll('budilifecycles', undefined, undefined, 1, undefined, undefined, [
            new EqualsFilter('name', RimsBudiLifecycle.READY_FOR_EUDAMED)
          ])
          .pipe(
            switchMap(response => {
              return this.dataService.update(entity.id, payload.itemBudiInfoId, {
                lifecycle: response.results[0].id
              });
            }),
            mapTo(noop()),
            tap(() => window.location.reload())
          );
      })
    )
  );

  openAddRiskClassToItemDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openAddRiskClassToItemDialog),
      switchMap(payload => {
        this.dialog.open<AddRiskClassToItemDialogComponent, OpenAddRiskClassToItemDialogPayload>(
          AddRiskClassToItemDialogComponent,
          {
            panelClass: 'custom-dialog-width',
            data: payload
          }
        );
        return of(noop());
      })
    )
  );
}
