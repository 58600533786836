import { Injectable } from '@angular/core';
import { BudiItemSyncStatus, RimsQueryParam } from '@rims/lib';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from '../../shared/services/request-builder/request-builder.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  constructor(private readonly requestBuilder: RequestBuilder) {}

  getItemSyncStatus(basicUdiDi: string): Observable<BudiItemSyncStatus> {
    return this.requestBuilder
      .request(environment.backendUrl, 'extsapbasicudis', 'sync-status')
      .param(RimsQueryParam.BASIC_UDI_DI, basicUdiDi)
      .get<BudiItemSyncStatus>();
  }
}
