<rims-header>
  <rims-header-title><img class="main-logo" src="images/rims_logo_white.png" alt="RIMS" /></rims-header-title>
</rims-header>

<div class="container" *ngIf="isRedirecting">
  <div class="row py-5">
    <div class="col">
      <h1 class="color-grey">You are being redirected...</h1>
    </div>
  </div>
</div>

<div class="container pb-5" *ngIf="!isRedirecting">
  <!-- Welcome -->
  <div class="row py-3 py-md-5" [attr.e2e-id]="'home-welcome-section'">
    <div class="col-12 position-relative">
      <div class="welcome-banner py-3 px-4">
        <h3 class="m-0 color-purple">{{ greet() }} {{ (loggedInUser | async)?.firstName }}!</h3>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div id="actions" class="row pb-5" [attr.e2e-id]="'home-most-visited-section'">
    <div class="col-12">
      <h2>Most Visited</h2>
    </div>

    <div class="col-12 col-md-6 col-xl-4 my-2" [attr.e2e-id]="view?.displayName" *ngFor="let view of highlightViews | async">
      <div class="h-100 bg-light-grey p-4 d-flex flex-row align-items-center justify-content-between clickable">
        <div class="d-flex flex-row align-items-center">
          <mat-icon class="icon-gray">{{ view?.icon?.name }}</mat-icon>
          <div class="ml-4">
            <h2 class="m-0">{{ view?.displayName }}</h2>
            <p class="m-0 text-dark">{{ view?.description }}</p>
          </div>
        </div>
        <a class="stretched-link unstyled" [routerLink]="view?.route">
          <mat-icon class="color-purple">arrow_forward</mat-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="row pb-5" [attr.e2e-id]="'home-views-section'">
    <div class="col-12">
      <h2>All Views</h2>
    </div>
    <div class="col-12">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let group of viewGroups | async | keyvalue | filterViewGroups : (views | async)" class="mat-elevation-z0">
          <mat-expansion-panel-header [attr.e2e-id]="'home-views-panel-header-' + group.value.name">
            <mat-panel-title>
              {{ group?.value?.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a
            *ngFor="let view of views | async | filterViews : group.value | orderBy : 'asc' : 'displayName'"
            mat-menu-item
            [routerLink]="[view?.route]"
            class="unstyled"
            [attr.e2e-id]="'home-views-link-' + view?.id"
          >
            {{ view?.displayName }}
          </a>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(views | async | filterViews : null : true)?.length > 0" class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>More Views</mat-panel-title>
          </mat-expansion-panel-header>

          <a *ngFor="let view of views | async | filterViews : null : true | orderBy : 'asc' : 'displayName'" mat-menu-item [routerLink]="[view?.route]" class="unstyled">
            {{ view?.displayName }}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
