import { Component, Input } from '@angular/core';

@Component({
  selector: 'rims-label-help-icon',
  templateUrl: './label-help-icon.component.html',
  styleUrls: ['./label-help-icon.component.scss']
})
export class LabelHelpIconComponent {
  @Input()
  description: string;

  @Input()
  legalReference: string;

  @Input()
  legalReferenceLink: string;
}
