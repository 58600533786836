<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title class="m-0">{{ getTitle() }}</h1>
      <span class="d-block color-grey" *ngIf="data.referenceName && data.fromName">
        {{ getRemoveFromTxt() }}
      </span>
      <span class="color-grey" *ngIf="data.referenceName">
        {{ getIgnoreTxt() }}
      </span>
    </div>
  </div>
  <div class="row pt-2 mb-4 mt-4">
    <div class="col-12">
      <ul class="pl-4">
        <li *ngFor="let entity of entities | async">
          {{
            data.noRelation
              ? (entity | dynamicPipe : data.entityName : undefined : undefined : data.pipeDefaultValue)
              : (entity[relation] | dynamicPipe : data.entityName : undefined : undefined : data.pipeDefaultValue)
          }}
        </li>
        <li *ngIf="remaining > 0">...</li>
      </ul>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-checkbox color="warn" [formControlName]="fieldNames.CONFIRM"><span [innerHtml]="getConfirmTxt()"></span></mat-checkbox>
        <mat-checkbox color="warn" [formControlName]="fieldNames.ADDITIONAL_CONFIRM" *ngIf="data.additionalConfirm">{{ data.additionalConfirm }}</mat-checkbox>
        <button type="submit" hidden #button></button>
      </form>
    </div>
  </div>

  <mat-dialog-actions class="p-0" align="end">
    <button mat-button [attr.e2e-id]="'submit-button'" color="warn" (click)="button.click()" [disabled]="form.invalid">{{ getActionTxt(true) }}</button>
    <button mat-button [attr.e2e-id]="'close-button'" (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>
