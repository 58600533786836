import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TableColumn } from '../../models/table/table-column.model';
import { AppEntity } from '../../models/utils/entity.model';
import { AppViewGroup } from '../../models/view/view-group.model';
import { View } from '../../models/view/view.model';

export interface MetadataDraggedColumn {
  viewId: number;
  columnId: number;
  previousIndex: number;
}

export interface ColumnPreferencesDialogState {
  viewId: number;
  available: TableColumn[];
  selected: TableColumn[];
}

export interface MetadataState {
  views: Record<string, View>;
  viewGroups: Record<string, AppViewGroup>;
  entities: Record<string, AppEntity>;
  draggedColumn: MetadataDraggedColumn;
  columnPreferencesDialog: ColumnPreferencesDialogState;
  rowSelection: {
    [key: string]: Array<string | number>;
  };
  checkboxColor: {
    [key: string]: string;
  };
  loading: boolean;
}

export const METADATA_INITIAL_STATE: MetadataState = {
  views: null,
  viewGroups: null,
  entities: null,
  draggedColumn: null,
  columnPreferencesDialog: null,
  rowSelection: {},
  checkboxColor: {},
  loading: false
};

export const selectMetadata = createFeatureSelector<MetadataState>('metadata');

export const viewFilters = (viewId: number) =>
  createSelector(selectMetadata, (state: MetadataState) => state.views[viewId].filters);

export const viewRoute = (viewId: number) =>
  createSelector(selectMetadata, (state: MetadataState) => state.views[viewId].route);
