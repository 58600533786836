import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, from, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { DataService } from '../../../shared/services/data/data.service';
import { AppState } from '../../../store/store.state';
import { RimsTableExportDialogComponent } from '../../components/rims-table-export-dialog/rims-table-export-dialog.component';
import { RIMS_TABLE_QUERY_PARAM_RELOAD_TIME } from '../../components/rims-table/rims-table-query-params';
import { viewToDataKey } from '../../pipes/view-to-data-key.pipe';
import { noop } from '../shared/shared.actions';
import {
  clearData,
  clearDataSuccess,
  closeExportDialog,
  createRecord,
  createRecordError,
  createRecordSuccess,
  deleteRecord,
  getData,
  getDataSuccess,
  GetDataSuccessPayload,
  getOne,
  getOneByName,
  getOneSuccess,
  openExportDialog,
  OpenExportDialogPayload,
  reloadView,
  updateData,
  updateDataSuccess
} from './data.actions';

@Injectable()
export class DataEffects {
  private readonly titleCasePipe = new TitleCasePipe();

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly dataService: DataService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}

  createRecord = createEffect(() =>
    this.actions.pipe(
      ofType(createRecord),
      switchMap(payload => {
        return this.dataService.create(payload.url, payload.payload, payload.options).pipe(
          map(result =>
            createRecordSuccess({
              result,
              navigateTo: payload.navigateTo
            })
          ),
          catchError(e => {
            return of(createRecordError(e));
          })
        );
      })
    )
  );

  createRecordSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(createRecordSuccess),
      switchMap(payload => {
        if (payload.navigateTo) {
          return from(this.router.navigateByUrl(payload.navigateTo(payload.result))).pipe(map(() => noop()));
        }
        return of(noop());
      })
    )
  );

  createRecordError = createEffect(() =>
    this.actions.pipe(
      ofType(createRecordError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        let msg = `❌  Could not create record.`;
        if ((err as any).status === 501) {
          msg = `⚠️ The creation enpoint is disabled for this entity.`;
        }
        this.snackBar.open(msg, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  deleteRecord = createEffect(() =>
    this.actions.pipe(
      ofType(deleteRecord),
      switchMap(payload => forkJoin(payload.recordIds.map(id => this.dataService.delete(payload.entityId, id)))),
      map(_ => reloadView())
    )
  );

  reloadView = createEffect(() =>
    this.actions.pipe(
      ofType(reloadView),
      switchMap(() => {
        // Update the URL to trigger reloading of table data
        this.router.navigate([], {
          queryParams: {
            [RIMS_TABLE_QUERY_PARAM_RELOAD_TIME]: Date.now()
          },
          queryParamsHandling: 'merge'
        });
        return of(noop());
      })
    )
  );

  getData = createEffect(() =>
    this.actions.pipe(
      ofType(getData),
      mergeMap(props => {
        return this.dataService
          .getAll(
            props.url,
            props.expand,
            props.page,
            props.pageSize,
            props.sort,
            props.viewId,
            props.filter,
            props.query
          )
          .pipe(
            map(data => {
              const successPayload: GetDataSuccessPayload = {
                key: props.key,
                page: data
              };
              return getDataSuccess(successPayload);
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.snackBar.open(
                  `🚫  You are not allowed to access data of type ${this.titleCasePipe.transform(props.key)}.`,
                  null,
                  {
                    duration: 4000,
                    horizontalPosition: 'right'
                  }
                );
                this.router.navigate(['/']);
              }
              return throwError(err);
            })
          );
      })
    )
  );

  updateData = createEffect(() =>
    this.actions.pipe(
      ofType(updateData),
      filter(props => {
        if (!(props.entityId || props.entityName || props.viewId)) {
          console.warn('Can not update data without entityId, entityName or viewId');
          return false;
        }
        return true;
      }),
      withLatestFrom(this.store.pipe(select(state => state.metadata))),
      mergeMap(([props, metadata]) => {
        let entityId = props.entityId;
        if (props.entityName && !entityId) {
          entityId = Object.keys(metadata.entities)
            .map(id => metadata.entities[id])
            .find(entity => entity.name === props.entityName).id;
        }
        let entity = metadata.entities[entityId];
        if (!entity) {
          const view = metadata.views[props.viewId];
          const viewEntity = view.entity;
          if (typeof viewEntity === 'number') {
            entity = metadata.entities[view.entity as number];
          } else {
            entity = viewEntity;
          }
        }
        return this.dataService.update(entity.id, props.id, props.value).pipe(
          map(() => {
            this.snackBar.open(`✅  Record has been updated`, null, {
              duration: 2000,
              horizontalPosition: 'right'
            });
            return updateDataSuccess({
              entityId: entity.id,
              recordId: props.id,
              expand: props.expand,
              afterSuccessActions: props.afterSuccessActions,
              skipDefaultAfterSuccessAction: props.skipDefaultAfterSuccessAction
            });
          })
        );
      })
    )
  );

  updateDataSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(updateDataSuccess),
      switchMap(props => {
        return [
          ...(props.skipDefaultAfterSuccessAction
            ? [noop()]
            : [
                getOne({
                  ...props
                })
              ]),
          ...(props.afterSuccessActions || [noop()])
        ];
      })
    )
  );

  getOneByName = createEffect(() =>
    this.actions.pipe(
      ofType(getOneByName),
      withLatestFrom(this.store.pipe(select(state => state.metadata))),
      map(([{ entityName, recordId, expand }, metadata]) => {
        const entityId = Object.keys(metadata.entities)
          .map(key => metadata.entities[key])
          .find(entity => entity.name === entityName).id;

        return getOne({
          entityId,
          recordId,
          expand
        });
      })
    )
  );

  getOne = createEffect(() =>
    this.actions.pipe(
      ofType(getOne),
      switchMap(props => {
        return this.dataService.getOne(props.entityId, props.recordId, props.expand).pipe(
          map(page => {
            return getOneSuccess({
              ...props,
              page
            });
          })
        );
      })
    )
  );

  openExportDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openExportDialog),
      switchMap(payload => {
        this.dialog.open<RimsTableExportDialogComponent, OpenExportDialogPayload>(RimsTableExportDialogComponent, {
          minWidth: '60%',
          maxWidth: '100%',
          data: payload
        });
        return of(noop());
      })
    )
  );

  closeExportDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeExportDialog),
      switchMap(payload => {
        return this.dataService.export(payload).pipe(mapTo(noop()));
      })
    )
  );

  clearData = createEffect(() =>
    this.actions.pipe(
      ofType(clearData),
      filter(({ viewId }) => !!viewId),
      withLatestFrom(this.store.pipe(select(state => state.metadata))),
      map(([{ viewId }, metadata]) => {
        const key = viewToDataKey(metadata.views[viewId]);
        return clearDataSuccess({ key });
      })
    )
  );
}
