<rims-header>
  <rims-header-title>Container {{ (result | async)?.id }}</rims-header-title>
  <rims-header-announcement *ngIf="hasProductOwnerPermission | async">
    <span class="d-flex">
      You are a Product Owner for this Container
      <mat-icon
        inline
        class="ml-1"
        matTooltipShowDelay="300"
        matTooltip="Because you have a GRA responsibility for a group that this container is part of, you can maintain data and perform actions that would otherwise not be allowed with your user role."
      >
        help_outline
      </mat-icon>
    </span>
  </rims-header-announcement>
</rims-header>

<rims-property-edit-menu (updateProperty)="onStateChange($event)"></rims-property-edit-menu>

<div class="container pb-3" *ngIf="result | async as result">
  <div class="row pt-5 mb-5">
    <div class="col-12">
      <h2>Container Information</h2>
    </div>
    <div class="col-12 col-sm-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Is Advanced?</mat-label>
        <mat-select [value]="result.isAdvanced" [disabled]="(entity | async)?.fields?.['isAdvanced']?.readOnly" #containerTypeSelect>
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="result"
        [entity]="entity | async"
        permissionBaseEntity="container"
        [forcePermission]="hasProductOwnerPermission | async | forceProductOwnerPermission"
        field="type.name"
        [menuConfig]="{
          onMenuOpen: {
            loadAll: true,
            url: 'containertypes',
            filters: containerTypeFilters | async
          },
          loadAsyncOptions: true,
          selectFieldName: 'name'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="result"
        [entity]="entity | async"
        permissionBaseEntity="container"
        [forcePermission]="hasProductOwnerPermission | async | forceProductOwnerPermission"
        field="size"
        [useCustomPipe]="{
          token: 'containerSize'
        }"
        [menuConfig]="{
          inputType: 'number',
          onMenuOpen: {
            validators: containerSizeValidators
          }
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="result"
        [entity]="entity | async"
        permissionBaseEntity="container"
        [forcePermission]="hasProductOwnerPermission | async | forceProductOwnerPermission"
        field="substanceUnit.name"
        [menuConfig]="{
          onMenuOpen: {
            loadAll: true,
            url: 'units'
          },
          loadAsyncOptions: true,
          selectFieldName: 'name'
        }"
      ></rims-field>
    </div>
  </div>

  <div class="row mb-5" *ngIf="result.isAdvanced">
    <div class="col-12">
      <h2>Advanced Information</h2>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="result"
        [entity]="entity | async"
        permissionBaseEntity="container"
        [forcePermission]="hasProductOwnerPermission | async | forceProductOwnerPermission"
        field="shape.name"
        [menuConfig]="{
          onMenuOpen: {
            loadAll: true,
            url: 'containershapes'
          },
          loadAsyncOptions: true,
          selectFieldName: 'name'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="result"
        [entity]="entity | async"
        permissionBaseEntity="container"
        [forcePermission]="hasProductOwnerPermission | async | forceProductOwnerPermission"
        field="color.name"
        [menuConfig]="{
          onMenuOpen: {
            loadAll: true,
            url: 'containercolors'
          },
          loadAsyncOptions: true,
          selectFieldName: 'name'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="result"
        [entity]="entity | async"
        permissionBaseEntity="container"
        [forcePermission]="hasProductOwnerPermission | async | forceProductOwnerPermission"
        field="cap.name"
        [menuConfig]="{
          onMenuOpen: {
            loadAll: true,
            url: 'containercaps'
          },
          loadAsyncOptions: true,
          selectFieldName: 'name'
        }"
      ></rims-field>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Production Sites</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [shouldDisplayCheckboxes]="true"
        [detailViewEntityName]="'company'"
        [primaryKeyPropertyName]="'company'"
        [dataKey]="'container_production_site'"
        [viewConfigPayload]="(resolveResult | async)?.viewPayloads['container_production_site']"
      >
        [emptyResultSetMessage]="'No production sites are associated with this container.'"
        <rims-table-action
          [attr.e2e-id]="'add-production-site-button'"
          (click)="openAddProductionSiteDialog()"
          *rimsRequirePermission="'container_production_site'; action: 'create'; force: hasProductOwnerPermission | async | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Site
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-production-site-button'"
          (click)="openRemoveProductionSite()"
          *rimsRequirePermission="
            'container_production_site';
            action: 'delete';
            condition: (selectedContainerProductionSites | async)?.length > 0;
            force: hasProductOwnerPermission | async | forceProductOwnerPermission
          "
        >
          <mat-icon>remove</mat-icon>
          Remove Site
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Product Groups</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [detailViewEntityName]="'productgroup'"
        [primaryKeyPropertyName]="'productGroup'"
        [dataKey]="'product_group_container'"
        [viewConfigPayload]="(resolveResult | async)?.viewPayloads['product_group_container']"
      ></rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>History</h2>
    </div>
    <div class="col">
      <rims-detail-view-record-history
        [config]="{
          entityIdOrName: (entity | async)?.id,
          recordId: result.id
        }"
      ></rims-detail-view-record-history>
    </div>
  </div>
</div>
