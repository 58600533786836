import { Pipe, PipeTransform } from '@angular/core';
import { AddressFormatPipe } from './address-format.pipe';

@Pipe({
  name: 'actorOptionFormat'
})
export class ActorOptionFormatPipe implements PipeTransform {
  private readonly addressPipe = new AddressFormatPipe();

  transform(actor: any): string {
    const address = this.addressPipe.transform(actor.company2?.address);
    return `${actor.actorType2?.name || ''}${actor.company2?.name ? ' - ' + actor.company2?.name : ''}${
      address.length > 0 ? ' - ' + address : ''
    }`;
  }
}
