<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title>Update Permission Level</h1>
      <p class="m-0">
        Changing the permission level of the
        <span class="font-weight-bold">{{ data.role.name }}</span>
        role will be effective immediately.
      </p>
      <p>Users with this role will be affected by the changed permission starting with their next request.</p>
    </div>
  </div>
  <div class="row pt-2 mb-4">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100">
          <input type="text" matInput formControlName="permissionLevel" />
        </mat-form-field>
        <mat-checkbox formControlName="confirm">I want to change the permission level</mat-checkbox>
        <button type="submit" hidden #button></button>
      </form>
    </div>
  </div>

  <mat-dialog-actions class="p-0" align="end">
    <button mat-button [attr.e2e-id]="'submit-button'" color="primary" [disabled]="form.invalid" (click)="button.click()">Submit</button>
    <button mat-button [attr.e2e-id]="'close-button'" (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>
