import { Pipe, PipeTransform } from '@angular/core';
import { Container } from '@rims/database';

@Pipe({
  name: 'containerOptionFormat'
})
export class ContainerOptionFormatPipe implements PipeTransform {
  private static NOT_APPLICABLE = 'n/a';

  transform(container?: Container | null): string {
    if (!container) {
      return '';
    }

    let advancedProperties = '';
    if (container.shape && container.shape.name !== ContainerOptionFormatPipe.NOT_APPLICABLE) {
      advancedProperties = container.shape.name;
    }
    if (container.cap && container.cap.name !== ContainerOptionFormatPipe.NOT_APPLICABLE) {
      advancedProperties += (advancedProperties.length > 0 ? ', ' : '') + container.cap.name;
    }
    if (container.color && container.color.name !== ContainerOptionFormatPipe.NOT_APPLICABLE) {
      advancedProperties += (advancedProperties.length > 0 ? ', ' : '') + container.color.name;
    }

    let result = container.type.name;
    if (advancedProperties.length > 0) {
      result += ` (${advancedProperties})`;
    }
    result += ` ${container.size} ${container.substanceUnit.shortName}`;
    if (Array.isArray(container.containerProductionSites) && container.containerProductionSites.length) {
      const companies = container.containerProductionSites.filter(company => company.deleted === false);
      if (companies.length > 0) {
        result += ` [${companies.map(city => city.company2?.address?.city).join(', ')}]`;
      }
    }
    return result;
  }
}
