export const isNullOrUndefined = (x: any) => typeof x === 'undefined' || x === null;

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * Useful in a plugin or module that accepts a JSON array of objects, but
 * you want to let the user specify where to find various bits of data
 * inside of each custom object instead of forcing a standardized
 * property list.
 *
 * @param propertyName nested A dot notation style parameter reference (ie "urls.small")
 * @param object (optional) The object to search
 * @param defaultValue (optional) this value will be used if the prop is undefined
 *
 * @return the value of the property in question
 *
 * @see https://gist.github.com/jasonrhodes/2321581
 */
export function getProperty(propertyName: string, object: object, defaultValue?: string) {
  if (!propertyName) {
    return defaultValue;
  }
  const parts = propertyName.split('.');
  let property = object || this;

  for (const part of parts) {
    if (isNullOrUndefined(property)) {
      return defaultValue;
    }
    property = property?.[part];
  }

  return isNullOrUndefined(property) ? defaultValue : property;
}
