<div [class.pb-2]="(filters | async)?.length > 0">
  <mat-chip-list>
    <ng-container *ngFor="let filter of filters | async">
      <ng-container *ngIf="{ fields: fields | async } as app">
        <mat-chip
          *ngIf="!filter?.hidden"
          [class.cursor-not-allowed]="filter?.readOnly"
          [removable]="!filter?.readOnly"
          (removed)="remove(filter)"
          [matTooltip]="getTooltip(filter, app.fields)"
          (click)="add(filter)"
        >
          {{ getTruncatedChip(filter, app.fields) }}
          <mat-icon *ngIf="!filter?.readOnly" matChipRemove [attr.e2e-id]="'rims-table-filters-' + viewId + '-' + filter?.fieldName + '-cancel'">cancel</mat-icon>
        </mat-chip>
      </ng-container>
    </ng-container>
  </mat-chip-list>
</div>
