import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, Directive, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Filter, snakeCaseToCamelCase } from '@rims/lib';
import * as pluralize from 'pluralize';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom
} from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { AppConfig, appConfig, APP_CONFIG } from '../../../../app.constants';
import { AppState } from '../../../store/store.state';
import { AppField } from '../../models/field/field.model';
import { TableColumn } from '../../models/table/table-column.model';
import { ChangeTrackedEntity } from '../../models/utils/change-tracked-entity.model';
import { AppEntity } from '../../models/utils/entity.model';
import { View } from '../../models/view/view.model';
import { PropertyAccessPipe } from '../../pipes/property-access.pipe';
import { DataService, SortExt } from '../../services/data/data.service';
import {
  clearData,
  getData,
  GetDataPayload,
  GetDataPayloadFromViewOptions,
  openExportDialog
} from '../../store/data/data.actions';
import { DataStateEntry, selectData } from '../../store/data/data.state';
import {
  addSelection,
  clearSelection,
  dropColumn,
  FilterMenu,
  FilterMenuGroup,
  getViewConfig,
  GetViewConfigPayload,
  openColumnPreferencesDialog,
  openFilterCreationDialog,
  OpenFilterCreationDialogPayload,
  removeColumnSettings,
  removeSelection,
  resetFilters,
  setDraggedColumn,
  setFiltersSuccess
} from '../../store/metadata/metadata.actions';
import { MetadataDraggedColumn, viewFilters } from '../../store/metadata/metadata.state';
import { getProperty } from '../../utils/object-utils';
import { RimsTableSearchQueryComponent } from '../rims-table-search-query/rims-table-search-query.component';
import { getQueryParamKey, RIMS_TABLE_QUERY_PARAM_RELOAD_TIME, ViewQueryParam } from './rims-table-query-params';
import { getValueByPropertyTree, shouldEnableSort, updateBrowserURL } from './rims-table-utils';

@Directive({
  selector: '[templateVariable]',
  exportAs: 'templateVariable'
})
export class TemplateVariableDirective {
  @Input() templateVariable: any;
}

@Component({
  selector: 'rims-table',
  templateUrl: './rims-table.component.html',
  styleUrls: ['./rims-table.component.scss']
})
export class RimsTableComponent implements OnInit, OnDestroy {
  selection = new SelectionModel<string | number>(true, []);

  /**
   * If provided, this URL will be used to load new data.
   * If not provided (the default) the URL will be found from
   * the associated view.
   *
   * This must be used together with `GetViewConfigPayload#url`:
   * packages/frontend/src/app/modules/shared/store/metadata/metadata.actions.ts
   */
  @Input()
  customUrl: string;

  /**
   * Setting this to true removes the possibility for the user
   * to modify or see applied filters of a table in the UI.
   *
   * Filters can still be createad by the view config or via query param.
   */
  @Input()
  hideFiltersAndFilterMenu: boolean;

  /**
   * This setting can be used to hide the column settings dialog.
   */
  @Input()
  hideColumnDialog: boolean;

  @Input()
  dataKey: string;

  /**
   * This property can be used to override the primary key
   * property name of the items being displayed in this table.
   * The default is "id".
   *
   * The property name is used for building the link to the detail view.
   */
  @Input()
  primaryKeyPropertyName: string | undefined;

  /**
   * This property can be used to override the entity name for building
   * the detail view link. By default, the entity name is used based on
   * the view associated with this table. For relation tables, the user might
   * wish to use the name of one of the related entities instead.
   *
   * The entity name is used for building the link to the detail view.
   */
  @Input()
  detailViewEntityName: string | undefined;

  /**
   * Set this property to true to display a link to the detail view.
   */
  @Input()
  shouldDisplayDetailLink: boolean | undefined;

  /**
   * Set this property to true to display checkboxes for each table row.
   */
  @Input()
  shouldDisplayCheckboxes: boolean | undefined;

  /**
   * For some entities you might need to change the property which is stored as
   * the selection. For example for relation tables or views, there might be no
   * `id` field.
   */
  @Input()
  checkBoxesProperty = 'id';

  /**
   * Set this property to true to display a button to toggle the search input.
   *
   * This should only be set if the corresponding `/search` endpoint for this view
   * is enabled. TODO: Refactor controller settings and request this information
   * with the view config.
   */
  @Input()
  shouldDisplaySearchInput: boolean | undefined;

  @Input()
  shouldDisplayExportDialog: boolean | undefined;

  /**
   * Whether of not the user of this table is allowed to export all records of this entity,
   * regardless of paging, filters or query.
   *
   * @default false
   */
  @Input()
  shouldAllowExportAll: boolean | undefined;

  /**
   * This input can be used to set the message which is displayed when the
   * table has no records to display.
   */
  @Input()
  emptyResultSetMessage = 'There are no records to be displayed.';

  /**
   * This input can be used to set the message which is displayed when the
   * table has at least one record to display.
   */
  @Input()
  nonEmptyResultSetMessage = '';

  @Input()
  filterMenus: (FilterMenu | FilterMenuGroup)[];

  @Input()
  set viewConfigPayload(viewConfigPayload: GetViewConfigPayload) {
    this.viewPayload = viewConfigPayload;
    this.viewId = viewConfigPayload.viewId;
    this.resolveView();
  }

  /**
   * This input can be used to display a certain amount of skeleton loaders while the the current table data is still subject to change
   * (currently only used for the item synchronization process when creating a BUDI).
   *
   * if `force` is true we are in the process of polling data and may or may not get further data entries over time.
   * We try to adjust the initially provided skeleton count based on the data we receive over time (computed in `loaderCount`)
   *
   * if `force` is false we either
   *    - display the default amount of skeleton loaders when no data has been fetched yet
   *    - display no skeleton loaders when the data has been fetched
   */
  @Input()
  loader = {
    force: false,
    count: appConfig.defaultLoaderCount
  };

  _showQueryInput = false;
  get showQueryInput() {
    return this._showQueryInput;
  }
  set showQueryInput(val: boolean) {
    this._showQueryInput = val;
    if (!this.showQueryInput) {
      this.searchQueryComponent.clear();
    } else {
      this.searchQueryComponent.focus();
    }
  }

  getValueByPropertyTree = getValueByPropertyTree;
  shouldEnableSort = shouldEnableSort;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild('searchQuery', { static: false })
  private searchQueryComponent: RimsTableSearchQueryComponent;

  /**
   * The update pipeline is a single stream of updated `ViewQueryParam` objects
   * which are emitted from various sources inside the table.
   *
   * The pipeline's job is to throttle these events to prevent unnecessary and
   * redundant data-updates resulting in too many backend calls and view updates.
   */
  updatePipeline = new Subject<ViewQueryParam>();

  /**
   * You can enable the display of debug information for this table via localStorage.
   */
  showDebugInfo = localStorage.getItem(this.config.storageKeys.RIMS_TABLE_SHOW_DEBUG_INFO) === 'true';

  // Indicates that the table currectly has at least one record.
  hasRecords: Observable<boolean>;

  pageEvent: PageEvent = {
    pageSize: this.config.pageSizeOptions[0],
    pageIndex: this.config.defaultPageIndex
  } as any;

  viewId: number;
  sortEvent: SortExt;
  filters: Filter[];
  query: string;
  urlColumns = new BehaviorSubject<number[]>([]);
  view: Observable<View>;
  data: Observable<DataStateEntry>;
  columnIds: Observable<string[]>;
  fields: Observable<AppField[]>;
  storeData: Observable<Record<string, DataStateEntry>>;
  allRowsSelected = false;
  someRowsSelected = false;
  totalResultsSize: number;
  checkboxColor: Observable<string> = this.store.select(state => state.metadata.checkboxColor[this.viewId]);
  clearFilterDisabled: Observable<boolean>;

  // must be null initially to avoid triggering changes in queryParamObservable when component gets initialized
  private queryParams: string = null;
  private dataPayload: GetDataPayload;
  private viewPayload: GetViewConfigPayload;
  private destroy$ = new Subject<boolean>();
  private viewInitialized = false;
  private searchProperties: string[] = [];
  public label = '';
  public loaderCount: Observable<number>;

  constructor(
    readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    @Inject(APP_CONFIG)
    public readonly config: AppConfig,
    // Router is used by imported function `updateBrowserURL`
    private readonly router: Router,
    private readonly breadcrumb: BreadcrumbService,
    private readonly dataService: DataService,
    private actions: ActionsSubject
  ) {}

  ngOnInit() {
    this.view = this.store.pipe(
      filter(() => this.viewInitialized),
      select(state => state.metadata.views[this.viewId])
    );

    this.storeData = this.store.pipe(
      filter(() => this.viewInitialized),
      select(selectData),
      map(data => data.entries)
    );
    this.data = this.store.pipe(
      filter(() => this.viewInitialized),
      select(selectData),
      filter(data => data.entries[this.dataKey]?.loading === false),
      map(data => data.entries[this.dataKey]),
      tap(entry => {
        this.searchProperties = entry?.page?.searchProperties?.map(prop => snakeCaseToCamelCase(prop)) ?? [];
        this.totalResultsSize = entry?.page?.totalResultsSize;
      })
    );
    this.hasRecords = this.data.pipe(map(data => data?.page?.resultsSize > 0));
    this.columnIds = combineLatest([this.view, this.urlColumns.asObservable()]).pipe(
      map(([view, cols]) => {
        return [
          ...(this.shouldDisplayCheckboxes ? ['select'] : []),
          ...(Array.isArray(cols) && cols.length > 0
            ? cols.map(col => `${col}`)
            : view.displayedColumns.map(col => `${col.id}`)),
          ...(this.shouldDisplayDetailLink ? ['details'] : [])
        ];
      })
    );
    this.fields = this.store.pipe(
      filter(() => this.viewInitialized),
      select(state => state.metadata),
      filter(metadata => !metadata.loading),
      map(metadata => {
        let entity = metadata.views[this.viewId].entity;
        if (typeof entity === 'number') {
          entity = metadata.entities[entity];
        } else {
          entity = metadata.entities[entity.id];
        }
        return Object.keys(entity.fields).map(key => (entity as AppEntity).fields[key]);
      })
    );

    this.store
      .pipe(
        filter(() => this.viewInitialized),
        take(1),
        tap(() => {
          this.startUpdatePipelineSubscription();
          this.startQueryParamsObservable();
          this.updateColumnsAndFilters();
        })
      )
      .subscribe();

    // only relevant for accessibility
    this.label = this.detailViewEntityName ? pluralize(this.detailViewEntityName) : this.dataKey;

    this.loaderCount = this.data.pipe(
      map(data => {
        if (this.loader.force) {
          // update initial loader count based on how many items have already been fetched
          return this.loader.count - (data?.page?.resultsSize ?? 0);
        }
        return this.loader.count;
      })
    );

    this.clearFilterDisabled = this.store.pipe(
      select(viewFilters(this.viewId)),
      map(filters => filters.every(f => f.readOnly))
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.clearFilters();

    this.store.dispatch(
      removeColumnSettings({
        viewId: this.viewId
      })
    );
    this.store.dispatch(clearSelection({ viewId: this.viewId }));
    this.store.dispatch(clearData({ viewId: this.viewId }));
  }

  private resolveView() {
    const columnsReady = this.store.pipe(map(state => this.waitForColumns(state)));

    const initialFiltersSet = this.actions.pipe(
      ofType(setFiltersSuccess),
      map(action => action.viewId === this.viewId)
    );

    combineLatest([columnsReady, initialFiltersSet])
      .pipe(
        filter(([columnsReady, filtersSet]) => columnsReady && filtersSet),
        tap(() => (this.viewInitialized = true)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.store.dispatch(getViewConfig(this.viewPayload));
  }

  private startUpdatePipelineSubscription() {
    this.updatePipeline
      .pipe(
        throttleTime(500, undefined, { leading: true, trailing: true }),
        tap(param => {
          updateBrowserURL.call(this, {
            [getQueryParamKey(this.viewId)]: param.encode()
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  /**
   * This component listens to changes in the query params encoded in the URL.
   * When a change happens, the current query params and the `view` associated
   * with this component are passed to {@link handleQueryParams}
   */
  private startQueryParamsObservable() {
    this.route.queryParamMap
      .pipe(
        filter(params => this.queryParamsChanged(params)),
        withLatestFrom(this.view),
        tap(([paramMap, view]) => {
          return this.handleQueryParams.call(this, paramMap, view);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private updateColumnsAndFilters() {
    this.view
      .pipe(
        tap(({ filters, columnIds }) => {
          if (columnIds) {
            this.urlColumns.next(columnIds);
          }
          this.filters = filters;
          this.onSortAndPageChange();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  /**
   * This function is called each time the URL query params change.
   * Depending on the settings encoded in the URL, it triggers a
   * data update.
   *
   * @param params Query Params directly from the router
   * @param view The `view` associated with this table
   */
  private handleQueryParams(params: ParamMap, view: View) {
    let forceReload = false;
    let isPagingDefined: boolean;
    let isSortingDefined: boolean;
    let isFilterDefined: boolean;
    let isQueryDefined: boolean;
    let areColumnsDefined: boolean;

    // The presence of this key indicates that data has changed
    // and needs to be refetched.
    if (params.has(RIMS_TABLE_QUERY_PARAM_RELOAD_TIME)) {
      forceReload = true;
    }

    const queryParam = params.get(getQueryParamKey(this.viewId));

    // Handle settings specific to this view, such as pagination and sort
    if (queryParam && this.queryParamsChanged(params, true)) {
      const param = ViewQueryParam.decode(queryParam);

      isPagingDefined = typeof param.s !== 'undefined' || typeof param.i !== 'undefined';
      isSortingDefined = typeof param.o !== 'undefined' && typeof param.d !== 'undefined';
      isFilterDefined = typeof param.f !== 'undefined';
      isQueryDefined = typeof param.q !== 'undefined';
      areColumnsDefined = typeof param.c !== 'undefined';

      if (isPagingDefined) {
        this.pageEvent = {
          pageSize: param.s,
          pageIndex: param.i || this.config.defaultPageIndex
        } as any;
      }

      if (isSortingDefined) {
        this.sortEvent = {
          active: param.o,
          direction: param.d
        };
      }

      if (isFilterDefined) {
        this.filters = param.f;
      } else {
        this.filters = undefined;
      }

      if (isQueryDefined) {
        this.query = param.q;
        this._showQueryInput = true;
      } else {
        this.query = undefined;
        if (!this.searchQueryComponent?.hasFocus()) {
          this._showQueryInput = false;
        }
      }

      if (areColumnsDefined) {
        this.urlColumns.next(param.c);
      }
    }

    const options: GetDataPayloadFromViewOptions = {
      url: this.customUrl
    };
    if (isPagingDefined) {
      options.page = this.pageEvent.pageIndex;
      options.pageSize = this.pageEvent.pageSize;
    }
    if (isSortingDefined) {
      options.sort = {
        ...this.sortEvent,
        // We need to map column ID to field name
        active: view.columns.find(col => `${col.id}` === this.sortEvent.active).field2.field
      };
    }
    // Store filters if they are not set yet
    if (isQueryDefined) {
      options.query = this.query;
    }
    this.store
      .pipe(
        take(1),
        filter(() => this.viewInitialized),
        select(state => state.metadata.views[view.id]),
        withLatestFrom(this.columnIds),
        tap(([view, columnIds]) => {
          const payload = GetDataPayload.fromView(view, options, columnIds);
          if (forceReload) {
            this.resetForceReload();
            return this.store.dispatch(getData(payload));
          }
          if (JSON.stringify(payload) !== JSON.stringify(this.dataPayload) && this.viewFiltersApplied(payload)) {
            this.dataPayload = payload;
            this.store.dispatch(getData(payload));
          }
        })
      )
      .subscribe();
  }

  /**
   * This function gets called from the paginator or the table sort headers.
   * It encodes the current sort and page settings and writes them to the URL.
   *
   * @param pageEvent updated pagination config, defaults to the first page and a
   *                  size setting from {@link AppSettings}
   * @param sortEvent updated sort config, defaults to undefined
   * @param filters array of filters, defaults to undefined
   * @param query query string for the `/search` endpoint, defaults to undefined
   */
  onSortAndPageChange(
    pageEvent = this.pageEvent,
    sortEvent = this.sortEvent,
    filters = this.filters,
    query = this.query
  ) {
    if (query) {
      this.store.dispatch(clearSelection({ viewId: this.viewId }));
    }

    const param = new ViewQueryParam({
      o: sortEvent?.active,
      d: sortEvent?.direction,
      s: pageEvent?.pageSize,
      i: pageEvent?.pageIndex,
      q: typeof query === 'string' && query.length > 0 ? query : undefined,
      f: Array.isArray(filters) && filters.length > 0 ? filters : undefined,
      c: Array.isArray(this.urlColumns.value) && this.urlColumns.value.length > 0 ? this.urlColumns.value : undefined
    });

    if (
      typeof pageEvent.pageIndex === 'undefined' ||
      pageEvent.pageSize !== this.pageEvent.pageSize ||
      sortEvent !== this.sortEvent ||
      filters !== this.filters ||
      query !== this.query
    ) {
      param.i = undefined;
    }

    this.sortEvent = sortEvent;

    this.updatePipeline.next(param);
  }

  /**
   * Removes all filters that are not readOnly
   */
  clearFilters() {
    this.store.dispatch(resetFilters({ viewId: this.viewId }));
  }

  addFilter(filterDef: OpenFilterCreationDialogPayload) {
    this.store.dispatch(
      openFilterCreationDialog({
        ...filterDef,
        viewId: this.viewId
      })
    );
  }

  dragStarted(event: CdkDragStart<MetadataDraggedColumn>) {
    this.store.dispatch(setDraggedColumn(event.source.data));
  }

  dropListDropped(event: CdkDragDrop<any>) {
    if (event) {
      this.store.dispatch(
        dropColumn({
          newIndex: event.currentIndex
        })
      );
    }
  }

  openColumnSortDialog(viewId: number) {
    this.store.dispatch(
      openColumnPreferencesDialog({
        viewId,
        fixedColumnIds:
          Array.isArray(this.urlColumns.value) && this.urlColumns.value.length > 0 ? this.urlColumns.value : undefined
      })
    );
  }

  /**
   * This function gets called whenever a row is selected or deselected.
   *
   * It triggers an action to update the selection in the store.
   *
   * @param row the selected/deselected record
   */
  updateSelection(row: ChangeTrackedEntity) {
    const selectedValue = PropertyAccessPipe.transform(row, this.checkBoxesProperty);
    if (typeof selectedValue !== 'number' && typeof selectedValue !== 'string') {
      console.error(`
[rims-table: updateSelection]
Please make sure that the selected value is of type string or number, not ${typeof selectedValue}.
You can use the "checkBoxesProperty" (currently set to "${this.checkBoxesProperty}")
property to change this.
      `);
      return;
    }

    this.isSelected(selectedValue)
      .pipe(take(1))
      .subscribe(isSelected => {
        const payload = {
          viewId: this.viewId,
          selection: [selectedValue]
        };
        const action = isSelected ? removeSelection(payload) : addSelection(payload);
        this.store.dispatch(action);
      });
  }

  isSelected(selectedValue: string | number) {
    return this.store.pipe(
      filter(() => this.viewInitialized),
      select(state => state.metadata),
      tap(metadata => {
        this.allRowsSelected = metadata.rowSelection[this.viewId]?.length === this.totalResultsSize;
        this.someRowsSelected = !this.allRowsSelected && metadata.rowSelection[this.viewId]?.length > 0;
      }),
      map(metadata => metadata.rowSelection[this.viewId]?.includes(selectedValue))
    );
  }

  allSelected(dataSource) {
    return this.store.pipe(
      filter(() => this.viewInitialized && dataSource),
      select(state => state.metadata),
      filter(metadata => !metadata.loading),
      map(metadata => metadata.rowSelection[this.viewId]?.length === dataSource.length),
      tap(allRowsSelected => {
        this.allRowsSelected = allRowsSelected;
      })
    );
  }

  selectAllTooltip() {
    const action = this.allRowsSelected ? 'Deselect' : 'Select';
    return `${action} all records across all pages`;
  }

  selectAllRows() {
    this.store
      .pipe(
        take(1),
        filter(() => this.viewInitialized),
        select(state => state.metadata.views[this.viewId]),
        withLatestFrom(this.columnIds),
        switchMap(([view, columnIds]) => {
          const payload = GetDataPayload.fromView(
            view,
            {
              url: this.customUrl
            },
            columnIds
          );
          return this.dataService.getAll(
            payload.url,
            payload.expand,
            0,
            Number.MAX_SAFE_INTEGER,
            payload.sort,
            payload.viewId,
            payload.filter,
            this.query,
            undefined,
            this.checkBoxesProperty
          );
        }),
        map(({ results }) => results),
        mergeMap((ids: Array<string | number>) => forkJoin([of(ids), this.allSelected(ids).pipe(take(1))]))
      )
      .subscribe(([ids, allSelected]) => {
        const payload = {
          viewId: this.viewId,
          selection: ids
        };
        const action = allSelected ? clearSelection(payload) : addSelection(payload);
        this.store.dispatch(action);
        this.allRowsSelected = !allSelected;
      });
  }

  /**
   * Returns the routerLink to the detail view of a table row.
   *
   * @param element an object represented in a table row
   */
  getDetailViewLink(element: ChangeTrackedEntity): Observable<string> {
    return this.view.pipe(
      map(view => {
        let entityName = (view.entity as AppEntity).name.toLowerCase();
        entityName = this.detailViewEntityName || this.config.entityNameMapping(entityName);
        const entityNameUrlSegment = pluralize(entityName.replace(/_/, ''));
        const id = getProperty(this.primaryKeyPropertyName || 'id', element);
        return `/view/${entityNameUrlSegment}/${id}`;
      })
    );
  }

  onDetailLinkClicked(element: ChangeTrackedEntity, resolvedColumn?: TableColumn) {
    const id = getProperty(this.primaryKeyPropertyName || 'id', element);
    this.breadcrumb.push({
      routerLink: id,
      label: id
    });

    if (resolvedColumn?.field2) {
      const { relation, linkProperty } = resolvedColumn.field2;
      const property = getProperty(linkProperty || this.primaryKeyPropertyName || 'id', element);

      const url = `/view/${relation}/${property}`;
      this.viewInitialized = false;
      return this.router.navigate([url]);
    }

    this.getDetailViewLink(element)
      .pipe(take(1))
      .subscribe(url => {
        this.viewInitialized = false;
        this.router.navigate([url]);
      });
  }

  /**
   * If the search input field is empty and the user clicks out of it
   * we want to display the search icon instead of the text input
   */
  onQueryBlur() {
    if (!this.queryParams.startsWith('q:') && !this.searchQueryComponent.hasFocus()) {
      this._showQueryInput = false;
    }
  }

  /**
   * only relevant for urls of the format "/view/<relation>/.." (detail-view-component)
   * not  relevant for urls of the format "/view/<relation>?.." (view-component)
   */
  public sameAsView(relation: string) {
    const viewName = this.router.url.split('/view/')[1].split('/')[0];
    return relation && relation === viewName;
  }

  /**
   * if the url has the format "/view/relation/.." the table is inside a detail-view-component
   */
  public withinDetailView() {
    return !!this.router.url.split('/view/')[1].split('/')[1];
  }

  public isSearchColumn(column: TableColumn) {
    return this.searchProperties.includes(column.field2.field);
  }

  openExportDialog() {
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.get(getQueryParamKey(this.viewId)) === curr.get(getQueryParamKey(this.viewId));
        }),
        map(params => {
          return ViewQueryParam.decode(params.get(getQueryParamKey(this.viewId)));
        }),
        take(1),
        tap(viewParam => {
          this.store.dispatch(
            openExportDialog({
              viewId: this.viewId,
              viewParam,
              allowExportAll: this.shouldAllowExportAll
            })
          );
        })
      )
      .subscribe();
  }

  private waitForColumns(state: AppState) {
    const { columnNames } = this.viewPayload;
    let ids: number[] | TableColumn[];
    if (columnNames?.length > 0) {
      ids = state.metadata.views[this.viewId].columnIds;
    } else {
      ids = state.metadata.views[this.viewId].displayedColumns;
    }

    return Array.isArray(ids) && ids.length > 0;
  }

  private queryParamsChanged(params: ParamMap, update = false) {
    const queryParams = this.getQueryParams(params);
    const changed = this.queryParams !== queryParams;
    if (changed && update) {
      this.queryParams = queryParams;
    }

    if (params.has(RIMS_TABLE_QUERY_PARAM_RELOAD_TIME)) {
      return true;
    }
    return changed && this.viewInitialized;
  }

  private getQueryParams(params: ParamMap): string {
    return params.get(getQueryParamKey(this.viewId));
  }

  private resetForceReload() {
    this.router.navigate([], {
      queryParams: {
        [RIMS_TABLE_QUERY_PARAM_RELOAD_TIME]: null
      },
      queryParamsHandling: 'merge'
    });
  }

  /**
   * Checks whether the filters defined in the viewPayload have already been applied
   */
  private viewFiltersApplied(payload: GetDataPayload): boolean {
    const viewFilters = this.viewPayload?.filters;

    if (!viewFilters?.length) {
      return true;
    }

    const filters = payload?.filter;

    if (!filters?.length) {
      return false;
    }

    // Check if every filter defined in viewFilters is present in filters
    return viewFilters.every(viewFilter =>
      filters.some(
        f => f.operator == viewFilter.operator && f.value == viewFilter.value && f.fieldName == viewFilter.fieldName
      )
    );
  }

  @HostListener('window:popstate')
  async resetView() {
    this.viewInitialized = false;
    this.clearFilters();
  }
}
