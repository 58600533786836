import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { select } from '@ngrx/store';
import { Action, ApplicationTier } from '@rims/lib';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { SyncPreviewService } from 'src/app/modules/sap/services/sync-preview.service';
import { PropertyEditMenuComponent } from 'src/app/modules/shared/components/rims-property-edit-menu/property-edit-menu.component';
import { PermissionsService } from 'src/app/modules/shared/services/permissions.service';
import { environment } from 'src/environments/environment';
import { DetailViewComponent } from '../../../detail-view/views/detail-view/detail-view.component';
import { openAddDialog, openRemoveDialog } from '../../../shared/store/shared/shared.actions';
import { openAddRiskClassToItemDialog, openChangeItemLifecycleDialog } from '../../store/item.actions';

@Component({
  selector: 'rims-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class ItemDetailComponent extends DetailViewComponent<any> implements OnInit {
  @ViewChild(PropertyEditMenuComponent, { static: false })
  editPropertyMenu: PropertyEditMenuComponent;

  selectedItemProductionSites: Observable<number[]>;
  selectedItemDocuments: Observable<number[]>;
  selectedItemNomenclatures: Observable<number[]>;
  selectedItemRiskClasses: Observable<number[]>;
  isInBudi: Observable<boolean>;
  isNotInBudi: Observable<boolean>;

  readonly isProductOwner = this.store.pipe(
    select(state => state.user.productOwnerPermissions?.item),
    switchMap(itemIds => {
      if (!Array.isArray(itemIds)) {
        return of(false);
      }

      return this.result.pipe(map(res => itemIds.includes(res.itemNumber)));
    })
  );

  readonly showSapSyncDebugInfo = of(environment.tier !== ApplicationTier.PRD).pipe(
    switchMap(precondition => {
      if (!precondition) {
        return of(false);
      }
      return this.permissions.hasActionPermission(Action.DEBUG_SAP_SYNC_PAYLOAD);
    })
  );
  sapSyncPreviewResult: any;

  constructor(
    injector: Injector,
    private readonly syncPreview: SyncPreviewService,
    private readonly permissions: PermissionsService
  ) {
    super(injector);
    this.options.next({
      pageTitleFunc: res => this.getProp('itemNumber2.itemNumber', res),
      primaryKeyProperty: 'itemNumber'
    });
  }

  ngOnInit() {
    this.isInBudi = this.store.pipe(
      select(state => state.data.entries['product_group_item']?.page?.results),
      map(groupRelations => groupRelations?.some(rel => !!rel.productGroup2?.budiInfo))
    );
    this.isNotInBudi = this.isInBudi.pipe(map(isInBudi => !isInBudi));
    this.selectedItemProductionSites = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(([resolveResult, selection]) => {
        return selection[resolveResult?.viewPayloads['item_production_site']?.viewId] as number[];
      })
    );
    this.selectedItemDocuments = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(([resolveResult, selection]) => {
        return selection[resolveResult?.viewPayloads['item_document']?.viewId] as number[];
      })
    );
    this.selectedItemNomenclatures = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(([resolveResult, selection]) => {
        return selection[resolveResult?.viewPayloads['item_nomenclature']?.viewId] as number[];
      })
    );
    this.selectedItemRiskClasses = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(([resolveResult, selection]) => {
        return selection[resolveResult?.viewPayloads['item_risk_class']?.viewId] as number[];
      })
    );
  }

  showSapSyncPreview() {
    return this.result
      .pipe(
        take(1),
        switchMap(result => this.syncPreview.getItemSyncPreview(result.itemNumber)),
        tap(data => {
          this.sapSyncPreviewResult = data;
        })
      )
      .subscribe();
  }

  openAddNomenclatureDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openAddDialog({
              key: 'item_nomenclature',
              entityName: 'nomenclature',
              dropdown: 'nomenclature types',
              toName: 'item',
              toId: res.itemNumber,
              searchLabel: 'Code Selection',
              placeholder: 'Search by Term or Number',
              duplicateHint: 'This nomenclature is already present on this item.',
              expand: ['nomenclatureType2'],
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveNomenclatureDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedItemNomenclatures])
      .pipe(
        take(1),
        tap(([res, resolveResult, itemNomenclatureIds]) => {
          const viewId = resolveResult?.viewPayloads['item_nomenclature']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_nomenclature',
              entityName: 'nomenclature',
              entityIds: itemNomenclatureIds,
              fromName: 'item',
              fromId: res.itemNumber
            })
          );
        })
      )
      .subscribe();
  }

  openAddDocumentDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openAddDialog({
              key: 'item_document',
              entityName: 'document',
              toName: 'item',
              toId: res.itemNumber,
              searchLabel: 'Search by Document Number',
              duplicateHint: 'This document is already linked to this item.',
              expand: ['dmsObject'],
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveDocumentDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedItemDocuments])
      .pipe(
        take(1),
        tap(([res, resolveResult, itemDocumentIds]) => {
          const viewId = resolveResult?.viewPayloads['item_document']?.viewId;

          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_document',
              entityName: 'document',
              entityIds: itemDocumentIds,
              fromName: 'item',
              fromId: res.itemNumber
            })
          );
        })
      )
      .subscribe();
  }

  openAddProductionSiteDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openAddDialog({
              key: 'item_production_site',
              entityName: 'company',
              displayName: 'production site',
              toName: 'item',
              toId: res.itemNumber,
              searchLabel: 'Search by Company Name or Address',
              notFoundHint: 'No company found. Try again with another query.',
              duplicateHint: 'This production site is already set for this item.',
              expand: ['address.country'],
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveProductionSite() {
    combineLatest([this.result, this.resolveResult, this.selectedItemProductionSites])
      .pipe(
        take(1),
        tap(([res, resolveResult, itemProductionSiteIds]) => {
          const viewId = resolveResult?.viewPayloads['item_production_site']?.viewId;

          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_production_site',
              entityName: 'company',
              displayName: 'production site',
              entityIds: itemProductionSiteIds,
              fromName: 'item',
              fromId: res.itemNumber
            })
          );
        })
      )
      .subscribe();
  }

  openChangeBudiLifecycleDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openChangeItemLifecycleDialog({
              itemBudiInfoId: res.budiInfo
            })
          );
        })
      )
      .subscribe();
  }

  openAddRiskClassDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openAddRiskClassToItemDialog({
              item: res.itemNumber
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveRiskClassDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedItemRiskClasses])
      .pipe(
        take(1),
        tap(([res, resolveResult, itemRiskClassIds]) => {
          const viewId = resolveResult?.viewPayloads['item_risk_class']?.viewId;

          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_risk_class',
              entityName: 'riskclass',
              displayName: 'risk class',
              entityIds: itemRiskClassIds,
              fromName: 'item',
              relation: 'riskClass2',
              fromId: res.itemNumber
            })
          );
        })
      )
      .subscribe();
  }
}
