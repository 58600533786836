<ng-container *rimsRequirePermission></ng-container>
<mat-menu [classList]="'property-edit-menu ' + widthClass" (closed)="onMenuClosed()">
  <!--
    Documentation for the available properties:

    - trigger
      Reference to the trigger that opened the menu
    - selectFieldName / selectExpression
      See PropertyEditMenuSelectValuePipe documentation.
    - emptyOption
      When given, a new "empty" option is displayed for select and boolean
      fields, allowing the user to set the field to `null`
    - useBaseEntityRecordIdForUpdate
      See PropertyEditEvent
    - optionValueExpression
      Map the options object to a new value which is then used for updating.
    - inPlaceCreateBtnTxt
      Enables this component to create new records when there are
      no search results.
    - confirm
      otional object with configuration regarding a confirm checkbox, namely:
        - conditionFn
          condition method which determines whether the confirm should be displayed  
        - description
          optional description text which is displayed above the checkbox
        - descriptionColor
          optional class for the description, e.g. 'warn' for red
        - checkboxTxt
          the text next to the checkbox which the user confirms
        - checkboxColor
          the color for the checkbox
  -->
  <ng-template
    matMenuContent
    let-trigger="trigger"
    let-placeholder="placeholder"
    let-element="element"
    let-field="field"
    let-value="value"
    let-inputType="inputType"
    let-options="options"
    let-loadAsyncOptions="loadAsyncOptions"
    let-selectFieldName="selectFieldName"
    let-selectExpression="selectExpression"
    let-entityName="entityName"
    let-recordId="recordId"
    let-isBoolean="isBoolean"
    let-emptyOption="emptyOption"
    let-inPlaceCreateBtnTxt="inPlaceCreateBtnTxt"
    let-search="search"
    let-date="date"
    let-useTextArea="useTextArea"
    let-disableOnEmpty="disableOnEmpty"
    let-useBaseEntityRecordIdForUpdate="useBaseEntityRecordIdForUpdate"
    let-optionValueExpression="optionValueExpression"
    let-confirm="confirm"
  >
    <mat-progress-bar mode="indeterminate" [class.invisible]="!(loading | async)"></mat-progress-bar>
    <div class="px-4 mt-2" (click)="$event.stopPropagation()" [attr.rims-field]="field | json">
      <ng-container [ngSwitch]="isBoolean">
        <!-- Boolean -->
        <ng-container *ngSwitchCase="true">
          <mat-form-field class="w-100" [class.d-none]="!!(error | async)">
            <mat-select [placeholder]="placeholder" [value]="value" (selectionChange)="newValue.setValue($event?.value); updateForm()">
              <ng-container *ngIf="emptyOption">
                <mat-option [disabled]="value | isUndefinedOrNull">{{ emptyOption }}</mat-option>
                <mat-divider></mat-divider>
              </ng-container>
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <!-- Input -->
          <ng-container *ngIf="!(options || loadAsyncOptions || search || date)" [ngSwitch]="useTextArea">
            <mat-form-field class="w-100" [class.d-none]="!!(error | async)">
              <textarea
                *ngSwitchCase="true"
                #textAreaControl
                (input)="newValue.setValue($event?.target?.value.trim() || ''); updateForm()"
                matInput
                [placeholder]="placeholder"
                [value]="value || ''"
                rows="6"
                [disabled]="storeNullValue | async"
              ></textarea>
              <input
                [type]="inputType ?? 'text'"
                *ngSwitchDefault
                #inputControl
                (input)="newValue.setValue($event?.target?.value.trim() || ''); updateForm()"
                matInput
                [placeholder]="placeholder"
                [value]="value || ''"
                [disabled]="storeNullValue | async"
              />
            </mat-form-field>
          </ng-container>

          <!-- Select -->
          <mat-form-field class="w-100" [class.d-none]="!!(error | async)" *ngIf="(options || loadAsyncOptions) && !search">
            <mat-select
              [disabled]="loading | async"
              [placeholder]="(loading | async) ? 'Loading options...' : placeholder"
              (selectionChange)="newValue.setValue($event?.value); updateForm()"
              [compareWith]="compareOptionsWithSelected"
              [value]="value"
            >
              <ng-container *ngIf="emptyOption">
                <mat-option [disabled]="value | isUndefinedOrNull">{{ emptyOption }}</mat-option>
                <mat-divider></mat-divider>
              </ng-container>
              <mat-option *ngFor="let opt of loadAsyncOptions ? (selectOptions | async)?.results : options" [value]="opt">
                {{ opt | propertyEditMenuSelectValue : selectFieldName : selectExpression }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Date -->
          <mat-form-field class="w-100" appearance="standard" [class.d-none]="!!(error | async)" *ngIf="date">
            <input #dateInput matInput readonly [matDatepicker]="picker" [value]="value" (dateChange)="onDateChange($event); updateForm()" [disabled]="storeNullValue | async" />
            <button *ngIf="emptyOption" [disabled]="dateIsEmpty()" matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); onStoreNullValueChange($event)">
              <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <!-- Search -->
          <ng-container *ngIf="search">
            <mat-form-field class="w-100" [class.d-none]="!!(error | async)">
              <input
                #searchInput
                type="text"
                [value]="query | async"
                matInput
                [matAutocomplete]="auto"
                (keyup)="query.next(searchInput.value); updateForm()"
                placeholder="Type to search..."
                [disabled]="storeNullValue | async"
              />
              <button
                class="create-new"
                matSuffix
                mat-raised-button
                *ngIf="enableInPlaceCreate && inPlaceCreateBtnTxt && !foundOptions && query.value && !(loading | async) && hasCreatePermission()"
                (click)="dispatchCreateNewRecordEvent()"
              >
                {{ inPlaceCreateBtnTxt }}
                <mat-icon matSuffix [matTooltip]="'This will create a new record of \'' + query.value + '\''"> info_outlined </mat-icon>
              </button>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelect($event)" [displayWith]="getOptionText.bind(this, selectFieldName, selectExpression)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option | propertyEditMenuSelectValue : selectFieldName : selectExpression }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="confirm?.conditionFn()">
        <span [ngClass]="confirm.descriptionColor" *ngIf="confirm.description" style="display: block; margin-bottom: 1rem" [innerHtml]="confirm.description"></span>
        <mat-checkbox [checked]="confirmChecked" (change)="onCheckConfirm($event)" [color]="confirm.checkboxColor"><span [innerHtml]="confirm.checkboxTxt"></span></mat-checkbox>
      </ng-container>
      <span class="error" *ngIf="error | async">{{ error | async }}</span>
      <mat-error *ngIf="newValue.value !== value && newValue.touched && newValue.hasError('duplicate')">{{ newValue.errors.duplicate }}</mat-error>
      <mat-error *ngIf="newValue.value !== value && newValue.touched && newValue.hasError('invalidNumber')">{{ newValue.errors.invalidNumber }}</mat-error>
      <mat-error *ngIf="newValue.touched && newValue.hasError('pattern')">{{ newValue.errors.pattern }}</mat-error>
    </div>
    <div class="px-4 pb-3 d-flex justify-content-between align-items-center" (click)="$event.stopPropagation()">
      <mat-checkbox *ngIf="value && search && emptyOption; else placeholderDiv" (change)="onStoreNullValueChange($event)">
        {{ emptyOption }}
      </mat-checkbox>
      <ng-template #placeholderDiv>
        <div></div>
      </ng-template>
      <div>
        <button
          mat-button
          color="primary"
          [disabled]="
            (search && !optionSelected.value && query.value) ||
            (emptyOption && search && !optionSelected.value && !(storeNullValue | async)) ||
            (loading | async) ||
            (error | async) ||
            !newValue.touched ||
            value == newValue.value ||
            ((newValue.value == null || newValue.value == '') && disableOnEmpty) ||
            (confirm?.conditionFn() && !confirmChecked) ||
            newValue.invalid ||
            newValue.pending
          "
          (click)="
            updateProperty.emit({
              element: element,
              entityName: entityName,
              recordId: recordId,
              value: getValueWithProperty(field, !!emptyOption, optionValueExpression),
              useBaseEntityRecordIdForUpdate: useBaseEntityRecordIdForUpdate
            });
            trigger.closeMenu();
            storeNullValue.next(false)
          "
        >
          Save
        </button>
        <button mat-button class="ml-2" (click)="trigger.closeMenu()">Cancel</button>
      </div>
    </div>
  </ng-template>
</mat-menu>
