<rims-header>
  <rims-header-title>
    {{ (view | async)?.displayName }}
  </rims-header-title>
</rims-header>

<div class="container view-margin-bottom">
  <div class="row pb-5 mb-3 pt-3">
    <div class="col">
      <rims-table
        *ngIf="resolveResult | async as res"
        [shouldDisplayDetailLink]="shouldDisplayDetailLink | async"
        [shouldDisplayCheckboxes]="shouldDisplayCheckboxes | async"
        [checkBoxesProperty]="checkBoxesProperty | async"
        [detailViewEntityName]="detailViewEntityName | async"
        [shouldDisplaySearchInput]="shouldDisplaySearchInput | async"
        [primaryKeyPropertyName]="primaryKeyPropertyName | async"
        [shouldDisplayExportDialog]="shouldDisplayExportDialog | async"
        [shouldAllowExportAll]="shouldAllowExportAll | async"
        [filterMenus]="filterMenus | async"
        [dataKey]="res.dataKey"
        [viewConfigPayload]="{ viewId: res.viewId, browserRefresh: res.browserRefresh }"
      >
        <rims-table-action
          e2e-id="delete-action"
          (click)="handleDelete()"
          *rimsRequirePermission="res.dataKey; action: 'delete'; condition: (shouldDisplayDeleteAction | async) && (selectedRecords | async)?.length > 0"
        >
          <mat-icon>remove</mat-icon>
          Delete {{ (selectedRecords | async).length | i18nPlural : getMapping('Record') }}
        </rims-table-action>
      </rims-table>
    </div>
  </div>
</div>

<ng-container *ngIf="createNewRecordLink | async as newRecordLink">
  <div *rimsRequirePermission="newRecordLink.entityName; action: 'create'" class="container">
    <div class="row">
      <div class="col">
        <rims-floating-button [routerLink]="newRecordLink.entityName | entityNameToNewRecordLink"></rims-floating-button>
      </div>
    </div>
  </div>
</ng-container>
