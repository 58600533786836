import { Component, ElementRef, ViewChild } from '@angular/core';
import { getTieredTitle } from '../../../utils/metadata';

@Component({
  selector: 'rims-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent {
  @ViewChild('title') title: ElementRef;

  getTitle() {
    return getTieredTitle(this.title.nativeElement.textContent);
  }
}
