import { createAction, props } from '@ngrx/store';
import { MaintenanceInformation } from '@rims/lib';
import { MetaState } from './meta.state';

const PREFIX = '[Meta]';

export const loadApplicationMetadata = createAction(`${PREFIX} Load Application Metadata`);
export const loadApplicationMetadataSuccess = createAction(
  `${PREFIX} Load Application Metadata Success`,
  props<Pick<MetaState, 'appVersion' | 'buildTime' | 'commitHash'>>()
);

export const getMaintenanceModeInformation = createAction(`${PREFIX} Get Maintenance Mode Information`);
export const getMaintenanceModeInformationSuccess = createAction(
  `${PREFIX} Get Maintenance Mode Information Success`,
  props<MaintenanceInformation>()
);
export const getMaintenanceModeInformationError = createAction(
  `${PREFIX} Get Maintenance Mode Information Error`,
  props<Error | undefined>()
);
