import { Component, Injector, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PropertyEditMenuComponent } from 'src/app/modules/shared/components/rims-property-edit-menu/property-edit-menu.component';
import { DetailViewComponent } from '../../../detail-view/views/detail-view/detail-view.component';
import { UpdatePermissionLevelDialogComponent } from '../../components/update-permission-level-dialog/update-permission-level-dialog.component';

@Component({
  selector: 'rims-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent extends DetailViewComponent<any> {
  @ViewChild(PropertyEditMenuComponent, { static: false })
  editPropertyMenu: PropertyEditMenuComponent;

  constructor(injector: Injector, private readonly dialog: MatDialog) {
    super(injector);
  }

  openUpdatePermissionLevelDialog() {
    combineLatest([this.result, this.entity])
      .pipe(
        take(1),
        map(([role, entity]) => {
          this.dialog.open(UpdatePermissionLevelDialogComponent, {
            panelClass: 'custom-dialog-width',
            data: {
              role,
              entity
            }
          });
        })
      )
      .subscribe();
  }
}
