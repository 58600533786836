import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Route, Router, RouterModule, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, filter, take } from 'rxjs/operators';
import { appConfig } from './app.constants';
import { HomeComponent } from './modules/+home/home.component';
import { FilterMenu, FilterMenuGroup } from './modules/shared/store/metadata/metadata.actions';
import { getTieredTitle } from './modules/shared/utils/metadata';
import { InvalidUserTypeLoginAttemptComponent } from './modules/shared/views/invalid-user-type-login-attempt/invalid-user-type-login-attempt.component';
import { MaintenanceComponent } from './modules/shared/views/maintenance/maintenance.component';
import { isLoading } from './modules/store/store.state';
import { ViewByUrlParamResolver } from './modules/view/resolvers/view-by-url-param.resolver';
import { ViewComponent } from './modules/view/views/view/view.component';

interface ViewComponentRoute extends Route {
  data?: {
    [key: string]: any;
    filterMenus: (FilterMenu | FilterMenuGroup)[];
  };
}

/**
 * CustomRoutes always expects the first route to be a `ViewComponentRoute` which is like `Route`
 * but also provides typings for `filterMenus`
 */
export type CustomRoutes = [ViewComponentRoute, ...Route[]];

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'invalid-user-type-login-attempt',
        component: InvalidUserTypeLoginAttemptComponent,
        data: {
          meta: {
            title: 'Invalid User Type'
          }
        }
      },
      {
        path: appConfig.maintenanceMode.route,
        component: MaintenanceComponent,
        data: {
          meta: {
            title: 'Maintenance Mode'
          }
        }
      },
      {
        path: 'version-information',
        redirectTo: '/docs/version-information'
      },
      {
        path: 'docs',
        loadChildren: () => import('./modules/documentation/documentation.module').then(m => m.DocumentationModule)
      },
      {
        path: 'home',
        component: HomeComponent,
        title: getTieredTitle('Home')
      },
      {
        path: 'view',
        children: [
          {
            path: 'products',
            loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
          },
          {
            path: 'actors',
            loadChildren: () => import('./modules/actors/actors.module').then(m => m.ActorsModule)
          },
          {
            path: 'addresses',
            loadChildren: () => import('./modules/addresses/addresses.module').then(m => m.AddressesModule)
          },
          {
            path: 'documents',
            loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule)
          },
          {
            path: 'document-types',
            loadChildren: () =>
              import('./modules/document-types/document-types.module').then(m => m.DocumentTypesModule)
          },
          {
            path: 'extdmsobjects',
            loadChildren: () =>
              import('./modules/ext-dms-objects/ext-dms-objects.module').then(m => m.ExtDmsObjectsModule)
          },
          {
            path: 'items',
            loadChildren: () => import('./modules/items/items.module').then(m => m.ItemsModule)
          },
          {
            path: 'productgroups',
            loadChildren: () =>
              import('./modules/product-groups/product-groups.module').then(m => m.ProductGroupsModule)
          },
          {
            path: 'roles',
            loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule)
          },
          {
            path: 'productgrouptypes',
            loadChildren: () =>
              import('./modules/product-group-types/product-group-types.module').then(m => m.ProductGroupTypesModule)
          },
          {
            path: 'companies',
            loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule)
          },
          {
            path: 'nomenclatures',
            loadChildren: () => import('./modules/nomenclatures/nomenclatures.module').then(m => m.NomenclaturesModule)
          },
          {
            path: 'containers',
            loadChildren: () => import('./modules/containers/containers.module').then(m => m.ContainersModule)
          },
          {
            path: 'users',
            loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
          },
          {
            path: 'permissions',
            loadChildren: () => import('./modules/permissions/permissions.module').then(m => m.PermissionsModule)
          },
          {
            path: 'controllersettings',
            loadChildren: () =>
              import('./modules/controller-settings/controller-settings.module').then(m => m.ControllerSettingsModule)
          },
          {
            path: 'intendedpurposes',
            loadChildren: () =>
              import('./modules/intended-purposes/intended-purposes.module').then(m => m.IntendedPurposesModule)
          },
          {
            path: ':viewId',
            component: ViewComponent,
            resolve: {
              result: ViewByUrlParamResolver
            }
          },
          {
            path: '**',
            redirectTo: '..'
          }
        ]
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

const routeLookupCache = new Map<string, boolean>();

export function routeHasOwnModule(route: string) {
  if (routeLookupCache.has(route)) {
    return routeLookupCache.get(route);
  }

  const viewModulesNode = routes[0].children.find(r => r.path === 'view');
  const hasOwnModule = !!viewModulesNode.children.find(child => child.path === route);
  routeLookupCache.set(route, hasOwnModule);
  return hasOwnModule;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  private popstate = false;
  private positions = new Map<string, [number, number]>();
  private previousUrl: string;

  constructor(private router: Router, private viewportScroller: ViewportScroller, private store: Store) {
    // scroll to top on all route changes which aren't solely query param changes
    // scroll to previously saved scroll position when navigating back
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart) {
        this.handleBackNavigation(e);
      } else if (e instanceof NavigationEnd) {
        this.setScrollPosition(e);
      }
    });
  }

  handleBackNavigation(e: NavigationStart) {
    if (e.navigationTrigger === 'popstate') {
      this.popstate = true;
    } else if (!e.url.includes('?')) {
      // save scroll position for next popstate
      this.positions.set(this.previousUrl, this.viewportScroller.getScrollPosition());
    }
  }

  setScrollPosition(e: NavigationEnd) {
    // the current url will be the 'previousUrl' on the next NavigationStart event
    this.previousUrl = e.url.split('?')[0];
    if (this.popstate) {
      this.popstate = false;
      this.store
        .select(isLoading)
        .pipe(
          debounceTime(400),
          filter(loading => !loading),
          take(1)
        )
        .subscribe(() => {
          const url = e.url.split('?')[0];
          const position = this.positions.get(url) || [0, 0];
          this.viewportScroller.scrollToPosition(position);
        });
    } else if (!e.url.includes('?')) {
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }
}
