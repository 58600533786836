import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SapBudiObject } from '@rims/sap-interface';

@Component({
  selector: 'rims-budi-materials-dialog',
  templateUrl: './budi-materials-dialog.component.html',
  styleUrls: ['./budi-materials-dialog.component.scss']
})
export class BudiMaterialsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: SapBudiObject
  ) {}
}
