import { Pipe, PipeTransform } from '@angular/core';
import { RiskClassOptionFormatPipe } from '../../shared/pipes/risk-class-option-format.pipe';
import { isNullOrUndefined } from '../utils/object-utils';

@Pipe({
  name: 'riskClassAndLegislation'
})
export class RiskClassAndLegislationPipe implements PipeTransform {
  private readonly riskClassOptionPipe = new RiskClassOptionFormatPipe();

  transform(productGroup: any): string {
    const riskClass = productGroup?.budiInfo2?.riskClass;
    if (isNullOrUndefined(riskClass?.class)) {
      return 'Not defined';
    }

    return this.riskClassOptionPipe.transform(riskClass);
  }
}
