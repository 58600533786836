import { Pipe, PipeTransform } from '@angular/core';
import { AddressFormatPipe } from './address-format.pipe';
import { SelectExpression } from './property-edit-menu-select-value.pipe';

const addressPipe = new AddressFormatPipe();

export const companySelectExpression: SelectExpression = company =>
  `${company.name} (${addressPipe.transform(company.address)})`;

@Pipe({
  name: 'companyOptionFormat'
})
export class CompanyOptionFormatPipe implements PipeTransform {
  transform(company: any): string {
    return companySelectExpression(company);
  }
}
