<div *ngIf="(loggedInUser | async) || (isInvalidLoginAttempt | async)">
  <div *ngIf="showTier" class="tier">
    <div class="container">
      <div class="row">
        <div class="col py-1">
          <span>
            You are working on the
            <strong class="text-uppercase">{{ tier }}</strong>
            environment. For productive use, please go to
            <a class="unstyled" href="https://rims.bbraun.cloud">rims.bbraun.cloud</a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  <rims-footer *ngIf="!(isMaintenanceModeEnabled | async)"></rims-footer>
</div>
<div *ngIf="!((loggedInUser | async) || (isInvalidLoginAttempt | async))">
  <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
</div>
