import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ItemNotDeleted, ProductGroupBudiInfo } from '@rims/database';
import { EqualsFilter, Filter, InFilter } from '@rims/lib';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ProductGroupValidator } from '../../product-groups/validators/product-group.validator';
import { DataService } from '../../shared/services/data/data.service';

export enum ValidatorErrors {
  DUPLICATE = 'duplicate',
  CUSTOM = 'custom',
  CAN_NOT_DETERMINE_UNIQUENESS = 'canNotDetermineUniqueness'
}

@Injectable({
  providedIn: 'root'
})
export class DuplicateValidator {
  constructor(
    private readonly data: DataService,
    private readonly productGroupValidator: ProductGroupValidator,
    private readonly http: HttpClient
  ) {}

  duplicateValidator(url: string, filters: Filter[], target = 'id'): Observable<ValidationErrors | null> {
    if (filters.some(filter => !filter?.value)) {
      return of({
        [ValidatorErrors.CAN_NOT_DETERMINE_UNIQUENESS]: true
      });
    }

    const isDuplicate = this.data.getAll(url, undefined, undefined, undefined, undefined, undefined, filters).pipe(
      map(page => {
        return {
          isDuplicate: page.resultsSize > 0,
          ids: page?.results.map(result => result[target])
        };
      })
    );

    return isDuplicate.pipe(
      map(({ isDuplicate, ids }) => {
        if (isDuplicate) {
          return {
            [ValidatorErrors.DUPLICATE]: ids // error will be thrown regardless of whether ids exist
          };
        }
        return null;
      })
    );
  }

  /**
   *
   * This validator does not only check for duplicates but also
   * - whether the item is already assigned to another group of the same type
   * - whether (in case of BUDI) the item properties are in sync with the ones of the BUDI
   *
   * This all needs to be done within one single validator to ensure the order of validation ('propertyMismatchValidator' needs to be executed last).
   * We can only allow the user to overwrite the properties of mismatched items + creating the respective product group item
   * when the previous validators did not throw (and thus the items are valid)
   */
  productGroupItemValidator(
    selectedItems: ItemNotDeleted[],
    group: number,
    duplicateHint: string,
    budiInfo?: ProductGroupBudiInfo,
    skipMismatchValidation = false,
    overwriteItemsPermission = false,
    isEbudi = false
  ): Observable<ValidationErrors | null> {
    const itemNumbers = selectedItems.map(item => item.itemNumber);

    if (!(group && itemNumbers)) {
      return of({
        [ValidatorErrors.CAN_NOT_DETERMINE_UNIQUENESS]: true
      });
    }

    const isDuplicate$ = this.data
      .getAll('productgroupitems', undefined, undefined, undefined, undefined, undefined, [
        new EqualsFilter('productGroup', group),
        new InFilter('item', itemNumbers.join(','))
      ])
      .pipe(
        map(page => {
          return page.results?.map(result => result.item);
        })
      );

    const groupType = this.data
      .getAll('productgroups', ['groupType2'], undefined, undefined, undefined, undefined, [
        new EqualsFilter('id', group)
      ])
      .pipe(
        map(page => {
          const shortName = page.results[0].groupType2.shortName;
          return {
            isBudi: shortName === 'BUDI' || shortName === 'EBUDI',
            isRmf: shortName === 'RMF'
          };
        })
      );

    return isDuplicate$.pipe(
      switchMap(duplicates => {
        const newItems = itemNumbers.filter(item => !duplicates.includes(item));
        let elements = duplicates.map(id => ({ id, message: duplicateHint })) ?? [];

        if (!newItems.length) {
          if (elements.length > 0) return of({ [ValidatorErrors.CUSTOM]: elements });
          return of(null);
        }

        return groupType.pipe(
          switchMap(({ isBudi, isRmf }) => {
            if (isBudi) {
              return this.checkGroupAssignment('BUDI, EBUDI', newItems).pipe(
                map(({ BUDI, EBUDI }) => {
                  const message = `Item already assigned to another`;
                  const assigned = [
                    ...BUDI.map(id => ({ id, message: `${message} BUDI` })),
                    ...EBUDI.map(id => ({ id, message: `${message} EBUDI` }))
                  ];
                  elements = [...elements, ...assigned];

                  return elements;
                })
              );
            }

            if (isRmf) {
              return this.checkGroupAssignment('RMF', newItems).pipe(
                map(({ RMF }) => {
                  const message = 'Item already assigned to another RMF';
                  const assigned = RMF.map(id => ({ id, message }));
                  elements = [...elements, ...assigned];

                  return elements;
                })
              );
            }

            return of([]);
          }),
          map(elements => {
            const elementIds = elements.map(element => element?.id);
            const validItems = selectedItems.filter(item => !elementIds.includes(item.itemNumber));

            if (budiInfo && validItems.length && !skipMismatchValidation) {
              const mismatchElements = this.productGroupValidator.propertyMismatchValidator(
                budiInfo,
                validItems,
                overwriteItemsPermission,
                isEbudi
              );

              return [...elements, ...mismatchElements];
            }

            return elements;
          }),
          map(errors => {
            return errors.length ? { [ValidatorErrors.CUSTOM]: errors } : null;
          })
        );
      })
    );
  }

  private checkGroupAssignment(type: string, items: any[]) {
    const url = new URL(`/productgroupitems/are-in-any-group-of-type`, environment.backendUrl);
    url.searchParams.set('type', type);
    url.searchParams.set('items', items.join(','));
    return this.http.get<{ BUDI?: string[]; EBUDI?: string[]; RMF?: string[] }>(url.toString());
  }
}
