/**
 * Returns the difference between two arrays.
 * Create a Set from b, then use Array.prototype.filter() on a to only keep values not contained in b.
 *
 * @see https://www.30secondsofcode.org/snippet/difference
 *
 * @param a array a
 * @param b array b
 */
export function difference<T = any>(a: T[], b: T[]) {
  const s = new Set(b);
  return a.filter(x => !s.has(x));
}

/**
 * Returns all unique values of an array, based on a provided comparator function.
 * Use Array.prototype.reduce() and Array.prototype.some() for an array containing only the first unique
 * occurrence of each value, based on the comparator function, fn. The comparator function takes two arguments:
 * the values of the two elements being compared.
 *
 * @see https://www.30secondsofcode.org/snippet/uniqueElementsBy
 *
 * @param arr an array
 * @param comparator function to compare
 */
export function uniqueElementsBy<T = any>(arr: T[], comparator: (a: T, b: T) => boolean) {
  return arr.reduce((acc, v) => {
    if (!acc.some(x => comparator(v, x))) acc.push(v);
    return acc;
  }, []);
}
