import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CrudQueryParameters, ProductOwnerPermissions } from '@rims/lib';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import { environment } from '../../../../../environments/environment';
import { AppField } from '../../models/field/field.model';
import { Page } from '../../models/page/page.model';
import { TableColumn } from '../../models/table/table-column.model';
import { AppEntity } from '../../models/utils/entity.model';
import { AppViewGroup } from '../../models/view/view-group.model';
import { View } from '../../models/view/view.model';
import { ViewConfig } from '../../store/data/data.state';
import { removeProductOwnerPermission, setProductOwnerPermission } from '../../store/user/user.actions';
import { RequestBuilder } from '../request-builder/request-builder.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  constructor(private readonly requestBuilder: RequestBuilder, private readonly store: Store<AppState>) {}

  getViews(): Observable<Page<View>> {
    return this.requestBuilder
      .request(environment.backendUrl, 'meta', 'views')
      .param(CrudQueryParameters.EXPAND, 'role,icon')
      .param(CrudQueryParameters.LOAD_RELATION_IDS, 'true')
      .param(CrudQueryParameters.ORDER_BY, 'displayName')
      .get();
  }

  getViewForField(fieldId: number): Observable<Page<View>> {
    return this.requestBuilder.request(environment.backendUrl, 'meta', 'fields', fieldId + '', 'view').get();
  }

  getViewForFieldRelation(fieldId: number): Observable<Page<View>> {
    return this.requestBuilder.request(environment.backendUrl, 'meta', 'fields', fieldId + '', 'relationView').get();
  }

  getViewGroups(): Observable<Page<AppViewGroup>> {
    return this.requestBuilder.request(environment.backendUrl, 'meta', 'view-groups').get();
  }

  getPermissions(): Observable<Page<AppEntity>> {
    // Implicitly disable pagination by setting 'limit' to a high value.
    // This is needed because we generally want pagination for permissions to be enabled but on app start it should load all permissions once
    // This is a temporary solution, TODO: refactor once we add a maximum limit based on the users role
    return this.requestBuilder
      .request(environment.backendUrl, 'permissions')
      .param(CrudQueryParameters.LIMIT, '1000')
      .param(CrudQueryParameters.LOAD_RELATION_IDS, 'true')
      .get();
  }

  getEntities(): Observable<Page<AppEntity>> {
    return this.requestBuilder.request(environment.backendUrl, 'meta', 'entities').get();
  }

  getFields(entityId: number): Observable<Page<AppField>> {
    return this.requestBuilder
      .request(environment.backendUrl, 'meta', 'fields')
      .param('entity', entityId.toString())
      .get();
  }

  getColumns(viewId: number): Observable<ViewConfig> {
    return this.requestBuilder
      .request(environment.backendUrl, 'meta', 'columns')
      .param('view', viewId.toString())
      .get();
  }

  updateColumns(viewId: number, columns: Pick<TableColumn, 'id' | 'defaultOrder' | 'hide'>[]): Observable<any> {
    return this.requestBuilder
      .request(environment.backendUrl, 'meta', 'columns')
      .param('view', viewId.toString())
      .body({
        overrides: columns.map(column => ({
          column: column.id,
          order: Number(column.defaultOrder),
          hide: column.hide
        }))
      })
      .patch();
  }

  resetColumns(viewId: number) {
    return this.requestBuilder
      .request(environment.backendUrl, 'meta', 'columns', 'preferences')
      .param('view', viewId.toString())
      .delete();
  }

  getMaintenanceModeInformation() {
    return this.requestBuilder.request(environment.backendUrl, 'meta', 'maintenance').get();
  }

  hasProductOwnerPermissions(entity: string, record: number | string, forceRecordFormat?: 'string' | 'number') {
    return this.store.pipe(
      select(state => state.user.user.id),
      take(1),
      switchMap(userId => {
        return this.requestBuilder
          .request(environment.backendUrl, 'permissions', 'has-product-owner-permissions')
          .param('entity', entity)
          .param('user', userId)
          .param('record', record + '')
          .param('forceRecordFormat', forceRecordFormat)
          .get()
          .pipe(
            tap((result: ProductOwnerPermissions) => {
              if (result.hasPermission) {
                this.store.dispatch(
                  setProductOwnerPermission({
                    entity,
                    record
                  })
                );
              } else {
                this.store.dispatch(
                  removeProductOwnerPermission({
                    entity,
                    record
                  })
                );
              }
            })
          );
      })
    );
  }
}
