import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AppViewGroup } from '../models/view/view-group.model';
import { View } from '../models/view/view.model';
import { FilterViewsPipe } from './filter-views.pipe';

@Pipe({
  name: 'filterViewGroups'
})
export class FilterViewGroupsPipe implements PipeTransform {
  transform(
    viewGroupKeyValuePairs: KeyValue<string, AppViewGroup>[],
    views: View[]
  ): KeyValue<string, AppViewGroup>[] | undefined {
    if (!(viewGroupKeyValuePairs && views)) {
      return undefined;
    }

    const filterViewsPipe = new FilterViewsPipe();
    return viewGroupKeyValuePairs.filter(keyValuePair => {
      const matchingViews = filterViewsPipe.transform(views, keyValuePair.value);
      return matchingViews.length > 0;
    });
  }
}
