import { createFeatureSelector } from '@ngrx/store';
import { RecordHistory } from '@rims/lib';
import { Page } from '../../models/page/page.model';
import { TableColumn } from '../../models/table/table-column.model';
import { View } from '../../models/view/view.model';

interface ColumnConfig {
  columns: TableColumn[];
  draggedColumn: TableColumn;
}

export interface ViewConfig {
  columns: TableColumn[];
  preferences: any;
  view: View;
}

export interface DataStateEntry<T = any> {
  page?: Page<T>;
  history?: RecordHistory;
  loading: boolean;
}

export interface DataState {
  entries: {
    [key: string]: DataStateEntry;
  };
  loading: boolean;
}

export const DATA_INITIAL_STATE: DataState = {
  entries: {},
  loading: false
};

export const selectData = createFeatureSelector<DataState>('data');
