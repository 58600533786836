import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ChangeTrackedEntity } from 'src/app/modules/shared/models/utils/change-tracked-entity.model';
import { updateData } from 'src/app/modules/shared/store/data/data.actions';
import { AppState } from 'src/app/modules/store/store.state';

export interface ChangeContainerTypeDialogConfig {
  newIsAdvancedValue: boolean;
  availableTypes: ChangeTrackedEntity[];
  containerEntityId: number;
  containerId: number;
}

@Component({
  selector: 'rims-change-container-type-dialog',
  templateUrl: './change-container-type-dialog.component.html',
  styleUrls: ['./change-container-type-dialog.component.scss']
})
export class ChangeContainerTypeDialogComponent {
  form = new UntypedFormGroup({
    type: new UntypedFormControl(null, Validators.required),
    confirmChange: new UntypedFormControl(false, Validators.requiredTrue)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: ChangeContainerTypeDialogConfig,
    private readonly store: Store<AppState>,
    private readonly ref: MatDialogRef<ChangeContainerTypeDialogComponent>
  ) {}

  onSubmit() {
    const payload = this.data.newIsAdvancedValue
      ? {
          type: this.form.value.type,
          isAdvanced: this.data.newIsAdvancedValue
        }
      : {
          type: this.form.value.type,
          isAdvanced: this.data.newIsAdvancedValue,
          color: null,
          cap: null,
          shape: null
        };
    this.store.dispatch(
      updateData({
        entityId: this.data.containerEntityId,
        id: this.data.containerId + '',
        value: payload
      })
    );

    this.ref.close();
  }
}
