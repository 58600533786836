import { Injector, Pipe, PipeTransform, Type } from '@angular/core';
import { ContainerOptionFormatPipe } from '../../containers/pipes/container-option-format.pipe';
import { ContainerSizePipe } from '../../containers/pipes/container-size.pipe';
import { ActorOptionFormatPipe } from './actor-option-format.pipe';
import { CoeOptionFormatPipe } from './coe-option-format.pipe';
import { CompanyOptionFormatPipe } from './company-option-format.pipe';
import { DocumentOptionFormatPipe } from './document-option-format.pipe';
import { IdentificationNumberPipe } from './identification-number.pipe';
import { IntendedPurposePipe } from './intended-purpose.pipe';
import { ItemOptionFormatPipe } from './item-option-format.pipe';
import { NamePipe } from './name.pipe';
import { NomenclatureOptionFormatPipe } from './nomenclature-option-format.pipe';
import { ProductGroupOptionFormatPipe } from './product-group-option-format.pipe';
import { PropertyAccessPipe } from './property-access.pipe';
import { RiskClassAndLegislationPipe } from './risk-class-and-legislation.pipe';
import { RiskClassOptionFormatPipe } from './risk-class-option-format.pipe';
import { UserOptionFormatPipe } from './user-option-format.pipe';

/**
 * A list of available pipes to be used with the dynamic pipe.
 *
 * @see map below
 */
export type ManagedPipeToken =
  | 'coe'
  | 'name'
  | 'riskclass'
  | 'riskClassAndLegislation'
  | 'containerSize'
  | 'container'
  | 'company'
  | 'document'
  | 'nomenclature'
  | 'actor'
  | 'productgroup'
  | 'item'
  | 'user'
  | 'intendedpurpose'
  | 'identificationnumber';

/**
 * To enable the `Injector` to access different pipes,
 * they must be listed in their module's `providers`.
 */
const map = new Map<ManagedPipeToken, Type<any>>([
  ['coe', CoeOptionFormatPipe],
  ['name', NamePipe],
  ['riskclass', RiskClassOptionFormatPipe],
  ['riskClassAndLegislation', RiskClassAndLegislationPipe],
  ['containerSize', ContainerSizePipe],
  ['container', ContainerOptionFormatPipe],
  ['company', CompanyOptionFormatPipe],
  ['document', DocumentOptionFormatPipe],
  ['nomenclature', NomenclatureOptionFormatPipe],
  ['actor', ActorOptionFormatPipe],
  ['productgroup', ProductGroupOptionFormatPipe],
  ['item', ItemOptionFormatPipe],
  ['user', UserOptionFormatPipe],
  ['intendedpurpose', IntendedPurposePipe],
  ['identificationnumber', IdentificationNumberPipe]
]);

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {
  constructor(private readonly injector: Injector) {}

  /**
   * @param value the object to be transformed
   * @param pipeToken one of the managed pipe names
   * @param prePipeProperty use value[prePipeProperty] instead of value for the pipe
   * @param fieldName if no pipe is given, use this field
   * @param defaultValue default value to be displayed
   * @param debug if true, the value object is printed to the console
   */
  transform(
    value: any,
    pipeToken: ManagedPipeToken,
    prePipeProperty: string,
    fieldName: string,
    defaultValue: string,
    debug = false
  ): string {
    if (debug) {
      console.debug(this.withPrefix('value'), value);
    }

    if (!pipeToken) {
      return PropertyAccessPipe.transform(value, fieldName, defaultValue);
    }

    const token = map.get(pipeToken);
    if (!token) {
      alert(this.withPrefix(`Please make sure "${pipeToken}" is a ManagedPipeToken and is present in the map!`));
      throw new Error(this.withPrefix(`Invalid ManagedPipeToken "${pipeToken}"!`));
    }

    let pipe: PipeTransform;
    try {
      pipe = this.injector.get(token);
    } catch (error) {
      alert(this.withPrefix(`Please make sure that ${token.name} is in the providers array of its declaring module!`));
      throw error;
    }

    if (prePipeProperty) {
      value = PropertyAccessPipe.transform(value, prePipeProperty);
      if (debug) {
        console.debug(this.withPrefix('After prePipeProperty'), value);
      }
    }

    return pipe.transform(value, defaultValue);
  }

  private withPrefix(message: string) {
    return `[${DynamicPipe.name}] ${message}`;
  }
}
