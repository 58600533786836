import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaintenanceInformation } from '@rims/lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import { navigateTo } from '../../store/router/router.actions';

@Component({
  selector: 'rims-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  maintenance: Observable<MaintenanceInformation>;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.maintenance = this.store
      .select(state => state.meta.maintenance)
      .pipe(
        tap(maintenance => {
          if (!maintenance?.enabled) {
            this.store.dispatch(
              navigateTo({
                routerLink: '/'
              })
            );
          }
        })
      );
  }
}
