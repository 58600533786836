import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import { startLoading, stopLoading } from '../../store/metadata/metadata.actions';

enum FieldName {
  QUERY = 'query'
}

@Component({
  selector: 'rims-table-search-query',
  templateUrl: './rims-table-search-query.component.html',
  styleUrls: ['./rims-table-search-query.component.scss']
})
export class RimsTableSearchQueryComponent implements OnInit {
  readonly fieldName = FieldName;
  readonly form = new UntypedFormGroup({
    [FieldName.QUERY]: new UntypedFormControl(null)
  });

  // In order to track whitespace input we need to compare the last value with the current one
  // Somehow whitespace input does not change the value but still triggers valueChanges
  lastValue = '';

  @Output()
  onChange = this.form.get(FieldName.QUERY).valueChanges.pipe(
    filter(value => this.lastValue !== value),
    tap(value => {
      this.store.dispatch(startLoading());
      this.lastValue = value;
    }),
    debounceTime(500),
    distinctUntilChanged()
  ) as Observable<string>;

  @Output()
  onQueryBlur = new EventEmitter();

  @ViewChild('input')
  private input: ElementRef<HTMLInputElement>;

  constructor(private readonly store: Store<AppState>, private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.setInitialValue();
  }

  clear() {
    const currentValue = this.form.get(FieldName.QUERY).value;
    this.form.get(FieldName.QUERY).setValue('');
    if (currentValue === '') {
      setTimeout(() => this.store.dispatch(stopLoading()), 500);
    }
  }

  focus() {
    requestAnimationFrame(() => {
      this.input.nativeElement.focus();
    });
  }

  hasFocus() {
    return this.input?.nativeElement === document.activeElement;
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onBlur() {
    this.onQueryBlur.emit();
  }

  /**
   * retrieves the search term from the query parameters and writes it to the search input
   *
   */
  private setInitialValue() {
    // the url is set up like this: ?view_123=q:searchTerm,s:10,..
    const viewQueryParam = Object.values(this.route.snapshot.queryParams)[0] as string;
    const searchTerm = viewQueryParam?.split('q:')[1]?.split(',')[0];

    this.form.get(FieldName.QUERY).setValue(searchTerm, {
      emitEvent: false
    });
  }
}
