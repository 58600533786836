<form [formGroup]="form">
  <mat-form-field class="w-100">
    <mat-label>Search ...</mat-label>
    <input
      matInput
      [formControlName]="fieldName.QUERY"
      autocomplete="off"
      type="search"
      (keypress)="onKeyPress($event)"
      #input
      [attr.e2e-id]="'rims-table-search-query-input'"
      placeholder="You can use % as a placeholder"
      (blur)="onBlur()"
    />
    <button
      *ngIf="form.get(fieldName.QUERY).value"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="Clear Search Input"
      (click)="form.get(fieldName.QUERY).setValue('')"
      [attr.e2e-id]="'rims-table-search-query-clear'"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
