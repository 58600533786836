import { DatePipe } from '@angular/common';
import { Params, Router } from '@angular/router';
import { FieldType } from '@rims/lib';
import { TableColumn } from '../../models/table/table-column.model';
import { BooleanPipe } from '../../pipes/boolean.pipe';

export interface DisplayValue {
  // The value displayed in a table cell
  value: string;
  // Optional title (e.g. for displaying the raw date value of a formatted date)
  title?: string;
}

// Todo: Add support for navigator.language and provide some localization data for Angular
const datePipe = new DatePipe('en-US');
const booleanPipe = new BooleanPipe();

/**
 * Gets the value of a property in a nested object.
 *
 * @param element Nested Object
 * @param propertyTree String of properties delimited by specific seperator
 * @param seperator Specific delimter. Default is '.'
 */
export function getValueByPropertyTree(
  element: any,
  propertyTree: string,
  titlePropertyTree: string = undefined,
  type: string = undefined,
  seperator = '.'
): DisplayValue {
  if (!propertyTree) {
    return {
      value: ''
    };
  }

  const properties = propertyTree.split(seperator);
  const rawValue = properties.reduce((object, key) => (object ? object[key] : ''), element);

  let title: string;
  if (titlePropertyTree) {
    const titleProperties = titlePropertyTree.split(seperator);
    title = titleProperties.reduce((object, key) => (object ? object[key] : ''), element);
  }

  return getFormattedValue(rawValue, title, type);
}

export function getFormattedValue(value: any, title = undefined, type = undefined): DisplayValue {
  switch (type) {
    case FieldType.BOOLEAN: {
      return {
        value: booleanPipe.transform(value),
        title
      };
    }
    case FieldType.DATE: {
      return {
        value: datePipe.transform(value, 'yyyy-MM-dd'),
        title: (value as string)?.length > 10 ? value : undefined
      };
    }
    case FieldType.DATE_TIME: {
      if ((value as string).length === 10) {
        return {
          value: datePipe.transform(value, 'yyyy-MM-dd 00:00'),
          title
        };
      }
      return {
        value: datePipe.transform(value, 'yyyy-MM-dd, h:mm a'),
        title: value
      };
    }
    case FieldType.TEXT:
    case FieldType.INTEGER:
    case FieldType.FLOAT: {
      return {
        value,
        title
      };
    }
    default: {
      console.warn(`The field type "${type}" is not defined!`);
      return {
        value,
        title
      };
    }
  }
}

/**
 * Sorting is currently only possible for direct properties
 * of the object. Not for relations like `coe.name`.
 *
 * @param col a TableColumn
 */
export function shouldEnableSort(col: TableColumn) {
  return !col.field2.field.includes('.');
}

/**
 * Update URL with given query parameters.
 *
 * Must be called with appropriate `thisArg` to make router available.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
 *
 * @param queryParams Query parameters
 */
export function updateBrowserURL(queryParams: Params, routerVariableName = 'router'): void {
  requestAnimationFrame(() => {
    (this[routerVariableName] as Router).navigate([], {
      queryParams: {
        ...queryParams
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
      replaceUrl: true
    });
  });
}
