import { HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { CrudQueryParameters, Direction, Filter, RecordHistory, RimsQueryParam } from '@rims/lib';
import * as pluralize from 'pluralize';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { AppConfig, APP_CONFIG } from '../../../../app.constants';
import { AppState } from '../../../store/store.state';
import { Page } from '../../models/page/page.model';
import { CreateRecordOptions, DeleteRecordOptions, RunAsProductOwnerFor } from '../../store/data/data.actions';
import { downloadFile } from '../file-download';
import { InternalRequestBuilder, Pageable, RequestBuilder } from '../request-builder/request-builder.service';

export interface ExportDataPayload {
  entityId: number;
  format: string;
  page?: number;
  pageSize?: number;
  sort?: SortExt;
  filter?: Filter[];
  query?: string;
  fields: string[];
  expand?: string[];
  exportAll?: boolean;
}

/**
 * Extended Sort interface which also supports the directions 'begins-with-asc' and 'begins-with-desc'
 *
 *
 * When using 'begins-with-asc' or 'begins-with-desc' the /search result will first be ordered by the searchProperties for the endpoint (stored in the DB).
 * That means if the search result for a specific searchProperty starts with the given search term it will take precedence over the other results
 *
 * After that the normal sorting logic applies (e.g. sort after itemNumber in ascending order)
 *
 */
export interface SortExt extends Omit<Sort, 'direction'> {
  direction: Direction;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  /**
   * In order to transform entity names to urls we need to replace the underscore char.
   */
  private readonly entityNameSanitationRegex = /_/g;

  constructor(
    readonly requestBuilder: RequestBuilder,
    private readonly store: Store<AppState>,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig
  ) {}

  getAll<T = any>(
    url: string,
    expand: string[] = [],
    page?: number,
    size?: number,
    sort?: SortExt,
    viewId?: number,
    filters?: Filter[],
    query?: string,
    loadRelationIds?: boolean,
    selectAll?: string
  ): Observable<Page<T>> {
    const parts = url.split('/');
    let entityName = parts[0];
    entityName = this.config.entityNameMapping(entityName);
    let _url = pluralize(entityName.replace(this.entityNameSanitationRegex, ''));
    if (parts.length > 1) {
      _url = pluralize(parts.shift()) + '/' + parts.join('/');
    }

    if (query) {
      _url += '/search';
    } else if (sort?.direction === Direction.BEGINS_WITH_ASC || sort?.direction === Direction.BEGINS_WITH_DESC) {
      // reset sort when we don't have a query but begins-with direction has been provided
      sort = undefined;
    }

    const request = this.requestBuilder.request(environment.backendUrl, _url).paging(new Pageable(page, size, sort));

    if (expand.length > 0) {
      request.param(CrudQueryParameters.EXPAND, expand.join());
    }

    if (viewId) {
      request.param(CrudQueryParameters.APPLY_FILTER_FOR_VIEW, `${viewId}`);
    }

    if (Array.isArray(filters) && filters.length > 0) {
      request.param(CrudQueryParameters.FILTER, Filter.encodeFilters(filters));
    }

    if (query) {
      request.param(CrudQueryParameters.QUERY, query);
    }

    if (loadRelationIds) {
      request.param(CrudQueryParameters.LOAD_RELATION_IDS, 'true');
    }

    if (selectAll) {
      request.param(CrudQueryParameters.SELECT_ALL, selectAll);
    }

    return request.get();
  }

  getOne(entityId: number, id: string | number, expand?: string[], loadRelationIds?: boolean): Observable<Page<any>> {
    return this.store.pipe(
      select(state => state.metadata.entities[entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const url = `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/${id}`;
        return this.getAll(url, expand, 0, 1, undefined, undefined, undefined, undefined, loadRelationIds);
      })
    );
  }

  getOneByEntity(
    entityName: string,
    id: string | number,
    expand?: string[],
    loadRelationIds?: boolean,
    filters?: Filter[]
  ): Observable<Page<any>> {
    entityName = this.config.entityNameMapping(entityName.toLowerCase());
    const url = `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/${id}`;
    return this.getAll(url, expand, 0, 1, undefined, undefined, filters, undefined, loadRelationIds);
  }

  update(entityId: number, recordId: string | number, value: any): Observable<any> {
    return this.store.pipe(
      select(state => state.metadata.entities[entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const url = `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/${recordId}`;
        return this.requestBuilder.request(environment.backendUrl, url).body(value).put();
      })
    );
  }

  bulkUpdate(entityId: number, recordIds: string[] | number[], payload: any): Observable<any> {
    return this.store.pipe(
      select(state => state.metadata.entities[entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const url = `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/update-records`;
        const request = this.requestBuilder.request(environment.backendUrl, url).body({ ids: recordIds, payload });

        return request.patch();
      })
    );
  }

  getHistory(
    entityIdOrName: number | string,
    recordId: number,
    omitCreatedRecordEntry = false
  ): Observable<RecordHistory> {
    return this.store.pipe(
      select(state => {
        let key = entityIdOrName;
        if (typeof entityIdOrName === 'string') {
          key = Object.keys(state.metadata.entities)
            .map(k => state.metadata.entities[k])
            .find(e => e.name === entityIdOrName).id;
        }
        return state.metadata.entities[key];
      }),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const url = new URL(
          `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/${recordId}/history`,
          environment.backendUrl
        );
        url.searchParams.set(RimsQueryParam.CHANGE_HISTORY_OMIT_CREATED_RECORD, `${omitCreatedRecordEntry}`);
        return this.requestBuilder.request(url.toString()).get();
      })
    );
  }

  /**
   * Creates one or more records
   *
   * @param url
   * @param payload can be a single record or several records
   * @param options
   * @param body (optional) can be used to set properties at the root level (instead of per record)
   *
   */
  create(url: string, payload: any | any[], options?: CreateRecordOptions, body = null): Observable<any> {
    // every basic post endpoint expects an array of records
    if (!Array.isArray(payload)) {
      payload = [payload];
    }

    const records = this.removeActionTypeFromPayload(payload);
    let request = this.requestBuilder.request(environment.backendUrl, url).body({ ...body, records });

    request = this.applyProductOwnerOptions(request, options?.runAsProductOwnerFor, url, 'POST');

    return request.post();
  }

  /**
   * @param url if provided, the url will not be taken from the given entity
   */
  delete(
    entityId: number,
    id: string | number = '',
    params?: HttpParams,
    url?: string,
    options?: DeleteRecordOptions
  ): Observable<any> {
    return this.store.pipe(
      select(state => state.metadata.entities[entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const _url = `${url || pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/${id}`;
        let request = this.requestBuilder.request(environment.backendUrl, _url).params(params);

        request = this.applyProductOwnerOptions(request, options?.runAsProductOwnerFor, url, 'DELETE');

        return request.delete();
      })
    );
  }

  bulkDelete(
    entityId: number,
    ids: string[] | number[] = [],
    params?: HttpParams,
    url?: string,
    options?: DeleteRecordOptions
  ): Observable<any> {
    if (!Array.isArray(ids) || ids.length < 1) return;

    if (ids?.length === 1) {
      return this.delete(entityId, ids[0], params, url, options);
    }

    return this.store.pipe(
      select(state => state.metadata.entities[entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const _url = `${url || pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/delete-records`;
        let request = this.requestBuilder.request(environment.backendUrl, _url).params(params);

        request = this.applyProductOwnerOptions(request, options?.runAsProductOwnerFor, url, 'PATCH');

        return request.body({ ids }).patch();
      })
    );
  }

  restartController(entityId: number) {
    return this.store.pipe(
      select(state => state.metadata.entities[entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);
        const url = `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/_restart`;
        return this.requestBuilder.request(environment.backendUrl, url).patch();
      })
    );
  }

  export(payload: ExportDataPayload) {
    return this.store.pipe(
      select(state => state.metadata.entities[payload.entityId]),
      take(1),
      switchMap(entity => {
        let entityName = entity.name.toLowerCase();
        entityName = this.config.entityNameMapping(entityName);

        return this.requestBuilder
          .request(
            environment.backendUrl,
            `${pluralize(entityName.replace(this.entityNameSanitationRegex, ''))}/export`
          )
          .param(CrudQueryParameters.PAGE, typeof payload.page === 'number' ? payload.page + '' : '')
          .param(CrudQueryParameters.LIMIT, typeof payload.pageSize === 'number' ? payload.pageSize + '' : '')
          .param(
            CrudQueryParameters.ORDER_BY,
            payload.sort?.active ? payload.sort.active + ':' + (payload.sort.direction || Direction.ASC) : ''
          )
          .param(CrudQueryParameters.FORMAT, payload.format)
          .param(CrudQueryParameters.FILTER, payload.filter ? Filter.encodeFilters(payload.filter) : '')
          .param(CrudQueryParameters.QUERY, payload.query || '')
          .param(CrudQueryParameters.FIELDS, payload.fields.join(','))
          .param(CrudQueryParameters.EXPAND, payload.expand.join(','))
          .param(CrudQueryParameters.EXPORT_ALL, payload.exportAll ? 'true' : '')
          .withFullResponse()
          .fileExport()
          .pipe(
            tap((response: HttpResponse<any>) => {
              downloadFile(
                new Blob([response.body], {
                  type: /(.*);/.exec(response.headers.get('Content-Type'))[1]
                }),
                `export.csv`
              );
            })
          );
      })
    );
  }

  private applyProductOwnerOptions(
    request: InternalRequestBuilder,
    options: RunAsProductOwnerFor,
    url: string,
    method: string
  ) {
    if (options) {
      if (Object.keys(options).length > 1) {
        console.warn(`
          You should only set one of the \`runAsProductOwnerFor\` options for the request
          ${method} ${url}

          ${JSON.stringify(options)}
        `);
      }

      if (options.productGroup) {
        request = request.param(RimsQueryParam.RUN_AS_PRODUCT_OWNER_FOR_GROUP, `${options.productGroup}`);
      }
      if (options.item) {
        request = request.param(RimsQueryParam.RUN_AS_PRODUCT_OWNER_FOR_ITEM, options.item);
      }
      if (options.container) {
        request = request.param(RimsQueryParam.RUN_AS_PRODUCT_OWNER_FOR_CONTAINER, `${options.container}`);
      }
    }

    return request;
  }

  /**
   *
   * Sanitizes the payload so that the 'ngrx' action type is not part of the payload that is being sent to the backend
   *
   * To reliably remove the type we need to first create a copy of the payload,
   * because sometimes the underlying payload object is not configurable (i.e. you can't delete properties)
   */
  private removeActionTypeFromPayload(payload: any[]) {
    return payload.map(pl => {
      const payloadCopy = { ...pl };
      const type = payloadCopy.type;
      if (type && typeof type === 'string' && type.startsWith('[')) {
        delete payloadCopy.type;
      }

      return payloadCopy;
    });
  }
}
