<rims-header>
  <rims-header-title>
    {{ (result | async)?.name }}
  </rims-header-title>
  <rims-header-announcement *ngIf="isProductOwner | async">
    <span class="d-flex">
      You are a Product Owner for this group
      <mat-icon
        inline
        class="ml-1"
        matTooltipShowDelay="300"
        matTooltip="Because you have a GRA responsibility for this group, you can maintain data and perform actions that would otherwise not be allowed with your user role."
      >
        help_outline
      </mat-icon>
    </span>
  </rims-header-announcement>
</rims-header>

<rims-property-edit-menu (updateProperty)="onStateChange($event); handleSpptOrMctChanges($event)"></rims-property-edit-menu>

<div
  class="container pb-3 backdrop-wrapper"
  *ngIf="{
    group: result | async,
    routeData: resolveResult | async,
    entity: entity | async,
    isProductOwner: isProductOwner | async,
    isBudi: isBudi | async,
    isEbudi: isEbudi | async,
    isSolution: isSolution | async
  } as observable"
>
  <div *ngIf="blockUi | async" class="backdrop"></div>
  <div class="row py-5">
    <div class="col-12">
      <h2>Basic Information</h2>
    </div>

    <!-- Group Type -->
    <div class="col-12 col-sm-6">
      <rims-field [obj]="observable.group" [entity]="observable.entity" field="groupType2.shortName"></rims-field>
    </div>

    <!-- Number -->
    <div class="col-12 col-sm-6">
      <rims-field [editMenu]="editPropertyMenu" [obj]="observable.group" [entity]="observable.entity" permissionBaseEntity="product_group" field="number"></rims-field>
    </div>

    <!-- Product Group Name -->
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.group"
        [entity]="observable.entity"
        permissionBaseEntity="product_group"
        field="name"
        [editMenuCondition]="!observable.isBudi"
        [menuConfig]="{
          disableOnEmpty: true,
          onMenuOpen: {
            asyncValidators: [groupNameAsyncValidator.bind(this)]
          }
        }"
      ></rims-field>
    </div>

    <!-- Product Type -->
    <div class="col-12 col-sm-6" *ngIf="getProp('productType.name', observable.group)">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.group"
        [entity]="observable.entity"
        permissionBaseEntity="product_group"
        field="productType.name"
        [menuConfig]="{
          onMenuOpen: {
            url: 'productType',
            loadAll: true
          },
          loadAsyncOptions: true,
          selectFieldName: 'name'
        }"
      ></rims-field>
    </div>

    <!-- CoE -->
    <div class="col-12 col-sm-6" *ngIf="getProp('coe.shortName', observable.group)">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.group"
        [entity]="observable.entity"
        permissionBaseEntity="product_group"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        field="coe.shortName"
        [useCustomPipe]="{
          token: 'coe',
          prePipeProperty: 'coe'
        }"
        [menuConfig]="{
          onMenuOpen: {
            url: 'coe',
            expand: ['division'],
            loadAll: true,
            compareProperty: 'shortName'
          },
          loadAsyncOptions: true,
          selectExpression: coeOptionExpression
        }"
      ></rims-field>
    </div>
  </div>

  <div class="row pb-5" *ngIf="observable.isBudi">
    <div class="col-12 col-md-10">
      <h2 class="m-0">Intended Purpose</h2>
      <span class="color-grey d-block mb-3">
        The available texts are maintained globally and can be used by multiple product groups at the same time.
        <br />
        Click
        <a href="/view/intendedpurposes" target="_blank" class="unstyled d-inline-flex">
          here
          <mat-icon style="font-size: 100%; line-height: 100%; height: 1rem; width: 1rem" class="align-self-center ml-1">open_in_new</mat-icon>
        </a>
        to view and maintain all available texts.
      </span>
    </div>

    <div class="col-12">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.group"
        [entity]="observable.entity"
        permissionBaseEntity="product_group"
        field="intendedPurpose.intendedPurpose"
        undefinedLabel="No Intended Purpose has been set for this group yet."
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [useTextArea]="true"
        [textAreaRows]="intendedPurposeTextAreaRows"
        [textAreaResize]="intendedPurposeTextAreaResize"
        [menuConfig]="{
          onMenuOpen: {
            url: 'intendedPurpose',
            useSearch: true,
            inPlaceCreateFieldName: 'intendedPurpose',
            inPlaceCreatePermissionEntity: 'intended_purpose',
            forceWidth: 'full-width'
          },
          loadAsyncOptions: true,
          search: true,
          selectFieldName: 'intendedPurpose',
          emptyOption: 'Clear Field',
          inPlaceCreateBtnTxt: 'Create New Intended Purpose'
        }"
      ></rims-field>
    </div>
  </div>

  <div class="row mb-5" *ngIf="observable.isBudi || observable.isEbudi" [ngClass]="{ 'ignore-backdrop undefined-border': !observable.group?.budiInfo2 }">
    <div class="col-12">
      <h2 class="m-0">BUDI Properties</h2>
    </div>
    <div class="col-12">
      <span class="color-grey d-block mb-3">
        Items in this group will inherit this information in case the respective property on the item is still undefined.
        <br />
        The lifecycle and properties that only exist on the group are not inherited.
      </span>
    </div>
    <ng-container *ngIf="observable.group?.budiInfo2; else budiInfoPending">
      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.animalTissuesCellsPresence"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.humanTissuesCellsPresence"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.humanBloodOrPlasmaPresence"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.medicinalSubstancePresence"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.isSterile"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.multipleGtinsForBaseUnit"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.active"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.administeringMedicine"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.implantable"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.measuringFunction"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.reusable"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [isBoolean]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [menuConfig]="{
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <ng-container *ngIf="{ types: productGroupActorTypeNames | async } as actors">
        <div class="col-12 col-sm-6">
          <rims-field
            [editMenu]="editPropertyMenu"
            [obj]="observable.group"
            [entity]="observable.entity"
            permissionBaseEntity="product_group"
            field="budiInfo2.systemProcedurePackType"
            [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
            [highlightIfNull]="true"
            [editMenuCondition]="(systemOrProcedurePackEditable | async) && (observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange))"
            [useCustomPipe]="{
              token: 'name',
              prePipeProperty: 'budiInfo2.systemProcedurePackType'
            }"
            [menuConfig]="{
              onMenuOpen: {
                url: 'systemProcedurePackTypes',
                loadAll: true,
                forceWidth: getSpptWidth(actors.types)
              },
              entityName: 'product_group_budi_info',
              recordIdProperty: 'budiInfo',
              loadAsyncOptions: true,
              selectFieldName: 'name',
              useBaseEntityRecordIdForUpdate: true,
              confirm: {
                description: getConfirmDescription(actors.types),
                descriptionColor: 'warn',
                checkboxTxt: getConfirmTxt(actors.types),
                checkboxColor: 'warn',
                conditionFn: getSpptConfirmConditionFn(observable.group, actors.types)
              }
            }"
          ></rims-field>
        </div>

        <div class="col-12 col-sm-6">
          <rims-field
            [editMenu]="editPropertyMenu"
            [obj]="observable.group"
            [entity]="observable.entity"
            permissionBaseEntity="product_group"
            field="budiInfo2.multiComponentType"
            [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
            [highlightIfNull]="true"
            [editMenuCondition]="(multiComponentTypeEditable | async) && (observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange))"
            [useCustomPipe]="{
              token: 'name',
              prePipeProperty: 'budiInfo2.multiComponentType'
            }"
            [menuConfig]="{
              onMenuOpen: {
                url: 'multiComponentTypes',
                loadAll: true,
                forceWidth: getMctWidth(actors.types)
              },
              entityName: 'product_group_budi_info',
              recordIdProperty: 'budiInfo',
              loadAsyncOptions: true,
              selectFieldName: 'name',
              useBaseEntityRecordIdForUpdate: true,
              confirm: {
                description: getConfirmDescription(actors.types),
                descriptionColor: 'warn',
                checkboxTxt: getConfirmTxt(actors.types),
                checkboxColor: 'warn',
                conditionFn: getMctConfirmConditionFn(actors.types)
              }
            }"
          ></rims-field>
        </div>
      </ng-container>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.specialDeviceType"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [editMenuCondition]="(specialDeviceTypeEditable | async) && (observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange))"
          [useCustomPipe]="{
            token: 'name',
            prePipeProperty: 'budiInfo2.specialDeviceType'
          }"
          [menuConfig]="{
            onMenuOpen: {
              url: 'specialDeviceTypes',
              loadAll: true
            },
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            loadAsyncOptions: true,
            selectFieldName: 'name',
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          field="budiInfo2.riskClass.class"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [editMenuCondition]="observable.isEbudi || (getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)"
          [useCustomPipe]="{
            token: 'riskClassAndLegislation'
          }"
          [menuConfig]="{
            onMenuOpen: {
              url: 'riskClasses',
              expand: ['legislation'],
              loadAll: true,
              filters: riskClassFilters,
              compareProperty: 'class'
            },
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            loadAsyncOptions: true,
            emptyOption: 'None',
            selectExpression: riskClassOptionExpression,
            useBaseEntityRecordIdForUpdate: true
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6">
        <rims-field
          [editMenu]="editPropertyMenu"
          [obj]="observable.group"
          [entity]="observable.entity"
          permissionBaseEntity="product_group"
          [forceShowBorder]="
            getProp('budiInfo2.riskClass.legislation.shortName', observable.group) !== 'MDR' && getProp('budiInfo2.plannedTransitionToMdr', observable.group) | isPastDate
          "
          field="budiInfo2.plannedTransitionToMdr"
          displayNameSuffix="(YYYY-MM-DD)"
          [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
          [highlightIfNull]="true"
          [menuConfig]="{
            date: true,
            entityName: 'product_group_budi_info',
            recordIdProperty: 'budiInfo',
            useBaseEntityRecordIdForUpdate: true,
            emptyOption: 'Clear Field'
          }"
        ></rims-field>
      </div>

      <div class="col-12 col-sm-6" *ngIf="!observable.isEbudi">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>
            {{ observable.entity?.fields?.['budiInfo2.lifecycle']?.displayName }}
            <rims-field-help-texts [entity]="observable.entity" field="budiInfo2.lifecycle"></rims-field-help-texts>
          </mat-label>
          <input
            matInput
            disabled
            [attr.rims-field-name]="'budiInfo2.lifecycle'"
            [placeholder]="observable.entity?.fields?.['budiInfo2.lifecycle']?.displayName"
            [value]="getProp('budiInfo2.lifecycle.name', observable.group, 'Not defined')"
          />
          <mat-icon
            *rimsRequirePermission="
              'product_group';
              field: 'budiInfo2.lifecycle';
              ready: !!editPropertyMenu?.menu;
              condition: getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange;
              force: observable.isProductOwner | forceProductOwnerPermission
            "
            class="edit-action-icon color-grey clickable"
            matSuffix
            mat-icon-button
            (click)="openChangeBudiLifecycleDialog()"
          >
            edit
          </mat-icon>
          <mat-icon *ngIf="!(getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)" matSuffix class="edit-action-icon color-grey">lock_outline</mat-icon>
          <mat-hint class="pt-2 d-block" *ngIf="!(getProp('budiInfo2.lifecycle.name', observable.group) | allowLifecycleChange)">
            The lifecycle is managed in SAP and can not be changed in RIMS.
          </mat-hint>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-template #budiInfoPending>
      <ng-container *ngFor="let _ of [].constructor(17)">
        <ngx-skeleton-loader
          class="col-12 col-sm-6 ignore-backdrop"
          [theme]="{ height: '59px', 'padding-bottom': '1.34375em', 'background-color': '#0000000d' }"
          animation="progress-dark"
        ></ngx-skeleton-loader>
      </ng-container>
    </ng-template>
  </div>

  <ng-container *ngIf="observable.isSolution">
    <ng-container *ngIf="{ selected: selectedProductGroupContainers | async } as containers">
      <div class="row mb-5">
        <div class="col-12">
          <h2 class="mb-3">Containers</h2>
        </div>
        <div class="col">
          <rims-table
            [hideColumnDialog]="true"
            [hideFiltersAndFilterMenu]="true"
            [shouldDisplayCheckboxes]="true"
            [shouldDisplayDetailLink]="true"
            [shouldDisplayExportDialog]="true"
            [detailViewEntityName]="'container'"
            [primaryKeyPropertyName]="'container'"
            [dataKey]="'product_group_container'"
            [viewConfigPayload]="observable.routeData?.viewPayloads['product_group_container']"
            [emptyResultSetMessage]="'No containers are associated with this product group.'"
          >
            <rims-table-action
              [attr.e2e-id]="'add-container-to-product-group-button'"
              (click)="openAddContainerDialog()"
              *rimsRequirePermission="'product_group_container'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
            >
              <mat-icon>add</mat-icon>
              Add Containers
            </rims-table-action>
            <rims-table-action
              [attr.e2e-id]="'remove-containers-from-product-group-button'"
              (click)="openRemoveContainerDialog()"
              *rimsRequirePermission="
                'product_group_container';
                action: 'delete';
                condition: containers.selected?.length > 0;
                force: observable.isProductOwner | forceProductOwnerPermission
              "
            >
              <mat-icon>remove</mat-icon>
              Remove {{ containers.selected?.length | i18nPlural : getMapping('Container') }}
            </rims-table-action>
          </rims-table>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <h2 class="mb-3">Production Sites</h2>
        </div>
        <div class="col">
          <rims-table
            *ngIf="{ selected: selectedProductGroupContainerProductionSites | async } as sites"
            [hideColumnDialog]="true"
            [shouldDisplayCheckboxes]="true"
            [hideFiltersAndFilterMenu]="true"
            [shouldDisplayDetailLink]="true"
            [detailViewEntityName]="'company'"
            [primaryKeyPropertyName]="'company2.id'"
            [checkBoxesProperty]="'company2.id'"
            [customUrl]="'product_group_container_production_site'"
            [dataKey]="'container_production_site'"
            [viewConfigPayload]="observable.routeData?.viewPayloads['container_production_site']"
            [emptyResultSetMessage]="'No production sites are associated with the containers in this group.'"
            [nonEmptyResultSetMessage]="
              'The information below is only a summary of what is defined at the container level.\nThe individual containers may have a different definition.'
            "
          >
            <rims-table-action
              [attr.e2e-id]="'add-container-production-site'"
              (click)="openAddContainerProductionSiteDialog()"
              *rimsRequirePermission="
                'container_production_site';
                action: 'create';
                condition: containers.selected?.length > 0;
                force: observable.isProductOwner | forceProductOwnerPermission
              "
            >
              <mat-icon>add</mat-icon>
              Add Site to
              <ng-container *ngTemplateOutlet="selectedContainers"></ng-container>
            </rims-table-action>
            <rims-table-action
              [attr.e2e-id]="'remove-container-production-site'"
              (click)="openRemoveContainerProductionSiteDialog()"
              *rimsRequirePermission="
                'container_production_site';
                action: 'delete';
                condition: sites.selected?.length > 0 && containers.selected?.length > 0;
                force: observable.isProductOwner | forceProductOwnerPermission
              "
            >
              <mat-icon>remove</mat-icon>
              Remove {{ sites.selected?.length | i18nPlural : getMapping('Site') }} from
              <ng-container *ngTemplateOutlet="selectedContainers"></ng-container>
            </rims-table-action>
          </rims-table>
        </div>
      </div>
      <ng-template #selectedContainers>
        <span matTooltip="This is based on the selection in the Containers table above" matTooltipShowDelay="300">
          {{ containers.selected?.length | i18nPlural : getMapping('Container') }}
        </span>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!observable.isSolution">
    <ng-container *ngIf="{ selected: selectedProductGroupItems | async, hasItems: groupHasItems | async } as pgi">
      <ng-container
        *ngIf="{
          loading: (syncStatus | async)?.itemsSynced === false,
          forceLoaders: forceLoaders | async,
          loaderCount: loaderCount | async
        } as sync"
      >
        <div class="row mb-5" [ngClass]="{ 'ignore-backdrop undefined-border': sync.loading }">
          <div class="col-12">
            <h2 class="mb-3">Items</h2>
          </div>
          <div class="col-12">
            <rims-table
              [loader]="{ force: sync.forceLoaders, count: sync.loaderCount }"
              [hideColumnDialog]="true"
              [hideFiltersAndFilterMenu]="false"
              [shouldDisplayDetailLink]="true"
              [shouldDisplayCheckboxes]="true"
              [shouldDisplayExportDialog]="true"
              [shouldDisplaySearchInput]="true"
              [detailViewEntityName]="'item'"
              [primaryKeyPropertyName]="'item'"
              [dataKey]="'product_group_item'"
              [viewConfigPayload]="observable.routeData?.viewPayloads['product_group_item']"
              [emptyResultSetMessage]="itemsEmptyResultSetMessage"
              [filterMenus]="filterMenus"
            >
              <rims-table-action
                [attr.e2e-id]="'add-item-to-product-group-button'"
                [ngStyle]="{ 'pointer-events': observable.isBudi && sync.loading ? 'none' : 'auto' }"
                (click)="openAddItemDialog()"
                *rimsRequirePermission="'product_group_item'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
              >
                <mat-icon>add</mat-icon>
                Add item
              </rims-table-action>
              <rims-table-action
                [attr.e2e-id]="'remove-items-from-product-group-button'"
                (click)="openRemoveItemDialog()"
                *rimsRequirePermission="'product_group_item'; action: 'delete'; condition: pgi.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
              >
                <mat-icon>remove</mat-icon>
                Remove {{ pgi.selected?.length | i18nPlural : getMapping('Item') }}
              </rims-table-action>
            </rims-table>
          </div>
        </div>
      </ng-container>

      <div class="row mb-5">
        <div class="col-12">
          <h2 class="mb-3">Production Sites</h2>
        </div>
        <div class="col">
          <rims-table
            *ngIf="{ selected: selectedProductGroupItemProductionSites | async } as sites"
            [hideColumnDialog]="true"
            [shouldDisplayCheckboxes]="true"
            [hideFiltersAndFilterMenu]="true"
            [shouldDisplayDetailLink]="true"
            [detailViewEntityName]="'company'"
            [primaryKeyPropertyName]="'company2.id'"
            [checkBoxesProperty]="'company2.id'"
            [customUrl]="'product_group_item_production_site'"
            [dataKey]="'item_production_site'"
            [viewConfigPayload]="observable.routeData?.viewPayloads['item_production_site']"
            [emptyResultSetMessage]="'No production sites are associated with the items in this group.'"
            [nonEmptyResultSetMessage]="'The information below is only a summary of what is defined at the item level.\nThe individual items may have a different definition.'"
          >
            <rims-table-action
              [attr.e2e-id]="'add-item-production-site'"
              (click)="openAddItemProductionSiteDialog()"
              *rimsRequirePermission="'item_production_site'; action: 'create'; condition: pgi.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
            >
              <mat-icon>add</mat-icon>
              Add Site to
              <ng-container *ngTemplateOutlet="selectedItems"></ng-container>
            </rims-table-action>
            <rims-table-action
              [attr.e2e-id]="'remove-item-production-site'"
              (click)="openRemoveItemProductionSiteDialog()"
              *rimsRequirePermission="
                'item_production_site';
                action: 'delete';
                condition: sites.selected?.length > 0 && pgi.selected?.length > 0;
                force: observable.isProductOwner | forceProductOwnerPermission
              "
            >
              <mat-icon>remove</mat-icon>
              Remove {{ sites.selected?.length | i18nPlural : getMapping('Site') }} from
              <ng-container *ngTemplateOutlet="selectedItems"></ng-container>
            </rims-table-action>
          </rims-table>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <h2 class="mb-3">Documents</h2>
        </div>
        <div class="col">
          <rims-table
            *ngIf="{ selected: selectedProductGroupDocuments | async } as documents"
            [hideColumnDialog]="true"
            [hideFiltersAndFilterMenu]="true"
            [shouldDisplayDetailLink]="true"
            [shouldDisplayCheckboxes]="true"
            [detailViewEntityName]="'document'"
            [primaryKeyPropertyName]="'document2.id'"
            [checkBoxesProperty]="'document2.id'"
            [customUrl]="'product_group_document'"
            [dataKey]="'item_document'"
            [viewConfigPayload]="observable.routeData?.viewPayloads['item_document']"
            [emptyResultSetMessage]="'No documents are associated with the items in this product group.'"
            [nonEmptyResultSetMessage]="'The information below is only a summary of what is defined at the item level.\nThe individual items may have a different definition.'"
            [description]="true"
          >
            <rims-table-action
              [attr.e2e-id]="'add-document'"
              (click)="openAddDocumentDialog()"
              *rimsRequirePermission="'item_document'; action: 'create'; condition: pgi.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
            >
              <mat-icon>add</mat-icon>
              Add Document to
              <ng-container *ngTemplateOutlet="selectedItems"></ng-container>
            </rims-table-action>
            <rims-table-action
              [attr.e2e-id]="'remove-document'"
              (click)="openRemoveDocumentDialog()"
              *rimsRequirePermission="
                'item_document';
                action: 'delete';
                condition: documents.selected?.length > 0 && pgi.selected?.length > 0;
                force: observable.isProductOwner | forceProductOwnerPermission
              "
            >
              <mat-icon>remove</mat-icon>
              Remove {{ documents.selected?.length | i18nPlural : getMapping('Document') }} from
              <ng-container *ngTemplateOutlet="selectedItems"></ng-container>
            </rims-table-action>
          </rims-table>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <h2 class="mb-3">Nomenclatures</h2>
        </div>
        <div class="col">
          <rims-table
            *ngIf="{ selected: selectedProductGroupNomenclatures | async } as nomenclatures"
            [hideColumnDialog]="true"
            [shouldDisplayCheckboxes]="true"
            [hideFiltersAndFilterMenu]="true"
            [shouldDisplayDetailLink]="true"
            [detailViewEntityName]="'nomenclature'"
            [primaryKeyPropertyName]="'nomenclature2.id'"
            [checkBoxesProperty]="'nomenclature2.id'"
            [customUrl]="'product_group_nomenclature'"
            [dataKey]="'item_nomenclature'"
            [viewConfigPayload]="observable.routeData?.viewPayloads['item_nomenclature']"
            [emptyResultSetMessage]="'No nomenclatures are associated with the items in this product group.'"
            [nonEmptyResultSetMessage]="'The information below is only a summary of what is defined at the item level.\nThe individual items may have a different definition.'"
          >
            <rims-table-action
              [attr.e2e-id]="'add-nomenclature'"
              (click)="openAddNomenclatureDialog()"
              *rimsRequirePermission="'item_nomenclature'; action: 'create'; condition: pgi.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
            >
              <mat-icon>add</mat-icon>
              Add Nomenclature to
              <ng-container *ngTemplateOutlet="selectedItems"></ng-container>
            </rims-table-action>
            <rims-table-action
              [attr.e2e-id]="'remove-nomenclature'"
              (click)="openRemoveNomenclatureDialog()"
              *rimsRequirePermission="
                'item_nomenclature';
                action: 'delete';
                condition: nomenclatures.selected?.length > 0 && pgi.selected?.length > 0;
                force: observable.isProductOwner | forceProductOwnerPermission
              "
            >
              <mat-icon>remove</mat-icon>
              Remove {{ nomenclatures.selected?.length | i18nPlural : getMapping('Nomenclature') }} from
              <ng-container *ngTemplateOutlet="selectedItems"></ng-container>
            </rims-table-action>
          </rims-table>
        </div>
      </div>
      <ng-template #selectedItems>
        <span matTooltip="This is based on the selection in the Items table above" matTooltipShowDelay="300">
          {{ pgi.selected?.length | i18nPlural : getMapping('Item') }}
        </span>
      </ng-template>
    </ng-container>

    <div class="row mb-5">
      <div class="col-12">
        <h2 class="mb-3">Actors</h2>
      </div>
      <div class="col">
        <rims-table
          *ngIf="{ selected: selectedProductGroupActors | async } as actors"
          [hideColumnDialog]="true"
          [shouldDisplayCheckboxes]="true"
          [hideFiltersAndFilterMenu]="true"
          [shouldDisplayDetailLink]="true"
          [shouldDisplayExportDialog]="true"
          [detailViewEntityName]="'actor'"
          [primaryKeyPropertyName]="'actor'"
          [dataKey]="'product_group_actor'"
          [viewConfigPayload]="observable.routeData?.viewPayloads['product_group_actor']"
          [emptyResultSetMessage]="'No actors are associated with this group.'"
        >
          <rims-table-action
            [attr.e2e-id]="'add-actor-to-product-group-button'"
            (click)="openAddActorDialog()"
            *rimsRequirePermission="'product_group_actor'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
          >
            <mat-icon>add</mat-icon>
            Add Actor
          </rims-table-action>
          <rims-table-action
            [attr.e2e-id]="'remove-actor-from-product-group-button'"
            (click)="openRemoveActorDialog()"
            *rimsRequirePermission="'product_group_actor'; action: 'delete'; condition: actors.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
          >
            <mat-icon>remove</mat-icon>
            Remove {{ actors.selected?.length | i18nPlural : getMapping('Actor') }}
          </rims-table-action>
        </rims-table>
      </div>
    </div>
  </ng-container>

  <div class="row mb-5">
    <div class="col-12">
      <h2 class="mb-3">Responsibilities</h2>
    </div>
    <div class="col">
      <rims-table
        *ngIf="{ selected: selectedProductGroupResponsibilities | async } as responsibilities"
        [hideColumnDialog]="true"
        [shouldDisplayCheckboxes]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayExportDialog]="true"
        [shouldDisplayDetailLink]="true"
        [detailViewEntityName]="'user'"
        [primaryKeyPropertyName]="'user'"
        [dataKey]="'product_group_responsibility'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['product_group_responsibility']"
        [emptyResultSetMessage]="'No responsibilities have been defined yet.'"
      >
        <rims-table-action
          [attr.e2e-id]="'add-responsibility-to-product-group-button'"
          (click)="openAddResponsibilityDialog()"
          *rimsRequirePermission="'product_group_responsibility'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Responsibility
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-responsibility-from-product-group-button'"
          (click)="openRemoveResponsibilityDialog()"
          *rimsRequirePermission="
            'product_group_responsibility';
            action: 'delete';
            condition: responsibilities.selected?.length > 0;
            force: observable.isProductOwner | forceProductOwnerPermission
          "
        >
          <mat-icon>remove</mat-icon>
          Remove {{ responsibilities.selected?.length | i18nPlural : getMapping('Responsibility') }}
        </rims-table-action>
        <rims-table-action [attr.e2e-id]="'send-email-to-responsibility-button'" (click)="mailResponsibilities()" *ngIf="responsibilities.selected?.length > 0">
          <mat-icon>mail</mat-icon>
          Send E-Mail
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2 class="mb-3">Child Groups</h2>
    </div>
    <div class="col">
      <rims-table
        *ngIf="{ selected: selectedProductGroupChildGroups | async } as childgroups"
        [shouldDisplayCheckboxes]="true"
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [shouldDisplayExportDialog]="true"
        [detailViewEntityName]="'productgroup'"
        [primaryKeyPropertyName]="'child'"
        [dataKey]="'product_group_children'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['product_group_children']"
        [emptyResultSetMessage]="'This product group does not have any child groups.'"
      >
        <rims-table-action
          [attr.e2e-id]="'add-child-group-to-product-group-button'"
          (click)="openAddChildGroupDialog()"
          *rimsRequirePermission="'product_group_children'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Child Group
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-child-group-from-product-group-button'"
          (click)="openRemoveChildGroupDialog()"
          *rimsRequirePermission="
            'product_group_children';
            action: 'delete';
            condition: childgroups.selected?.length > 0;
            force: observable.isProductOwner | forceProductOwnerPermission
          "
        >
          <mat-icon>remove</mat-icon>
          Remove {{ childgroups.selected?.length | i18nPlural : getMapping('Child Group') }}
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2 class="mb-3">3rd Party Applications</h2>
      <span class="color-grey d-block mb-3">Applications and websites in context to this product group are listed here.</span>
    </div>
    <div class="col">
      <a
        *ngIf="isRmf | async"
        mat-button
        target="_blank"
        rel="noopener noreferrer"
        [href]="'https://bdocs.bbraun.com/otcs/cs.exe/Open/RMFUserInterfaceWR?objAction=RunReport&structure=RMF&status=EFFECTIVE&rmfnumber=' + observable.group?.number"
        class="mb-2 mr-2"
      >
        RMF-Browser
        <mat-icon inline>open_in_new</mat-icon>
      </a>
      <a
        *ngIf="observable.isSolution"
        mat-button
        target="_blank"
        rel="noopener noreferrer"
        [href]="'https://products.bbraun.com/search/?text=' + (productCenterQuery | async)"
        class="mb-2 mr-2"
      >
        Product Center
        <mat-icon inline>open_in_new</mat-icon>
      </a>
      <a
        *ngIf="observable.isBudi || observable.isEbudi"
        mat-button
        target="_blank"
        rel="noopener noreferrer"
        [href]="'https://ec.europa.eu/tools/eudamed/#/screen/search-device?submitted=true&basicUdi=' + observable.group?.number"
        class="mb-2 mr-2"
      >
        EUDAMED
        <mat-icon inline>open_in_new</mat-icon>
      </a>
    </div>
  </div>

  <div class="row mb-5 ignore-backdrop">
    <div class="col-12">
      <h2>History</h2>
    </div>
    <div class="col">
      <ng-container *ngIf="observable.group?.id">
        <rims-detail-view-record-history
          *ngIf="observable.isBudi && observable.group.budiInfo2?.id"
          [config]="{
            entityIdOrName: 'product_group_budi_info',
            recordId: observable.group.budiInfo2?.id,
            hideCreatedRecordEntry: true
          }"
          [showLastDivider]="true"
        ></rims-detail-view-record-history>
        <rims-detail-view-record-history
          [config]="{
            entityIdOrName: 'product_group',
            recordId: observable.group.id
          }"
        ></rims-detail-view-record-history>
      </ng-container>
    </div>
  </div>

  <div class="row mb-5 pb-5 ignore-backdrop" *ngIf="showSapSyncDebugInfo | async">
    <div class="col-12 mb-3">
      <h2 class="d-flex">
        Debugging Functionality
        <mat-icon class="align-self-center ml-2" matTooltip="This section is only visible in non-production environments" matTooltipPosition="above">help_outline</mat-icon>
      </h2>
      <button mat-button (click)="showSapSyncPreview()">Preview SAP Sync Payload</button>
    </div>
    <div class="col-12">
      <pre style="font-size: 0.8rem">{{ sapSyncPreviewResult | json }}</pre>
    </div>
  </div>
</div>
