import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { SapModule } from '../sap/sap.module';
import { SharedModule } from '../shared/shared.module';
import { AddRiskClassToItemDialogComponent } from './components/add-risk-class-to-item-dialog/add-risk-class-to-item-dialog.component';
import { ChangeItemLifecycleDialogComponent } from './components/change-item-lifecycle-dialog/change-item-lifecycle-dialog.component';
import { ItemsRoutingModule } from './items-routing.module';
import { DisplayLifecyclePipe } from './pipes/display-lifecycle.pipe';
import { CreateItemComponent } from './views/create-item/create-item.component';
import { ItemDetailComponent } from './views/item-detail/item-detail.component';

@NgModule({
  declarations: [
    ItemDetailComponent,
    CreateItemComponent,
    ChangeItemLifecycleDialogComponent,
    AddRiskClassToItemDialogComponent,
    DisplayLifecyclePipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    ItemsRoutingModule,
    SapModule
  ]
})
export class ItemsModule {}
