import { createAction, props } from '@ngrx/store';

const PREFIX = '[Router]';

export interface ViewNotFoundPayload {
  view: string;
  redirectUrl?: string;
}

export interface NavigateToPayload {
  routerLink: string;
}

export const viewAccessNotAllowed = createAction(`${PREFIX} Access Not Allowed`);
export const viewNotFound = createAction(`${PREFIX} View Not Found`, props<ViewNotFoundPayload>());

export const navigateTo = createAction(`${PREFIX} Navigate To`, props<NavigateToPayload>());
