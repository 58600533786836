import * as pluralize from 'pluralize';
import { MetadataState } from '../store/metadata/metadata.state';

export function getEntityUrlAndView(metadata: MetadataState, name: string) {
  const entity = getEntity(metadata, name);
  const url = getUrl(name);
  const view = Object.keys(metadata.views)
    .map(key => metadata.views[key])
    .find(view => view.route.endsWith(`/${url}`));

  return { entity, url, view };
}

export function getEntity(metadata: MetadataState, name: string) {
  const entity = Object.keys(metadata.entities)
    .map(key => metadata.entities[key])
    .find(en => en.name === name);

  return entity;
}

export function getView(metadata: MetadataState, name: string) {
  const url = getUrl(name);
  return Object.keys(metadata.views)
    .map(key => metadata.views[key])
    .find(view => view.route.endsWith(url));
}

/**
 * @example 'item_riskclass' --> 'itemriskclasses'
 *
 * (We could use a method where we access entity.name instead of an arbitrary name but this method is preferred,
 * because there are cases where an entity doesn't exist (e.g. 'product_group_documents') but we have a matching url & view)
 *
 */
export function getUrl(name: string) {
  const entityName = name.toLowerCase();
  const url = `${pluralize(entityName.replace(/_/g, ''))}`;

  return url;
}

/**
 * Maps a provided entity name to a string that represents how many rows are selected.
 * Example: 1 Document, 2 Documents, 3 Documents, ...
 */
export function getMapping(name: string) {
  return {
    '=1': `1 ${name}`,
    other: `# ${pluralize(name)}`
  };
}
