import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { select, Store } from '@ngrx/store';
import { Filter, FilterOperator } from '@rims/lib';
import { combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import { DynamicPipe } from '../../pipes/dynamic.pipe';
import { PropertyAccessPipe } from '../../pipes/property-access.pipe';
import { DataService } from '../../services/data/data.service';
import {
  closeFilterCreationDialog,
  CloseFilterCreationDialogPayload,
  FilterConfiguration,
  OpenFilterCreationDialogPayload
} from '../../store/metadata/metadata.actions';
import { viewFilters, viewRoute } from '../../store/metadata/metadata.state';
import { filterConfigurations } from './filter.config';

export type RimsTableFilterCreationDialogInput = OpenFilterCreationDialogPayload;

@Component({
  selector: 'rims-rims-table-filter-creation-dialog',
  templateUrl: './rims-table-filter-creation-dialog.component.html',
  styleUrls: ['./rims-table-filter-creation-dialog.component.scss']
})
export class RimsTableFilterCreationDialogComponent implements OnInit {
  readonly dynamicPipe = new DynamicPipe(this.injector);
  private currentFilter: Filter;
  private viewFilters: Filter[] = [];

  dateRangeStart: string;
  dateRangeEnd: string;

  operators = [FilterOperator.EQUALS, FilterOperator.GREATER_THAN, FilterOperator.LESS_THAN];
  config: FilterConfiguration;

  options: { display: string; [key: string]: any }[];

  get isDate() {
    return this.config?.filterType === 'date';
  }
  get isNumber() {
    return this.config?.filterType === 'number';
  }

  get isSelect() {
    return !!this.config?.optionUrl || this.config?.options?.length > 0 || this.config?.filterType === 'boolean';
  }

  form = new FormGroup({
    operator: new FormControl<FilterOperator>(null, Validators.required),
    fieldName: new FormControl(this.data.fieldName, Validators.required),
    fieldLabel: new FormControl(null),
    value: new FormControl(null, Validators.required),
    valueLabel: new FormControl(null),
    selected: new FormControl([])
  });

  constructor(
    private readonly store: Store<AppState>,
    public readonly dialogRef: MatDialogRef<RimsTableFilterCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: RimsTableFilterCreationDialogInput,
    private readonly dataService: DataService,
    private injector: Injector
  ) {}

  ngOnInit() {
    const route$ = this.store.pipe(select(viewRoute(this.data.viewId)), take(1));
    const viewFilters$ = this.store.pipe(select(viewFilters(this.data.viewId)), take(1));

    combineLatest([route$, viewFilters$]).subscribe(([route, viewFilters]) => {
      this.viewFilters = viewFilters;
      this.loadFilterConfigAndUpdateForm(route, this.data.fieldName);

      if (this.isSelect) {
        this.loadSelectOptions();
      }
    });
  }

  save() {
    const filter = this.createFilter();
    const payload: CloseFilterCreationDialogPayload = {
      viewId: this.data.viewId
    };

    payload.saveFilter = filter;

    this.store.dispatch(closeFilterCreationDialog(payload));
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  onSelectChange(event: MatSelectChange) {
    const value = this.getValueWithLabel(event.value);
    const valueArray = Array.isArray(value) ? value : [value];

    this.form.get('value').setValue(valueArray);
  }

  onOperatorChange(event: any) {
    if (this.isDate) {
      if (event.value === FilterOperator.IS_NULL) {
        this.form.get('value').disable();
      } else {
        this.form.get('value').reset();
      }
    }
  }

  onDateChange(event: MatDatepickerInputEvent<moment.Moment>) {
    const date = event.value?.format('YYYY-MM-DD');
    this.form.get('value').patchValue(date);
    this.form.markAsDirty();
  }

  onDateRangeChange(rangeStart: HTMLInputElement, rangeEnd: HTMLInputElement) {
    this.form.get('value').patchValue(`${rangeStart.value} \u{02212} ${rangeEnd.value}`);
    this.form.markAsDirty();
  }

  // Prevent typing negative numbers and 'e'
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '-' || event.key.toLowerCase() === 'e') {
      event.preventDefault();
    }
  }

  // Prevent pasting negative numbers and 'e'
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';
    if (pastedText.includes('-') || /e/i.test(pastedText)) {
      event.preventDefault();
    }
  }

  /**
   * If display is too big to be fully visible within its dropdown field
   * it will be returned here (so that it gets displayed as a tooltip)
   */
  getTooltip(display: any) {
    const maxVisibleLength = 84;
    if (display.length > maxVisibleLength) return display;
  }

  private loadFilterConfigAndUpdateForm(route: string, fieldName: string) {
    const filterConfigs = filterConfigurations[route];
    this.config = filterConfigs?.find(filter => filter.fieldName === fieldName);
    this.currentFilter = this.viewFilters.find(f => f.fieldName === this.data.fieldName);

    // Update form fields
    this.form.get('fieldLabel').patchValue(this.config.displayName);
    this.form.get('operator').patchValue(this.getOperator());

    if (this.isDate) {
      this.operators = [FilterOperator.EQUALS, FilterOperator.DATE_RANGE, FilterOperator.IS_NULL];
    }

    const value = this.data?.value ?? this.currentFilter?.value;
    if (value != null) {
      if (this.form.get('operator').value === FilterOperator.DATE_RANGE) {
        [this.dateRangeStart, this.dateRangeEnd] = value.split(' \u{02212} ');
      }
      this.form.get('value').patchValue(value);
    }
  }

  private loadSelectOptions() {
    this.getOptions()
      .pipe(take(1))
      .subscribe(options => {
        this.options = this.transformOptions(options);

        this.updateAlreadySelected();
      });
  }

  private updateAlreadySelected() {
    const name = this.data.fieldName.split('.')[1];

    const selected = this.options.filter(opt => {
      const value = opt?.[name] || opt?.id || opt.display;
      return this.currentFilter?.value.toString().split(',').includes(String(value));
    });

    this.form.get('selected').setValue(selected);
  }

  private getOptions() {
    if (this.config?.options) {
      return of(this.config?.options);
    } else if (this.config?.optionUrl) {
      return this.dataService
        .getAll(
          this.config?.optionUrl,
          this.config?.optionExpand,
          0,
          Number.MAX_SAFE_INTEGER,
          undefined,
          undefined,
          this.config?.optionFilters
        )
        .pipe(
          take(1),
          map(response => response.results)
        );
    }
    return of([]);
  }

  /**
   * Transforms options by
   *
   * - Adding a display value for each option
   * - Sorting options alphabetically based on the display value
   * - Adding a 'Not defined' value (if defined in data)
   */
  private transformOptions(options: any[]) {
    if (this.config?.filterType === 'boolean') {
      return [
        { id: 'IS_NULL', display: 'Not Defined' },
        { id: 'true', display: 'Yes' },
        { id: 'false', display: 'No' }
      ];
    }
    options = options
      .map(option => {
        if (typeof option === 'string') {
          return { display: option };
        }

        const display = this.config?.optionField
          ? this.dynamicPipe.transform(
              option,
              this.config?.optionCustomPipe?.token,
              this.config?.optionCustomPipe?.prePipeProperty,
              this.config?.optionField,
              undefined,
              this.config?.optionCustomPipe?.debug
            )
          : option[this.data.fieldName.split('.')[1]] ?? option.name ?? option.id;

        return { ...option, display };
      })
      .filter((option, index, self) => index === self.findIndex(opt => opt.display === option.display))
      .sort((a, b) => a.display.localeCompare(b.display, 'en', { sensitivity: 'base' }));

    return options;
  }

  private createFilter(): Filter {
    if (!this.isSelect) {
      return new Filter(this.form.getRawValue());
    }

    this.form.removeControl('selected');

    const filters: Filter[] = this.form.value.value.map(({ value, valueLabel }): Filter => {
      const filter = new Filter({ ...this.form.getRawValue(), value, valueLabel });

      // add labels for boolean dropdown options
      switch (value) {
        case 'IS_NULL':
          filter.valueLabel = 'Not Defined';
          break;

        case 'true':
          filter.valueLabel = 'Yes';
          break;

        case 'false':
          filter.valueLabel = 'No';
          break;
      }

      return filter;
    });

    return this.reduceFilters(filters);
  }

  /**
   * Reduces several filters into one IN filter.
   * If only one filter is provied it will be turned into an EQUALS filter
   */
  private reduceFilters(filters: Filter[]) {
    const [firstFilter, ...restFilters] = filters;

    const combinedFilter = restFilters.reduce(
      (acc, filter) => {
        acc.valueLabel = `${acc.valueLabel ?? acc.value} or ${filter.valueLabel ?? filter.value}`;
        acc.value += `,${filter.value}`;

        acc.operator = FilterOperator.IN;
        return acc;
      },
      new Filter({
        ...firstFilter,
        operator: FilterOperator.EQUALS
      })
    );

    return combinedFilter;
  }

  private getValueWithLabel(val: any) {
    if (Array.isArray(val)) {
      return val.map(v => this.getValueWithLabel(v));
    }

    const name = this.data.fieldName.split('.')[1];
    const value = val?.[name] || val?.id || val?.display || val;
    const valueLabel = this.config?.optionField
      ? PropertyAccessPipe.transform(val, this.config?.optionField)
      : val[name] ?? val.name;

    return { value, valueLabel };
  }

  private getOperator() {
    if (this.currentFilter?.operator != null) {
      return this.currentFilter.operator;
    }
    const useEquals =
      this.config?.optionUrl ||
      this.config?.options ||
      this.config?.filterType === 'boolean' ||
      this.config?.filterType === 'date' ||
      this.config?.filterType === 'number';

    return useEquals ? FilterOperator.EQUALS : FilterOperator.LIKE;
  }
}
