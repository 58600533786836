import { createReducer, on } from '@ngrx/store';
import {
  getMaintenanceModeInformation,
  getMaintenanceModeInformationSuccess,
  loadApplicationMetadata,
  loadApplicationMetadataSuccess
} from './meta.actions';
import { META_INITIAL_STATE } from './meta.state';

export const metaReducer = createReducer(
  META_INITIAL_STATE,
  on(loadApplicationMetadata, state => ({
    ...state,
    loading: true
  })),
  on(loadApplicationMetadataSuccess, (state, payload) => ({
    ...state,
    ...payload,
    loading: false
  })),
  on(getMaintenanceModeInformation, state => ({
    ...state,
    loading: true
  })),
  on(getMaintenanceModeInformationSuccess, (state, payload) => ({
    ...state,
    maintenance: payload,
    loading: false
  }))
);
