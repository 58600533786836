import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Action } from '@rims/lib';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppState } from '../../store/store.state';
import { RequestBuilder } from './request-builder/request-builder.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(private readonly requestBuilder: RequestBuilder, private readonly store: Store<AppState>) {}

  hasActionPermission(action: Action) {
    return this.requestBuilder
      .request(environment.backendUrl, 'permissions', 'has-action-permission')
      .param('action', action)
      .get()
      .pipe(map(response => response.hasPermission));
  }
}
