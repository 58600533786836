import { Direction, Filter } from '@rims/lib';

/**
 * Query Param Key for the given view
 */
export const getQueryParamKey = viewId => `view_${viewId}`;

/**
 * Query Param Key used to indicate an update.
 *
 * It is used to notify the component to trigger a data update.
 */
export const RIMS_TABLE_QUERY_PARAM_RELOAD_TIME = '_r';

/**
 * Defines the value of a query param used by the RIMS-Table
 * to save information about pagination and sorting.
 *
 * The key is identified by the view's ID. The value is an
 * encoded instance of this class.
 *
 * @see
 * For encoding and decoding, see the functions of this class.
 *
 * @example
 * `?view_3=o:11,d:asc,s:20,i:3,f:number~=~4,c:47.11.187`
 */
export class ViewQueryParam {
  /**
   * Page Size
   */
  s?: number;
  /**
   * Page Index (zero-based)
   */
  i?: number;
  /**
   * Order by
   */
  o?: string;
  /**
   * Order by Direction
   */
  d?: Direction;
  /**
   * Filters
   */
  f?: Filter[];
  /**
   * Columns
   */
  c?: number[];
  /**
   * Query for the `/search` endpoint
   */
  q?: string;

  constructor(init?: Partial<ViewQueryParam>) {
    Object.assign(this, init);
  }

  private static readonly PARAMS_DELIMITER = ',';
  private static readonly PARAMS_VALUE_DELIMITER = ':';
  private static readonly PARAMS_VALUE_COLUMNS_DELIMITER = '.';

  encode(): string {
    const parts = [];
    const encodeProp = (key: keyof ViewQueryParam) =>
      typeof this[key] !== 'undefined' && parts.push(`${key}${ViewQueryParam.PARAMS_VALUE_DELIMITER}${this[key]}`);
    encodeProp('o');
    encodeProp('q');
    encodeProp('d');
    encodeProp('s');
    encodeProp('i');
    typeof this['c'] !== 'undefined' &&
      parts.push(
        `c${ViewQueryParam.PARAMS_VALUE_DELIMITER}${this.c.join(ViewQueryParam.PARAMS_VALUE_COLUMNS_DELIMITER)}`
      );
    typeof this['f'] !== 'undefined' &&
      parts.push(`f${ViewQueryParam.PARAMS_VALUE_DELIMITER}${Filter.encodeFilters(this.f)}`);
    return parts.join(ViewQueryParam.PARAMS_DELIMITER);
  }

  static decode(paramString: string): ViewQueryParam {
    const param = new ViewQueryParam();

    if (!paramString) {
      return param;
    }

    const parts = paramString.split(ViewQueryParam.PARAMS_DELIMITER);
    parts.forEach(part => {
      const valueParts = part.split(ViewQueryParam.PARAMS_VALUE_DELIMITER);
      switch (valueParts[0]) {
        case 'o':
          param.o = valueParts[1];
          break;
        case 'q':
          param.q = valueParts[1];
          break;
        case 'd':
          param.d = valueParts[1] as Direction;
          break;
        case 's':
          param.s = Number(valueParts[1]);
          break;
        case 'i':
          param.i = Number(valueParts[1]);
          break;
        case 'f':
          param.f = Filter.decodeFilters(valueParts[1]);
          break;
        case 'c':
          param.c = valueParts[1].split(ViewQueryParam.PARAMS_VALUE_COLUMNS_DELIMITER).map(n => Number(n));
          break;
        default:
          console.warn(
            `Encountered unknown key "${valueParts[0]}" while decoding ViewQueryParam from string "${paramString}". This property will be ignored.`
          );
      }
    });
    return param;
  }
}
