import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containerSize'
})
export class ContainerSizePipe implements PipeTransform {
  transform(container: any): string {
    let str = `${container.size}`;
    const unit = container?.substanceUnit?.shortName;
    if (unit) {
      str += ` ${unit}`;
    }
    return str;
  }
}
