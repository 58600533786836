import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { AppState, isLoading } from '../../../store/store.state';

@Component({
  selector: 'rims-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loading: Observable<boolean>;

  constructor(private readonly store: Store<AppState>, public readonly breadcrumb: BreadcrumbService) {}

  ngOnInit() {
    this.loading = this.store.pipe(select(isLoading));
  }
}
