import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);

    // Open external links in new tab
    if (href.startsWith('http')) {
      return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
    }

    return html;
  };

  const tableRenderer = renderer.table;
  renderer.table = (header: string, body: string) => {
    const html = tableRenderer.call(renderer, header, body);
    return html.replace('<table>', '<table class="table table-bordered table-hover">');
  };

  const imageRenderer = renderer.image;
  renderer.image = (href: string, title: string, text: string) => {
    const html = imageRenderer.call(renderer, href, title, text);
    const classes = ['w-100'];
    if (href.includes('border=1')) {
      classes.push('border');
    }
    return html.replace('<img', `<img class="${classes.join(' ')}"`);
  };

  return {
    renderer
  };
}
