import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { ActorsEffects } from '../actors/store/actors.effects';
import { CompaniesEffects } from '../companies/store/companies.effects';
import { ControllerSettingsEffects } from '../controller-settings/store/controller-settings.effects';
import { DocumentsEffects } from '../documents/store/documents.effects';
import { ItemEffects } from '../items/store/item.effects';
import { MetaEffects } from '../meta/store/meta.effects';
import { ProductGroupEffects } from '../product-groups/store/product-groups.effects';
import { DataEffects } from '../shared/store/data/data.effects';
import { MetadataEffects } from '../shared/store/metadata/metadata.effects';
import { RouterEffects } from '../shared/store/router/router.effects';
import { SharedEffects } from '../shared/store/shared/shared.effects';
import { UserEffects } from '../shared/store/user/user.effects';
import { reducers } from './store.reducer';
import { AppState } from './store.state';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('RIMS Reducers');

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forRoot([
      UserEffects,
      MetadataEffects,
      DataEffects,
      RouterEffects,
      ProductGroupEffects,
      DocumentsEffects,
      MetaEffects,
      ControllerSettingsEffects,
      CompaniesEffects,
      ItemEffects,
      ActorsEffects,
      SharedEffects
    ]),
    StoreModule.forRoot(REDUCER_TOKEN, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      name: 'RIMS Store',
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    })
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useFactory: reducers
    }
  ]
})
export class RimsStoreModule {}
