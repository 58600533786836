import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CancelService {
  requests = new Map<string, Subject<void>>();

  /**
   * add 'url' to our list of requests
   */
  add(url: string) {
    this.requests.set(url, new Subject<void>());
  }

  /**
   * cancel the request to 'url'
   */
  cancel(url: string) {
    this.requests.get(url)?.next();
  }

  /**
   * returns an Observable that listens to cancel events for the specified url
   */
  get(url: string) {
    return this.requests.get(url).asObservable();
  }

  /**
   * remove 'url' from our list of requests
   */
  remove(url: string) {
    this.requests.delete(url);
  }
}
