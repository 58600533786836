import { Filter } from '@rims/lib';

/**
 * Adds missing entities to 'expand' based on provided filters.
 *
 * Some filters may reference columns that are not part of the displayed columns of a table and thus not part of the expand array yet.
 *
 */
export function filtersToExpandQuery(expand: string[], filters: Filter[]) {
  const relations = filters
    // find all relations
    .filter(f => f.fieldName.includes('.'))
    // find related entity name
    .map(f => f.fieldName.split('.').slice(0, -1).join('.'));

  // combine relations with expand and remove duplicates
  const uniqueRelations = new Set([...relations, ...expand]);

  return Array.from(uniqueRelations);
}
