<div *ngIf="loading | async">
  <div class="row">
    <div class="col d-flex mt-2">
      <div class="info-value py-1 px-2 rounded capitalize-text">Fetching History...</div>
    </div>
  </div>
</div>
<div *ngIf="(history | async)?.length > 0">
  <div class="row">
    <div *ngFor="let record of history | async; let isLast = last" class="col-12">
      <span *ngFor="let change of record?.changes" [ngSwitch]="change?.kind">
        <ng-container *ngSwitchCase="'E'">
          <div class="d-flex flex-column flex-lg-row my-2 justify-content-between">
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-md-center">
              <div class="property-value py-1 px-2 rounded mr-2 mb-1 mb-md-0">
                <span class="capitalize-text">{{ change?.path[0] }}</span>
              </div>
              <div class="old-value py-1 px-2 mb-1 mb-md-0 rounded" *ngIf="!!change?.lhs" title="Old Value">
                <span>
                  <del>{{ change?.lhs }}</del>
                </span>
              </div>
              <mat-icon class="mx-2 d-none d-sm-block" *ngIf="change?.rhs && change?.lhs">arrow_forward</mat-icon>
              <div class="new-value py-1 px-2 mb-1 mb-md-0 rounded" *ngIf="!!change?.rhs" title="New Value">
                <span>{{ change?.rhs }}</span>
              </div>
              <div class="info-value py-1 px-2 ml-sm-2 mb-1 mb-md-0 rounded" *ngIf="!change?.lhs">
                <span>property added</span>
              </div>
              <div class="info-value py-1 px-2 ml-sm-2 mb-1 mb-md-0 rounded" *ngIf="!change?.rhs">
                <span>property removed</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'C'">
          <div class="d-flex flex-column flex-lg-row my-2 justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <div class="new-value py-1 px-2 rounded capitalize-text">created record</div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'D'">
          <div class="d-flex flex-column flex-lg-row my-2 justify-content-between">
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-md-center">
              <div class="property-value py-1 px-2 rounded mr-2 mb-1 mb-md-0">
                <span class="capitalize-text">{{ change?.path[0] }}</span>
              </div>
              <div class="old-value py-1 px-2 mr-2 mb-1 mb-md-0 rounded" title="Old Value">
                <span>
                  <del>{{ change?.lhs }}</del>
                </span>
              </div>
              <div class="info-value py-1 px-2 ml-md-2 mb-1 mb-md-0 rounded">
                <span>property deleted</span>
              </div>
            </div>
          </div>
        </ng-container>
      </span>
      <div class="d-flex flex-row align-items-center my-2 mt-lg-0">
        <div class="info-value py-1 px-2 rounded mr-2">
          {{ record?.updatedBy }}
        </div>
        <div class="info-value py-1 px-2 rounded">
          {{ record?.updatedAt | date : 'medium' }}
        </div>
      </div>
      <hr *ngIf="!isLast || showLastDivider" class="mt-3" />
    </div>
  </div>
</div>
