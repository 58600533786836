import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  PG_FUNCTION_EXECUTED_NO_RETURN_STATEMENT,
  PG_INVALID_DATETIME_FORMAT,
  PG_UNIQUE_VIOLATION
} from '@drdgvhbh/postgres-error-codes';
import { Store } from '@ngrx/store';
import { Action, RimsErrorCode } from '@rims/lib';
import { environment } from '../../../../../../environments/environment';
import { AppState } from '../../../../store/store.state';
import { stopLoading } from '../../../store/metadata/metadata.actions';

const makeCodeSpan = (content: string) => `<code style="font-size: 75%">${content}</code>`;

@Component({
  selector: 'error-snackbar',
  templateUrl: 'error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent implements OnInit {
  message: SafeHtml;
  animationProgress = 100;
  private animationStart: DOMHighResTimeStamp;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public readonly data: {
      error: any;
      duration: number;
    },
    public readonly ref: MatSnackBarRef<ErrorSnackbarComponent>,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit() {
    requestAnimationFrame(this.animationStep.bind(this));

    if (this.data.error.name === Error.name) {
      this.data.error.name = 'Something went wrong';
    }
    if (this.data.error.name === 'HttpErrorResponse') {
      const url = (this.data.error.url as string).replace(environment.backendUrl, '');
      const urlSpan = makeCodeSpan(url);
      if (this.data.error.status === 401) {
        this.data.error.name = `Missing Permission`;
        if (url.includes('/progress')) {
          this.data.error.message = `Ask your admin to grant you the ${makeCodeSpan(
            Action.READ_JOB_PROGRESS
          )} permission.`;
        } else {
          this.data.error.message = `Your user or role is not permitted to access the following URL:<br>${urlSpan}`;
        }
      } else {
        const originalError = (this.data.error as HttpErrorResponse).error;
        const code = originalError?.code;
        switch (code) {
          case RimsErrorCode.LEGMM:
          case RimsErrorCode.BUITM:
          case RimsErrorCode.ITEOL:
          case RimsErrorCode.ONEGT:
            this.data.error.name = 'Item can not be added';
            break;
          case RimsErrorCode.ITMLC:
            this.data.error.name = 'Item can not be removed';
            break;
          case RimsErrorCode.BUEOL:
            this.data.error.name = 'Item can not be added or removed';
            break;
          case RimsErrorCode.LEGBU:
            this.data.error.name = 'Risk Class can not be added';
            break;
          case RimsErrorCode.RCINH:
            this.data.error.name = 'Risk Class can not be removed';
            break;
          case RimsErrorCode.LCATR:
          case RimsErrorCode.LCITR:
          case RimsErrorCode.LCEMD:
          case RimsErrorCode.LCLEG:
          case RimsErrorCode.LCACT:
          case RimsErrorCode.INTPP:
            this.data.error.name = 'Lifecycle Update failed';
            break;
          case RimsErrorCode.ATGTM:
          case RimsErrorCode.ATICO:
          case RimsErrorCode.ACTTY:
            this.data.error.name = 'Actor Restriction';
            break;
          case RimsErrorCode.BEMDN:
            this.data.error.name = 'Can not remove Nomenclature';
            break;
          case RimsErrorCode.SQLIN:
            this.data.error.name = 'Filter Input has been blocked';
            originalError.message = originalError.message.replace(`[${RimsErrorCode.SQLIN}] `, '');
            break;
          case RimsErrorCode.CRCPC:
            this.data.error.name = 'Can not create parent/child relationship';
            break;
          case RimsErrorCode.IPFNB:
            this.data.error.name = 'Non BUDI product group cannot have intended purpose';
            break;
          case RimsErrorCode.CONAD:
            this.data.error.name = 'Container-Type Restriction';
            break;
          case PG_UNIQUE_VIOLATION:
            this.data.error.name = 'Duplicate';
            originalError.message = originalError.detail;
            break;
          case PG_FUNCTION_EXECUTED_NO_RETURN_STATEMENT:
            this.data.error.name = originalError.where;
            break;
          case PG_INVALID_DATETIME_FORMAT:
            this.data.error.name = 'Invalid Date Format';
            originalError.message = `"${originalError.parameters[0]}" is not a valid date (Please use YYYY-MM-DD format)`;
            break;
          default:
            this.data.error.name = this.data.error.statusText;
        }
        this.data.error.message = originalError?.message || urlSpan;
      }
    }

    this.message = this.sanitizer.bypassSecurityTrustHtml(this.data.error.message);
    this.store.dispatch(stopLoading());
  }

  private animationStep(timestamp: DOMHighResTimeStamp) {
    if (!this.animationStart) {
      this.animationStart = timestamp;
    }
    const millisecondsPassed = timestamp - this.animationStart;
    const timePassedRatio = millisecondsPassed / this.data.duration;

    this.animationProgress = 100 - 100 * timePassedRatio;

    if (millisecondsPassed < this.data.duration) {
      requestAnimationFrame(this.animationStep.bind(this));
    }
  }
}
