import { Pipe, PipeTransform } from '@angular/core';
import { PropertyAccessPipe } from './property-access.pipe';

export type SelectExpression<T = any> = (o: T, ...args: any[]) => string;

/**
 * This pipe is used in the `<rims-property-edit-menu>` component to display
 * options in dropdown menus.
 *
 * Two modes are available:
 * - `selectFieldName`
 * - `selectExpression`
 *
 * `selectExpression` has priority over `selectField`
 */
@Pipe({
  name: 'propertyEditMenuSelectValue'
})
export class PropertyEditMenuSelectValuePipe implements PipeTransform {
  transform<T = any>(value: T, selectFieldName?: string, selectExpression?: SelectExpression<T>): string {
    return PropertyEditMenuSelectValuePipe.transform(value, selectFieldName, selectExpression);
  }

  static transform<T = any>(value: T, selectFieldName?: string, selectExpression?: SelectExpression<T>): string {
    if (selectExpression) {
      return selectExpression(value);
    }
    if (selectFieldName) {
      return PropertyAccessPipe.transform(value, selectFieldName);
    }

    return value['id'];
  }
}
