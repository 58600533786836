import { Pipe, PipeTransform } from '@angular/core';
import { SapBudiLifecycle, sapBudiLifecycleToLifecycle } from '@rims/sap-interface';

@Pipe({
  name: 'sapLifecycleToRimsLifecycle'
})
export class SapLifecycleToRimsLifecyclePipe implements PipeTransform {
  transform(sapLifecycle: SapBudiLifecycle): string {
    return sapBudiLifecycleToLifecycle.get(sapLifecycle) || sapLifecycle;
  }
}
