import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { EqualsFilter, materialNumberMaxLength, materialNumberRegex } from '@rims/lib';
import { SapInterfaceResponse, SapMaraObject, SapResponseCode } from '@rims/sap-interface';
import { Job } from 'bullmq';
import { BehaviorSubject, forkJoin, fromEvent, interval, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { Page } from 'src/app/modules/shared/models/page/page.model';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { createRecord } from 'src/app/modules/shared/store/data/data.actions';
import { environment } from 'src/environments/environment';
import { AppState } from '../../../store/store.state';

enum FormFieldName {
  SECTION_ONE_VALID = 'sectionOneValid',
  SECTION_ONE_FINISHED = 'sectionOneFinished',
  SECTION_TWO_FINISHED = 'sectionTwoFinished',
  NO_DUPLICATE_ITEM = 'noDuplicateItem',
  NO_DUPLICATE_EXT_SAP_ITEM = 'noDuplicateExtSapItem',
  EXT_SAP_ITEM_CREATION_PENDING = 'extSapItemCreationPending',
  EXT_SAP_ITEM_CHECKED = 'extSapItemChecked',
  EXT_SAP_ITEM_NOT_FOUND = 'extSapItemNotFound',
  EXT_SAP_ITEM_UNDEFINED_ERROR = 'extSapItemUndefinedError',
  EXT_SAP_ITEM_STATUS_ERROR = 'extSapItemStatusError',
  EXT_SAP_ITEM_ERROR_MESSAGE = 'extSapItemErrorMessage',
  EXT_SAP_ITEM = 'extSapItem',
  ITEM_NUMBER = 'itemNumber',
  PRODUCT = 'product',
  PRODUCT_QUERY = 'product_query'
}

@Component({
  selector: 'rims-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss']
})
export class CreateItemComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly formFieldName = FormFieldName;

  filteredProducts: Observable<any[]>;
  private filteredProductExactMatch: boolean;
  showNoSelectionInfo = false;
  specialDeviceTypes: Observable<any>;
  systemProcedurePackTypes: Observable<any>;
  multiComponentTypes: Observable<any>;
  itemIsLoading = false;

  existingItemsResult = new BehaviorSubject<Page<any>>(null);
  existingExtSapItemResult = new BehaviorSubject<Page<any>>(null);

  form = new UntypedFormGroup({
    // Control fields
    [FormFieldName.SECTION_ONE_VALID]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.SECTION_ONE_FINISHED]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.SECTION_TWO_FINISHED]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.NO_DUPLICATE_ITEM]: new UntypedFormControl(undefined, Validators.requiredTrue),
    [FormFieldName.NO_DUPLICATE_EXT_SAP_ITEM]: new UntypedFormControl(undefined, Validators.required),
    [FormFieldName.EXT_SAP_ITEM_CREATION_PENDING]: new UntypedFormControl(false),
    [FormFieldName.EXT_SAP_ITEM_CHECKED]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.EXT_SAP_ITEM_NOT_FOUND]: new UntypedFormControl(false),
    [FormFieldName.EXT_SAP_ITEM_UNDEFINED_ERROR]: new UntypedFormControl(false),
    [FormFieldName.EXT_SAP_ITEM_STATUS_ERROR]: new UntypedFormControl(false),
    [FormFieldName.EXT_SAP_ITEM_ERROR_MESSAGE]: new UntypedFormControl(null),
    [FormFieldName.PRODUCT_QUERY]: new UntypedFormControl(null),
    // Content fields
    [FormFieldName.PRODUCT]: new UntypedFormControl(null),
    [FormFieldName.ITEM_NUMBER]: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(materialNumberMaxLength),
      Validators.pattern(materialNumberRegex)
    ]),
    [FormFieldName.EXT_SAP_ITEM]: new UntypedFormControl(null)
  });

  @ViewChild('itemNumberInput')
  private itemNumberInput: ElementRef<HTMLInputElement>;

  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient,
    private readonly data: DataService,
    private readonly breadcrumb: BreadcrumbService
  ) {}

  ngOnInit() {
    this.specialDeviceTypes = this.store.select(state => state.data.entries['special_device_type'].page.results);
    this.systemProcedurePackTypes = this.store.select(
      state => state.data.entries['system_procedure_pack_type'].page.results
    );
    this.multiComponentTypes = this.store.select(state => state.data.entries['multi_component_type'].page.results);
    this.filteredProducts = this.form.get(FormFieldName.PRODUCT_QUERY).valueChanges.pipe(
      filter(val => typeof val === 'string' && val.length > 0),
      map(val => val.toLowerCase()),
      tap(() => {
        this.form.get(FormFieldName.PRODUCT).setValue(null);
        this.form.get(FormFieldName.PRODUCT_QUERY).markAsPending();
      }),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(query => {
        return this.data.getAll('product', undefined, 0, 20, undefined, undefined, undefined, query).pipe(
          map(response => {
            this.filteredProductExactMatch =
              response.resultsSize === 1 && (response.results[0].name as string).toLowerCase() === query;

            // To clear the pending state
            this.form.get(FormFieldName.PRODUCT_QUERY).setErrors(null);

            return response.results;
          })
        );
      })
    );

    this.breadcrumb.pushCreationPage();
  }

  ngAfterViewInit() {
    fromEvent(this.itemNumberInput.nativeElement, 'keyup')
      .pipe(
        map(() => this.form.value[FormFieldName.ITEM_NUMBER] as string),
        tap(() => {
          this.existingItemsResult.next(null);
          this.form.reset({
            [FormFieldName.ITEM_NUMBER]: this.form.get(FormFieldName.ITEM_NUMBER).value,
            [FormFieldName.EXT_SAP_ITEM_CHECKED]: { value: false, disabled: false }, // enable previously disabled control again
            [FormFieldName.SECTION_TWO_FINISHED]: false
          });
        }),
        filter(itemNumber => itemNumber.length > 0 && this.form.get(FormFieldName.ITEM_NUMBER).valid),
        tap(() => (this.itemIsLoading = true)),
        debounceTime(500),
        withLatestFrom(this.store.select(state => state.metadata.entities)),
        switchMap(([itemNumber, entities]) => {
          const itemEntityId = Object.keys(entities)
            .map(key => entities[key])
            .find(entity => entity?.name === 'item').id;
          const extSapItemEntityId = Object.keys(entities)
            .map(key => entities[key])
            .find(entity => entity?.name === 'ext_sap_item').id;

          return forkJoin([
            this.data.getOne(itemEntityId, itemNumber),
            this.data.getOne(extSapItemEntityId, itemNumber)
          ]).pipe(
            tap(([itemResult, extSapItemResult]) => {
              this.itemIsLoading = false;
              this.form.get(FormFieldName.NO_DUPLICATE_ITEM).setValue(itemResult.resultsSize === 0);
              this.form.get(FormFieldName.NO_DUPLICATE_EXT_SAP_ITEM).setValue(extSapItemResult.resultsSize === 0);
              this.form
                .get(FormFieldName.SECTION_ONE_VALID)
                .setValue(this.form.get(FormFieldName.NO_DUPLICATE_ITEM).value);
              this.existingItemsResult.next(itemResult);
              this.existingExtSapItemResult.next(extSapItemResult);
            })
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  nextStage() {
    // Handle Section 1 (Validation)
    if (
      this.form.get(FormFieldName.SECTION_ONE_VALID).value &&
      !this.form.get(FormFieldName.SECTION_ONE_FINISHED).value
    ) {
      this.form.get(FormFieldName.SECTION_ONE_FINISHED).setValue(true);

      if (!this.form.get(FormFieldName.NO_DUPLICATE_EXT_SAP_ITEM).value) {
        this.form.get(FormFieldName.EXT_SAP_ITEM_CHECKED).setValue(true);
        this.form.get(FormFieldName.SECTION_TWO_FINISHED).setValue(true);
        return;
      }
    }

    // Handle Section 2.1 (Load Ext Sap Item)
    if (
      !this.form.get(FormFieldName.EXT_SAP_ITEM_CHECKED).value &&
      !this.form.get(FormFieldName.SECTION_TWO_FINISHED).value &&
      this.form.get(FormFieldName.SECTION_ONE_FINISHED).value
    ) {
      this.fetchItemFromSap();

      return;
    }

    // Handle Section 2.2 (Confirm Ext Sap item)
    if (
      this.form.get(FormFieldName.EXT_SAP_ITEM_CHECKED).value &&
      !this.form.get(FormFieldName.SECTION_TWO_FINISHED).value
    ) {
      this.createExtSapItem();

      return;
    }

    // Handle Section 3 (Item Creation)
    if (this.form.get(FormFieldName.SECTION_TWO_FINISHED).value) {
      // The control is disabled at this point and therefore not present in form.value
      const itemNumber = this.form.get(FormFieldName.ITEM_NUMBER).value;
      const { product } = this.form.value;
      this.store.dispatch(
        createRecord({
          url: 'items',
          payload: {
            product,
            itemNumber
          },
          navigateTo: () => `/view/items/${itemNumber}`
        })
      );
    }
  }

  private createExtSapItem() {
    this.form.get(FormFieldName.EXT_SAP_ITEM_CREATION_PENDING).setValue(true);
    this.form.get(FormFieldName.EXT_SAP_ITEM_CHECKED).disable();
    const itemNumber = this.form.get(FormFieldName.ITEM_NUMBER).value;
    let extSapItemCreated = false;

    this.http
      .post<Job>(`${environment.backendUrl}/extsapitems/from-material-number?materialNumber=${itemNumber}`, null)
      .pipe(
        switchMap(() => {
          return interval(2000).pipe(
            filter(() => !extSapItemCreated),
            switchMap(() =>
              this.data.getAll('extsapitem', undefined, 0, 1, undefined, undefined, [
                new EqualsFilter('item_number', itemNumber)
              ])
            ),
            filter(page => page.totalResultsSize === 1),
            tap(() => {
              extSapItemCreated = true;
              this.form.get(FormFieldName.SECTION_TWO_FINISHED).setValue(true);
              this.form.get(FormFieldName.EXT_SAP_ITEM_CREATION_PENDING).setValue(false);
            }),
            takeUntil(this.destroy$)
          );
        })
      )
      .subscribe();
  }

  private fetchItemFromSap() {
    const numberCtrl = this.form.get(FormFieldName.ITEM_NUMBER);
    numberCtrl.disable();
    this.http
      .get<SapInterfaceResponse<SapMaraObject>>(
        `${environment.backendUrl}/sap/material/${this.form.get(FormFieldName.ITEM_NUMBER).value}`
      )
      .pipe(
        tap(result => {
          const status = result[0].status;
          switch (status) {
            case SapResponseCode.SUCCESSFUL:
              if (result[0].payload?.materialStatus === 'M4') {
                this.form.get(FormFieldName.EXT_SAP_ITEM_STATUS_ERROR).setValue(true);
              } else {
                this.form.get(FormFieldName.EXT_SAP_ITEM).setValue(result[0].payload);
              }
              break;
            case SapResponseCode.MATERIAL_DOES_NOT_EXIST:
              this.form.get(FormFieldName.EXT_SAP_ITEM_NOT_FOUND).setValue(true);
              break;
            default:
              this.form.get(FormFieldName.EXT_SAP_ITEM_UNDEFINED_ERROR).setValue(true);
              console.error(result[0]);
              break;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.form.get(FormFieldName.EXT_SAP_ITEM_UNDEFINED_ERROR).setValue(true);
          this.form.get(FormFieldName.EXT_SAP_ITEM_ERROR_MESSAGE).setValue(error.message);
          console.error(error);
          return of(undefined);
        }),
        tap(() => {
          numberCtrl.enable();
        })
      )
      .subscribe();
  }

  onProductSelect(sel: MatAutocompleteSelectedEvent) {
    this.form.get(FormFieldName.PRODUCT).setValue(sel.option.value.id);
    this.form.get(FormFieldName.PRODUCT_QUERY).setValue(sel.option.value.name, {
      emitEvent: false
    });
  }

  onProductAutocompleteClosed() {
    this.showNoSelectionInfo = false;
    if (!this.form.get(FormFieldName.PRODUCT).value) {
      if (this.filteredProductExactMatch) {
        this.form.get(FormFieldName.PRODUCT_QUERY).setErrors({
          closedWithoutSelection: true
        });
      } else {
        this.showNoSelectionInfo = true;
      }
    }
  }

  retry() {
    this.form.get(FormFieldName.EXT_SAP_ITEM_UNDEFINED_ERROR).patchValue(false);
    this.form.get(FormFieldName.EXT_SAP_ITEM_NOT_FOUND).patchValue(false);
    this.nextStage();
  }
}
