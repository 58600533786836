import { createAction, props } from '@ngrx/store';

const PREFIX = '[Actors]';

export interface OpenAddIdentificationNumberDialogPayload {
  actor: number;
}

export interface CloseAddIdentificationNumberDialogPayload {
  actor: number;
  identificationNumberType: number;
  identificationNumber: string;
}

export const openAddIdentificationNumberDialog = createAction(
  `${PREFIX} Open Add Identification Number Dialog`,
  props<OpenAddIdentificationNumberDialogPayload>()
);
export const closeAddIdentificationNumberDialog = createAction(
  `${PREFIX} Close Add Identification Number Dialog`,
  props<CloseAddIdentificationNumberDialogPayload>()
);
