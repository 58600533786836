/**
 * Download Blob data as a file.
 *
 * @param data see https://developer.mozilla.org/en-US/docs/Web/API/Blob#Examples
 * @param name filename with extension
 */
export function downloadFile(data: Blob, name: string) {
  const url = URL.createObjectURL(data);
  const link = document.getElementById('js-download-link') as HTMLAnchorElement;
  link.href = url;
  link.download = name;
  link.click();
  URL.revokeObjectURL(url);
}
