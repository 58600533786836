import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
  transform(value: any, defaultValue?: string, includeShortName = false, reverse = false): string | undefined {
    let result = value?.name || defaultValue;

    if (value?.shortName && includeShortName) {
      result += ` (${value.shortName})`;
    }

    // currently only used for '/departments'
    if (reverse) {
      return `${value?.shortName} - ${value?.name}`;
    }

    return result;
  }
}
