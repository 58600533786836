import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from 'src/app/app.constants';
import { AppState } from 'src/app/modules/store/store.state';
import { AppViewGroup } from '../../models/view/view-group.model';
import { View } from '../../models/view/view.model';
import { loggedInUser, UserState } from '../../store/user/user.state';

@Component({
  selector: 'rims-view-menu',
  templateUrl: './view-menu.component.html',
  styleUrls: ['./view-menu.component.scss']
})
export class ViewMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  loggedInUser: Observable<UserState['user']>;
  views: Observable<View[]>;
  viewGroups: Observable<Record<string, AppViewGroup>>;

  @ViewChildren('menu')
  menus: QueryList<MatMenu>;
  menusArr: MatMenu[];
  private menusSub: Subscription;

  constructor(
    private readonly store: Store<AppState>,
    @Inject(APP_CONFIG)
    public readonly appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.store.pipe(select(loggedInUser));
    this.views = this.store.pipe(
      select(state => state.metadata.views),
      withLatestFrom(this.loggedInUser),
      filter(([view, user]) => !!view && !!user),
      map(([views, user]) => {
        const viewsArray = [];
        for (const key in views) {
          viewsArray.push(views[key]);
        }
        return viewsArray.filter(view => user.role.permissionLevel >= view.role.permissionLevel);
      })
    );
    this.viewGroups = this.store.pipe(select(state => state.metadata.viewGroups));
  }

  ngAfterViewInit() {
    this.initViewMenus();
  }

  ngOnDestroy() {
    this.menusSub?.unsubscribe();
  }

  private initViewMenus() {
    this.menusSub = this.menus.changes
      .pipe(
        // When navigating to the view for the 2nd, 3rd, ... time, there will be no change,
        // so we manually create the event
        startWith(undefined)
      )
      .subscribe(_ => {
        const menus = this.menus.toArray();
        if (Array.isArray(menus)) {
          requestAnimationFrame(() => {
            this.menusArr = menus;
          });
        }
      });
  }
}
