import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { getData } from '../../shared/store/data/data.actions';
import { DataStateEntry } from '../../shared/store/data/data.state';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class CreateProductGroupResolver implements Resolve<Record<string, DataStateEntry>> {
  private readonly productGroupTypeKey = 'product_group_type';
  private readonly productTypeKey = 'product_type';
  private readonly coeKey = 'coe';
  private readonly divisionKey = 'division';
  private readonly intendedPurposeKey = 'intended_purpose';

  constructor(readonly store: Store<AppState>) {}

  resolve(): Observable<Record<string, DataStateEntry>> {
    return this.store.pipe(
      select(state => state.data.entries),
      take(1),
      switchMap(data => {
        if (!data[this.intendedPurposeKey]) {
          this.store.dispatch(
            getData({
              url: 'intendedpurpose',
              key: this.intendedPurposeKey,
              pageSize: Number.MAX_SAFE_INTEGER
            })
          );
        }
        if (!data[this.productGroupTypeKey]) {
          this.store.dispatch(
            getData({
              url: 'productgrouptype',
              key: this.productGroupTypeKey,
              pageSize: Number.MAX_SAFE_INTEGER
            })
          );
        }
        if (!data[this.productTypeKey]) {
          this.store.dispatch(
            getData({
              url: 'producttype',
              key: this.productTypeKey,
              pageSize: Number.MAX_SAFE_INTEGER
            })
          );
        }
        if (!data[this.coeKey]) {
          this.store.dispatch(
            getData({
              url: 'coe',
              key: this.coeKey,
              pageSize: Number.MAX_SAFE_INTEGER,
              expand: ['division']
            })
          );
        }
        if (!data[this.divisionKey]) {
          this.store.dispatch(
            getData({
              url: 'division',
              key: this.divisionKey,
              pageSize: Number.MAX_SAFE_INTEGER
            })
          );
        }
        return this.store.pipe(
          select(state => state.data.entries),
          filter(entries => {
            let done = true;
            done =
              done &&
              !!entries[this.intendedPurposeKey] &&
              !entries[this.intendedPurposeKey].loading &&
              !!entries[this.productGroupTypeKey] &&
              !entries[this.productGroupTypeKey].loading &&
              !!entries[this.coeKey] &&
              !entries[this.coeKey].loading &&
              !!entries[this.divisionKey] &&
              !entries[this.divisionKey].loading &&
              !!entries[this.productTypeKey] &&
              !entries[this.productTypeKey].loading;
            return done;
          }),
          take(1)
        );
      })
    );
  }
}
