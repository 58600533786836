import { TableColumn } from '../models/table/table-column.model';
import { AppEntity } from '../models/utils/entity.model';
import { ViewConfig } from '../store/data/data.state';

export function columnsToExpandQuery(columns: TableColumn[]): string[] {
  return (
    columns
      // Find all relations
      .filter(col => col.field2.field.includes('.'))
      // Find related entity name
      .map(col => col.field2.field.split('.').slice(0, -1).join('.'))
      // Remove duplicates
      .filter((value, index, self) => self.indexOf(value) === index)
  );
}

export function columnIdsFromNames(columnNames: string[], { columns, view }: ViewConfig): number[] | null {
  if (!columnNames || columnNames.length === 0) return null;

  return columnNames
    .map(name => {
      const id = columns.find(col => col.field2?.field === name)?.id;
      if (!id) {
        console.warn(
          `View ${view.id} (${
            (view.entity as AppEntity).name
          }):\nCould not resolve column id for column name "${name}". This column will be ignored and not be displayed. Please check the resolver for this view and fix the column name. Also check if the database contains the fields and columns for the view where you expected it to be. If not, also check the relevant init-data.`
        );
      }
      return id;
    })
    .filter(id => typeof id === 'number');
}
