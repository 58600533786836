import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms a `Document` record into a string which
 * can be displayed in the "Add Document" autocomplete input.
 */
@Pipe({
  name: 'documentOptionFormat'
})
export class DocumentOptionFormatPipe implements PipeTransform {
  transform(document: any): string {
    let str = `${document.docNumber}`;
    if (document.docRevision) {
      str += ` (Rev. ${document.docRevision})`;
    }
    return str;
  }
}
