import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/store/store.state';
import {
  closeChangeBudiLifecycleDialog,
  OpenChangeBudiLifecycleDialogPayload
} from '../../store/product-groups.actions';

enum FieldNames {
  CONFIRM = 'confirm'
}

@Component({
  selector: 'rims-change-budi-lifecycle-dialog',
  templateUrl: './change-budi-lifecycle-dialog.component.html',
  styleUrls: ['./change-budi-lifecycle-dialog.component.scss']
})
export class ChangeBudiLifecycleDialogComponent {
  readonly fieldNames = FieldNames;

  form = new UntypedFormGroup({
    [FieldNames.CONFIRM]: new UntypedFormControl(false, Validators.requiredTrue)
  });

  constructor(
    public readonly dialogRef: MatDialogRef<ChangeBudiLifecycleDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: OpenChangeBudiLifecycleDialogPayload,
    private readonly store: Store<AppState>
  ) {}

  onSubmit() {
    this.store.dispatch(
      closeChangeBudiLifecycleDialog({
        ...this.data
      })
    );
    this.dialogRef.close();
  }
}
