import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoutes } from '../../app-routing.module';
import { FilterMenu, FilterMenuGroup } from '../shared/store/metadata/metadata.actions';
import { ViewByUrlParamResolver } from '../view/resolvers/view-by-url-param.resolver';
import { ViewComponent } from '../view/views/view/view.component';
import { CreateItemResolver } from './resolvers/create-item.resolver';
import { ItemDetailResolver } from './resolvers/item-detail.resolver';
import { CreateItemComponent } from './views/create-item/create-item.component';
import { ItemDetailComponent } from './views/item-detail/item-detail.component';

export const itemFilters: (FilterMenu | FilterMenuGroup)[] = [
  { fieldName: 'itemNumber', displayName: 'Item Number' },
  { fieldName: 'product.name', displayName: 'Product Name' },
  {
    isGroup: true,
    name: 'SAP Information',
    filterDefinitions: [
      { fieldName: 'itemNumber2.name', displayName: 'Material Name' },
      { fieldName: 'itemNumber2.materialStatus', displayName: 'Material Status' },
      { fieldName: 'itemNumber2.materialStatusChangeDate', displayName: 'Material Status Change' },
      { fieldName: 'itemNumber2.distributionStatus', displayName: 'Distribution Status' },
      { fieldName: 'itemNumber2.distributionStatusChangeDate', displayName: 'Distribution Status Change' },
      { fieldName: 'itemNumber2.shelfLife', displayName: 'Shelf Life' },
      { fieldName: 'itemNumber2.marketScope', displayName: 'Market Scope' }
    ]
  },
  {
    isGroup: true,
    name: 'Properties',
    filterDefinitions: [
      { fieldName: 'isSterile', displayName: 'Sterile' },
      { fieldName: 'active', displayName: 'Active' },
      { fieldName: 'administeringMedicine', displayName: 'Administering Medicine' },
      { fieldName: 'implantable', displayName: 'Implantable' },
      { fieldName: 'reusable', displayName: 'Reusable' },
      { fieldName: 'measuringFunction', displayName: 'Measuring Function' }
    ]
  },
  {
    fieldName: 'budiInfo2.lifecycle',
    displayName: 'BUDI Lifecycle'
  }
];

const routes: CustomRoutes = [
  {
    path: '',
    component: ViewComponent,
    resolve: {
      result: ViewByUrlParamResolver
    },
    data: {
      primaryKeyPropertyName: 'itemNumber2.itemNumber',
      shouldDisplayDetailLink: true,
      shouldDisplaySearchInput: true,
      shouldDisplayExportDialog: true,
      shouldDisplayDeleteAction: true,
      shouldDisplayCheckboxes: true,
      checkBoxesProperty: 'itemNumber',
      createNewRecordLink: {
        entityName: 'item'
      },
      filterMenus: itemFilters
    }
  },
  {
    path: 'new',
    component: CreateItemComponent,
    resolve: {
      result: CreateItemResolver
    },
    data: {
      meta: {
        title: 'Create Item'
      }
    }
  },
  {
    path: ':id',
    component: ItemDetailComponent,
    resolve: {
      result: ItemDetailResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItemsRoutingModule {}
