<mat-form-field
  appearance="fill"
  class="w-100"
  [class.prepare-border]="forceShowBorder || highlightIfNull"
  [class.show-border]="forceShowBorder || ((getProp(field, obj) | isUndefinedOrNull) && (editTrigger || !editMenuCondition))"
>
  <mat-label>
    <ng-container *ngIf="entity?.fields; else loadingLabel">
      {{ getDisplay() }}
      <rims-field-help-texts [entity]="entity" [field]="field"></rims-field-help-texts>
    </ng-container>
    <ng-template #loadingLabel>
      {{ field }}
    </ng-template>
  </mat-label>

  <ng-container [ngSwitch]="isBoolean" *ngIf="entity?.fields; else dummyFormField">
    <ng-container *ngSwitchCase="false" [ngSwitch]="useTextArea">
      <input
        *ngSwitchCase="false"
        matInput
        disabled
        [attr.rims-field-name]="field"
        [placeholder]="entity?.fields?.[field]?.displayName"
        [value]="obj | dynamicPipe : useCustomPipe?.token : useCustomPipe?.prePipeProperty : field : undefinedLabel : useCustomPipe?.debug"
      />
      <textarea
        *ngSwitchCase="true"
        matInput
        disabled
        [rows]="textAreaRows ? textAreaRows(obj) : 5"
        [style.resize]="textAreaResize ? textAreaResize(obj) : 'vertical'"
        [attr.rims-field-name]="field"
        [placeholder]="entity?.fields?.[field]?.displayName"
        [value]="obj | dynamicPipe : useCustomPipe?.token : useCustomPipe?.prePipeProperty : field : undefinedLabel : useCustomPipe?.debug"
      ></textarea>
    </ng-container>
    <input
      *ngSwitchCase="true"
      matInput
      disabled
      [attr.rims-field-name]="field"
      [placeholder]="entity?.fields?.[field]?.displayName"
      [value]="getProp(field, obj, undefinedLabel) | boolean : trueLabel : falseLabel : undefinedLabel"
    />
  </ng-container>

  <ng-template #dummyFormField>
    <input matInput value="&hellip;" />
  </ng-template>

  <mat-icon
    *rimsRequirePermission="
      permissionBaseEntity;
      field: permissionField || field;
      ready: !!editMenu?.menu && !!entity?.fields;
      condition: editMenuCondition && !useDefaultPropertyEditMenu;
      force: forcePermission
    "
    class="edit-action-icon color-grey clickable"
    matSuffix
    [attr.data-current-value]="obj | dynamicPipe : useCustomPipe?.token : useCustomPipe?.prePipeProperty : field : null : useCustomPipe?.debug"
    #customEditTrigger
    (click)="emitCustomEditEvent(customEditTrigger)"
  >
    {{ menuIcon }}
  </mat-icon>

  <mat-icon
    *rimsRequirePermission="
      permissionBaseEntity;
      field: permissionField || field;
      ready: !!editMenu?.menu && !!entity?.fields;
      condition: editMenuCondition && useDefaultPropertyEditMenu;
      force: forcePermission
    "
    matSuffix
    class="edit-action-icon color-grey clickable"
    (onMenuOpen)="menuConfig?.onMenuOpen ? editMenu?.onMenuOpened(menuConfig?.onMenuOpen) : undefined"
    #trigger="matMenuTrigger"
    [matMenuTriggerFor]="editMenu?.menu || placeholderMenu"
    [matMenuTriggerData]="{
      trigger: trigger,
      placeholder: entity?.fields?.[field]?.displayName,
      element: entity,
      field: entity?.fields?.[field],
      value: getProp(field, obj),
      inputType: menuConfig?.inputType,
      options: menuConfig?.options,
      loadAsyncOptions: menuConfig?.loadAsyncOptions,
      selectFieldName: menuConfig?.selectFieldName,
      selectExpression: menuConfig?.selectExpression,
      entityName: menuConfig?.entityName || entity.name,
      recordId: getProp(menuConfig?.recordIdProperty || 'id', obj),
      useBaseEntityRecordIdForUpdate: menuConfig?.useBaseEntityRecordIdForUpdate,
      isBoolean: isBoolean,
      emptyOption: menuConfig?.emptyOption,
      inPlaceCreateBtnTxt: menuConfig?.inPlaceCreateBtnTxt,
      search: menuConfig?.search,
      date: menuConfig?.date,
      disableOnEmpty: menuConfig?.disableOnEmpty,
      useTextArea: menuConfig?.useTextArea,
      optionValueExpression: menuConfig?.optionValueExpression,
      confirm: menuConfig?.confirm
    }"
  >
    {{ menuIcon }}
  </mat-icon>

  <a *ngIf="forwardLink && entity?.fields" class="unstyled" matSuffix mat-icon-button [href]="forwardLink(obj)" [matTooltip]="forwardLinkTooltip" matTooltipShowDelay="500">
    <mat-icon>arrow_forward</mat-icon>
  </a>
</mat-form-field>

<!-- While the passed-in menu or the permission directive is not ready -->
<mat-menu #placeholderMenu></mat-menu>
