import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { FilterMenu, FilterMenuGroup } from 'src/app/modules/shared/store/metadata/metadata.actions';
import { RimsTableComponent } from '../../../shared/components/rims-table/rims-table.component';
import { AppEntity } from '../../../shared/models/utils/entity.model';
import { View } from '../../../shared/models/view/view.model';
import { reloadView } from '../../../shared/store/data/data.actions';
import { openRemoveDialog } from '../../../shared/store/shared/shared.actions';
import { getMapping } from '../../../shared/utils/entity-utils';
import { getTieredTitle } from '../../../shared/utils/metadata';
import { AppState, isLoading } from '../../../store/store.state';
import { ViewByUrlParamResolverResult } from '../../resolvers/view-by-url-param.resolver';

@Component({
  selector: 'rims-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  getMapping = getMapping;
  loading: Observable<boolean>;
  view: Observable<View>;
  resolveResult: Observable<ViewByUrlParamResolverResult>;
  detailViewEntityName: Observable<string>;
  primaryKeyPropertyName: Observable<string>;
  shouldDisplayDetailLink: Observable<boolean>;
  shouldDisplayDeleteAction: Observable<boolean>;
  shouldDisplayCheckboxes: Observable<boolean>;
  checkBoxesProperty: Observable<boolean>;
  createNewRecordLink: Observable<{
    /**
     * Entity Name in snake_case
     */
    entityName: string;
  }>;
  shouldDisplaySearchInput: Observable<boolean>;
  shouldDisplayExportDialog: Observable<boolean>;
  shouldAllowExportAll: Observable<boolean>;
  filterMenus: Observable<(FilterMenu | FilterMenuGroup)[]>;
  selectedRecords: Observable<(string | number)[]>;

  @ViewChildren(RimsTableComponent) tableRefs: QueryList<RimsTableComponent>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly meta: Title,
    private readonly breadcrumb: BreadcrumbService,
    private readonly matDialog: MatDialog
  ) {
    this.resolveResult = this.route.data.pipe(map(data => data.result));
    this.detailViewEntityName = this.route.data.pipe(map(data => data.detailViewEntityName));
    this.primaryKeyPropertyName = this.route.data.pipe(map(data => data.primaryKeyPropertyName));
    this.shouldDisplayDetailLink = this.route.data.pipe(map(data => data.shouldDisplayDetailLink));
    this.shouldDisplayDeleteAction = this.route.data.pipe(map(data => data.shouldDisplayDeleteAction));
    this.shouldDisplayCheckboxes = this.route.data.pipe(map(data => data.shouldDisplayCheckboxes));
    this.checkBoxesProperty = this.route.data.pipe(map(data => data.checkBoxesProperty));
    this.createNewRecordLink = this.route.data.pipe(map(data => data.createNewRecordLink));
    this.shouldDisplaySearchInput = this.route.data.pipe(map(data => data.shouldDisplaySearchInput));
    this.shouldDisplayExportDialog = this.route.data.pipe(map(data => data.shouldDisplayExportDialog));
    this.shouldAllowExportAll = this.route.data.pipe(map(data => data.shouldAllowExportAll));
    this.filterMenus = this.route.data.pipe(map(data => data.filterMenus));
    this.view = this.store.pipe(
      withLatestFrom(this.resolveResult),
      select(([state, resolveResult]) => state.metadata.views[resolveResult.viewId]),
      tap(view => this.setMetaData(view))
    );
    this.loading = this.store.select(isLoading);
  }

  ngOnInit() {
    this.selectedRecords = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(map(([resolveResult, selection]) => selection[resolveResult?.viewId]));
  }

  handleDelete() {
    combineLatest([this.selectedRecords, this.view])
      .pipe(
        take(1),
        map(([recordIds, view]) => {
          const viewId = view.id;
          const key = (view.entity as AppEntity).name;
          const dataId = key === 'item' ? 'itemNumber' : null;

          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key,
              entityName: key.replace(/_/g, ''),
              displayName: view.displayName.toLowerCase(),
              entityIds: recordIds as number[],
              actionTxt: 'delete',
              dataId,
              noRelation: true,
              reloadAction: reloadView
            })
          );
        })
      )
      .subscribe();
  }

  private setMetaData(view: View) {
    const title = getTieredTitle(view.displayName);
    this.meta.setTitle(title);
    this.breadcrumb.set([
      {
        routerLink: view.route,
        label: view.displayName
      }
    ]);
  }
}
