import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entityNameToNewRecordLink'
})
export class EntityNameToNewRecordLinkPipe implements PipeTransform {
  transform(entityName: string): string {
    entityName = `${entityName.replace('_', '')}`;
    if (entityName.endsWith('s')) {
      entityName += 'e';
    }
    if (entityName.endsWith('y')) {
      entityName = entityName.slice(0, entityName.length - 1) + 'ie';
    }
    entityName += 's';
    return `/view/${entityName}/new`;
  }
}
