import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JobService } from './services/job.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [JobService]
})
export class QueueModule {}
