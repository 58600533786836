import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Filter, FilterOperator } from '@rims/lib';
import { mapTo } from 'rxjs/operators';
import { DetailViewResolver } from '../../detail-view/resolvers/detail-view.resolver';
import { MetadataService } from '../../shared/services/metadata/metadata.service';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class ContainerDetailResolver extends DetailViewResolver {
  constructor(private readonly meta: MetadataService, store: Store<AppState>, router: Router) {
    super(store, router, {
      baseEntityName: 'container',
      expand: ['substanceUnit', 'type', 'shape', 'color', 'cap'],
      viewPayloads: (id, metadata) => {
        const views = Object.keys(metadata.views).map(key => metadata.views[key]);
        const containerProductionSitesView = views.find(view => view.route.endsWith('/containerproductionsites'));
        const containerProductGroups = views.find(view => view.route.endsWith('/containerproductgroups'));
        return {
          container_production_site: {
            viewId: containerProductionSitesView.id,
            filters: [
              new Filter({
                fieldName: 'container',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          },
          product_group_container: {
            viewId: containerProductGroups.id,
            filters: [
              new Filter({
                fieldName: 'container',
                operator: FilterOperator.EQUALS,
                value: id,
                readOnly: true
              })
            ]
          }
        };
      },
      interceptor: (baseEntityName, expand, id, result, state) => {
        return this.meta.hasProductOwnerPermissions(baseEntityName, id).pipe(mapTo(result));
      }
    });
  }
}
