import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { from, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getMaintenanceModeInformation } from 'src/app/modules/meta/store/meta.actions';
import { AppConfig, APP_CONFIG } from '../../../../app.constants';
import { AppState } from '../../../store/store.state';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { getEntities, getViewGroups } from '../metadata/metadata.actions';
import { noop } from '../shared/shared.actions';
import {
  invalidUserTypeLoginAttempt,
  login,
  loginError,
  loginSuccess,
  logout,
  redirectToTargetUrl,
  reset,
  setInstanaData
} from './user.actions';

@Injectable()
export class UserEffects {
  private readonly storage = window.sessionStorage;

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig,
    private readonly data: DataService
  ) {}

  login = createEffect(() =>
    this.actions.pipe(
      ofType(login),
      switchMap(() => {
        return this.data
          .getAll('users', ['role', 'contact'], undefined, undefined, undefined, undefined, [
            new EqualsFilter('id', this.authService.userId)
          ])
          .pipe(
            switchMap(user => of(loginSuccess(user))),
            catchError(err => {
              // 422 Unprocessable Entity
              if (err.status === 422) {
                return of(invalidUserTypeLoginAttempt());
              }
              this.snackBar.open(`❌  Could not find user`, null, {
                duration: 4000,
                horizontalPosition: 'right'
              });
              return of(loginError());
            })
          );
      })
    )
  );

  loginError = createEffect(() =>
    this.actions.pipe(
      ofType(loginError),
      tap(() => {
        this.snackBar.open(
          'We could not connect to the server to log you in. Please try again in a few moments. If this problem persists, please talk to your administrator.'
        );
      }),
      mapTo(noop())
    )
  );

  invalidUserTypeLoginAttempt = createEffect(() =>
    this.actions.pipe(
      ofType(invalidUserTypeLoginAttempt),
      map(() => {
        this.router.navigate(['invalid-user-type-login-attempt']);
        return noop();
      })
    )
  );

  loginSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(loginSuccess),
      switchMap(() => {
        return [
          setInstanaData(),
          redirectToTargetUrl(),
          getEntities(),
          getViewGroups(),
          getMaintenanceModeInformation()
        ];
      })
    )
  );

  setInstanaData = createEffect(() =>
    this.actions.pipe(
      ofType(setInstanaData),
      withLatestFrom(this.store.pipe(select(state => state))),
      map(([_, state]) => {
        const user = state.user.user;
        const meta = state.meta;
        ineum('user', user.id, `${user.firstName} ${user.lastName}`, user?.contact?.eMail);
        ineum('meta', 'version', meta.appVersion);
        ineum('whitelistedOrigins', [/api\.rims\..*\.bbraun\.cloud/]);
        return noop();
      })
    )
  );

  redirectToTargetUrl = createEffect(() =>
    this.actions.pipe(
      ofType(redirectToTargetUrl),
      switchMap(() => {
        const path = this.storage.getItem(this.config.storageKeys.REDIRECT_PATH);
        if (!path || location.href.includes(path) || path.startsWith('/auth')) {
          this.storage.removeItem(this.config.storageKeys.REDIRECT_PATH);
          return of(noop());
        }
        return from(this.router.navigateByUrl(path)).pipe(
          tap(() => this.storage.removeItem(this.config.storageKeys.REDIRECT_PATH)),
          map(() => noop())
        );
      })
    )
  );

  logout = createEffect(() =>
    this.actions.pipe(
      ofType(logout),
      switchMap(() => {
        this.authService.logout();
        this.snackBar.open(`👋  Successfully logged out`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(reset());
      })
    )
  );
}
