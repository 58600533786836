import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '../../models/user/user.model';

export interface UserState {
  user: User & {
    isAdmin?: boolean;
  };
  loading: boolean;
  invalidLoginAttempt?: boolean;
  productOwnerPermissions: {
    [entity: string]: (number | string)[];
  };
}

export const USER_INITIAL_STATE: UserState = {
  user: null,
  loading: false,
  invalidLoginAttempt: false,
  productOwnerPermissions: null
};

export const selectUser = createFeatureSelector<UserState>('user');

export const loggedInUser = createSelector(selectUser, (state: UserState) => state.user);
export const isInvalidLoginAttempt = createSelector(selectUser, (state: UserState) => state.invalidLoginAttempt);
