<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title>Assigned Items</h1>
      <p>
        This BUDI contains
        <ng-container *ngIf="data?.materialValues?.length; else noMaterials">
          {{ data?.materialValues?.length }} item{{ data?.materialValues?.length === 1 ? '' : 's' }}.
        </ng-container>
        <ng-template #noMaterials>no items.</ng-template>
      </p>
    </div>
  </div>
  <div class="row pt-2 mb-4">
    <div class="col-12" style="max-height: 10rem; overflow: auto">
      <ul class="list-unstyled">
        <li *ngFor="let mat of data?.materialValues">
          {{ mat?.materialNumber }}
        </li>
      </ul>
    </div>
  </div>

  <mat-dialog-actions class="p-0" align="end">
    <button mat-button [attr.e2e-id]="'close-button'" mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
