import { createReducer, on } from '@ngrx/store';
import { appConfig } from 'src/app/app.constants';
import * as UserActions from './user.actions';
import { USER_INITIAL_STATE } from './user.state';

export const userReducer = createReducer(
  USER_INITIAL_STATE,
  on(UserActions.login, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(UserActions.loginSuccess, (state, payload) => ({
    ...state,
    user: {
      ...payload.results[0],
      isAdmin: payload.results[0].role?.name === appConfig.roles.systemAdministratorRoleName
    },
    loading: false
  })),
  on(UserActions.invalidUserTypeLoginAttempt, (state, payload) => ({
    ...state,
    invalidLoginAttempt: true,
    loading: false
  })),
  on(UserActions.reset, () => USER_INITIAL_STATE),
  on(UserActions.setProductOwnerPermission, (state, payload) => ({
    ...state,
    productOwnerPermissions: {
      ...(state.productOwnerPermissions || {}),
      [payload.entity]: [...((state.productOwnerPermissions || {})[payload.entity] || []), payload.record]
    }
  })),
  on(UserActions.removeProductOwnerPermission, (state, payload) => ({
    ...state,
    productOwnerPermissions: {
      ...(state.productOwnerPermissions || {}),
      [payload.entity]: [
        ...((state.productOwnerPermissions || {})[payload.entity] || []).filter(item => item !== payload.record)
      ]
    }
  }))
);
