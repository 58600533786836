<mat-icon [matMenuTriggerFor]="menu">help</mat-icon>

<mat-menu #menu yPosition="above" [class]="'label-help-icon-menu'" backdropClass="label-help-icon-menu-backdrop">
  <strong *ngIf="description" class="help-header">Description</strong>
  <div class="help-area" [innerHTML]="description"></div>

  <strong *ngIf="legalReference" class="d-block mt-3">Legal Reference</strong>
  <div class="legal-area" [innerHTML]="legalReference"></div>

  <a *ngIf="legalReferenceLink" class="unstyled d-inline-flex align-items-end mt-3" [href]="legalReferenceLink" target="_blank" rel="noopener noreferrer">
    Learn more
    <mat-icon class="ml-1">open_in_new</mat-icon>
  </a>
</mat-menu>
