import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { RimsBudiLifecycle } from '@rims/sap-interface';
import { forkJoin, of } from 'rxjs';
import { map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';
import { getData, GetDataPayload, getOne, updateData } from '../../shared/store/data/data.actions';
import { noop } from '../../shared/store/shared/shared.actions';
import { getEntity, getEntityUrlAndView, getUrl, getView } from '../../shared/utils/entity-utils';
import { AppState } from '../../store/store.state';
import { AddContainersToProductGroupDialogComponent } from '../components/add-containers-to-product-group-dialog/add-containers-to-product-group-dialog.component';
import { ChangeBudiLifecycleDialogComponent } from '../components/change-budi-lifecycle-dialog/change-budi-lifecycle-dialog.component';
import {
  closeAddContainersToProductGroupDialog,
  closeChangeBudiLifecycleDialog,
  getProductGroupActors,
  openAddContainerToProductGroupDialog,
  OpenAddContainerToProductGroupDialogPayload,
  openChangeBudiLifecycleDialog,
  OpenChangeBudiLifecycleDialogPayload,
  reloadContainersAndRelatedEntities,
  reloadProductGroupItemsAndRelatedEntities,
  setSystemProcedurePackType
} from './product-groups.actions';

@Injectable()
export class ProductGroupEffects {
  constructor(
    private readonly actions: Actions,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>
  ) {}

  getProductGroupItems = createEffect(() =>
    this.actions.pipe(
      ofType(reloadProductGroupItemsAndRelatedEntities),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const view = getView(metadata, 'product_group_item');
        return getData(
          GetDataPayload.fromView({
            ...view
          })
        );
      })
    )
  );

  getProductGroupItemProductionSites = createEffect(() =>
    this.actions.pipe(
      ofType(reloadProductGroupItemsAndRelatedEntities),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, 'product_group_item_production_site');
        return getData(
          GetDataPayload.fromView(
            {
              ...view
            },
            { url }
          )
        );
      })
    )
  );

  getProductGroupDocuments = createEffect(() =>
    this.actions.pipe(
      ofType(reloadProductGroupItemsAndRelatedEntities),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, 'product_group_document');
        return getData(
          GetDataPayload.fromView(
            {
              ...view
            },
            { url }
          )
        );
      })
    )
  );

  getProductGroupNomenclatures = createEffect(() =>
    this.actions.pipe(
      ofType(reloadProductGroupItemsAndRelatedEntities),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, 'product_group_nomenclature');
        return getData(
          GetDataPayload.fromView(
            {
              ...view
            },
            { url }
          )
        );
      })
    )
  );

  openAddContainerToProductGroupDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openAddContainerToProductGroupDialog),
      switchMap(payload => {
        this.dialog.open<AddContainersToProductGroupDialogComponent, OpenAddContainerToProductGroupDialogPayload>(
          AddContainersToProductGroupDialogComponent,
          {
            minWidth: '60%',
            maxHeight: '90vh',
            data: payload
          }
        );
        return of(noop());
      })
    )
  );

  closeAddContainersToProductGroupDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeAddContainersToProductGroupDialog),
      switchMap(payload => {
        const url = getUrl('product_group_container');

        return forkJoin([
          ...payload.containerIds.map(id =>
            this.dataService.create(
              url,
              {
                productGroup: payload.productGroupId,
                container: id
              },
              {
                runAsProductOwnerFor: {
                  productGroup: payload.productGroupId
                }
              }
            )
          )
        ]).pipe(map(() => reloadContainersAndRelatedEntities()));
      })
    )
  );

  getProductGroupContainers = createEffect(() =>
    this.actions.pipe(
      ofType(reloadContainersAndRelatedEntities),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const view = getView(metadata, 'product_group_container');
        return getData(
          GetDataPayload.fromView({
            ...view
          })
        );
      })
    )
  );

  getProductGroupContainerProductionSites = createEffect(() =>
    this.actions.pipe(
      ofType(reloadContainersAndRelatedEntities),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, 'product_group_container_production_site');
        return getData(
          GetDataPayload.fromView(
            {
              ...view
            },
            { url }
          )
        );
      })
    )
  );

  openChangeBudiLifecycleDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openChangeBudiLifecycleDialog),
      switchMap(payload => {
        this.dialog.open<ChangeBudiLifecycleDialogComponent, OpenChangeBudiLifecycleDialogPayload>(
          ChangeBudiLifecycleDialogComponent,
          {
            panelClass: 'custom-dialog-width',
            data: payload
          }
        );
        return of(noop());
      })
    )
  );

  closeChangeBudiLifecycleDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeChangeBudiLifecycleDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const entity = getEntity(metadata, 'product_group_budi_info');
        return this.dataService
          .getAll('budilifecycles', undefined, undefined, 1, undefined, undefined, [
            new EqualsFilter('name', RimsBudiLifecycle.READY_FOR_EUDAMED)
          ])
          .pipe(
            switchMap(response => {
              return this.dataService.update(entity.id, payload.productGroupBudiInfoId, {
                lifecycle: response.results[0].id
              });
            }),
            mapTo(noop()),
            tap(() => window.location.reload())
          );
      })
    )
  );

  setSystemProcedurePackType = createEffect(() =>
    this.actions.pipe(
      ofType(setSystemProcedurePackType),
      map(({ viewId, budiInfoId, groupEntityId, groupId, expand, sppt }) => {
        return updateData({
          viewId,
          entityName: 'product_group_budi_info',
          id: `${budiInfoId}`,
          value: {
            systemProcedurePackType: sppt
          },
          expand,
          afterSuccessActions: [
            getOne({
              entityId: groupEntityId,
              recordId: groupId,
              expand
            })
          ],
          skipDefaultAfterSuccessAction: true
        });
      })
    )
  );

  getProductGroupActors = createEffect(() =>
    this.actions.pipe(
      ofType(getProductGroupActors),
      withLatestFrom(this.store.select(state => state.metadata)),
      map(([, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, 'product_group_actors');
        return getData(
          GetDataPayload.fromView(
            {
              ...view
            },
            { url }
          )
        );
      })
    )
  );
}
