import { Pipe, PipeTransform } from '@angular/core';
import { escape } from '@rims/lib';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, searchTerm: string): any {
    if (!searchTerm || !value) {
      return value;
    }

    const safeSearchTerm = escape(searchTerm);
    const regex = new RegExp(safeSearchTerm, 'gi');

    return value.toString().replace(regex, `<span class='highlight'>$&</span>`);
  }
}
