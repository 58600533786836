import { Component, Input, OnInit } from '@angular/core';
import { RecordHistory } from '@rims/lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from '../../services/data/data.service';
import { GetHistoryPayload } from '../../store/data/data.actions';

@Component({
  selector: 'rims-detail-view-record-history',
  templateUrl: './record-history.component.html',
  styleUrls: ['./record-history.component.scss']
})
export class RecordHistoryComponent implements OnInit {
  @Input()
  config: GetHistoryPayload;

  @Input()
  showLastDivider = false;

  loading = new BehaviorSubject<boolean>(true);
  history: Observable<RecordHistory>;

  constructor(private readonly data: DataService) {}

  ngOnInit() {
    this.history = this.data
      .getHistory(this.config.entityIdOrName, this.config.recordId, this.config.hideCreatedRecordEntry)
      .pipe(tap(_ => this.loading.next(false)));
  }
}
