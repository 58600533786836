import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressFormat'
})
export class AddressFormatPipe implements PipeTransform {
  transform(address: any): string {
    if (!address) {
      return '';
    }
    return `${address.streetName ? address.streetName + ' ' : ''}${address.streetNumber ? address.streetNumber : ''}${
      address.streetName || address.streetNumber ? ', ' : ''
    }${address.zipCode ? address.zipCode + ' ' : ''}${address.city ? address.city : ''}${
      address.country?.shortName ? ', ' + address.country?.shortName : ''
    }`;
  }
}
