import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Role } from '../../../shared/models/role/role.model';
import { AppEntity } from '../../../shared/models/utils/entity.model';
import { updateData } from '../../../shared/store/data/data.actions';
import { AppState } from '../../../store/store.state';

export interface UpdatePermissionLevelDialogInput {
  role: Role;
  entity: AppEntity;
}

@Component({
  selector: 'rims-update-permission-level-dialog',
  templateUrl: './update-permission-level-dialog.component.html',
  styleUrls: ['./update-permission-level-dialog.component.scss']
})
export class UpdatePermissionLevelDialogComponent implements OnInit {
  form = new UntypedFormGroup({
    permissionLevel: new UntypedFormControl(null, Validators.required),
    confirm: new UntypedFormControl(false, Validators.requiredTrue)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: UpdatePermissionLevelDialogInput,
    private readonly dialogRef: MatDialogRef<UpdatePermissionLevelDialogComponent>,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit() {
    this.form.get('permissionLevel').setValue(this.data.role.permissionLevel);
  }

  onSubmit() {
    this.store.dispatch(
      updateData({
        entityId: this.data.entity.id,
        id: this.data.role.id + '',
        value: {
          permissionLevel: this.form.value.permissionLevel
        }
      })
    );
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
