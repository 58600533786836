import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CancelService } from '../services/cancel/cancel.service';

/**
 * Cancels a previous request when a new request (to the same url) is fired before the older one finished
 *
 * Requests to '/meta' are excluded because:
 * - they are very 'static' in nature because they are only requested once per table (e.g. for fetching column and field names)
 * - simultaneous calls to '/meta' might all be relevant (-> should not be cancelled) because they fundamentally differ based on the provided query params (e.g. ?view=47 vs ?view=38)
 *   (we can't include query params in our url comparison because there are many other requests which should be cancelled regardless of whether they differ in their query params or not)
 */
@Injectable()
export class CancelInterceptor implements HttpInterceptor {
  constructor(private cancelService: CancelService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.urlWithParams;
    if (
      !url.includes('/meta') &&
      !url.includes('/link-items-to-product-group-status') &&
      !url.includes('/sync-status') &&
      !url.includes('query=') &&
      request.method === 'GET'
    ) {
      // cancel previous still pending request to the same url (if there is any)
      this.cancelService.cancel(url);
    }
    this.cancelService.add(url);
    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          this.cancelService.remove(url);
        }
      }),
      takeUntil(this.cancelService.get(url))
    );
  }
}
