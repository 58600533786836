import { Pipe, PipeTransform } from '@angular/core';
import { TableColumn } from '../models/table/table-column.model';

@Pipe({
  name: 'columnById'
})
export class ColumnByIdPipe implements PipeTransform {
  transform(columnId: string, columns: TableColumn[]): TableColumn {
    return columns.find(col => col.id === Number(columnId));
  }
}
