import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private readonly _paths = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs = this._paths.asObservable();

  set(breadcrumbs: Breadcrumb[], absolute = false) {
    const home: Breadcrumb = {
      routerLink: '/',
      label: 'Home'
    };
    this._paths.next(absolute ? breadcrumbs : [home, ...breadcrumbs]);
  }

  push(crumb: Breadcrumb) {
    this._paths.next([...this._paths.value, crumb]);
  }

  pushCreationPage() {
    this.push({
      routerLink: 'new',
      label: 'New'
    });
  }

  breakdownUntil(path: string) {
    const index = this._paths.value.findIndex(crumb => crumb.routerLink === path);
    const remaining = this._paths.value.slice(0, index + 1);
    this._paths.next(remaining);
  }

  clear() {
    this._paths.next([]);
  }
}
