import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { ChangeTrackedEntity } from 'src/app/modules/shared/models/utils/change-tracked-entity.model';
import { createRecord } from 'src/app/modules/shared/store/data/data.actions';
import { AppState } from 'src/app/modules/store/store.state';

@Component({
  selector: 'rims-create-container',
  templateUrl: './create-container.component.html',
  styleUrls: ['./create-container.component.scss']
})
export class CreateContainerComponent implements OnInit, OnDestroy {
  containerTypes: Observable<ChangeTrackedEntity[]>;
  units: Observable<ChangeTrackedEntity[]>;
  shapes: Observable<ChangeTrackedEntity[]>;
  colors: Observable<ChangeTrackedEntity[]>;
  caps: Observable<ChangeTrackedEntity[]>;

  form = new UntypedFormGroup({
    size: new UntypedFormControl(null, Validators.required),
    isAdvanced: new UntypedFormControl(false, Validators.required),
    substanceUnit: new UntypedFormControl(null, Validators.required),
    type: new UntypedFormControl(null, Validators.required),
    shape: new UntypedFormControl(null),
    color: new UntypedFormControl(null),
    cap: new UntypedFormControl(null)
  });

  selectedUnitSuffix: Observable<string>;

  private isAdvancedFormFieldChanges = this.form
    .get('isAdvanced')
    .valueChanges.pipe(startWith(this.form.get('isAdvanced').value)) as Observable<boolean>;
  private isAdvancedSub: Subscription;

  constructor(private readonly store: Store<AppState>, private readonly breadcrumb: BreadcrumbService) {}

  ngOnInit() {
    this.containerTypes = combineLatest([
      this.store.select(state => state.data.entries['container_type'].page.results),
      this.isAdvancedFormFieldChanges
    ]).pipe(map(([results, fromControlValue]) => results.filter((val: any) => val.isAdvanced === fromControlValue)));
    this.units = this.store.select(state => state.data.entries['unit'].page.results);
    this.shapes = this.store.select(state => state.data.entries['container_shape'].page.results);
    this.colors = this.store.select(state => state.data.entries['container_color'].page.results);
    this.caps = this.store.select(state => state.data.entries['container_cap'].page.results);
    this.selectedUnitSuffix = combineLatest([
      this.units,
      this.form.get('substanceUnit').valueChanges.pipe(startWith(this.form.get('substanceUnit').value))
    ]).pipe(map(([units, selectedUnitId]) => (units.find(u => u.id === selectedUnitId) as any)?.shortName));
    this.handleIsAdvancedChanges();

    this.breadcrumb.pushCreationPage();
  }

  ngOnDestroy() {
    if (this.isAdvancedSub) {
      this.isAdvancedSub.unsubscribe();
    }
  }

  onSubmit() {
    this.store.dispatch(
      createRecord({
        payload: this.form.value,
        url: 'containers',
        navigateTo: result => `/view/containers/${result[0].id}`
      })
    );
  }

  private handleIsAdvancedChanges() {
    this.isAdvancedSub = this.isAdvancedFormFieldChanges
      .pipe(
        tap(isAdvanced => {
          this.form.get('type').setValue(null);
          if (!isAdvanced) {
            this.form.get('shape').setValue(null);
            this.form.get('color').setValue(null);
            this.form.get('cap').setValue(null);
          }
        })
      )
      .subscribe();
  }
}
