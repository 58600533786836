import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfig, APP_CONFIG } from './app.constants';
import { RimsErrorHandler } from './error-handler';
import { markedOptionsFactory } from './markdown-options';
import { HomeModule } from './modules/+home/home.module';
import { DetailViewModule } from './modules/detail-view/detail-view.module';
import { GraphqlModule } from './modules/graphql/graphql.module';
import { ItemsModule } from './modules/items/items.module';
import { MaterialModule } from './modules/material/material.module';
import { ProductGroupsModule } from './modules/product-groups/product-groups.module';
import { RolesModule } from './modules/roles/roles.module';
import { SapModule } from './modules/sap/sap.module';
import { CancelInterceptor } from './modules/shared/interceptors/cancel.interceptor';
import { LoadingInterceptor } from './modules/shared/interceptors/loading.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { RimsStoreModule } from './modules/store/store.module';
import { ViewModule } from './modules/view/view.module';

export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

const authConfig: AuthConfig = {
  issuer: environment.oauth2.issuer,
  redirectUri: environment.oauth2.redirectUri,
  clientId: environment.oauth2.clientId,
  scope: environment.oauth2.scope,
  strictDiscoveryDocumentValidation: false
};

const components = [AppComponent];

const providers = [
  {
    provide: APP_CONFIG,
    useValue: appConfig
  },
  {
    provide: ErrorHandler,
    useClass: RimsErrorHandler
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
  },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: CancelInterceptor
  },
  {
    provide: OAuthStorage,
    useFactory: storageFactory
  },
  {
    provide: AuthConfig,
    useValue: authConfig
  }
];

const modules = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  AppRoutingModule,
  MaterialModule,
  OAuthModule.forRoot({
    resourceServer: {
      sendAccessToken: true
    }
  }),
  RimsStoreModule,
  SharedModule,
  HomeModule,
  ViewModule,
  ProductGroupsModule,
  DetailViewModule,
  ItemsModule,
  RolesModule,
  SapModule,
  MarkdownModule.forRoot({
    markedOptions: {
      provide: MarkedOptions,
      useFactory: markedOptionsFactory
    }
  }),
  GraphqlModule
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  providers: [...providers],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    this.trimInputOnChange();
  }

  /**
   * trims every text input before any form specific logic applies
   */
  private trimInputOnChange() {
    const original = DefaultValueAccessor.prototype.registerOnChange;
    DefaultValueAccessor.prototype.registerOnChange = function (fn) {
      return original.call(this, value => {
        const trimmed = typeof value === 'string' ? value.trim() : value;
        return fn(trimmed);
      });
    };
  }
}
