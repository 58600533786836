import { createReducer, on } from '@ngrx/store';
import * as DataActions from './data.actions';
import { DataState, DATA_INITIAL_STATE } from './data.state';

export const dataReducer = createReducer(
  DATA_INITIAL_STATE,
  on(DataActions.getOne, (state, payload) => {
    const key = payload.entityId + '_' + payload.recordId;
    const newState: DataState = {
      ...state,
      entries: {
        ...state.entries,
        [key]: {
          ...state.entries[key],
          loading: true
        }
      },
      loading: true
    };
    return newState;
  }),
  on(DataActions.getOneSuccess, (state, payload) => {
    const key = payload.entityId + '_' + payload.recordId;
    const newState: DataState = {
      ...state,
      entries: {
        ...state.entries,
        [key]: {
          loading: false,
          page: payload.page
        }
      },
      loading: false
    };
    return newState;
  }),
  on(DataActions.getData, (state, payload) => {
    const newState: DataState = {
      ...state,
      entries: {
        ...state.entries,
        [payload.key]: {
          ...state.entries[payload.key],
          loading: true
        }
      },
      loading: true
    };
    return newState;
  }),
  on(DataActions.getDataSuccess, (state, payload) => {
    const newState: DataState = {
      ...state,
      entries: {
        ...state.entries,
        [payload.key]: {
          loading: false,
          page: payload.page
        }
      },
      loading: false
    };
    return newState;
  }),
  on(DataActions.clearDataSuccess, (state, payload) => {
    const newState: DataState = {
      ...state,
      entries: {
        ...state.entries,
        [payload.key]: {
          loading: false
        }
      }
    };
    return newState;
  })
);
