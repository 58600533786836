import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EqualsFilter, Filter, FilterOperator } from '@rims/lib';
import { mapTo } from 'rxjs/operators';
import { DetailViewResolver } from '../../detail-view/resolvers/detail-view.resolver';
import { MetadataService } from '../../shared/services/metadata/metadata.service';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupDetailResolver extends DetailViewResolver {
  constructor(private readonly meta: MetadataService, store: Store<AppState>, router: Router) {
    super(store, router, {
      baseEntityName: 'product_group',
      expand: [
        'coe.division',
        'productType',
        'intendedPurpose',
        'groupType2',
        'budiInfo2.systemProcedurePackType',
        'budiInfo2.multiComponentType',
        'budiInfo2.specialDeviceType',
        'budiInfo2.riskClass',
        'budiInfo2.riskClass.legislation',
        'budiInfo2.lifecycle',
        'rmfInfo2',
        'solutionInfo2'
      ],
      viewPayloads: (id, metadata) => {
        const views = Object.keys(metadata.views).map(key => metadata.views[key]);
        const productGroupItemsView = views.find(view => view.route.endsWith('/productgroupitems'));
        const productGroupDocumentsView = views.find(view => view.route.endsWith('/productgroupdocuments'));
        const productGroupContainerView = views.find(view => view.route.endsWith('/productgroupcontainers'));
        const productGroupItemProductionSiteView = views.find(view =>
          view.route.endsWith('/productgroupitemproductionsites')
        );
        const productGroupContainerProductionSiteView = views.find(view =>
          view.route.endsWith('/productgroupcontainerproductionsites')
        );
        const productGroupActorsView = views.find(view => view.route.endsWith('/productgroupactors'));
        const productGroupResponsibilitiesView = views.find(view =>
          view.route.endsWith('/productgroupresponsibilities')
        );
        const productGroupNomenclaturesView = views.find(view => view.route.endsWith('/productgroupnomenclatures'));
        const productGroupChildrenView = views.find(view => view.route.endsWith('/productgroupchildren'));

        const productGroupFilter = new Filter({
          fieldName: 'product_group',
          operator: FilterOperator.EQUALS,
          value: id,
          readOnly: true,
          hidden: true
        });

        return {
          product_group_actor: {
            viewId: productGroupActorsView.id,
            filters: [productGroupFilter]
          },
          item_production_site: {
            viewId: productGroupItemProductionSiteView.id,
            url: 'product_group_item_production_site',
            filters: [new Filter({ ...productGroupFilter, fieldName: 'productGroup' })]
          },
          container_production_site: {
            viewId: productGroupContainerProductionSiteView.id,
            url: 'product_group_container_production_site',
            filters: [new Filter({ ...productGroupFilter, fieldName: 'productGroup' })]
          },
          item_document: {
            viewId: productGroupDocumentsView.id,
            url: 'product_group_document',
            filters: [productGroupFilter]
          },
          product_group_container: {
            viewId: productGroupContainerView.id,
            filters: [productGroupFilter]
          },
          item_nomenclature: {
            viewId: productGroupNomenclaturesView.id,
            url: 'product_group_nomenclature',
            filters: [productGroupFilter]
          },
          product_group_item: {
            viewId: productGroupItemsView.id,
            filters: [productGroupFilter]
          },
          product_group_responsibility: {
            viewId: productGroupResponsibilitiesView.id,
            filters: [productGroupFilter]
          },
          product_group_children: {
            viewId: productGroupChildrenView.id,
            filters: [new EqualsFilter('parent', id)]
          }
        };
      },
      interceptor: (baseEntityName, expand, id, result, state) => {
        return this.meta.hasProductOwnerPermissions(baseEntityName, id).pipe(mapTo(result));
      }
    });
  }
}
