import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { ContainerOptionFormatPipe } from '../containers/pipes/container-option-format.pipe';
import { MaterialModule } from '../material/material.module';
import { AddDialogComponent } from './components/add-dialog/add-dialog.component';
import { FieldHelpTextsComponent } from './components/field-help-texts/field-help-texts.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderAnnouncementComponent } from './components/header/header-announcement/header-announcement.component';
import { HeaderTitleComponent } from './components/header/header-title/header-title.component';
import { HeaderComponent } from './components/header/header.component';
import { LabelHelpIconComponent } from './components/label-help-icon/label-help-icon.component';
import { PendingSnackbarComponent } from './components/pending-snackbar/pending-snackbar.component';
import { RecordHistoryComponent } from './components/record-history/record-history.component';
import { RemoveDialogComponent } from './components/remove-dialog/remove-dialog.component';
import { RimsAutocompleteComponent } from './components/rims-autocomplete/rims-autocomplete.component';
import { RimsFieldComponent } from './components/rims-field/rims-field.component';
import { PropertyEditMenuComponent } from './components/rims-property-edit-menu/property-edit-menu.component';
import { TableActionComponent } from './components/rims-table-action/table-action.component';
import { RimsTableColumnSortDialogComponent } from './components/rims-table-column-sort-dialog/rims-table-column-sort-dialog.component';
import { RimsTableExportDialogComponent } from './components/rims-table-export-dialog/rims-table-export-dialog.component';
import { RimsTableFilterCreationDialogComponent } from './components/rims-table-filter-creation-dialog/rims-table-filter-creation-dialog.component';
import { RimsTableFiltersComponent } from './components/rims-table-filters/rims-table-filters.component';
import { RimsTableScrollIndicatorComponent } from './components/rims-table-scroll-indicator/rims-table-scroll-indicator.component';
import { RimsTableSearchQueryComponent } from './components/rims-table-search-query/rims-table-search-query.component';
import { RimsTableComponent, TemplateVariableDirective } from './components/rims-table/rims-table.component';
import { ErrorSnackbarComponent } from './components/snackbar/error-snackbar/error-snackbar.component';
import { ViewMenuComponent } from './components/view-menu/view-menu.component';
import { RequirePermissionDirective } from './directives/require-permission.directive';
import { NumericalIdGuard } from './guards/numerical-id.guard';
import { ActorOptionFormatPipe } from './pipes/actor-option-format.pipe';
import { AddressFormatPipe } from './pipes/address-format.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { CoeOptionFormatPipe } from './pipes/coe-option-format.pipe';
import { ColumnByIdPipe } from './pipes/column-by-id.pipe';
import { CompanyOptionFormatPipe } from './pipes/company-option-format.pipe';
import { DocumentOptionFormatPipe } from './pipes/document-option-format.pipe';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { FilterOperatorLabelPipe } from './pipes/filter-operator-label.pipe';
import { FilterViewGroupsPipe } from './pipes/filter-view-groups.pipe';
import { FilterViewsPipe } from './pipes/filter-views.pipe';
import { ForceProductOwnerPermissionPipe } from './pipes/force-product-owner-permission.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { IdentificationNumberPipe } from './pipes/identification-number.pipe';
import { IntendedPurposePipe } from './pipes/intended-purpose.pipe';
import { IsPastDatePipe } from './pipes/is-past-date.pipe';
import { IsUndefinedOrNullPipe } from './pipes/is-undefined-or-null.pipe';
import { ItemOptionFormatPipe } from './pipes/item-option-format.pipe';
import { LegislationOptionFormatPipe } from './pipes/legislation-option-format.pipe';
import { MapPipe } from './pipes/map.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { NamePipe } from './pipes/name.pipe';
import { NomenclatureOptionFormatPipe } from './pipes/nomenclature-option-format.pipe';
import { OnlyNumericalStringsPipe } from './pipes/only-numerical-strings.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { ProductGroupOptionFormatPipe } from './pipes/product-group-option-format.pipe';
import { PropertyAccessPipe } from './pipes/property-access.pipe';
import { PropertyEditMenuSelectValuePipe } from './pipes/property-edit-menu-select-value.pipe';
import { RiskClassAndLegislationPipe } from './pipes/risk-class-and-legislation.pipe';
import { RiskClassOptionFormatPipe } from './pipes/risk-class-option-format.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { UserEmailPipe } from './pipes/user-email.pipe';
import { UserOptionFormatPipe } from './pipes/user-option-format.pipe';
import { ViewExistsPipe } from './pipes/view-exists.pipe';
import { ViewParamPipe } from './pipes/view-param.pipe';
import { ViewToDataKeyPipe } from './pipes/view-to-data-key.pipe';
import { AuthService } from './services/auth/auth.service';
import { RequestBuilder } from './services/request-builder/request-builder.service';
import { InvalidUserTypeLoginAttemptComponent } from './views/invalid-user-type-login-attempt/invalid-user-type-login-attempt.component';
import { MaintenanceComponent } from './views/maintenance/maintenance.component';

const exportComponents = [
  TableActionComponent,
  RimsTableColumnSortDialogComponent,
  TemplateVariableDirective,
  RimsTableComponent,
  RecordHistoryComponent,
  HeaderComponent,
  HeaderTitleComponent,
  HeaderAnnouncementComponent,
  FooterComponent,
  MarkdownPipe,
  SafePipe,
  FloatingButtonComponent,
  PropertyEditMenuComponent,
  PropertyAccessPipe,
  OrderByPipe,
  MapPipe,
  ViewMenuComponent,
  FilterViewsPipe,
  FilterViewGroupsPipe,
  BooleanPipe,
  HighlightPipe,
  RequirePermissionDirective,
  CompanyOptionFormatPipe,
  AddressFormatPipe,
  DocumentOptionFormatPipe,
  NomenclatureOptionFormatPipe,
  ActorOptionFormatPipe,
  ProductGroupOptionFormatPipe,
  UserOptionFormatPipe,
  LabelHelpIconComponent,
  FieldHelpTextsComponent,
  CoeOptionFormatPipe,
  RimsFieldComponent,
  RimsAutocompleteComponent,
  PendingSnackbarComponent,
  ErrorSnackbarComponent,
  DynamicPipe,
  NamePipe,
  RiskClassAndLegislationPipe,
  LegislationOptionFormatPipe,
  RiskClassOptionFormatPipe,
  CoeOptionFormatPipe,
  UserEmailPipe,
  ItemOptionFormatPipe,
  ContainerOptionFormatPipe,
  IntendedPurposePipe,
  IdentificationNumberPipe,
  IsUndefinedOrNullPipe,
  ForceProductOwnerPermissionPipe,
  IsPastDatePipe,
  RemoveDialogComponent,
  AddDialogComponent
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BreadcrumbModule,
    NgxSkeletonLoaderModule
  ],
  providers: [
    AuthService,
    RequestBuilder,
    // List some pipes as providers to use them in the DynamicPipe
    CoeOptionFormatPipe,
    NamePipe,
    RiskClassAndLegislationPipe,
    RiskClassOptionFormatPipe,
    CompanyOptionFormatPipe,
    DocumentOptionFormatPipe,
    NomenclatureOptionFormatPipe,
    ActorOptionFormatPipe,
    ProductGroupOptionFormatPipe,
    ItemOptionFormatPipe,
    ContainerOptionFormatPipe,
    UserOptionFormatPipe,
    IntendedPurposePipe,
    IdentificationNumberPipe,
    NumericalIdGuard
  ],
  declarations: [
    ...exportComponents,
    RimsTableFiltersComponent,
    RimsTableFilterCreationDialogComponent,
    InvalidUserTypeLoginAttemptComponent,
    FilterOperatorLabelPipe,
    ColumnByIdPipe,
    OnlyNumericalStringsPipe,
    MaintenanceComponent,
    RimsTableSearchQueryComponent,
    RimsTableExportDialogComponent,
    ViewParamPipe,
    ViewToDataKeyPipe,
    PropertyEditMenuSelectValuePipe,
    RimsTableScrollIndicatorComponent,
    ViewExistsPipe
  ],
  exports: [...exportComponents]
})
export class SharedModule {}
