import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemOptionFormat'
})
export class ItemOptionFormatPipe implements PipeTransform {
  transform(item: any, hideName = false): string {
    let str = item?.itemNumber2?.itemNumber;

    const name = item?.itemNumber2?.name;
    const product = item?.product?.name;
    if (!hideName && name) {
      str += ` - ${name}`;
    }
    if (product) {
      str += ` - ${product}`;
    }

    return str;
  }
}
