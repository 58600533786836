import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from 'src/app/app.constants';
import { MetadataService } from '../../shared/services/metadata/metadata.service';
import { navigateTo } from '../../shared/store/router/router.actions';
import { noop } from '../../shared/store/shared/shared.actions';
import {
  getMaintenanceModeInformation,
  getMaintenanceModeInformationError,
  getMaintenanceModeInformationSuccess,
  loadApplicationMetadata,
  loadApplicationMetadataSuccess
} from './meta.actions';

interface VersionJson {
  appVersion: string;
  buildTime: string;
  commitHash: string;
}

@Injectable()
export class MetaEffects {
  loadApplicationMetadata = createEffect(() =>
    this.actions.pipe(
      ofType(loadApplicationMetadata),
      switchMap(() => {
        return this.http.get('/assets/_version/version.json').pipe(
          catchError(() => {
            return of({
              appVersion: 'No version information available.',
              buildTime: 'No build time information available.',
              commitHash: 'No commit hash information available.'
            });
          }),
          map(({ appVersion, buildTime, commitHash }: VersionJson) => {
            return loadApplicationMetadataSuccess({
              appVersion,
              buildTime,
              commitHash
            });
          })
        );
      })
    )
  );

  getMaintenanceModeInformation = createEffect(() =>
    this.actions.pipe(
      ofType(getMaintenanceModeInformation),
      switchMap(() => {
        return this.metadataService.getMaintenanceModeInformation().pipe(
          switchMap(info => of(getMaintenanceModeInformationSuccess(info))),
          catchError(err => of(getMaintenanceModeInformationError(err)))
        );
      })
    )
  );

  getMaintenanceModeInformationSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(getMaintenanceModeInformationSuccess),
      map(maintenanceMode => {
        if (maintenanceMode.enabled) {
          return navigateTo({
            routerLink: '/' + this.config.maintenanceMode.route
          });
        }
        return noop();
      })
    )
  );

  getMaintenanceModeInformationError = createEffect(() =>
    this.actions.pipe(
      ofType(getMaintenanceModeInformationError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        this.snackBar.open(`⚠️  Could not load maintenance mode information. Please inform your administrator.`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly http: HttpClient,
    private readonly metadataService: MetadataService,
    private readonly snackBar: MatSnackBar,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig
  ) {}
}
