<rims-header>
  <rims-header-title>
    <ng-container *ngIf="result | async as item">
      {{ item?.product?.name || item?.itemNumber }}
    </ng-container>
  </rims-header-title>
  <rims-header-announcement *ngIf="isProductOwner | async">
    <span class="d-flex">
      You are a Product Owner for this item
      <mat-icon
        inline
        class="ml-1"
        matTooltipShowDelay="300"
        matTooltip="Because you have a GRA responsibility for a group that this item is part of, you can maintain data and perform actions that would otherwise not be allowed with your user role."
      >
        help_outline
      </mat-icon>
    </span>
  </rims-header-announcement>
</rims-header>

<rims-property-edit-menu (updateProperty)="onStateChange($event)"></rims-property-edit-menu>

<div class="container pb-3" *ngIf="{ item: result | async, routeData: resolveResult | async, entity: entity | async, isProductOwner: isProductOwner | async } as observable">
  <div class="row pt-5 mb-5" *ngIf="{ isInBudi: isInBudi | async } as obs">
    <div class="col-12 mb-3">
      <h2 class="m-0">Properties</h2>
      <span class="color-grey d-block" *ngIf="obs.isInBudi">
        Because this item is part of a Basic UDI, some properties are inherited from that product group and can not be changed here.
      </span>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        field="product.name"
        [menuConfig]="{
          onMenuOpen: {
            url: 'product',
            useSearch: true,
            inPlaceCreateFieldName: 'name',
            inPlaceCreatePermissionEntity: 'product'
          },
          loadAsyncOptions: true,
          search: true,
          selectFieldName: 'name',
          recordIdProperty: 'itemNumber',
          emptyOption: 'Clear Field',
          inPlaceCreateBtnTxt: 'Create New Product'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [isBoolean]="true"
        field="isSterile"
        [highlightIfNull]="obs.isInBudi"
        [editMenuCondition]="!obs.isInBudi"
        [menuConfig]="{
          recordIdProperty: 'itemNumber'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [highlightIfNull]="obs.isInBudi"
        [editMenuCondition]="!obs.isInBudi"
        [isBoolean]="true"
        field="active"
        [menuConfig]="{
          recordIdProperty: 'itemNumber'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [highlightIfNull]="obs.isInBudi"
        [editMenuCondition]="!obs.isInBudi"
        [isBoolean]="true"
        field="administeringMedicine"
        [menuConfig]="{
          recordIdProperty: 'itemNumber'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [highlightIfNull]="obs.isInBudi"
        [editMenuCondition]="!obs.isInBudi"
        [isBoolean]="true"
        field="implantable"
        [menuConfig]="{
          recordIdProperty: 'itemNumber'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [highlightIfNull]="obs.isInBudi"
        [editMenuCondition]="!obs.isInBudi"
        [isBoolean]="true"
        field="measuringFunction"
        [menuConfig]="{
          recordIdProperty: 'itemNumber'
        }"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-6">
      <rims-field
        [editMenu]="editPropertyMenu"
        [obj]="observable.item"
        [entity]="observable.entity"
        permissionBaseEntity="item"
        [forcePermission]="observable.isProductOwner | forceProductOwnerPermission"
        [highlightIfNull]="obs.isInBudi"
        [editMenuCondition]="!obs.isInBudi"
        [isBoolean]="true"
        field="reusable"
        [menuConfig]="{
          recordIdProperty: 'itemNumber'
        }"
      ></rims-field>
    </div>
  </div>

  <div class="row mb-5" *ngIf="observable.item?.budiInfo2">
    <div class="col-12">
      <h2>UDI Information</h2>
    </div>

    <div class="col-12 col-sm-6" *ngIf="observable.item | displayLifecycle as lc">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{ observable.entity?.fields?.['budiInfo2.lifecycle']?.displayName }}</mat-label>
        <input
          matInput
          disabled
          [attr.rims-field-name]="'budiInfo2.lifecycle'"
          [placeholder]="observable.entity?.fields?.['budiInfo2.lifecycle']?.displayName"
          [value]="getProp('label', lc, 'Not defined')"
        />
        <mat-icon
          *rimsRequirePermission="
            'product_group';
            field: 'budiInfo2.lifecycle';
            ready: !!editPropertyMenu?.menu;
            condition: !lc.readonly;
            force: observable.isProductOwner | forceProductOwnerPermission
          "
          class="edit-action-icon color-grey clickable"
          matSuffix
          mat-icon-button
          (click)="openChangeBudiLifecycleDialog()"
        >
          edit
        </mat-icon>
        <mat-icon *ngIf="lc?.readonly" matSuffix class="edit-action-icon color-grey">lock_outline</mat-icon>
        <mat-hint class="pt-2 d-block" *ngIf="lc?.readonly" [ngSwitch]="lc?.pendingSap">
          <ng-container *ngSwitchDefault>The lifecycle is managed in SAP and can not be changed in RIMS.</ng-container>
          <ng-container *ngSwitchCase="true">The lifecycle is completely set from RA perspective and is waiting for further processing in SAP.</ng-container>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Production Sites</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [shouldDisplayCheckboxes]="true"
        [detailViewEntityName]="'company'"
        [primaryKeyPropertyName]="'company2.id'"
        [emptyResultSetMessage]="'No production sites are associated with this item.'"
        [dataKey]="'item_production_site'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['item_production_site']"
        *ngIf="{ selected: selectedItemProductionSites | async } as obs"
      >
        <rims-table-action
          [attr.e2e-id]="'add-production-site-button'"
          (click)="openAddProductionSiteDialog()"
          *rimsRequirePermission="'item_production_site'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Site
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-production-site-button'"
          (click)="openRemoveProductionSite()"
          *rimsRequirePermission="'item_production_site'; action: 'delete'; condition: obs.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>remove</mat-icon>
          Remove {{ obs.selected?.length | i18nPlural : getMapping('Site') }}
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Documents</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [shouldDisplayCheckboxes]="true"
        [detailViewEntityName]="'document'"
        [primaryKeyPropertyName]="'document'"
        [emptyResultSetMessage]="'No documents are associated with this item.'"
        [dataKey]="'item_document'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['item_document']"
        *ngIf="{ selected: selectedItemDocuments | async } as obs"
      >
        <rims-table-action
          [attr.e2e-id]="'add-document-button'"
          (click)="openAddDocumentDialog()"
          *rimsRequirePermission="'item_document'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Document
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-document-button'"
          (click)="openRemoveDocumentDialog()"
          *rimsRequirePermission="'item_document'; action: 'delete'; condition: obs.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>remove</mat-icon>
          Remove {{ obs.selected?.length | i18nPlural : getMapping('Document') }}
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Nomenclatures</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [shouldDisplayCheckboxes]="true"
        [detailViewEntityName]="'nomenclature'"
        [primaryKeyPropertyName]="'nomenclature'"
        [emptyResultSetMessage]="'No nomenclatures are associated with this item.'"
        [dataKey]="'item_nomenclature'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['item_nomenclature']"
        *ngIf="{ selected: selectedItemNomenclatures | async } as obs"
      >
        <rims-table-action
          [attr.e2e-id]="'add-nomenclature-button'"
          (click)="openAddNomenclatureDialog()"
          *rimsRequirePermission="'item_nomenclature'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Nomenclature
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-nomenclature-button'"
          (click)="openRemoveNomenclatureDialog()"
          *rimsRequirePermission="'item_nomenclature'; action: 'delete'; condition: obs.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>remove</mat-icon>
          Remove {{ obs.selected?.length | i18nPlural : getMapping('Nomenclature') }}
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Risk Classes</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="false"
        [shouldDisplayCheckboxes]="true"
        [detailViewEntityName]="'riskclass'"
        [primaryKeyPropertyName]="'riskClass.id'"
        [emptyResultSetMessage]="'No risk classes are associated with this item.'"
        [dataKey]="'item_risk_class'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['item_risk_class']"
        *ngIf="{ selected: selectedItemRiskClasses | async } as obs"
      >
        <rims-table-action
          [attr.e2e-id]="'add-risk-class-button'"
          (click)="openAddRiskClassDialog()"
          *rimsRequirePermission="'item_risk_class'; action: 'create'; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>add</mat-icon>
          Add Risk Class
        </rims-table-action>
        <rims-table-action
          [attr.e2e-id]="'remove-risk-class-button'"
          (click)="openRemoveRiskClassDialog()"
          *rimsRequirePermission="'item_risk_class'; action: 'delete'; condition: obs.selected?.length > 0; force: observable.isProductOwner | forceProductOwnerPermission"
        >
          <mat-icon>remove</mat-icon>
          Remove {{ obs.selected?.length | i18nPlural : getMapping('Risk Class') }}
        </rims-table-action>
      </rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Product Groups</h2>
    </div>
    <div class="col">
      <rims-table
        [hideColumnDialog]="true"
        [hideFiltersAndFilterMenu]="true"
        [shouldDisplayDetailLink]="true"
        [detailViewEntityName]="'productgroup'"
        [primaryKeyPropertyName]="'productGroup'"
        [dataKey]="'product_group_item'"
        [viewConfigPayload]="observable.routeData?.viewPayloads['product_group_item']"
      ></rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2 class="d-flex">
        SAP Information
        <mat-icon class="align-self-center ml-2" matTooltip="These attributes can not be changed in RIMS" matTooltipPosition="above">lock_outline</mat-icon>
      </h2>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ observable.entity?.fields?.['itemNumber2.itemNumber']?.displayName }}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'itemNumber2.itemNumber'" [value]="getProp('itemNumber2.itemNumber', observable.item)" />
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ observable.entity?.fields?.['itemNumber2.name']?.displayName }}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'itemNumber2.name'" [value]="getProp('itemNumber2.name', observable.item)" />
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ observable.entity?.fields?.['itemNumber2.materialStatus']?.displayName }}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'itemNumber2.materialStatus'" [value]="getProp('itemNumber2.materialStatus', observable.item)" />
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ observable.entity?.fields?.['itemNumber2.distributionStatus']?.displayName }}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'itemNumber2.distributionStatus'" [value]="getProp('itemNumber2.distributionStatus', observable.item)" />
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ observable.entity?.fields?.['itemNumber2.marketScope']?.displayName }}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'itemNumber2.marketScope'" [value]="getProp('itemNumber2.marketScope', observable.item)" />
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{ observable.entity?.fields?.['itemNumber2.shelfLife']?.displayName }}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'itemNumber2.shelfLife'" [value]="getProp('itemNumber2.shelfLife', observable.item)" />
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2 class="mb-3">3rd Party Applications</h2>
      <span class="color-grey d-block mb-3">Applications and websites in context to this item are listed here.</span>
    </div>
    <div class="col">
      <a mat-button target="_blank" rel="noopener noreferrer" [href]="'https://products.bbraun.com/p/' + observable.item.itemNumber" class="mb-2 mr-2">
        Product Center
        <mat-icon inline>open_in_new</mat-icon>
      </a>
      <a
        mat-button
        target="_blank"
        rel="noopener noreferrer"
        [href]="'https://accessgudid.nlm.nih.gov/devices/search?query=catalogNumber:(%22' + observable.item.itemNumber + '%22)'"
        class="mb-2 mr-2"
      >
        AccessGUDID
        <mat-icon inline>open_in_new</mat-icon>
      </a>
      <a
        mat-button
        target="_blank"
        rel="noopener noreferrer"
        [href]="'https://ec.europa.eu/tools/eudamed/#/screen/search-device?submitted=true&reference=' + observable.item.itemNumber"
        class="mb-2 mr-2"
      >
        EUDAMED
        <mat-icon inline>open_in_new</mat-icon>
      </a>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>History</h2>
    </div>
    <div class="col">
      <rims-detail-view-record-history
        [config]="{
          entityIdOrName: observable.entity?.id,
          recordId: observable.item?.itemNumber2.itemNumber
        }"
      ></rims-detail-view-record-history>
    </div>
  </div>

  <div class="row mb-5 pb-5" *ngIf="showSapSyncDebugInfo">
    <div class="col-12 mb-3">
      <h2 class="d-flex">
        Debugging Functionality
        <mat-icon class="align-self-center ml-2" matTooltip="This section is only visible in non-production environments">help_outline</mat-icon>
      </h2>
      <button mat-button (click)="showSapSyncPreview()">Preview SAP Sync Payload</button>
    </div>
    <div class="col-12">
      <pre style="font-size: 0.8rem">{{ sapSyncPreviewResult | json }}</pre>
    </div>
  </div>
</div>
