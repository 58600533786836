import { Pipe, PipeTransform } from '@angular/core';
import { SelectExpression } from './property-edit-menu-select-value.pipe';

export const riskClassOptionExpression: SelectExpression = (riskClass, hideLegislation = false) => {
  let str = `${riskClass.class}`;
  if (!hideLegislation) {
    const legislation = riskClass.legislation;
    if (!legislation) {
      str += ' (No legislation defined)';
    } else {
      str += ` (${legislation.shortName} - ${legislation.name})`;
    }
  }
  return str;
};

@Pipe({
  name: 'riskClassOptionFormat'
})
export class RiskClassOptionFormatPipe implements PipeTransform {
  transform(riskClass: any, hideLegislation = false): string {
    return riskClassOptionExpression(riskClass, hideLegislation);
  }
}
