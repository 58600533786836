import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppState } from '../../store/store.state';
import { startLoading, stopLoading } from '../store/metadata/metadata.actions';

const exclusions = [/jobs\/.+\/progress/];

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<AppState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const shouldLoad = !exclusions.some(regex => regex.test(request.url));

    if (shouldLoad) {
      this.store.dispatch(startLoading());
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (shouldLoad) {
          setTimeout(() => this.store.dispatch(stopLoading()), 400);
        }
      })
    );
  }
}
