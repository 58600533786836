<div class="container-fluid bg-light-grey footer d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col d-flex flex-row justify-content-between">
        <a routerLink="/home" class="d-flex align-items-center footer-link" (click)="breadcrumb.breakdownUntil('/home')">
          <mat-icon routerLinkActive="color-green">home</mat-icon>
          <span class="pl-1 d-none d-sm-block" routerLinkActive="color-green">Home</span>
        </a>

        <a routerLink="/view/productgroups" class="d-flex align-items-center footer-link">
          <mat-icon routerLinkActive="color-green">category</mat-icon>
          <span class="pl-1 d-none d-sm-block" routerLinkActive="color-green">Product Groups</span>
        </a>

        <a routerLink="/view/items" class="d-flex align-items-center footer-link">
          <mat-icon routerLinkActive="color-green">devices_other</mat-icon>
          <span class="pl-1 d-none d-sm-block" routerLinkActive="color-green">Items</span>
        </a>

        <a routerLink="/view/containers" class="d-flex align-items-center footer-link" routerLinkActive="color-green">
          <mat-icon routerLinkActive="color-green">opacity</mat-icon>
          <span class="pl-1 d-none d-sm-block" routerLinkActive="color-green">Containers</span>
        </a>
      </div>
    </div>
  </div>
</div>
