import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '@rims/database';
import { RimsBudiLifecycle, SapBudiLifecycle, sapBudiLifecycleToLifecycle } from '@rims/sap-interface';

@Pipe({
  name: 'displayLifecycle'
})
export class DisplayLifecyclePipe implements PipeTransform {
  transform(item: Item): {
    leadingSystem: 'rims' | 'sap';
    readonly: boolean;
    label: string;
    pendingSap?: boolean;
  } {
    const eudamedLifecycle = item.itemNumber2?.eudamedLifecycle;
    const rimsLifecycle = item.budiInfo2?.lifecycle;

    if (
      eudamedLifecycle &&
      eudamedLifecycle !== SapBudiLifecycle.CREATED &&
      rimsLifecycle.name !== RimsBudiLifecycle.CREATED
    ) {
      return {
        label: sapBudiLifecycleToLifecycle.get(eudamedLifecycle as SapBudiLifecycle),
        leadingSystem: 'sap',
        readonly: true
      };
    }

    return {
      label: rimsLifecycle.name,
      leadingSystem: 'rims',
      readonly: rimsLifecycle.name !== RimsBudiLifecycle.CREATED,
      pendingSap: rimsLifecycle.name !== RimsBudiLifecycle.CREATED
    };
  }
}
