import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ApplicationTier } from '@rims/lib';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { loadApplicationMetadata } from './modules/meta/store/meta.actions';
import { User } from './modules/shared/models/user/user.model';
import { AuthService } from './modules/shared/services/auth/auth.service';
import { login } from './modules/shared/store/user/user.actions';
import { isInvalidLoginAttempt, loggedInUser } from './modules/shared/store/user/user.state';
import { AppState } from './modules/store/store.state';

@Component({
  selector: 'rims-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  tier = environment.tier;
  showTier = this.tier !== ApplicationTier.PRD;

  loggedInUser: Observable<User>;
  isInvalidLoginAttempt: Observable<boolean>;
  isMaintenanceModeEnabled: Observable<boolean>;

  constructor(private readonly store: Store<AppState>, private readonly auth: AuthService) {
    this.auth.login();
    this.store.dispatch(loadApplicationMetadata());
    this.loggedInUser = this.store.pipe(select(loggedInUser));
    this.isInvalidLoginAttempt = this.store.pipe(select(isInvalidLoginAttempt));
    this.isMaintenanceModeEnabled = this.store.pipe(select(state => state?.meta?.maintenance?.enabled));
    this.auth.hasLoggedIn.asObservable().subscribe(loggedIn => {
      if (loggedIn) {
        this.store.dispatch(login());
      }
    });
  }
}
