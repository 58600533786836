import { Pipe, PipeTransform } from '@angular/core';
import { allowBudiLifecycleChange, RimsBudiLifecycle } from '@rims/sap-interface';

@Pipe({
  name: 'allowLifecycleChange'
})
export class AllowLifecycleChangePipe implements PipeTransform {
  transform(lifecycle: RimsBudiLifecycle): boolean {
    return allowBudiLifecycleChange(lifecycle);
  }
}
