import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppConfig, APP_CONFIG } from '../../../app.constants';
import { viewNotFound } from '../store/router/router.actions';

@Injectable()
export class NumericalIdGuard implements CanActivate {
  constructor(
    private store: Store,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const id = route.paramMap.get('id');

    if (isNaN(+id) || !this.isValidId(+id)) {
      console.error('This detail view only allows valid numerical ids');

      this.store.dispatch(
        viewNotFound({
          view: state.url,
          redirectUrl: `view/${route.parent.url[0].path}` // e.g. 'view/productgroups'
        })
      );
      return false;
    }

    return true;
  }

  /**
   * Checks whether the provided is is valid.
   *
   * An id is considered valid when it is above 0 and below the maximum allowed postgres integer number.
   */
  private isValidId(id: number): boolean {
    return id > 0 && id < this.config.maxIntegerValue;
  }
}
