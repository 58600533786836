import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select } from '@ngrx/store';
import { MultiComponentType, ProductGroupType, SystemProcedurePackType } from '@rims/database';
import { Action, ApplicationTier, BudiItemSyncStatus, EqualsFilter, Filter, FilterOperator, InFilter } from '@rims/lib';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, timer } from 'rxjs';
import {
  delay,
  filter,
  map,
  mergeMap,
  repeat,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { JobService } from 'src/app/modules/queue/services/job.service';
import { coeAndDivision } from 'src/app/modules/shared/pipes/coe-option-format.pipe';
import { riskClassOptionExpression } from 'src/app/modules/shared/pipes/risk-class-option-format.pipe';
import { UserEmailPipe } from 'src/app/modules/shared/pipes/user-email.pipe';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { environment } from 'src/environments/environment';
import { appConfig } from '../../../../app.constants';
import { DetailViewComponent } from '../../../detail-view/views/detail-view/detail-view.component';
import { itemFilters } from '../../../items/items-routing.module';
import { SyncPreviewService } from '../../../sap/services/sync-preview.service';
import { PendingSnackbarComponent } from '../../../shared/components/pending-snackbar/pending-snackbar.component';
import {
  PropertyEditEvent,
  PropertyEditMenuComponent
} from '../../../shared/components/rims-property-edit-menu/property-edit-menu.component';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { getOne } from '../../../shared/store/data/data.actions';
import { FilterMenu, FilterMenuGroup, prefixFieldName } from '../../../shared/store/metadata/metadata.actions';
import { closeRemoveDialog, openAddDialog, openRemoveDialog } from '../../../shared/store/shared/shared.actions';
import { ProductGroup } from '../../models/product-group.model';
import {
  getProductGroupActors,
  openAddContainerToProductGroupDialog,
  openChangeBudiLifecycleDialog,
  reloadContainersAndRelatedEntities,
  reloadProductGroupItemsAndRelatedEntities,
  setSystemProcedurePackType
} from '../../store/product-groups.actions';
import { ProductGroupValidator } from '../../validators/product-group.validator';

enum SnackbarState {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

@Component({
  selector: 'rims-product-group-detail',
  templateUrl: './product-group-detail.component.html',
  styleUrls: ['./product-group-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupDetailComponent extends DetailViewComponent<ProductGroup> implements OnInit, OnDestroy {
  private readonly userEmailPipe = new UserEmailPipe();

  @ViewChild(PropertyEditMenuComponent, { static: false })
  editPropertyMenu: PropertyEditMenuComponent;

  selectedProductGroupItems: Observable<number[]>;
  selectedProductGroupDocuments: Observable<number[]>;
  selectedProductGroupNomenclatures: Observable<number[]>;
  selectedProductGroupContainers: Observable<number[]>;
  selectedProductGroupActors: Observable<number[]>;
  selectedProductGroupResponsibilities: Observable<number[]>;
  selectedProductGroupChildGroups: Observable<number[]>;
  selectedProductGroupItemProductionSites: Observable<number[]>;
  selectedProductGroupContainerProductionSites: Observable<number[]>;

  filterMenus: (FilterMenu | FilterMenuGroup)[] = prefixFieldName(itemFilters, 'item2.');

  // type names of the actors of this product group
  productGroupActorTypeNames: Observable<string[]>;

  readonly isProductOwner = this.store.pipe(
    select(state => state.user.productOwnerPermissions?.product_group),
    switchMap(productGroupIds => {
      if (!Array.isArray(productGroupIds)) {
        return of(false);
      }

      return this.result.pipe(map(res => productGroupIds.includes(res.id) || productGroupIds.includes(`${res.id}`)));
    })
  );
  readonly groupHasItems = this.storeData.pipe(map(data => data['product_group_item']?.page?.totalResultsSize > 0));
  readonly groupHasContainers = this.storeData.pipe(
    map(data => data['product_group_container']?.page?.totalResultsSize > 0)
  );
  readonly productCenterQuery = this.result.pipe(map(group => encodeURIComponent(group?.name).split('%20').join('+')));
  readonly isSolution = this.result.pipe(map(group => group?.groupType2?.isSolution));
  readonly isBudi = this.result.pipe(map(group => group?.groupType2?.shortName === 'BUDI'));
  readonly isRmf = this.result.pipe(map(group => group?.groupType2?.shortName === 'RMF'));
  readonly isEbudi = this.result.pipe(map(group => group?.groupType2?.shortName === 'EBUDI'));
  readonly showSapSyncDebugInfo = this.isBudi.pipe(
    map(isBudi => isBudi && environment.tier !== ApplicationTier.PRD),
    switchMap(precondition => {
      if (!precondition) {
        return of(false);
      }
      return this.permissions.hasActionPermission(Action.DEBUG_SAP_SYNC_PAYLOAD);
    })
  );

  readonly systemOrProcedurePackEditable = this.result.pipe(
    map(
      group =>
        (!group?.budiInfo2?.multiComponentType || group?.budiInfo2?.multiComponentType?.notApplicable) &&
        (!group?.budiInfo2?.specialDeviceType || group?.budiInfo2?.specialDeviceType?.notApplicable)
    )
  );
  readonly multiComponentTypeEditable = this.result.pipe(
    map(
      group =>
        (!group?.budiInfo2?.systemProcedurePackType || group?.budiInfo2?.systemProcedurePackType?.notApplicable) &&
        (!group?.budiInfo2?.specialDeviceType || group?.budiInfo2?.specialDeviceType?.notApplicable)
    )
  );
  readonly specialDeviceTypeEditable = this.result.pipe(
    map(
      group =>
        (!group?.budiInfo2?.multiComponentType || group?.budiInfo2?.multiComponentType?.notApplicable) &&
        (!group?.budiInfo2?.systemProcedurePackType || group?.budiInfo2?.systemProcedurePackType?.notApplicable)
    )
  );

  private groupId: number = null;
  private groupNumber: string = null;
  private groupType: ProductGroupType = null;
  private currentSnackbar: SnackbarState = null;

  blockUi: Observable<boolean>;
  loaderCount: Observable<number>;
  actualAmount: number;
  syncStatus = new BehaviorSubject<BudiItemSyncStatus>(null);

  // enforces the display of skeleton loaders in rims-table while polling product group items
  forceLoaders: Observable<boolean>;

  itemsEmptyResultSetMessage = 'No items are associated with this product group.';

  sapSyncPreviewResult: any;

  readonly riskClassFilters: Filter[] = [
    new Filter({
      fieldName: 'legislation.shortName',
      operator: FilterOperator.IN,
      value: `MDR,IVDR`
    })
  ];
  readonly riskClassOptionExpression = riskClassOptionExpression;
  readonly coeOptionExpression = coeAndDivision;
  readonly intendedPurposeTextAreaRows = obj => (obj ? 6 : 1);
  readonly intendedPurposeTextAreaResize = obj => (obj ? 'vertical' : 'none');

  constructor(
    injector: Injector,
    private readonly jobService: JobService,
    private readonly snackbar: MatSnackBar,
    private readonly syncPreview: SyncPreviewService,
    private readonly dataService: DataService,
    private readonly permissions: PermissionsService,
    private readonly groupValidator: ProductGroupValidator
  ) {
    super(injector);
    this.options.next({
      pageTitleFunc: res => this.getProp('name', res)
    });
  }

  ngOnInit() {
    // reset everything when groupId changes (this usually happens when navigating to a child group of the same group type)
    this.result.pipe(takeUntil(this.destroy$)).subscribe(group => {
      if (this.groupId !== null && this.groupId !== group.id) {
        this.groupId = null;
        this.destroy$.next(true);
        this.syncStatus.next(null);
        this.ngOnInit();
      }
      this.groupId = group.id;
      this.groupType = group.groupType2;
      this.groupNumber = group.number;
    });

    this.blockUi = combineLatest([this.isBudi, this.syncStatus, this.result]).pipe(
      map(([isBudi, status, group]) => {
        return isBudi && (!group?.budiInfo2 || (status && !status.itemsSynced));
      }),
      takeUntil(this.destroy$)
    );

    this.getBudiInfo();

    this.pollItemSyncStatus();

    this.loaderCount = this.getLoaderCount();

    this.selectedProductGroupItems = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) => selection[resolveResult?.viewPayloads['product_group_item']?.viewId] as number[]
      )
    );
    this.selectedProductGroupDocuments = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(([resolveResult, selection]) => selection[resolveResult?.viewPayloads['item_document']?.viewId] as number[])
    );
    this.selectedProductGroupNomenclatures = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) => selection[resolveResult?.viewPayloads['item_nomenclature']?.viewId] as number[]
      )
    );
    this.selectedProductGroupContainers = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) =>
          selection[resolveResult?.viewPayloads['product_group_container']?.viewId] as number[]
      )
    );
    this.selectedProductGroupActors = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) =>
          selection[resolveResult?.viewPayloads['product_group_actor']?.viewId] as number[]
      )
    );
    this.selectedProductGroupResponsibilities = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) =>
          selection[resolveResult?.viewPayloads['product_group_responsibility']?.viewId] as number[]
      )
    );
    this.selectedProductGroupChildGroups = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) =>
          selection[resolveResult?.viewPayloads['product_group_children']?.viewId] as number[]
      )
    );
    this.selectedProductGroupItemProductionSites = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) =>
          selection[resolveResult?.viewPayloads['item_production_site']?.viewId] as number[]
      )
    );
    this.selectedProductGroupContainerProductionSites = combineLatest([
      this.resolveResult,
      this.store.select(state => state.metadata.rowSelection)
    ]).pipe(
      map(
        ([resolveResult, selection]) =>
          selection[resolveResult?.viewPayloads['container_production_site']?.viewId] as number[]
      )
    );

    this.productGroupActorTypeNames = this.storeData.pipe(
      map(data => data['product_group_actor']?.page?.results.map(a => a.actor2.actorType2.name as string)),
      takeUntil(this.destroy$)
    );

    this.forceLoaders = this.syncStatus.asObservable().pipe(
      switchMap(value => {
        const force = value?.itemsSynced === false;

        // Display loaders instantly while items are not synced yet.
        // Avoid hiding loaders instantly once items have been synced, because rendering the data takes longer than hiding the loaders.
        const delay = force ? 0 : 1000;
        return timer(delay).pipe(map(() => force));
      })
    );
  }

  override ngOnDestroy() {
    this.snackbar.dismiss();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showSapSyncPreview() {
    return this.result
      .pipe(
        take(1),
        switchMap(result => this.syncPreview.getBudiSyncPreview(result.id)),
        tap(data => {
          this.sapSyncPreviewResult = data;
        })
      )
      .subscribe();
  }

  openAddItemDialog() {
    this.result
      .pipe(
        take(1),
        withLatestFrom(this.isBudi, this.isEbudi),
        tap(([res, isBudi, isEbudi]) => {
          this.store.dispatch(
            openAddDialog({
              key: 'product_group_item',
              entityName: 'item',
              toName: 'product group',
              toId: res.id,
              searchLabel: 'Item',
              expand: ['itemNumber2', 'product'],
              duplicateHint: 'Item already assigned',
              target: 'itemNumber',
              reloadAction: reloadProductGroupItemsAndRelatedEntities,
              multiple: true,
              pipeMultiple: false,
              pasteMultiple: true,
              budiInfo: res.budiInfo2,
              isABudi: isBudi || isEbudi,
              isEbudi
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveItemDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedProductGroupItems])
      .pipe(
        take(1),
        tap(([res, resolveResult, productGroupItemIds]) => {
          const viewId = resolveResult?.viewPayloads['product_group_item']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'product_group_item',
              entityName: 'item',
              entityIds: productGroupItemIds,
              fromName: 'product group',
              fromId: res.id,
              pipeDefaultValue: true,
              reloadAction: reloadProductGroupItemsAndRelatedEntities
            })
          );
        })
      )
      .subscribe();
  }

  openAddNomenclatureDialog() {
    combineLatest([this.result, this.selectedProductGroupItems])
      .pipe(
        take(1),
        tap(([res, selectedProductGroupItems]) => {
          this.store.dispatch(
            openAddDialog({
              key: 'item_nomenclature',
              relationKey: 'product_group_nomenclature',
              entityName: 'nomenclature',
              referenceName: 'item',
              referenceIds: selectedProductGroupItems,
              dropdown: 'nomenclature types',
              toName: 'product group',
              toId: res.id,
              searchLabel: 'Code Selection',
              placeholder: 'Search by Term or Number',
              expand: ['nomenclatureType2'],
              confirm: true,
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveNomenclatureDialog() {
    combineLatest([
      this.result,
      this.resolveResult,
      this.selectedProductGroupNomenclatures,
      this.selectedProductGroupItems
    ])
      .pipe(
        take(1),
        tap(([res, resolveResult, nomenclatureIds, selectedProductGroupItems]) => {
          const viewId = resolveResult?.viewPayloads['item_nomenclature']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_nomenclature',
              relationKey: 'product_group_nomenclature',
              entityName: 'nomenclature',
              entityIds: nomenclatureIds,
              fromName: 'product group',
              fromId: res.id,
              referenceName: 'item',
              referenceIds: selectedProductGroupItems,
              dataId: 'nomenclature2.id'
            })
          );
        })
      )
      .subscribe();
  }

  openAddDocumentDialog() {
    combineLatest([this.result, this.selectedProductGroupItems])
      .pipe(
        take(1),
        tap(([res, selectedProductGroupItems]) => {
          this.store.dispatch(
            openAddDialog({
              key: 'item_document',
              relationKey: 'product_group_document',
              entityName: 'document',
              toName: 'product group',
              toId: res.id,
              referenceName: 'item',
              referenceIds: selectedProductGroupItems,
              searchLabel: 'Search by Document Number',
              expand: ['dmsObject'],
              confirm: true,
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveDocumentDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedProductGroupDocuments, this.selectedProductGroupItems])
      .pipe(
        take(1),
        tap(([res, resolveResult, documentIds, selectedProductGroupItems]) => {
          const viewId = resolveResult?.viewPayloads['item_document']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_document',
              relationKey: 'product_group_document',
              entityName: 'document',
              entityIds: documentIds,
              fromName: 'product group',
              fromId: res.id,
              referenceName: 'item',
              referenceIds: selectedProductGroupItems,
              dataId: 'document2.id'
            })
          );
        })
      )
      .subscribe();
  }

  openAddContainerDialog() {
    this.result
      .pipe(
        take(1),
        switchMap(result =>
          this.dataService.requestBuilder
            .request(environment.backendUrl, 'productgroups', `${result.id}`, 'allowed-container-types')
            .get()
        ),
        tap(data => {
          this.store.dispatch(
            openAddContainerToProductGroupDialog({
              productGroupId: data.groupId,
              allowedContainerTypes: data.allowedContainerTypes
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveContainerDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedProductGroupContainers])
      .pipe(
        take(1),
        tap(([res, resolveResult, productGroupContainerIds]) => {
          const viewId = resolveResult?.viewPayloads['product_group_container']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'product_group_container',
              entityName: 'container',
              entityIds: productGroupContainerIds,
              fromName: 'product group',
              fromId: res.id,
              reloadAction: reloadContainersAndRelatedEntities
            })
          );
        })
      )
      .subscribe();
  }

  openAddActorDialog() {
    this.result
      .pipe(
        take(1),
        withLatestFrom(this.view, this.resolveResult, this.isBudi, this.isEbudi),
        tap(([res, view, resolveResult, isBudi, isEbudi]) => {
          const sppt: SystemProcedurePackType = res.budiInfo2?.systemProcedurePackType;
          let reloadAction = null;
          let reloadActionPayload = null;

          // if sppt is not defined we enable the user to set it in the add-actor dialog
          if (sppt == null && (isBudi || isEbudi)) {
            reloadAction = setSystemProcedurePackType;
            reloadActionPayload = {
              budiInfoId: res.budiInfo,
              useBaseEntityRecordIdForUpdate: true,
              viewId: view.id,
              expand: resolveResult.expand,
              groupId: res.id,
              groupEntityId: resolveResult.entityId
            };
          }

          this.store.dispatch(
            openAddDialog({
              key: 'product_group_actor',
              entityName: 'actor',
              toName: 'product group',
              toId: res.id,
              searchLabel: 'Search by Company Name or Address',
              duplicateHint: 'This actor already exists in the product group.',
              expand: ['company2.address.country', 'actorType2'],
              isABudi: isBudi || isEbudi,
              reloadAction,
              reloadActionPayload,
              sppt
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveActorDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedProductGroupActors])
      .pipe(
        take(1),
        tap(([res, resolveResult, productGroupActorIds]) => {
          const viewId = resolveResult?.viewPayloads['product_group_actor']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'product_group_actor',
              entityName: 'actor',
              entityIds: productGroupActorIds,
              fromName: 'product group',
              fromId: res.id
            })
          );
        })
      )
      .subscribe();
  }

  openAddResponsibilityDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openAddDialog({
              key: 'product_group_responsibility',
              displayName: 'responsibility',
              entityName: 'user',
              dropdown: 'departments',
              toName: 'product group',
              toId: res.id,
              duplicateHint: 'This user already has a responsibility in this group.',
              emptyHint: 'Enter a name to start searching.',
              expand: ['contact'],
              multiple: true,
              pasteMultiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveResponsibilityDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedProductGroupResponsibilities])
      .pipe(
        take(1),
        tap(([res, resolveResult, productGroupResponsibilityIds]) => {
          const viewId = resolveResult?.viewPayloads['product_group_responsibility']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'product_group_responsibility',
              displayName: 'responsibility',
              entityName: 'user',
              entityIds: productGroupResponsibilityIds,
              fromName: 'product group',
              fromId: res.id,
              noRelation: true,
              pipeDefaultValue: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveChildGroupDialog() {
    combineLatest([this.result, this.resolveResult, this.selectedProductGroupChildGroups])
      .pipe(
        take(1),
        tap(([res, resolveResult, childGroupIds]) => {
          const viewId = resolveResult?.viewPayloads['product_group_children']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'product_group_children',
              displayName: 'child group',
              entityName: 'productgroup',
              entityIds: childGroupIds,
              fromName: 'product group',
              fromId: res.id,
              relation: 'child2'
            })
          );
        })
      )
      .subscribe();
  }

  openAddChildGroupDialog() {
    this.result
      .pipe(
        take(1),
        mergeMap(res =>
          forkJoin([
            of(res.id),
            this.dataService
              .getAll(
                'allowedchildgrouptype',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [new EqualsFilter('parent_type', res.groupType2.id)],
                undefined,
                true
              )
              .pipe(
                take(1),
                map(({ results }) => results.map(res => res.childType))
              )
          ])
        ),
        tap(([id, allowedChildGroupTypeIds]) => {
          this.store.dispatch(
            openAddDialog({
              allowedChildGroupTypeIds,
              key: 'product_group_children',
              displayName: 'child group',
              entityName: 'productgroup',
              toName: 'product group',
              duplicateHint: 'This group is already a child group.',
              toId: id,
              relation: 'child2',
              expand: ['groupType2'],
              multiple: true,
              pasteMultiple: true,
              searchTarget: 'number'
            })
          );
        })
      )
      .subscribe();
  }

  openAddItemProductionSiteDialog() {
    combineLatest([this.result, this.selectedProductGroupItems])
      .pipe(
        take(1),
        tap(([res, selectedProductGroupItems]) => {
          this.store.dispatch(
            openAddDialog({
              toId: res.id,
              key: 'item_production_site',
              relationKey: 'product_group_item_production_site',
              toName: 'product group',
              referenceName: 'item',
              referenceIds: selectedProductGroupItems,
              entityName: 'company',
              displayName: 'production site',
              searchLabel: 'Search by Company Name or Address',
              notFoundHint: 'No company found. Try again with another query.',
              expand: ['address.country'],
              confirm: true,
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveItemProductionSiteDialog() {
    combineLatest([
      this.result,
      this.resolveResult,
      this.selectedProductGroupItemProductionSites,
      this.selectedProductGroupItems
    ])
      .pipe(
        take(1),
        tap(([res, resolveResult, companyIds, selectedProductGroupItems]) => {
          const viewId = resolveResult?.viewPayloads['item_production_site']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'item_production_site',
              relationKey: 'product_group_item_production_site',
              displayName: 'production site',
              entityName: 'company',
              entityIds: companyIds,
              fromName: 'product group',
              fromId: res.id,
              referenceName: 'item',
              referenceIds: selectedProductGroupItems,
              dataId: 'company2.id'
            })
          );
        })
      )
      .subscribe();
  }

  openAddContainerProductionSiteDialog() {
    combineLatest([this.result, this.selectedProductGroupContainers])
      .pipe(
        take(1),
        tap(([res, selectedProductGroupContainers]) => {
          this.store.dispatch(
            openAddDialog({
              toId: res.id,
              key: 'container_production_site',
              relationKey: 'product_group_container_production_site',
              toName: 'product group',
              referenceName: 'container',
              referenceIds: selectedProductGroupContainers,
              entityName: 'company',
              displayName: 'production site',
              searchLabel: 'Search by Company Name or Address',
              notFoundHint: 'No company found. Try again with another query.',
              expand: ['address.country'],
              confirm: true,
              multiple: true
            })
          );
        })
      )
      .subscribe();
  }

  openRemoveContainerProductionSiteDialog() {
    combineLatest([
      this.result,
      this.resolveResult,
      this.selectedProductGroupContainerProductionSites,
      this.selectedProductGroupContainers
    ])
      .pipe(
        take(1),
        tap(([res, resolveResult, companyIds, selectedProductGroupContainers]) => {
          const viewId = resolveResult?.viewPayloads['container_production_site']?.viewId;
          this.store.dispatch(
            openRemoveDialog({
              viewId,
              key: 'container_production_site',
              relationKey: 'product_group_container_production_site',
              displayName: 'production site',
              entityName: 'company',
              entityIds: companyIds,
              fromName: 'product group',
              fromId: res.id,
              referenceName: 'container',
              referenceIds: selectedProductGroupContainers,
              dataId: 'company2.id'
            })
          );
        })
      )
      .subscribe();
  }

  openChangeBudiLifecycleDialog() {
    this.result
      .pipe(
        take(1),
        tap(res => {
          this.store.dispatch(
            openChangeBudiLifecycleDialog({
              productGroupBudiInfoId: res.budiInfo
            })
          );
        })
      )
      .subscribe();
  }

  mailResponsibilities() {
    this.selectedProductGroupResponsibilities
      .pipe(
        take(1),
        withLatestFrom(this.storeData.pipe(map(data => data['product_group_responsibility']))),
        map(([ids, entries]) => entries.page.results.filter(entry => ids.includes(entry.id))),
        switchMap(responsibilities =>
          this.dataService.getAll('users', ['contact'], 0, responsibilities.length, undefined, undefined, [
            new InFilter('id', responsibilities.map(r => `${r.user}`).join(','))
          ])
        ),
        tap(users => {
          const mails = users.results.map(user => this.userEmailPipe.transform(user)).join(',');
          const link = `mailto:${mails}`;
          window.open(link, '_self');
        })
      )
      .subscribe();
  }

  /**
   * Checks whether there was a change on the system procedure pack type or multi component type and whether it has an impact on the actors of this group.
   *
   * If that's the case we trigger the removal of conflicting actors
   *
   * @example if the group currently has an actor of type 'Manufacturer' but we changed the spp type from 'Not applicable' to 'System' we need to remove that actor.
   */
  handleSpptOrMctChanges(changes: PropertyEditEvent) {
    const sppt: SystemProcedurePackType = changes.value['budiInfo2.systemProcedurePackType'] as any;
    const mct: MultiComponentType = changes.value['budiInfo2.multiComponentType'] as any;
    const spptApplicable = sppt?.notApplicable === false;
    const mctApplicable = mct?.notApplicable === false;

    if (sppt || mct) {
      this.productGroupActorTypeNames.pipe(take(1)).subscribe(types => {
        const { hasManufacturers, hasSystems } = this.hasActorTypes(types);

        if (mct && mctApplicable && hasSystems) {
          return this.removeSpptActorsOfTypes('System/Procedure Pack Producer');
        }

        if (sppt && !spptApplicable && hasSystems) {
          return this.removeSpptActorsOfTypes('System/Procedure Pack Producer');
        }

        if (sppt && spptApplicable && hasManufacturers) {
          return this.removeSpptActorsOfTypes('Manufacturer');
        }
      });
    }
  }

  private removeSpptActorsOfTypes(type: string) {
    this.storeData
      .pipe(
        take(1),
        map(data =>
          data['product_group_actor']?.page?.results
            .filter(actor => {
              return actor.actor2.actorType2.name === type;
            })
            .map(actor => {
              return actor.id;
            })
        )
      )
      .subscribe(actorIds => {
        if (actorIds.length > 0) {
          this.store.dispatch(
            closeRemoveDialog({
              key: 'product_group_actor',
              fromId: this.groupId,
              entityIds: actorIds
            })
          );
        }
      });
  }

  getConfirmDescription(actorTypes: string[]) {
    const type = this.currentSpptActorType(actorTypes);

    return `This BUDI currently has actors of type <i>${type}</i> which are in conflict with your selection.`;
  }

  getConfirmTxt(actorTypes: string[]) {
    const type = this.currentSpptActorType(actorTypes);

    return `I confirm that I want to remove all actors of type <i>${type}</i>.`;
  }

  /**
   * @returns a method which will be executed by the property-edit-menu to determine whether a confirmation checkbox
   * that informs the user about the automatic removal of conflicting actors needs to be displayed
   */
  getSpptConfirmConditionFn(group: ProductGroup, actorTypes: string[]) {
    const sppt = group.budiInfo2.systemProcedurePackType;
    const spptApplicable = sppt?.notApplicable === false;
    const { hasManufacturers, hasSystems } = this.hasActorTypes(actorTypes);

    if ((sppt == null || !spptApplicable) && hasManufacturers) return this.systemOrProcedurePackSelected;
    if ((sppt == null || spptApplicable) && hasSystems) return this.notApplicableSelected;

    return () => false;
  }

  /**
   * @returns a method which will be executed by the property-edit-menu to determine whether a confirmation checkbox
   * that informs the user about the automatic removal of conflicting actors needs to be displayed
   */
  getMctConfirmConditionFn(actorTypes: string[]) {
    const { hasSystems } = this.hasActorTypes(actorTypes);

    if (hasSystems) return this.systemOrProcedurePackSelected;

    return () => false;
  }

  /**
   * Stretching the modal to 'mid-width' is only necessary when we have a confirm dialog
   * If there are no system actors present, we dont need a confirm dialog for the MultiComponentType
   * In that case we can keep the default width
   */
  getMctWidth(actorTypes: string[]) {
    const { hasSystems } = this.hasActorTypes(actorTypes);
    if (hasSystems) return 'mid-width';

    return null;
  }

  /**
   * Stretching the modal to 'mid-width' is only necessary when we have a confirm dialog
   * If there are no SoPP actors present, we dont need a confirm dialog for the SystemProcedurePackType
   * In that case we can keep the default width
   */
  getSpptWidth(actorTypes: string[]) {
    const { hasSystems, hasManufacturers } = this.hasActorTypes(actorTypes);
    if (hasSystems || hasManufacturers) return 'mid-width';

    return null;
  }

  /**
   * we need to explicitly check with '=== false' because null values need to be ignored
   */
  private systemOrProcedurePackSelected = () => {
    const spptApplicable = this.editPropertyMenu.newValue.value?.notApplicable === false;
    const mctApplicable = this.editPropertyMenu.newValue.value?.notApplicable === false;

    return spptApplicable || mctApplicable;
  };

  private notApplicableSelected = () => {
    const spptNotApplicable = this.editPropertyMenu.newValue.value?.notApplicable;
    const mctNotApplicable = this.editPropertyMenu.newValue.value?.notApplicable;

    return spptNotApplicable || mctNotApplicable;
  };

  private currentSpptActorType(actorTypes: string[]) {
    const { hasManufacturers, hasSystems } = this.hasActorTypes(actorTypes);
    return hasManufacturers ? 'Manufacturer' : hasSystems ? 'System/Procedure Pack Producer' : null;
  }

  private hasActorTypes(actorTypes: string[]) {
    const hasManufacturers = !!actorTypes?.find(type => type === 'Manufacturer');
    const hasSystems = !!actorTypes?.find(type => type === 'System/Procedure Pack Producer');

    return {
      hasManufacturers,
      hasSystems
    };
  }

  getLoaderCount() {
    return this.syncStatus.pipe(
      takeWhile(() => !this.syncStatus.value?.itemsSynced && this.syncStatus.value?.desired !== 0),
      map(status => {
        if (!status) return appConfig.defaultLoaderCount;

        // if desired is unknown yet we assume 10 (or more) items are going to be synchronized
        if (status.desired === -1) return 10;

        if (!status.sapItemsSynced && status.desired) {
          return Math.min(status.desired, 10);
        }

        if (!status.itemsSynced) {
          return Math.min(status.desired ?? 10 - status.items, 10);
        }

        return appConfig.defaultLoaderCount;
      }),
      takeUntil(merge(this.destroy$, this.isBudi.pipe(filter(isBudi => !isBudi))))
    );
  }

  /**
   * Starts polling the itemSyncStatus (every 2 seconds)
   *
   * If 'desired' is 0 then the items have already been synchronized (we deliberately set desired to 0 once all items have been synchronized)
   * Only after 'getItemSyncStatus' finishes a new request is being sent (via repeat())
   *
   * Stops polling once all items have been added to the BUDI
   */
  pollItemSyncStatus() {
    of({})
      .pipe(
        // only continue when productGroup is a budi
        takeUntil(merge(this.destroy$, this.isBudi.pipe(filter(isBudi => !isBudi)))),
        takeWhile(() => !this.syncStatus.value?.itemsSynced && this.syncStatus.value?.desired !== 0),
        mergeMap(() => {
          return this.jobService.getItemSyncStatus(this.groupNumber);
        }),
        tap((status: BudiItemSyncStatus) => {
          const state = status?.itemsSynced ? SnackbarState.SUCCESS : SnackbarState.PENDING;
          this.syncStatus.next(status);

          switch (state) {
            case SnackbarState.PENDING:
              if (this.currentSnackbar !== SnackbarState.PENDING) {
                this.snackbar.openFromComponent(PendingSnackbarComponent, {
                  horizontalPosition: 'right',
                  panelClass: 'pending-snackbar',
                  data: {
                    status: this.syncStatus.asObservable(),
                    text: 'Item creation is pending. You can interact with this Product Group once this process has finished.'
                  }
                });
                this.currentSnackbar = SnackbarState.PENDING;
              }
              if (status?.items > this.actualAmount) {
                this.store.dispatch(reloadProductGroupItemsAndRelatedEntities());
              }
              this.actualAmount = status.items;
              break;
            default:
              if (status.desired !== 0) {
                this.snackbar.open(`Items have been synchronized`, undefined, {
                  horizontalPosition: 'right',
                  panelClass: 'success-snackbar',
                  duration: 4000
                });
                this.currentSnackbar = SnackbarState.SUCCESS;
                this.store.dispatch(reloadProductGroupItemsAndRelatedEntities());
                // fetch budi info (again) once all items have been synchronized for the first time
                this.resolveResult.pipe(take(1)).subscribe(({ entityId, expand }) => {
                  this.store.dispatch(
                    getOne({
                      entityId,
                      expand,
                      recordId: this.groupId
                    })
                  );
                });
              } else {
                this.snackbar.dismiss();
              }
          }
        }),
        delay(2000),
        repeat(),
        takeUntil(merge(this.destroy$, this.isBudi.pipe(filter(isBudi => !isBudi)))),
        takeWhile(() => !this.syncStatus.value?.itemsSynced && this.syncStatus.value?.desired !== 0)
      )
      .subscribe();
  }

  /**
   * Reloads the current product group every 2 seconds until the budiInfo is present.
   * This way we do not need to reload the page manually once budiInfo has been added.
   */
  private getBudiInfo() {
    timer(0, 2000)
      .pipe(
        withLatestFrom(this.result, this.resolveResult),
        takeWhile(([, group]) => !group?.budiInfo2),
        takeUntil(merge(this.destroy$, this.isBudi.pipe(filter(isBudi => !isBudi))))
      )
      .subscribe(([, , { entityId, expand }]) => {
        this.store.dispatch(
          getOne({
            entityId,
            expand,
            recordId: this.groupId
          })
        );

        this.store.dispatch(getProductGroupActors());
      });
  }

  groupNameAsyncValidator(control: AbstractControl) {
    const name = control?.value;
    return this.groupValidator.productGroupNameValidator(this.groupType, name);
  }
}
