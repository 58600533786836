import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ProductGroupType } from '@rims/database';
import { BudiItemSyncStatus, EqualsFilter } from '@rims/lib';
import {
  sapBudiDivisionToDivisionShortName,
  SapBudiObject,
  SapInterfaceResponse,
  SapResponseCode
} from '@rims/sap-interface';
import { Job } from 'bullmq';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { Page } from 'src/app/modules/shared/models/page/page.model';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { environment } from 'src/environments/environment';
import { PropertyEditMenuComponent } from '../../../shared/components/rims-property-edit-menu/property-edit-menu.component';
import { ChangeTrackedEntity } from '../../../shared/models/utils/change-tracked-entity.model';
import { createRecord } from '../../../shared/store/data/data.actions';
import { AppState } from '../../../store/store.state';
import { BudiMaterialsDialogComponent } from '../../components/budi-materials-dialog/budi-materials-dialog.component';
import { ProductGroupValidator } from '../../validators/product-group.validator';

enum FormFieldName {
  GROUP_TYPE = 'groupType',
  NUMBER = 'number',
  NAME = 'name',
  COE = 'coe',
  PRODUCT_TYPE = 'productType',
  INTENDED_PURPOSE = 'intendedPurpose',
  PRODUCT_GROUP_ALREADY_EXISTS = 'ProductGroupAlreadyExists',
  SECTION_ONE_VALID = 'sectionOneValid',
  SECTION_ONE_FINISHED = 'sectionOneFinished',
  SECTION_TWO_FINISHED = 'sectionTwoFinished',
  EXT_SAP_BASIC_UDI_CHECKED = 'extSapBasicUdiChecked',
  EXT_SAP_BASIC_UDI_NOT_FOUND = 'extSapBasicUdiNotFound',
  EXT_SAP_BASIC_UDI_UNDEFINED_ERROR = 'extSapBasicUdiUndefinedError',
  EXT_SAP_BASIC_UDI = 'extSapBasicUdi',
  EXT_SAP_BASIC_UDI_ERROR_MESSAGE = 'extSapBasicUdiErrorMessage',
  INTENDED_PURPOSE_CHECKED = 'intendedPurposeChecked',
  INTENDED_PURPOSE_RESOLVED = 'intendedPurposeResolved'
}

@Component({
  selector: 'rims-create-product-group',
  templateUrl: './create-product-group.component.html',
  styleUrls: ['./create-product-group.component.scss']
})
export class CreateProductGroupComponent implements OnInit, OnDestroy, AfterViewInit {
  groupTypes: ProductGroupType[];
  selectedGroupType: ProductGroupType;
  coes: Observable<ChangeTrackedEntity[]>;
  productTypes: Observable<ChangeTrackedEntity[]>;
  isBudi = false;

  @ViewChild(PropertyEditMenuComponent, { static: false })
  editPropertyMenu: PropertyEditMenuComponent;

  private destroy$ = new Subject<boolean>();
  readonly formFieldName = FormFieldName;

  /**
   * In case the user wants to create a new intended purpose,
   * this property contains a link to navigate to the prefilled form.
   */
  createNewIntendedPurposeLink: string;

  form = new UntypedFormGroup({
    // Control fields
    [FormFieldName.SECTION_ONE_VALID]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.SECTION_ONE_FINISHED]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.SECTION_TWO_FINISHED]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.PRODUCT_GROUP_ALREADY_EXISTS]: new UntypedFormControl(undefined, [
      Validators.required,
      this.requiredFalse
    ]),
    [FormFieldName.EXT_SAP_BASIC_UDI_CHECKED]: new UntypedFormControl(false, Validators.requiredTrue),
    [FormFieldName.EXT_SAP_BASIC_UDI_NOT_FOUND]: new UntypedFormControl(false),
    [FormFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR]: new UntypedFormControl(false),
    [FormFieldName.EXT_SAP_BASIC_UDI_ERROR_MESSAGE]: new UntypedFormControl(null),
    [FormFieldName.INTENDED_PURPOSE_CHECKED]: new UntypedFormControl(false, Validators.required),
    [FormFieldName.INTENDED_PURPOSE_RESOLVED]: new UntypedFormControl(false, Validators.requiredTrue),
    // Content fields
    [FormFieldName.GROUP_TYPE]: new UntypedFormControl(null, Validators.required),
    [FormFieldName.NUMBER]: new FormControl(null, {
      validators: Validators.required,
      asyncValidators: [this.validateNumber.bind(this)]
    }),
    [FormFieldName.NAME]: new UntypedFormControl(null, {
      validators: Validators.required,
      asyncValidators: [this.validateName.bind(this)],
      updateOn: 'blur'
    }),
    [FormFieldName.COE]: new UntypedFormControl(null, Validators.required),
    [FormFieldName.PRODUCT_TYPE]: new UntypedFormControl(null, Validators.required),
    [FormFieldName.INTENDED_PURPOSE]: new UntypedFormControl(null),
    [FormFieldName.EXT_SAP_BASIC_UDI]: new UntypedFormControl(null)
  });

  existingProductGroupsResult = new BehaviorSubject<Page<any>>(null);
  desiredExtSapItemCount: number;
  extSapItemSyncStatus = new BehaviorSubject<BudiItemSyncStatus>(null);

  constructor(
    private readonly store: Store<AppState>,
    private readonly breadcrumb: BreadcrumbService,
    private readonly data: DataService,
    private readonly http: HttpClient,
    private readonly matDialog: MatDialog,
    private readonly validator: ProductGroupValidator
  ) {}

  ngOnInit() {
    this.store
      .select(state => state.data.entries['product_group_type'].page.results)
      .pipe(take(1))
      .subscribe((types: ProductGroupType[]) => {
        this.groupTypes = types;
      });
    this.coes = this.store.pipe(
      select(state => state.data.entries['coe'].page.results),
      map(coes => {
        const division = this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value?.division;
        if (division) {
          const shortName = sapBudiDivisionToDivisionShortName(division);
          if (shortName) {
            return coes.filter(coe => coe.division.shortName === shortName);
          }
        }
        return coes;
      })
    );
    this.productTypes = this.store.select(state => state.data.entries['product_type'].page.results);
    this.breadcrumb.pushCreationPage();
  }

  ngAfterViewInit() {
    const groupTypeChange = this.form
      .get(FormFieldName.GROUP_TYPE)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    const numberChange = this.form
      .get(FormFieldName.NUMBER)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$));

    const validChange = this.form.get(FormFieldName.NUMBER).statusChanges.pipe(
      startWith(this.form.get(FormFieldName.NUMBER).status),
      filter(status => status === 'VALID'),
      take(1)
    );

    combineLatest([numberChange, groupTypeChange])
      .pipe(
        tap(([_, typeId]) => {
          const type = this.groupTypes.find(type => type.id === typeId);
          this.selectedGroupType = type;
        }),
        map(([number]) => number as string),
        tap(() => {
          this.existingProductGroupsResult.next(null);
          this.isBudi = false;
          this.form.reset({
            [FormFieldName.NUMBER]: this.form.get(FormFieldName.NUMBER).value,
            [FormFieldName.GROUP_TYPE]: this.form.get(FormFieldName.GROUP_TYPE).value,
            [FormFieldName.EXT_SAP_BASIC_UDI_CHECKED]: { value: false, disabled: false }, // enable previously disabled control again
            [FormFieldName.INTENDED_PURPOSE_CHECKED]: false // make sure this required control has an initial value
          });
        }),
        debounceTime(500),
        filter(groupNumber => groupNumber?.length > 0),
        switchMap(groupNumber => {
          return validChange.pipe(map(_ => groupNumber));
        }), // wait until validators have resolved without any errors
        switchMap(groupNumber => {
          this.isBudi = this.selectedGroupType.shortName === 'BUDI';
          return this.data.getAll('/productgroups', undefined, 0, 1, undefined, undefined, [
            new EqualsFilter('number', groupNumber)
          ]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(productGroupResult => {
        const alreadyExists = productGroupResult.resultsSize > 0;
        this.form.get(FormFieldName.PRODUCT_GROUP_ALREADY_EXISTS).setValue(alreadyExists);
        this.form.get(FormFieldName.SECTION_ONE_VALID).setValue(!alreadyExists);
        this.existingProductGroupsResult.next(productGroupResult);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async nextStage() {
    // Handle Section 1 (Validation)
    if (
      this.form.get(FormFieldName.SECTION_ONE_VALID).value &&
      !this.form.get(FormFieldName.SECTION_ONE_FINISHED).value
    ) {
      this.form.get(FormFieldName.SECTION_ONE_FINISHED).setValue(true);

      if (!this.isBudi) {
        this.form.get(FormFieldName.INTENDED_PURPOSE_RESOLVED).setValue(true);
        this.form.get(FormFieldName.INTENDED_PURPOSE_CHECKED).setValue(true);
        this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_CHECKED).setValue(true);
        this.form.get(FormFieldName.SECTION_TWO_FINISHED).setValue(true);
        return;
      }
    }

    // Handle Section 2.1 (Load Ext Sap Basic Udi)
    if (
      !this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_CHECKED).value &&
      !this.form.get(FormFieldName.SECTION_TWO_FINISHED).value &&
      this.form.get(FormFieldName.SECTION_ONE_FINISHED).value
    ) {
      this.fetchBasicUdiFromSap();

      return;
    }
    // Handle Section 2.2 (Confirm Ext Sap Basic Udi)
    if (
      this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_CHECKED).value &&
      !this.form.get(FormFieldName.SECTION_TWO_FINISHED).value
    ) {
      this.form.get(FormFieldName.SECTION_TWO_FINISHED).setValue(true);
      this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_CHECKED).disable();

      return;
    }

    // Handle Section 3 (BUDI Creation)
    if (this.form.get(FormFieldName.SECTION_TWO_FINISHED).value) {
      let groupNumber: string = this.form.get(FormFieldName.NUMBER).value;
      if (this.isBudi) {
        this.createExtSapBasicUdi();
        const budi = this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value;
        groupNumber = budi.basicUdiDi;
      }

      const groupType = this.form.get(FormFieldName.GROUP_TYPE).value;
      const { name, coe, productType, intendedPurpose } = this.form.value;
      this.store.dispatch(
        createRecord({
          url: 'productgroups',
          payload: {
            number: groupNumber,
            groupType,
            name,
            coe,
            productType,
            intendedPurpose
          },
          navigateTo: results => `/view/productgroups/${results[0].id}`
        })
      );
    }
  }

  private createExtSapBasicUdi() {
    this.http
      .post<Job>(
        `${environment.backendUrl}/extsapbasicudis/from-material-number?materialNumber=${
          this.form.get(FormFieldName.NUMBER).value
        }`,
        null
      )
      .subscribe();
  }

  private prefillWithExtSapBasicUdi() {
    const basicUdi = this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value as SapBudiObject;
    if (basicUdi) {
      this.form.get(FormFieldName.NAME).setValue(basicUdi.basicUdiName);
      if (typeof basicUdi?.classValues?.lifecycle === 'string' && basicUdi.classValues.lifecycle !== '01') {
        this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).setErrors({
          lifecycle: true
        });
      }
      if (basicUdi.materialValues?.length) {
        this.desiredExtSapItemCount = basicUdi.materialValues.length;
      }
      this.fetchIntendedPurpose();
    }
  }

  retry() {
    this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR).patchValue(false);
    this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_NOT_FOUND).patchValue(false);
    this.nextStage();
  }

  private fetchBasicUdiFromSap() {
    const numberCtrl = this.form.get(FormFieldName.NUMBER);
    const typeCtrl = this.form.get(FormFieldName.GROUP_TYPE);
    numberCtrl.disable();
    typeCtrl.disable();

    this.http
      .get<SapInterfaceResponse<SapBudiObject>>(`${environment.backendUrl}/sap/material/${numberCtrl.value}`)
      .pipe(
        tap(result => {
          const status = result[0].status;
          switch (status) {
            case SapResponseCode.SUCCESSFUL:
              this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).setValue(result[0].payload);
              this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR).setValue(false);
              this.prefillWithExtSapBasicUdi();
              this.fillCreateIntendedPurposeLink();
              break;
            case SapResponseCode.MATERIAL_DOES_NOT_EXIST:
              this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_NOT_FOUND).setValue(true);
              break;
            default:
              this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR).setValue(true);
              console.error(result[0]);
              break;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_UNDEFINED_ERROR).setValue(true);
          this.form.get(FormFieldName.EXT_SAP_BASIC_UDI_ERROR_MESSAGE).setValue(error.message);
          console.error(error);
          return of(undefined);
        }),
        tap(() => {
          numberCtrl.enable();
          typeCtrl.enable();
        })
      )
      .subscribe();
  }

  private fillCreateIntendedPurposeLink() {
    const url = new URL('/view/intendedpurposes/new', location.origin);
    const intendedPurpose = this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value?.intendedPurpose || '';
    url.searchParams.append('text', encodeURIComponent(intendedPurpose));
    this.createNewIntendedPurposeLink = url.toString();
  }

  private fetchIntendedPurpose() {
    const intendedPurpose = this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value?.intendedPurpose;
    if (intendedPurpose) {
      this.validator
        .getMatchingIntendedPurpose(intendedPurpose)
        .pipe(
          tap(id => {
            if (id) {
              this.form.get(FormFieldName.INTENDED_PURPOSE).setValue(id);
              this.form.get(FormFieldName.INTENDED_PURPOSE_RESOLVED).setValue(true);
            }
            this.form.get(FormFieldName.INTENDED_PURPOSE_CHECKED).setValue(true);
          })
        )
        .subscribe();
    } else {
      this.form.get(FormFieldName.INTENDED_PURPOSE_RESOLVED).setValue(true);
    }
  }

  discardIntendedPurpose() {
    this.form.get(FormFieldName.INTENDED_PURPOSE).setValue(null);
    const budi = this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value;
    this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).setValue({
      ...budi,
      intendedPurpose: `The text has been discarded.`
    });
    this.form.get(FormFieldName.INTENDED_PURPOSE_RESOLVED).setValue(true);
  }

  showBudiMaterialsDialog() {
    this.matDialog.open(BudiMaterialsDialogComponent, {
      data: this.form.get(FormFieldName.EXT_SAP_BASIC_UDI).value,
      panelClass: 'budi-materials-dialog'
    });
  }

  private validateNumber(control: UntypedFormControl): Observable<ValidationErrors | null> {
    const num = control.value;
    return this.validator.validatePattern(this.selectedGroupType, 'number', num);
  }

  private validateName(control: UntypedFormControl) {
    const name = control.value;
    return this.validator.productGroupNameValidator(this.selectedGroupType, name);
  }

  /**
   * Complement of Angulars 'requiredTrue' Validator
   */
  private requiredFalse(control: AbstractControl): ValidationErrors | null {
    if (control.value === false) {
      return null;
    }

    return { requiredFalse: true };
  }
}
