import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NumericalIdGuard } from '../shared/guards/numerical-id.guard';
import { ViewByUrlParamResolver } from '../view/resolvers/view-by-url-param.resolver';
import { ViewComponent } from '../view/views/view/view.component';
import { RoleDetailResolver } from './resolvers/role-detail.resolver';
import { RoleDetailComponent } from './views/role-detail/role-detail.component';

const routes: Routes = [
  {
    path: '',
    component: ViewComponent,
    resolve: {
      result: ViewByUrlParamResolver
    },
    data: {
      shouldDisplayDetailLink: true
    }
  },
  {
    path: ':id',
    component: RoleDetailComponent,
    canActivate: [NumericalIdGuard],
    resolve: {
      result: RoleDetailResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesRoutingModule {}
