import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../store/store.state';
import { getFieldsForEntity } from '../store/metadata/metadata.actions';

/**
 * This directive can be used to add elements to the DOM conditionally
 * based on the presence of required permission to perform a certain action.
 *
 * The directive acts like `*ngIf` but accepts special configuration parameters
 * and internally uses the user's role and entity permissions to determine
 * if an element should be present in the DOM.
 *
 * The directive can be used in two ways:
 *
 * *For editing:*
 * ```html
 * <element *rimsRequirePermission="'product_group'; field: 'number'"></element>
 * ```
 *
 * *For creating/deleting/reading:*
 * ```html
 * <element *rimsRequirePermission="'product_group_document'; action: 'create'"></element>
 * ```
 *
 * *Use the `condition` to specify additional show/hide requirements:*
 * ```html
 * <element *rimsRequirePermission="'product_group_document'; action: 'delete'; condition: (selectedProductGroupDocuments | async)?.length > 0"></element>
 * ```
 *
 * *Use the `force` flag to bypass the internal permission checks:*
 * ```html
 * <element *rimsRequirePermission="'product_group_document'; action: 'create'; force: false"></element>
 * <element *rimsRequirePermission="'product_group_document'; action: 'delete'; force: true"></element>
 * ```
 */
@Directive({
  selector: '[rimsRequirePermission]'
})
export class RequirePermissionDirective {
  /**
   * Name of the entity to use for permission checking.
   */
  private entity: string;
  /**
   * Field name to check for writable status.
   *
   * Set to 'all' if you don't want to check for a specific field but whether you can update any properties in general.
   * Checking whether a specific field is read-only will be skipped in this case
   */
  private field: string;
  /**
   * The desired action to perform.
   */
  private action: 'create' | 'read' | 'update' | 'delete' = 'update';
  /**
   * Additional boolean expression that will act as an additional
   * `*ngIf` wrapper.
   */
  private condition = true;
  /**
   * Directive will not create a view unless this is `true`.
   *
   * If the usage of the permission is depending on external
   * conditions, you can set this to `false` initially.
   */
  private ready = true;
  /**
   * Set this to bypass the normal permission check and force
   * either a positive or negative result.
   */
  private force: boolean;
  /**
   * Internal flag to keep track of the state of the directive.
   */
  private hasView: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly store: Store<AppState>
  ) {}

  @Input()
  set rimsRequirePermission(entity: string) {
    this.entity = entity;
    this.updateVisibility();
  }

  @Input()
  set rimsRequirePermissionField(field: string) {
    this.field = field;
    this.updateVisibility();
  }

  @Input()
  set rimsRequirePermissionAction(action: RequirePermissionDirective['action']) {
    this.action = action;
    this.updateVisibility();
  }

  @Input()
  set rimsRequirePermissionCondition(condition: boolean) {
    this.condition = condition;
    this.updateVisibility();
  }

  @Input()
  set rimsRequirePermissionReady(ready: boolean) {
    this.ready = ready;
    this.updateVisibility();
  }

  @Input()
  set rimsRequirePermissionForce(force: boolean) {
    this.force = force;
    this.updateVisibility();
  }

  get permission(): boolean {
    return this.hasView;
  }

  private updateVisibility() {
    if (typeof this.force === 'boolean') {
      return this.force && this.condition ? this.show() : this.hide();
    }

    const qualifiedForUpdate =
      this.action === 'update' && typeof this.entity === 'string' && typeof this.field === 'string';
    const qualifiedForReadCreateDelete = this.entity && this.action !== 'update';
    const qualifiedToDisplay = (qualifiedForUpdate || qualifiedForReadCreateDelete) && this.condition;

    if (!qualifiedToDisplay) {
      return this.hide();
    }

    this.store
      .pipe(
        map(state => ({
          user: state.user,
          entity: Object.keys(state.metadata.entities)
            .map(id => state.metadata.entities[id])
            .find(en => en.name === this.entity)
        })),
        tap(data => {
          const role = data.user.user.role;
          if (!data.entity) {
            console.warn(`[rimsRequirePermission] Entity "${this.entity}" not found!`);
            return this.hide();
          }

          const ensureEntityMetadataAndRetry = () => {
            if (!data.entity?.fieldDataRequested) {
              this.store.dispatch(getFieldsForEntity({ entityId: data.entity.id }));
            } else {
              setTimeout(() => this.updateVisibility(), 500);
            }
            return this.hide();
          };

          if (!data.entity?.fields) {
            return ensureEntityMetadataAndRetry();
          }

          if (this.field !== 'all' && this.action === 'update' && data.entity?.fields?.[this.field]?.readOnly) {
            return this.hide();
          }

          if (!data.entity?.permissions) {
            return ensureEntityMetadataAndRetry();
          }

          const actionPermissionLevel = data.entity.permissions[this.action + 'PermissionLevel'];
          const userPermissionLevel = role.permissionLevel;

          const hasPermission =
            typeof userPermissionLevel === 'number' &&
            typeof actionPermissionLevel === 'number' &&
            userPermissionLevel >= actionPermissionLevel;

          if (qualifiedToDisplay && hasPermission && !this.hasView) {
            this.show();
          } else if (!(qualifiedToDisplay && hasPermission) && this.hasView) {
            this.hide();
          }
        })
      )
      .subscribe()
      .unsubscribe();
  }

  private show() {
    if (!this.hasView && this.ready) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  private hide() {
    this.viewContainer.clear();
    this.hasView = false;
  }
}
