import { Pipe, PipeTransform } from '@angular/core';
import { Breadcrumb } from '../model/breadcrumb';

@Pipe({
  name: 'makeAbsoluteLink'
})
export class MakeAbsoluteLinkPipe implements PipeTransform {
  transform(currentCrumb: Breadcrumb, allCrumbs: Breadcrumb[]): string {
    let path = '';
    for (const crumb of allCrumbs) {
      path = this.join(path, crumb.routerLink);
      if (crumb.routerLink === currentCrumb.routerLink) {
        break;
      }
    }
    return path;
  }

  /**
   * From: https://gist.github.com/creationix/7435851
   */
  private join(...args: string[]) {
    let parts = [];
    for (let i = 0, l = args.length; i < l; i++) {
      parts = parts.concat(args[i].split('/'));
    }
    const newParts = [];
    for (let i = 0, l = parts.length; i < l; i++) {
      const part = parts[i];
      if (!part || part === '.') continue;
      if (part === '..') newParts.pop();
      else newParts.push(part);
    }
    if (parts[0] === '') newParts.unshift('');
    return newParts.join('/') || (newParts.length ? '/' : '.');
  }
}
