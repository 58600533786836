import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyNumericalStrings'
})
export class OnlyNumericalStringsPipe implements PipeTransform {
  transform(values: string[]): string[] {
    return values?.filter(value => !Number.isNaN(parseInt(value, 10)));
  }
}
