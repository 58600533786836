import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userOptionFormat'
})
export class UserOptionFormatPipe implements PipeTransform {
  transform(user: any, showDepartment = false): string {
    if (showDepartment) {
      return `${user.department2.shortName} - ${user.user2.id} - ${user.user2.firstName} ${user.user2.lastName}`;
    }
    return `${user.id} - ${user.firstName} ${user.lastName}`;
  }
}
