import { createSelector } from '@ngrx/store';
import { MetaState, META_INITIAL_STATE, selectMeta } from '../meta/store/meta.state';
import { DataState, DATA_INITIAL_STATE, selectData } from '../shared/store/data/data.state';
import { MetadataState, METADATA_INITIAL_STATE, selectMetadata } from '../shared/store/metadata/metadata.state';
import { selectUser, UserState, USER_INITIAL_STATE } from '../shared/store/user/user.state';

export interface AppState {
  data: DataState;
  user: UserState;
  metadata: MetadataState;
  meta: MetaState;
  router: any;
}

export const APP_INITIAL_STATE: AppState = {
  data: DATA_INITIAL_STATE,
  user: USER_INITIAL_STATE,
  metadata: METADATA_INITIAL_STATE,
  meta: META_INITIAL_STATE,
  router: null
};

export const isLoading = createSelector(
  selectUser,
  selectMetadata,
  selectMeta,
  selectData,
  (user: UserState, metadata: MetadataState, meta: MetaState, data: DataState) =>
    user.loading || metadata.loading || meta.loading || data.loading
);
