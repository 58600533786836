import { AfterViewInit, Component, Injector, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';
import { HeaderTitleComponent } from '../../../shared/components/header/header-title/header-title.component';
import { PropertyEditEvent } from '../../../shared/components/rims-property-edit-menu/property-edit-menu.component';
import { RimsTableComponent } from '../../../shared/components/rims-table/rims-table.component';
import { ChangeTrackedEntity } from '../../../shared/models/utils/change-tracked-entity.model';
import { AppEntity } from '../../../shared/models/utils/entity.model';
import { View } from '../../../shared/models/view/view.model';
import { getOne, updateData } from '../../../shared/store/data/data.actions';
import { DataStateEntry } from '../../../shared/store/data/data.state';
import { getMapping } from '../../../shared/utils/entity-utils';
import { getProperty } from '../../../shared/utils/object-utils';
import { AppState, isLoading } from '../../../store/store.state';
import { DetailResolverResult } from '../../models/detail-resolver-result';

interface UpdateEvent {
  fieldName: string;
  value: string | number;
}

export interface DetailViewComponentOptions<T> {
  /**
   * A function which returns the page title, given the current result object.
   *
   * If this function is not provided, the `id` property will be used.
   */
  pageTitleFunc?: (res: T) => string;
  /**
   * For entities that do not have an `id` proeprty,
   * you can set this to a custom column name.
   *
   * @example `Item` -> `itemNumber`
   */
  primaryKeyProperty?: string;
}

@Component({
  template: ''
})
export abstract class DetailViewComponent<T extends ChangeTrackedEntity<number | string>>
  implements AfterViewInit, OnDestroy
{
  getProp = getProperty;
  getMapping = getMapping;

  resolveResult: Observable<DetailResolverResult>;
  loading: Observable<boolean>;
  result: Observable<T>;
  entity: Observable<AppEntity>;
  view: Observable<View>;
  destroy$ = new Subject<boolean>();

  storeData: Observable<Record<string, DataStateEntry>>;

  private readonly valueChanges = new Subject<UpdateEvent>();
  protected options = new BehaviorSubject<DetailViewComponentOptions<T>>({});

  private readonly idProperty = () => this.options.value?.primaryKeyProperty || 'id';

  @ViewChildren(RimsTableComponent) tableRefs: QueryList<RimsTableComponent>;
  @ViewChild(HeaderTitleComponent) headerTitle: HeaderTitleComponent;

  protected readonly store: Store<AppState>;
  protected readonly router: Router;
  protected readonly route: ActivatedRoute;
  protected readonly breadcrumb: BreadcrumbService;
  protected readonly title: Title;

  constructor(injector: Injector) {
    this.store = injector.get(Store);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.breadcrumb = injector.get(BreadcrumbService);
    this.title = injector.get(Title);

    this.storeData = this.store.select(state => state.data.entries);
    this.resolveResult = this.route.data.pipe(map(data => data.result));
    this.result = this.resolveResult.pipe(
      switchMap(res => this.store.select<T>(state => state.data.entries[res.dataKey].page.results[0])),
      takeUntil(this.destroy$)
    );
    this.entity = this.resolveResult.pipe(
      switchMap(res => this.store.select<AppEntity>(state => state.metadata.entities[res.entityId])),
      takeUntil(this.destroy$)
    );
    this.view = this.entity.pipe(
      switchMap(entity =>
        this.store.select<View>(state => {
          return Object.keys(state.metadata.views)
            .map(k => state.metadata.views[k])
            .find(view => {
              if (typeof view.entity === 'number') {
                return view.entity === entity.id;
              } else {
                return view.entity.id === entity.id;
              }
            });
        })
      ),
      takeUntil(this.destroy$)
    );
    this.loading = this.store.select(isLoading);
    combineLatest([
      this.entity,
      this.store.select<Record<string, View>>(state => state.metadata.views),
      this.result,
      this.options
    ])
      .pipe(
        map(([entity, views, result, options]) => {
          const viewArr = Object.keys(views).map(key => views[key]);
          const view = viewArr.find(view => {
            if (typeof view.entity === 'number') {
              return view.entity === entity.id;
            } else {
              return view.entity.id === entity.id;
            }
          });
          return [view, result, options];
        }),
        tap(([view, result, options]) =>
          this.setMetaData(result as T, view as View, options as DetailViewComponentOptions<T>)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.startValueChangesSubscription();
  }

  ngAfterViewInit(): void {
    const title = this.headerTitle.getTitle();
    this.title.setTitle(title);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * This function is called when a new relation is selected.
   */
  onRelationValueChange(
    value: any,
    field: string,
    entityName?: string,
    recordId?: number,
    useBaseEntityRecordIdForUpdate?: boolean
  ) {
    this.result
      .pipe(
        take(1),
        map(res => res.id),
        withLatestFrom(this.view, this.resolveResult),
        tap(([id, view, resolveResult]) => {
          this.store.dispatch(
            updateData({
              viewId: view.id,
              entityName,
              id: (recordId || id) + '',
              value: {
                [field]: value
              },
              expand: resolveResult.expand,
              afterSuccessActions:
                entityName && recordId
                  ? [
                      getOne({
                        entityId: resolveResult.entityId,
                        recordId: useBaseEntityRecordIdForUpdate ? id : recordId || id,
                        expand: resolveResult.expand
                      })
                    ]
                  : undefined,
              skipDefaultAfterSuccessAction: !!entityName && !!recordId
            })
          );
        })
      )
      .subscribe()
      .unsubscribe();
  }

  /**
   * Triggers a new data change event which is then handled
   * by `valueChangesSub`.
   *
   * This only affects direct properties of the record (e.g. the
   * ones without a `.` in the field name).
   *
   * @param changes change event from input blur
   */
  onStateChange(changes: PropertyEditEvent) {
    const fieldName = Object.keys(changes.value)[0];
    const explicitEntityNameSet = changes.entityName !== (changes.element as AppEntity).name;
    if (explicitEntityNameSet) {
      this.onRelationValueChange(
        changes.value[fieldName],
        fieldName.split('.')[changes.entityName ? 1 : 0],
        changes.entityName,
        changes.recordId,
        changes.useBaseEntityRecordIdForUpdate
      );
    } else if (!fieldName.includes('.')) {
      this.valueChanges.next({
        fieldName,
        value: changes.value[fieldName]
      });
    } else {
      this.onRelationValueChange(
        changes.value[fieldName],
        fieldName.split('.')[changes.entityName ? 0 : 1],
        changes.entityName,
        changes.recordId
      );
    }
  }

  /**
   * Handles changes emitted by the input fields.
   */
  private startValueChangesSubscription() {
    this.valueChanges
      .pipe(
        withLatestFrom(this.result, this.entity, this.resolveResult),
        tap(([event, result, entity, resolveResult]) => {
          this.store.dispatch(
            updateData({
              entityId: entity.id,
              id: result[this.idProperty()].toString(),
              value: {
                [event.fieldName]: event.value
              },
              expand: resolveResult.expand
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setMetaData(result: T, view: View, options: DetailViewComponentOptions<T>) {
    if (!result) return;
    const id = result[this.idProperty()];
    let breadcrumbTitle = `${id || ''}`;

    if (options.pageTitleFunc) {
      breadcrumbTitle = options.pageTitleFunc(result);
    }

    this.breadcrumb.set([
      {
        routerLink: view.route,
        label: view.displayName
      },
      {
        routerLink: id + '',
        label: breadcrumbTitle
      }
    ]);
  }
}
