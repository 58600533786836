import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken('app.config');

export interface AppConfig {
  storageKeys: {
    REDIRECT_PATH: string;
    ACCESS_TOKEN: string;
    RIMS_TABLE_SHOW_DEBUG_INFO: string;
  };
  eventNames: {
    RIMS_TABLE_CLEAR_SELECTION: string;
  };
  defaultLoaderCount: number;
  defaultPageIndex: number;
  pageSizeOptions: number[];
  roles: {
    systemAdministratorRoleName: string;
  };
  /**
   * This mapping can be used to override the URL for entities
   * with special names. By default, the url equals the entity name,
   * but in some cases, we did not follow this convention.
   */
  entityNameMapping: (entityName: string) => string;
  maintenanceMode: {
    route: string;
  };
  /**
   * A global setting to enable or disable the `highlightIfNull`
   * property of `<rims-field>`s.
   */
  shouldShowBorderForNullFields: boolean;
  /**
   * The maximum allowed int32 value in postgres
   */
  maxIntegerValue: number;
}

export const appConfig: AppConfig = {
  storageKeys: {
    // TODO: Make sure this is in sync with property window.__RIMS__.REDIRECT_STORAGE_KEY (see index.html)
    REDIRECT_PATH: 'rims_redirect_path',
    ACCESS_TOKEN: 'access_token',
    RIMS_TABLE_SHOW_DEBUG_INFO: 'rims_table_show_debug_info'
  },
  eventNames: {
    RIMS_TABLE_CLEAR_SELECTION: 'rims_table_clear_selection'
  },
  defaultLoaderCount: 5,
  defaultPageIndex: 0,
  pageSizeOptions: [10, 20, 50, 100, 200],
  roles: {
    systemAdministratorRoleName: 'System Administrator'
  },
  entityNameMapping: name => {
    return name.replace('app_', '').replace(/_/g, '');
  },
  maintenanceMode: {
    route: 'maintenance-mode'
  },
  shouldShowBorderForNullFields: false,
  maxIntegerValue: 2147483647
};
