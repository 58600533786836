import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {
  /**
   * Transform a boolean value into a string.
   * Non-boolean values are left untouched.
   */
  transform(value: any, trueLabel = 'Yes', falseLabel = 'No', undefinedLabel = 'Not defined'): string {
    if (typeof value === 'undefined' || value === null) {
      return undefinedLabel;
    }
    if (typeof value !== 'boolean') {
      return value;
    }

    return value ? trueLabel : falseLabel;
  }
}
