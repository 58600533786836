import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationTier } from '@rims/lib';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';

interface IdentityClaims {
  /**
   * The 422-username in case of B. Braun employee
   */
  mailnickname: string;
  sub: string;
  aud: string;
  jti: string;
  iss: string;
  iat: number;
  exp: number;
  /**
   * LDAP groups.
   *
   * @example
   * CN=LDE08_Citrix_Office_2013_Access,OU=CITRIX,OU=Cr…ps,OU=DE08,OU=Locations,DC=bbmag,DC=bbraun,DC=com
   */
  groups: string[];
  orgid: string;
  'pi.sri': string;
  nonce: string;
  at_hash: string;
}

@Injectable()
export class AuthService {
  hasLoggedIn: Subject<boolean> = new Subject();

  constructor(private readonly oAuthService: OAuthService, private readonly route: ActivatedRoute) {}

  logout() {
    this.oAuthService.logOut();
    this.hasLoggedIn.next(false);
  }

  get userId(): string {
    if (environment.tier === ApplicationTier.E2E) {
      return environment.userId;
    }
    const claims = this.oAuthService.getIdentityClaims() as IdentityClaims;
    if (!claims) {
      return null;
    }
    return claims.mailnickname.toLowerCase();
  }

  login() {
    if (environment.tier === ApplicationTier.E2E) {
      // use setTimeout so that the subscriber of 'hasLoggedIn' has enough time to get registered before the first 'next' call
      return setTimeout(() => {
        return this.hasLoggedIn.next(true);
      });
    }

    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.setupAutomaticSilentRefresh(undefined, 'access_token');
    this.oAuthService.loadDiscoveryDocumentAndLogin().then(loggedIn => this.hasLoggedIn.next(loggedIn));
  }
}
