import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { capitalize } from '@rims/lib';
import * as pluralize from 'pluralize';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../../store/store.state';
import { AddDialogComponent } from '../../components/add-dialog/add-dialog.component';
import { RemoveDialogComponent } from '../../components/remove-dialog/remove-dialog.component';
import { DataService } from '../../services/data/data.service';
import { getEntity, getEntityUrlAndView } from '../../utils/entity-utils';
import { getData, GetDataPayload } from '../data/data.actions';
import { clearCheckboxColor, removeSelection, setCheckboxColor } from '../metadata/metadata.actions';
import {
  closeAddDialog,
  closeRemoveDialog,
  CloseRemoveDialogPayload,
  noop,
  openAddDialog,
  OpenAddDialogPayload,
  openRemoveDialog,
  OpenRemoveDialogPayload,
  reloadAfterAdd,
  reloadAfterClose
} from './shared.actions';

@Injectable()
export class SharedEffects {
  constructor(
    private readonly actions: Actions,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly dataService: DataService
  ) {}

  openRemoveDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openRemoveDialog),
      switchMap(payload => {
        const dialogRef = this.dialog.open<RemoveDialogComponent, OpenRemoveDialogPayload>(RemoveDialogComponent, {
          panelClass: 'remove-dialog',
          data: payload
        });
        this.store.dispatch(setCheckboxColor({ checkboxColor: 'warn', viewId: payload.viewId }));
        dialogRef.afterClosed().subscribe(() => {
          this.store.dispatch(clearCheckboxColor());
        });
        return of(noop());
      })
    )
  );

  closeRemoveDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeRemoveDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const entity = getEntity(metadata, payload.key);
        const { url, view } = getEntityUrlAndView(metadata, payload.relationKey ?? payload.key);
        const { key: fromKey, options } = this.getOptionsAndKey(payload.fromName, payload.fromId);
        const params = this.getParams(payload, fromKey);

        return (
          payload.referenceName
            ? this.dataService.delete(entity.id, undefined, params, url, options)
            : this.dataService.bulkDelete(entity.id, payload.entityIds, undefined, undefined, options)
        ).pipe(
          map(() => {
            return reloadAfterClose({
              view,
              url,
              reloadAction: payload.reloadAction,
              entityIds: payload.entityIds
            });
          })
        );
      })
    )
  );

  reloadAfterClose = createEffect(() =>
    this.actions.pipe(
      ofType(reloadAfterClose),
      mergeMap(({ view, url, reloadAction, entityIds }) => {
        return [
          reloadAction
            ? reloadAction()
            : getData(
                GetDataPayload.fromView(
                  {
                    ...view
                  },
                  {
                    url
                  }
                )
              ),
          removeSelection({
            viewId: view.id,
            selection: entityIds
          })
        ];
      })
    )
  );

  openAddDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openAddDialog),
      switchMap(payload => {
        this.dialog.open<AddDialogComponent, OpenAddDialogPayload>(AddDialogComponent, {
          minWidth: '60%',
          maxWidth: '100%',
          data: payload
        });
        return of(noop());
      })
    )
  );

  closeAddDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeAddDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, payload.relationKey ?? payload.key);

        const createPayload = {} as any;

        // only relevant for 'product_group_responsibility'
        if (payload.department) {
          createPayload['department'] = payload.department;
        }

        const { key: toKey, options } = this.getOptionsAndKey(payload.toName, payload.toId);
        createPayload[toKey] = payload.toId;

        const referenceKey = this.getReferenceKey(payload.referenceIds, payload.referenceName, payload.toName);
        if (referenceKey) {
          createPayload[referenceKey] = payload.referenceIds;
        }

        const records = payload.entityIds.map(entityId => {
          const record = { ...createPayload, [payload.entityName]: entityId };

          // only relevant for 'product_group'item'
          if (payload.mismatch && payload.mismatch.includes(entityId)) {
            record['mismatch'] = true;
          }

          return record;
        });

        let body = null;

        // only relevant for 'product_group'item'
        if (payload.mismatch && payload.budiInfo) {
          const { active, isSterile, administeringMedicine, implantable, measuringFunction, reusable } =
            payload.budiInfo;

          const budiInfo = {
            active,
            isSterile,
            administeringMedicine,
            implantable,
            measuringFunction,
            reusable
          };

          body = { budiInfo };
        }

        return this.dataService.create(`${url}`, records, options, body).pipe(
          map(() =>
            reloadAfterAdd({
              view,
              url,
              reloadAction: payload.reloadAction,
              reloadActionPayload: payload.reloadActionPayload
            })
          )
        );
      })
    )
  );

  reloadAfterAdd = createEffect(() =>
    this.actions.pipe(
      ofType(reloadAfterAdd),
      mergeMap(({ view, url, reloadAction, reloadActionPayload }) => {
        const actions = [
          getData(
            GetDataPayload.fromView(
              {
                ...view
              },
              {
                url
              }
            )
          )
        ];

        reloadAction && actions.push(reloadAction(reloadActionPayload));

        return actions;
      })
    )
  );

  /**
   * returns the params needed for the delete operation(s), including
   *  - the instance we remove from (e.g. product group, here 'fromKey')
   *  - the ids of the entities we want to remove (e.g. production sites, here 'entityKey')
   *  - the ids of the selected entities in case we only want to remove from those (e.g. items within a product group, here 'referenceKey')
   */
  private getParams(payload: CloseRemoveDialogPayload, fromKey: string) {
    if (!payload.referenceName || !payload.fromId || !fromKey) return;

    const entityKey = pluralize(payload.entityName).toLowerCase();
    let params = new HttpParams({
      fromObject: {
        [fromKey]: payload.fromId + '',
        [entityKey]: payload.entityIds.join(',')
      }
    });

    const referenceKey = this.getReferenceKey(payload.referenceIds, payload.referenceName, payload.fromName);
    if (referenceKey) {
      params = params.append(referenceKey, payload.referenceIds.join(','));
    }

    return params;
  }

  /**
   * returns the 'key' for the selected entity from which we remove or to which we add, @example 'selectedProductGroupContainers'
   */
  private getReferenceKey(referenceIds: number[], refName: string, fromToName: string) {
    if (!Array.isArray(referenceIds) || referenceIds.length < 1) return;

    // e.g. 'Product Group Items'
    const referenceName = capitalize(`${fromToName} ${pluralize(refName)}`);
    // e.g. 'selectedProductGroupItems'
    const referenceKey = `selected${referenceName.replace(/\s/g, '')}`;

    return referenceKey;
  }

  /**
   * returns
   *  - options with the 'runAsProductOwnerFor' property which will be sent with each delete or post request
   *  - the key of the instance we remove from ('fromKey') or the key of the instance we add to ('toKey'), e.g. 'productGroup' or 'item'
   */
  private getOptionsAndKey(name: string, id: string | number) {
    let options;
    let key;

    if (name) {
      const [name1, name2] = name.split(' ');
      key = [name1, capitalize(name2)].join('');
      options = {
        runAsProductOwnerFor: {
          [key]: id
        }
      };
    }

    return {
      options,
      key
    };
  }
}
