import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { AsyncValidatorFn, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatMenu } from '@angular/material/menu';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Filter } from '@rims/lib';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { RequirePermissionDirective } from '../../directives/require-permission.directive';
import { AppField } from '../../models/field/field.model';
import { Page } from '../../models/page/page.model';
import { PropertyEditMenuSelectValuePipe } from '../../pipes/property-edit-menu-select-value.pipe';
import { DataService } from '../../services/data/data.service';
import { createRecord } from '../../store/data/data.actions';
import { getUrl } from '../../utils/entity-utils';
import { getProperty } from '../../utils/object-utils';

export interface PropertyEditEvent<T = any> {
  element: T;
  value: { [key: string]: any };
  entityName?: string;
  recordId?: number;
  /**
   * For a relation field, e.g. "budiInfo2.animalTissueCellsPresence"
   * this means that the GET call to update the data on screen
   * is based not on "product_group_budi_info", but on the
   * underlying "product_group"
   */
  useBaseEntityRecordIdForUpdate: true;
}

/**
 * An event that is emitted if the `createNewRecordAction`
 * option is present on this menu and when the user clicks
 * the action button.
 *
 * The host component is responsible to handle the event and perform
 * the appropriate action (e.g. open a dialog).
 */
export interface CreateNewRecordEvent {
  query: string;
  config: OnMenuOpenData;
}

export interface OnMenuOpenData {
  /**
   * This URL will be passed to the data service to load options for
   * the select form control.
   */
  url: string;
  pageSize?: number;
  loadAll?: boolean;
  expand?: string[];
  useSearch?: boolean;
  validators?: ValidatorFn;
  asyncValidators?: AsyncValidatorFn;

  /**
   * the entity which needs to be checked regarding create permissions
   */
  inPlaceCreatePermissionEntity?: string;
  /**
   * the field name in the payload when creating a new record
   */
  inPlaceCreateFieldName?: string;
  /**
   * If you need to set a special width mode for
   * the dialog, use one of the ones provided here.
   *
   * @see Make sure to keep the`property-edit-menu-*` classes
   * in `styles.scss` in sync with the available modes.
   */
  forceWidth?: 'full-width' | 'mid-width';
  filters?: Filter[];
  /**
   * When opening a dropdown we try to compare the current value with each option object (which each contains several different properties).
   * With @compareProperty we can explicitly define with which object property we try to compare the current value in order to find a match.
   * If a match is found we pre select the dropdown with this value.
   *
   * @example 'role.name' (which then will be compared with a current value of e.g. 'System Author')
   */
  compareProperty?: string;
}

@Component({
  selector: 'rims-property-edit-menu',
  templateUrl: './property-edit-menu.component.html',
  styleUrls: ['./property-edit-menu.component.scss']
})
export class PropertyEditMenuComponent {
  filteredOptions: Observable<any[]>;
  selectOptions: Observable<Page<any>>;
  readonly loading = new BehaviorSubject<boolean>(false);
  readonly error = new BehaviorSubject<string>(null);
  readonly query = new BehaviorSubject<string>(null);
  /**
   * If the `emptyOption` is supplied, this keeps track of the checkbox state.
   */
  readonly storeNullValue = new BehaviorSubject<boolean>(false);
  readonly newValue = new UntypedFormControl(null);
  private optionSelected = new BehaviorSubject<boolean>(false);
  private options: OnMenuOpenData;

  widthClass: string;
  foundOptions = false;
  confirmChecked = false;

  /**
   * feature flag for the adhoc creation of records from within the menu
   * when no matching entries can be found in the search bar (RIMS-1093)
   */
  enableInPlaceCreate = false;

  @ViewChild(MatMenu, { read: MatMenu, static: true })
  menu: MatMenu;

  @ViewChild('inputControl')
  private inputControl: ElementRef<HTMLInputElement>;

  @ViewChild('textAreaControl')
  private textAreaControl: ElementRef<HTMLTextAreaElement>;

  @ViewChild('dateInput')
  private dateInput: ElementRef<HTMLInputElement>;

  @ViewChild('searchInput')
  private searchInput: ElementRef<HTMLInputElement>;

  @ViewChild(RequirePermissionDirective) permissionDirective: RequirePermissionDirective;

  @Output()
  updateProperty = new EventEmitter<PropertyEditEvent | MatSelectChange>();

  @Output()
  createNewRecord = new EventEmitter<CreateNewRecordEvent>();

  constructor(private readonly data: DataService, private readonly store: Store) {}

  onMenuClosed(): void {
    this.widthClass = null;
    this.newValue.reset();
    this.storeNullValue.next(false);
    this.selectOptions = null;
    this.query.next(null);

    if (this.options?.validators) {
      this.newValue.removeValidators(this.options.validators);
    }

    if (this.options?.asyncValidators) {
      this.newValue.removeAsyncValidators(this.options.asyncValidators);
    }
  }

  onMenuOpened(options: OnMenuOpenData) {
    this.options = options;
    this.storeNullValue.next(false);

    if (this.options.url) {
      this.query.next(null);
      this.error.next(null);
      this.optionSelected.next(false);
      this.newValue.setValue(null);

      if (!this.options.useSearch) {
        this.loadStaticOptions();
      } else {
        this.loadSearchOptions();
      }
    }

    if (this.options.forceWidth) {
      this.widthClass = `property-edit-menu-${this.options.forceWidth}`;
    }

    if (this.enableInPlaceCreate && this.options.inPlaceCreatePermissionEntity) {
      this.permissionDirective.rimsRequirePermissionAction = 'create';
      this.permissionDirective.rimsRequirePermission = this.options.inPlaceCreatePermissionEntity;
    }

    if (this.options?.validators) {
      this.newValue.addValidators(this.options.validators);
      this.newValue.updateValueAndValidity();
    }

    if (this.options?.asyncValidators) {
      this.newValue.addAsyncValidators(this.options.asyncValidators);
      this.newValue.updateValueAndValidity();
    }
  }

  getValueWithProperty(field: AppField, shouldAcceptNullValues = false, optionValueExpression = null) {
    let value = this.newValue.value;
    if (typeof value === 'undefined' && shouldAcceptNullValues) {
      value = null;
    }
    return {
      [field.field]: optionValueExpression ? optionValueExpression(value) : value
    };
  }

  getOptionText(selectFieldName = null, selectExpression = null, option) {
    return PropertyEditMenuSelectValuePipe.transform(option, selectFieldName, selectExpression);
  }

  /**
   * This must be an arrow function in order for 'this' to be accessible
   */
  compareOptionsWithSelected = (option: any, current: any): boolean => {
    if (!this.options.compareProperty) {
      if (option.id && current?.id) {
        return option.id === current?.id;
      } else if (option.name && current?.name) {
        return option.name === current?.name;
      }
      return option.id === current || option.name === current;
    }

    return getProperty(this.options.compareProperty, option) === current;
  };

  private loadStaticOptions() {
    this.loading.next(true);
    this.selectOptions = this.data
      .getAll(
        this.options.url,
        this.options.expand,
        undefined,
        this.options.loadAll ? Number.MAX_SAFE_INTEGER : this.options.pageSize,
        undefined,
        undefined,
        this.options.filters
      )
      .pipe(tap(() => this.loading.next(false)));
  }

  private loadSearchOptions() {
    this.filteredOptions = this.query.pipe(
      filter(query => !!query),
      tap(() => {
        this.newValue.setValue(null);
        this.optionSelected.next(false);
        this.loading.next(true);
      }),
      debounceTime(500),
      switchMap(query => {
        return this.data
          .getAll(this.options.url, this.options.expand, 0, 20, undefined, undefined, undefined, query)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status === 501) {
                this.error.next(`Search is deactivated for /${this.options.url}. Please talk to your admin.`);
              } else {
                this.error.next(`The search failed: ${err.statusText}. Please talk to your admin.`);
              }
              return of(null);
            }),
            map(response => response?.results),
            tap(results => {
              this.foundOptions = results.length > 0;
              this.loading.next(false);
            })
          );
      })
    );
  }

  onOptionSelect(event: MatAutocompleteSelectedEvent) {
    this.optionSelected.next(true);
    this.newValue.setValue(event.option.value);
  }

  onStoreNullValueChange(event: MatCheckboxChange) {
    this.storeNullValue.next(event.checked);
    this.query.next(null);
    this.newValue.setValue(null);
    this.newValue.markAsTouched();
    if (this.inputControl?.nativeElement) {
      this.inputControl.nativeElement.value = null;
    }
    if (this.textAreaControl?.nativeElement) {
      this.textAreaControl.nativeElement.value = null;
    }
    if (this.dateInput?.nativeElement) {
      this.dateInput.nativeElement.value = null;
    }
    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.value = null;
    }
  }

  onCheckConfirm(event: MatCheckboxChange) {
    this.confirmChecked = event.checked;
  }

  updateForm() {
    this.newValue.markAsTouched();
    this.confirmChecked = false;
  }

  dispatchCreateNewRecordEvent() {
    this.store.dispatch(
      createRecord({
        url: getUrl(this.options.url),
        payload: { [this.options.inPlaceCreateFieldName]: this.query.value }
      })
    );

    this.query.next(this.query.value);
  }

  hasCreatePermission() {
    return this.permissionDirective.permission;
  }

  onDateChange(event: MatDatepickerInputEvent<moment.Moment>) {
    const date = event.value.format('YYYY-MM-DD');
    this.newValue.setValue(date);
  }

  dateIsEmpty() {
    return !this.dateInput?.nativeElement?.value;
  }
}
