import { Pipe, PipeTransform } from '@angular/core';
import { AppEntity } from '../models/utils/entity.model';
import { View } from '../models/view/view.model';

export function viewToDataKey(view: View): string {
  return (view.entity as AppEntity).name;
}

@Pipe({
  name: 'viewToDataKey'
})
export class ViewToDataKeyPipe implements PipeTransform {
  transform(view: View) {
    return viewToDataKey(view);
  }
}
