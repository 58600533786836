<button mat-icon-button [matMenuTriggerFor]="viewMenu" [attr.e2e-id]="'view-menu-trigger'">
  <mat-icon>settings</mat-icon>
</button>

<mat-menu #viewMenu="matMenu" class="view-menu">
  <ng-container *ngIf="loggedInUser | async as user">
    <a mat-menu-item class="unstyled user" [routerLink]="'/view/users/' + user.id">
      <span>{{ user.firstName }} {{ user.lastName }}</span>
      <br />
      <small [ngSwitch]="user.role.name">
        <ng-container *ngSwitchCase="'Default'">You have the default role</ng-container>
        <ng-container *ngSwitchDefault>{{ user.role.name }}</ng-container>
      </small>
    </a>
    <mat-divider></mat-divider>
  </ng-container>
  <a
    mat-menu-item
    class="unstyled d-flex align-items-center justify-content-between"
    target="_blank"
    rel="noopener nofollow"
    href="https://bbraunp.service-now.com/serviceportal?id=sc_cat_item&sys_id=47d734ef795e24002ab1e1095445005e"
    [attr.e2e-id]="'view-menu-feedback-dialog-trigger'"
  >
    <span style="padding-right: 2rem">Report an Issue</span>
    <mat-icon class="mr-0 material-icons-outlined">bug_report_outlined</mat-icon>
  </a>
  <a mat-menu-item class="unstyled d-flex align-items-center justify-content-between" routerLink="/docs" [attr.e2e-id]="'view-menu-docs-link'">
    <span style="padding-right: 2rem">Documentation &amp; Help</span>
    <mat-icon class="mr-0">help_outlined</mat-icon>
  </a>
  <mat-divider></mat-divider>
  <button mat-menu-item *ngFor="let menu of menusArr" [matMenuTriggerFor]="menu">
    {{ (viewGroups | async | propertyAccess : menu?._elementRef?.nativeElement?.getAttribute('group-id'))?.name }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="restMenu" *ngIf="(views | async | filterViews : null : true)?.length > 0">More Views</button>
</mat-menu>

<mat-menu *ngFor="let group of viewGroups | async | keyvalue | filterViewGroups : (views | async)" #menu="matMenu" [attr.group-id]="group?.value?.id">
  <a *ngFor="let view of views | async | filterViews : group.value | orderBy : 'asc' : 'displayName'" mat-menu-item class="unstyled" [routerLink]="[view?.route]">
    {{ view?.displayName }}
  </a>
</mat-menu>

<mat-menu #restMenu="matMenu">
  <a *ngFor="let view of views | async | filterViews : null : true | orderBy : 'asc' : 'displayName'" mat-menu-item class="unstyled" [routerLink]="[view?.route]">
    {{ view?.displayName }}
  </a>
</mat-menu>
