import { AsyncValidatorFn } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { ProductGroupBudiInfo, SystemProcedurePackType } from '@rims/database';
import { View } from '../../models/view/view.model';

const PREFIX = '[Shared]';
export interface OpenRemoveDialogPayload {
  /**
   * Needed to apply the checkbox color to the correct table
   */
  viewId: number;

  /**
   * In some cases we want to trigger several get requests (via a specific action) instead of just the one for the view
   */
  reloadAction?: any;

  /**
   * The name of the selected entity, @example 'item', 'company', 'nomenclature', 'container', etc.
   */
  entityName: string;

  /**
   * In case we want to display an alternative name for the entity, @example 'production site' for the entity 'company'
   */
  displayName?: string;

  /**
   * The ids of the selected entities, e.g. the ids of the selected nomenclatures
   */
  entityIds: number[];

  /**
   * The name of the instance we remove from, @example 'product group'
   */
  fromName?: string;

  /**
   * The id of the instance we remove from, e.g. a productGroupId
   */
  fromId?: number;

  /**
   * The data key of the entity, @example 'item_production_site'
   */
  key: string;

  /**
   * The data key of the relation, @example 'product_group_item_production_site'
   */
  relationKey?: string;

  /**
   * In some constellations we need to ensure that the identifier is not ambiguous.
   * @example 'document2.id' instead of 'id'
   */
  dataId?: string;

  /**
   * The name of the referenced entities, @example 'item' or 'container'
   */
  referenceName?: string;

  /**
   * The ids of the referenced entities, e.g. the ids of containers from which we want to remove the selected production sites
   */
  referenceIds?: number[];

  /**
   * The key of the relation. Most of the time this is just the 'entityName2', e.g. 'container2' for the entityName 'container'.
   * Sometimes entityName and relation differ, that's why we can specifiy it here explicitly.
   * @example entityName: 'productgroup', relation: 'child2'
   */
  relation?: string;

  /**
   * Sometimes all necessary data is already present and we don't need to access a relation
   */
  noRelation?: boolean;

  /**
   * The name of the to-be-performed action. Default is 'remove'.
   */
  actionTxt?: string;

  /**
   * Sometimes the underlying pipe of the dynamic pipe needs to behave differently based on the situation,
   * e.g. we display data of product group items differently depending on whether we try to add or remove items.
   * This can be configured with the dynamic pipes 'defaultValue' argument
   */
  pipeDefaultValue?: any;

  /**
   * The user might need to confirm additional side effects that come with this change
   */
  additionalConfirm?: string;
}

export interface CloseRemoveDialogPayload {
  /**
   * The id of the instance we remove from, e.g. a productGroupId
   */
  fromId?: number;

  /**
   * The ids of the selected entities, e.g. the ids of the selected nomenclatures
   */
  entityIds: number[];

  /**
   * The name of the instance we remove from, @example 'product group'
   */
  fromName?: string;

  /**
   * The name of the referenced entities, @example 'item' or 'container'
   */
  referenceName?: string;

  /**
   * If provided, the entities will only be removed from these containers/items/etc.
   */
  referenceIds?: number[];

  /**
   * The data key of the entity, @example 'product_group_item'
   */
  key: string;

  /**
   * The data key of the relation, @example 'product_group_item_production_site'
   */
  relationKey?: string;

  /**
   * The name of the selected entity, @example 'item', 'company', 'nomenclature', 'container', etc.
   */
  entityName?: string;

  /**
   * In some cases we want to trigger several get requests (via a specific action) instead of just the one for the view
   */
  reloadAction?: any;
}

export interface ReloadAfterClosePayload {
  view: View;
  url: string;

  /**
   * In some cases we want to trigger several get requests (via a specific action) instead of just the one for the view
   */
  reloadAction?: any;

  /**
   * The ids of the selected entities, e.g. the ids of the selected nomenclatures
   */
  entityIds: number[];
}

export interface ReloadAfterAddPayload {
  view: View;
  url: string;

  /**
   * In some cases we want to trigger several get requests (via a specific action) instead of just the one for the view
   */
  reloadAction?: any;
  reloadActionPayload?: any;
}

export interface OpenAddDialogPayload {
  /**
   * The name of the selected entity, @example 'item', 'company', 'nomenclature', 'container', etc.
   */
  entityName: string;

  /**
   * In case we want to display an alternative name for the entity, @example 'production site' for the entity 'company'
   */
  displayName?: string;

  /**
   * The name of the instance we add to, @example 'product group'
   */
  toName: string;

  /**
   * The id of the instance we add to, e.g. a productGroupId
   */
  toId: number;

  /**
   * The data key of the entity, @example 'item_production_site'
   */
  key: string;

  /**
   * The data key of the relation, @example 'product_group_item_production_site'
   */
  relationKey?: string;

  /**
   * The name of the referenced entities, @example 'item' or 'container'
   */
  referenceName?: string;

  /**
   * The ids of the referenced entities, e.g. the ids of containers from which we want to remove the selected production sites
   */
  referenceIds?: number[];

  /**
   * The key of the relation. Most of the time this is just the 'entityName2', e.g. 'container2' for the entityName 'container'.
   * Sometimes entityName and relation differ, that's why we can specifiy it here explicitly.
   * @example entityName: 'productgroup', relation: 'child2'
   */
  relation?: string;

  /**
   * Sometimes all necessary data is already present and we don't need to access a relation
   */
  noRelation?: boolean;

  /**
   * Sometimes the underlying pipe of the dynamic pipe needs to behave differently based on the situation,
   * e.g. we display data of product group items differently depending on whether we try to add or remove items.
   * This can be configured with the dynamic pipes 'defaultValue' argument
   */
  pipeDefaultValue?: any;

  /**
   * The user might need to confirm additional side effects that come with this change
   */
  additionalConfirm?: string;

  closeAction?: any;

  /**
   * By default the label for the rims-autocomplete is "Search by <displayName>/<entityName>"
   * But it can be overwritten, @example 'Search by Company Name or Address'.
   */
  searchLabel?: string;

  /**
   * By default the rims-autocomplete does not display any placeholder value.
   * Defines a placeholder, @example 'Search by Term or Number'.
   */
  placeholder?: string;

  /**
   * Specifies an expand value for the rims-autocomplete get request, @example 'address.country'
   */
  expand?: string[];

  /**
   * Add async validators to the rims-autocomplete
   */
  asyncValidators?: AsyncValidatorFn[];

  /**
   * In some cases we want to trigger several get requests (via a specific action) instead of just the one for the view
   */
  reloadAction?: any;

  reloadActionPayload?: any;

  /**
   * overwrites the notFoundHint of the rims-autocomplete
   */
  notFoundHint?: string;

  /**
   * overwrites the duplicateHint of the rims-autocomplete
   */
  duplicateHint?: string;

  /**
   * overwrites the emptyHint of the rims-autocomplete
   */
  emptyHint?: string;

  /**
   * The target property name of the selected option whose value will be used for the SELECTED_VALUE form control
   */
  target?: string;

  /**
   * Alternative for when the target property for the /search request differs (currently the case for child groups)
   */
  searchTarget?: string;

  /**
   * Set to true to enable the confirmation-checkbox which is disabled by default.
   */
  confirm?: boolean;

  /**
   * Adds a dropdown to the dialog. The search query is disabled when no element in the dropdown is selected.
   * This value is also used to query the DB for all occurrences, @example 'nomenclature types' will make a call to '/nomenclaturetypes' for the underyling dropdown.
   */
  dropdown?: string;

  /**
   * This is a special case. Before adding product group children we need to know which child group types are allowed.
   */
  allowedChildGroupTypeIds?: number[];

  /**
   * This is a special case. For 'product_group_actor' we need to know which system procedure pack is currently defined, so that we can set the appropriate filters for the rims-autocomplete.
   * @example If the sppt is applicable we filter out any actors of type manufacturer
   * @example if sppt is not applicable we filter out all actors of type System/ProcedurePack
   */
  sppt?: SystemProcedurePackType;

  /**
   * Whether the instance we add to is a either a BUDI or EBUDI
   */
  isABudi?: boolean;

  /**
   * Whether the instance we add to is an EBUDI specifically
   */
  isEbudi?: boolean;

  /**
   * Set true to enable adding multiple entities at once
   */
  multiple?: boolean;

  /**
   * By default we use the same pipe for the chips representing each entity as the pipe for the options of the dropdown.
   * We can disable this behavior and only display the raw selected value by setting this flag to false
   */
  pipeMultiple?: boolean;

  /**
   * Set true to enable pasting multiple entities at once
   */
  pasteMultiple?: boolean;

  /**
   * Needed for the validation of product_group_item
   */
  budiInfo?: ProductGroupBudiInfo;
}

export interface CloseAddDialogPayload {
  /**
   * The id of the instance we remove from, e.g. a productGroupId
   */
  toId: number | string;

  /**
   * The ids of the selected entities, e.g. the ids of the selected nomenclatures
   */
  entityIds: number[] | string[];

  /**
   * The name of the instance we add to, @example 'product group'
   */
  toName: string;

  /**
   * The name of the referenced entities, @example 'item' or 'container'
   */
  referenceName?: string;

  /**
   * If provided, the entities will only be removed from these containers/items/etc.
   */
  referenceIds?: number[];

  /**
   * The data key of the entity, @example 'product_group_item'
   */
  key: string;

  /**
   * The data key of the relation, @example 'product_group_item_production_site'
   */
  relationKey?: string;

  /**
   * The name of the selected entity, @example 'item', 'company', 'nomenclature', 'container', etc.
   */
  entityName: string;

  /**
   * In some cases we want to trigger several get requests (via a specific action) instead of just the one for the view
   */
  reloadAction?: any;

  reloadActionPayload?: any;

  /**
   * When creating a 'product_group_responsibility' we need to pass the selected department from the dropdown.
   * This is currently the only occasion where we use the dropdowns' value in the Post Request.
   */
  department?: number;

  /**
   * When creating a 'product_group_item' the properties of the item need to match with the ones of the BUDI.
   * 'mismatch' contains the itemNumbers of items that have different properties.
   * These properties need to be overwritten with the ones of the BUDI before creating the 'product_group_item'
   */
  mismatch?: string[];

  /**
   * Needed for product_group_item
   */
  budiInfo?: ProductGroupBudiInfo;
}

export const noop = createAction(`${PREFIX} Noop`);

export const openRemoveDialog = createAction(`${PREFIX} Open Remove Dialog`, props<OpenRemoveDialogPayload>());
export const closeRemoveDialog = createAction(`${PREFIX} Close Remove Dialog`, props<CloseRemoveDialogPayload>());
export const reloadAfterClose = createAction(`${PREFIX} Reload After Close`, props<ReloadAfterClosePayload>());

export const openAddDialog = createAction(`${PREFIX} Open Add Dialog`, props<OpenAddDialogPayload>());
export const closeAddDialog = createAction(`${PREFIX} Close Add Dialog`, props<CloseAddDialogPayload>());
export const reloadAfterAdd = createAction(`${PREFIX} Reload After Add`, props<ReloadAfterAddPayload>());
