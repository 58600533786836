import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_REQUEST } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from 'src/app/app.constants';
import { AppState } from '../../../store/store.state';
import { noop } from '../shared/shared.actions';
import { navigateTo, viewAccessNotAllowed, viewNotFound } from './router.actions';

@Injectable()
export class RouterEffects {
  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly location: Location,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig
  ) {}

  navigateTo = createEffect(() =>
    this.actions.pipe(
      ofType(navigateTo),
      map(payload => {
        this.router.navigateByUrl(payload.routerLink);
        return noop();
      })
    )
  );

  onNavigationRequest = createEffect(() =>
    this.actions.pipe(
      ofType(ROUTER_REQUEST),
      map((action: any) => action.payload),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        if (store?.meta?.maintenance?.enabled && !action.event.url.includes(this.config.maintenanceMode.route)) {
          return of(
            navigateTo({
              routerLink: '/' + this.config.maintenanceMode.route
            })
          );
        }
        return of(noop());
      })
    )
  );

  onViewAccessNotAllowed = createEffect(() =>
    this.actions.pipe(
      ofType(viewAccessNotAllowed),
      switchMap(() => {
        this.router.navigate(['home']);
        this.snackBar.open(`❌  You are not allowed to access this view`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  onViewNotFound = createEffect(() =>
    this.actions.pipe(
      ofType(viewNotFound),
      switchMap(props => {
        console.log(`View "${props.view}" could not be found.`);

        this.router.navigate([props.redirectUrl ?? 'home']);
        this.snackBar.open(`❌  The requested view was not found`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });

        return of(noop());
      })
    )
  );
}
