import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AllowLifecycleChangePipe } from './pipes/allow-lifecycle-change.pipe';
import { SapDivisionToRimsDivisionPipe } from './pipes/sap-division-to-rims-division.pipe';
import { SapLifecycleToRimsLifecyclePipe } from './pipes/sap-lifecycle-to-rims-lifecycle.pipe';

@NgModule({
  declarations: [SapDivisionToRimsDivisionPipe, SapLifecycleToRimsLifecyclePipe, AllowLifecycleChangePipe],
  imports: [CommonModule],
  exports: [SapDivisionToRimsDivisionPipe, SapLifecycleToRimsLifecyclePipe, AllowLifecycleChangePipe]
})
export class SapModule {}
