import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Filter, FilterOperator, truncate } from '@rims/lib';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import { AppField } from '../../models/field/field.model';
import { AppEntity } from '../../models/utils/entity.model';
import { FilterOperatorLabelPipe } from '../../pipes/filter-operator-label.pipe';
import {
  openFilterCreationDialog,
  OpenFilterCreationDialogPayload,
  removeFilters
} from '../../store/metadata/metadata.actions';

@Component({
  selector: 'rims-table-filters',
  templateUrl: './rims-table-filters.component.html',
  styleUrls: ['./rims-table-filters.component.scss']
})
export class RimsTableFiltersComponent implements OnInit {
  @Input()
  viewId: number;

  filters: Observable<Filter[]>;
  fields: Observable<Record<string, AppField>>;
  operatorLabelPipe = new FilterOperatorLabelPipe();

  private maxVisibleLength = 90;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit() {
    this.filters = this.store.pipe(
      select(state => state.metadata.views[this.viewId].filters),
      filter(filters => Array.isArray(filters))
    );
    this.fields = this.store.pipe(
      select(state => {
        let entityId = state.metadata.views[this.viewId].entity;
        if (typeof entityId === 'object') {
          entityId = (state.metadata.views[this.viewId].entity as AppEntity).id;
        }
        return state.metadata.entities[entityId].fields;
      })
    );
  }

  add(filterDef: OpenFilterCreationDialogPayload) {
    this.store.dispatch(
      openFilterCreationDialog({
        ...filterDef,
        viewId: this.viewId
      })
    );
  }

  remove(filter: Filter) {
    this.store.dispatch(
      removeFilters({
        viewId: this.viewId,
        filters: [filter]
      })
    );
  }

  /**
   * returns a tooltip when the chip was truncated
   */
  getTooltip(filter: Filter, fields: Record<string, AppField>) {
    const chip = this.generateChipText(filter, fields);
    if (chip.length > this.maxVisibleLength) return chip;
  }

  /**
   * returns the chip text, truncated if it exceeds the max visible length
   */
  getTruncatedChip(filter: Filter, fields: Record<string, AppField>) {
    const chip = this.generateChipText(filter, fields);
    return truncate(chip, this.maxVisibleLength);
  }

  private generateChipText(filter: Filter, fields: Record<string, AppField>) {
    const name = fields?.[filter?.fieldName]?.displayName || filter?.fieldLabel || filter?.fieldName;
    const operator =
      filter?.operator === FilterOperator.IN || filter?.operator === FilterOperator.DATE_RANGE
        ? '='
        : this.operatorLabelPipe.transform(filter?.operator, true);
    const value = filter?.valueLabel || filter?.value;

    return `${name} ${operator} ${value}`;
  }
}
