<mat-toolbar color="primary">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>RIMS</h1>
      </div>
    </div>
  </div>
</mat-toolbar>
<div class="container py-5">
  <div class="row py-5">
    <div class="col py-5">
      <h1 class="color-green">Maintenance Mode</h1>
      <p class="color-grey">The RIMS is currently in maintenance mode. Last update {{ maintenance | async | propertyAccess : 'details.updatedAt' | date : 'long' }}.</p>
      <ng-container [ngSwitch]="!!(maintenance | async | propertyAccess : 'details.raw')">
        <div *ngSwitchCase="true">No further information has been provided.</div>
        <div *ngSwitchDefault [innerHTML]="maintenance | async | propertyAccess : 'details.markdown' | markdown"></div>
      </ng-container>
    </div>
  </div>
</div>
