import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/app/modules/breadcrumb/services/breadcrumb.service';

@Component({
  selector: 'rims-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(public readonly breadcrumb: BreadcrumbService) {}
}
