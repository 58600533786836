import { createAction, props } from '@ngrx/store';
import { Filter } from '@rims/lib';
import { AppField } from '../../models/field/field.model';
import { Page } from '../../models/page/page.model';
import { AppEntity } from '../../models/utils/entity.model';
import { AppViewGroup } from '../../models/view/view-group.model';
import { View } from '../../models/view/view.model';
import { ManagedPipeToken } from '../../pipes/dynamic.pipe';
import { MetadataDraggedColumn } from './metadata.state';

const PREFIX = '[Metadata]';

/**
 *
 * Adds a `prefix` to each `filter`'s displayName
 *
 * @example 'product.name' --> 'item2.product.name'
 */
export function prefixFieldName(
  filters: (FilterMenu | FilterMenuGroup)[],
  prefix: string
): (FilterMenu | FilterMenuGroup)[] {
  return filters.map(filter => {
    if (!filter.isGroup) {
      return {
        ...filter,
        fieldName: `${prefix}${(filter as any).fieldName}`
      };
    } else {
      const prefixedFilters = prefixFieldName(filter.filterDefinitions, prefix) as FilterMenu[];
      return { ...filter, filterDefinitions: prefixedFilters };
    }
  });
}

export interface GetViewConfigPayload {
  viewId: number;
  filters?: Filter[];
  /**
   * You can define a custom URL to be used.
   * If not provided (the default), the url will be
   * found from the associated view.
   *
   * If you provide this, please make sure to also provide
   * the `customUrl` input on `<rims-table>`!
   */
  url?: string;
  /**
   * Can be used to display fixed columns and ignore user preferences.
   *
   */
  columnNames?: string[];

  /**
   * When the browser was refreshed, e.g. there has been no navigation action yet this property will be set to true
   * When true a reloadView action will be triggered after the view has been loaded
   */
  browserRefresh?: boolean;
}

export interface GetViewConfigSuccessPayload {
  view: View;
  filters?: Filter[];
  url?: string;
  browserRefresh?: boolean;
}

export interface UpdateColumnsPayload {
  viewId: number;
}

export interface DropColumnPayload {
  newIndex: number;
}

export interface ResetColumnPreferencesPayload {
  viewId: number;
}

export interface ResetUrlQueryPaginationPayload {
  viewId: number;
}

export interface OpenColumnPreferencesDialogPayload {
  viewId: number;
  fixedColumnIds?: number[];
}

export interface MoveColumnPayload {
  source: 'available' | 'selected';
  target: 'available' | 'selected';
  sourceIndex: number;
  targetIndex: number;
}

export interface StoreSelectionPayload {
  viewId: number;
  /**
   * These items will be added/removed to/from the selection
   * depending on the action.
   */
  selection: (string | number)[];
}

export interface GetFieldsForEntityPayload {
  entityId: number;
}

export interface GetFieldsForEntitySuccessPayload {
  page: Page<AppField>;
  entityId: number;
}

export interface OpenFilterCreationDialogPayload {
  viewId: number;
  fieldName: string;
  value?: any;
  operator: string;
}

export interface FilterConfiguration {
  fieldName: string;
  displayName: string;
  filterType?: string;
  optionUrl?: string;
  optionExpand?: string[];
  optionFilters?: Filter[];
  optionField?: string;
  /**
   * Directly provides dropdown option values instead of fetching them via `optionUrl`.
   * Takes precedence over `optionUrl`
   */
  options?: string[];

  /**
   * Use a pipe to display the option value.
   *
   * @see DynamicPipe
   */
  optionCustomPipe?: {
    token: ManagedPipeToken;
    prePipeProperty?: string;
    debug?: boolean;
  };
}

/**
 * Allows you to define the filter menu items for a rims-table view.
 *
 * In the route's data options, provide
 * a `filterMenus` array of `FilterMenu` objects.
 *
 * @example `/src/app/modules/product-groups/product-groups-routing.module.ts`
 */
export interface FilterMenu {
  isGroup?: false;
  fieldName: string;
  displayName: string;
}

export interface FilterMenuGroup {
  isGroup: true;
  name: string;
  filterDefinitions: FilterMenu[];
}

export interface CloseFilterCreationDialogPayload {
  viewId: number;
  addFilters?: Filter[];
  removeFilters?: Filter[];
  saveFilter?: Filter;
}

export interface AddFiltersPayload {
  viewId: number;
  filters: Filter[];
}

export interface SaveFilterPayload {
  viewId: number;
  filter: Filter;
}
export interface SetFiltersPayload {
  viewId: number;
  filters: Filter[];
}

export interface RemoveFiltersPayload {
  viewId: number;
  filters: Filter[];
}

export interface ResetFiltersPayload {
  viewId: number;
}

export interface RemoveColumnSettingsPayload {
  viewId: number;
}

export interface GetViewForFieldPayload {
  fieldId: number;
}

export interface CheckboxColorPayload {
  viewId: number;
  checkboxColor: string;
}

/**
 * It is generally not necessary to manually trigger this,
 * as it will be done by a network interceptor.
 */
export const startLoading = createAction(`${PREFIX} Start Loading`);

/**
 * It is generally not necessary to manually trigger this,
 * as it will be done by a network interceptor.
 */
export const stopLoading = createAction(`${PREFIX} Stop Loading`);

export const setCheckboxColor = createAction(`${PREFIX} Set Checkbox Color`, props<CheckboxColorPayload>());
export const clearCheckboxColor = createAction(`${PREFIX} Clear Checkbox Color`);

export const getEntities = createAction(`${PREFIX} Get Entities`);
export const getEntitiesSuccess = createAction(`${PREFIX} Get Entities Success`, props<Page<AppEntity>>());
export const getEntitiesError = createAction(`${PREFIX} Get Entities Error`, props<Error | undefined>());

export const getPermissions = createAction(`${PREFIX} Get Permissions`);
export const getPermissionsSuccess = createAction(`${PREFIX} Get Permissions Success`, props<Page<any>>());
export const getPermissionsError = createAction(`${PREFIX} Get Permissions Error`, props<Error | undefined>());

export const getViewGroups = createAction(`${PREFIX} Get View Groups`);
export const getViewGroupsSuccess = createAction(`${PREFIX} Get View Groups Success`, props<Page<AppViewGroup>>());
export const getViewGroupsError = createAction(`${PREFIX} Get View Groups Error`, props<Error | undefined>());

export const getFieldsForEntityCheck = createAction(
  `${PREFIX} Get Fields For Entity Check`,
  props<GetFieldsForEntityPayload>()
);
export const getFieldsForEntity = createAction(`${PREFIX} Get Fields For Entity`, props<GetFieldsForEntityPayload>());
export const getFieldsForEntitySuccess = createAction(
  `${PREFIX} Get Fields For Entity Success`,
  props<GetFieldsForEntitySuccessPayload>()
);
export const getFieldsForEntityError = createAction(
  `${PREFIX} Get Fields For Entity Error`,
  props<Error | undefined>()
);

export const getViewForField = createAction(`${PREFIX} Get View for Field`, props<GetViewForFieldPayload>());
export const getViewForFieldRelation = createAction(
  `${PREFIX} Get View for Field Relation`,
  props<GetViewForFieldPayload>()
);

export const getViews = createAction(`${PREFIX} Get Views`);
export const getViewsSuccess = createAction(`${PREFIX} Get Views Success`, props<Page<View>>());
export const getViewsError = createAction(`${PREFIX} Get Views Error`, props<Error | undefined>());
export const getViewConfig = createAction(`${PREFIX} Get View Config`, props<GetViewConfigPayload>());
export const getViewConfigSuccess = createAction(
  `${PREFIX} Get View Config Success`,
  props<GetViewConfigSuccessPayload>()
);
export const getViewConfigError = createAction(`${PREFIX} Get View Config Error`, props<Error | undefined>());

export const setDraggedColumn = createAction(`${PREFIX} Set Dragged Column`, props<MetadataDraggedColumn>());
export const dropColumn = createAction(`${PREFIX} Drop Column`, props<DropColumnPayload>());
export const clearDraggedColumn = createAction(`${PREFIX} Clear Dragged Column`);
export const updateColumns = createAction(`${PREFIX} Update Columns`, props<UpdateColumnsPayload>());

export const openColumnPreferencesDialog = createAction(
  `${PREFIX} Open Column Preferences Dialog`,
  props<OpenColumnPreferencesDialogPayload>()
);
export const closeColumnPreferencesDialog = createAction(`${PREFIX} Close Column Preferences Dialog`);
export const moveColumn = createAction(`${PREFIX} Move Column`, props<MoveColumnPayload>());
export const saveColumns = createAction(`${PREFIX} Save Columns`);
export const resetColumnPreferences = createAction(
  `${PREFIX} Reset Column Preferences`,
  props<ResetColumnPreferencesPayload>()
);
export const resetColumnPreferencesSuccess = createAction(`${PREFIX} Reset Column Preferences Success`);

export const openFilterCreationDialog = createAction(
  `${PREFIX} Open Filter Creation Dialog`,
  props<OpenFilterCreationDialogPayload>()
);
export const closeFilterCreationDialog = createAction(
  `${PREFIX} Close Filter Creation Dialog`,
  props<CloseFilterCreationDialogPayload>()
);
export const addFilters = createAction(`${PREFIX} Add Filters`, props<AddFiltersPayload>());
export const setFilters = createAction(`${PREFIX} Set Filters`, props<SetFiltersPayload>());
export const setFiltersSuccess = createAction(`${PREFIX} Set Filters Success`, props<SetFiltersPayload>());
export const removeFilters = createAction(`${PREFIX} Remove Filters`, props<RemoveFiltersPayload>());
export const resetFilters = createAction(`${PREFIX} Reset Filters`, props<ResetFiltersPayload>());
export const saveFilter = createAction(`${PREFIX} Save Filter`, props<SaveFilterPayload>());

export const removeColumnSettings = createAction(
  `${PREFIX} Remove Column Settings`,
  props<RemoveColumnSettingsPayload>()
);

export const addSelection = createAction(`${PREFIX} Add Selection`, props<StoreSelectionPayload>());
export const removeSelection = createAction(`${PREFIX} Remove Selection`, props<StoreSelectionPayload>());
export const clearSelection = createAction(`${PREFIX} Clear Selection`, props<Pick<StoreSelectionPayload, 'viewId'>>());

export const resetUrlQueryPagination = createAction(
  `${PREFIX} Reset URL Query Pagination`,
  props<ResetUrlQueryPaginationPayload>()
);
