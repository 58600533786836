import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoutes } from '../../app-routing.module';
import { NumericalIdGuard } from '../shared/guards/numerical-id.guard';
import { ViewByUrlParamResolver } from '../view/resolvers/view-by-url-param.resolver';
import { ViewComponent } from '../view/views/view/view.component';
import { CreateProductGroupResolver } from './resolvers/create-product-group.resolver';
import { ProductGroupDetailResolver } from './resolvers/product-group-detail.resolver';
import { CreateProductGroupComponent } from './views/create-product-group/create-product-group.component';
import { ProductGroupDetailComponent } from './views/product-group-detail/product-group-detail.component';

const routes: CustomRoutes = [
  {
    path: '',
    component: ViewComponent,
    resolve: {
      result: ViewByUrlParamResolver
    },
    data: {
      shouldDisplayDetailLink: true,
      shouldDisplaySearchInput: true,
      shouldDisplayExportDialog: true,
      shouldDisplayDeleteAction: true,
      shouldDisplayCheckboxes: true,
      checkBoxesProperty: 'id',
      createNewRecordLink: {
        entityName: 'product_group'
      },
      filterMenus: [
        { fieldName: 'groupType', displayName: 'Group Type' },
        { fieldName: 'number', displayName: 'Group Number' },
        { fieldName: 'name', displayName: 'Group Name' },
        { fieldName: 'productType', displayName: 'Product Type' },
        { fieldName: 'coe', displayName: 'CoE' },
        { fieldName: 'intendedPurpose', displayName: 'Intended Purpose' },
        {
          isGroup: true,
          name: 'BUDI Properties',
          filterDefinitions: [
            { fieldName: 'budiInfo2.animalTissuesCellsPresence', displayName: 'Animal Tissues Cells Presence' },
            { fieldName: 'budiInfo2.humanTissuesCellsPresence', displayName: 'Human Tissues Cells Presence' },
            { fieldName: 'budiInfo2.humanBloodOrPlasmaPresence', displayName: 'Human Blood or Plasma Presence' },
            { fieldName: 'budiInfo2.medicinalSubstancePresence', displayName: 'Medicinal Substance Presence' },
            { fieldName: 'budiInfo2.isSterile', displayName: 'Sterile' },
            { fieldName: 'budiInfo2.multipleGtinsForBaseUnit', displayName: 'Multiple GTINs for Base Unit' },
            { fieldName: 'budiInfo2.active', displayName: 'Active' },
            { fieldName: 'budiInfo2.administeringMedicine', displayName: 'Administering Medicine' },
            { fieldName: 'budiInfo2.implantable', displayName: 'Implantable' },
            { fieldName: 'budiInfo2.measuringFunction', displayName: 'Measuring Function' },
            { fieldName: 'budiInfo2.reusable', displayName: 'Reusable Surgical Instrument' },
            { fieldName: 'budiInfo2.systemProcedurePackType', displayName: 'System Procedure Pack Type' },
            { fieldName: 'budiInfo2.multiComponentType', displayName: 'Multi Component Type' },
            { fieldName: 'budiInfo2.specialDeviceType', displayName: 'Special Device Type' },
            { fieldName: 'budiInfo2.riskClass', displayName: 'Risk Class' },
            { fieldName: 'budiInfo2.plannedTransitionToMdr', displayName: 'Planned Transition to MDR' },
            { fieldName: 'budiInfo2.lifecycle', displayName: 'Lifecycle' }
          ]
        }
      ]
    }
  },
  {
    path: 'new',
    component: CreateProductGroupComponent,
    data: {
      meta: {
        title: 'Create Product Group'
      }
    },
    resolve: {
      result: CreateProductGroupResolver
    }
  },
  {
    path: ':id',
    resolve: {
      result: ProductGroupDetailResolver,
      _: CreateProductGroupResolver
    },
    canActivate: [NumericalIdGuard],
    children: [
      {
        path: '',
        component: ProductGroupDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductGroupsRoutingModule {}
