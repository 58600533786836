import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'identificationNumber'
})
export class IdentificationNumberPipe implements PipeTransform {
  transform(number: any): string {
    return `${number.number} (${number.type2?.shortName})`;
  }
}
