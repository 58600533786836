import { createAction, props } from '@ngrx/store';

const PREFIX = '[Documents]';

export interface CreateExtDmsObjectAndDocumentPayload {
  dmsId?: number;
  isFolder?: boolean;
  documentType: number;
  docRevision: string;
  docNumber?: number;
  effectiveDate: Date;
  expiryDate?: Date;
}

export interface CreateDocumentPayload extends CreateExtDmsObjectAndDocumentPayload {
  dmsObject?: string;
}

export const createExtDmsObjectAndDocument = createAction(
  `${PREFIX} Create Ext DMS Object and Document`,
  props<CreateExtDmsObjectAndDocumentPayload>()
);
export const createDocument = createAction(`${PREFIX} Create Document`, props<CreateDocumentPayload>());
