import { Component, Input } from '@angular/core';

@Component({
  selector: 'rims-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {
  @Input()
  routerLink: string;
}
