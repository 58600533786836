import { Pipe, PipeTransform } from '@angular/core';
import { FilterOperator, FilterOperatorLabels, FilterOperatorSymbols } from '@rims/lib';

@Pipe({
  name: 'filterOperatorLabel'
})
export class FilterOperatorLabelPipe implements PipeTransform {
  transform(operator: FilterOperator, symbol = false): string {
    if (symbol) {
      return FilterOperatorSymbols.get(operator) || operator;
    }
    return FilterOperatorLabels.get(operator) || operator;
  }
}
