<rims-header>
  <rims-header-title>
    {{ (result | async)?.name }}
  </rims-header-title>
</rims-header>

<rims-property-edit-menu (updateProperty)="onStateChange($event)"></rims-property-edit-menu>

<div class="container pb-3" *ngIf="result | async">
  <div class="row py-5">
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{(entity | async)?.fields?.['id']?.displayName}}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'id'" [value]="getProp('id', result | async)" />
      </mat-form-field>
    </div>
    <div class="col-12 col-sm-4">
      <rims-field
        [editMenu]="editPropertyMenu"
        [menuConfig]="{
          disableOnEmpty: true
        }"
        [obj]="result | async"
        [entity]="entity | async"
        permissionBaseEntity="app_role"
        field="name"
      ></rims-field>
    </div>
    <div class="col-12 col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{(entity | async)?.fields?.['permissionLevel']?.displayName}}</mat-label>
        <input matInput disabled [attr.rims-field-name]="'permissionLevel'" [value]="getProp('permissionLevel', result | async)" />
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-5" *rimsRequirePermission="'app_permission'; field: 'updatePermissionLevel'">
    <div class="col-12">
      <h2>Actions</h2>
    </div>
    <div class="col">
      <button mat-button (click)="openUpdatePermissionLevelDialog()">Update Permission Level</button>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>Users with this role</h2>
    </div>
    <div class="col">
      <rims-table [hideFiltersAndFilterMenu]="true" [dataKey]="'user'" [viewConfigPayload]="(resolveResult | async)?.viewPayloads['user']"></rims-table>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <h2>History</h2>
    </div>
    <div class="col">
      <rims-detail-view-record-history
        [config]="{
          entityIdOrName: (entity | async)?.id,
          recordId: (result | async)?.id
        }"
      ></rims-detail-view-record-history>
    </div>
  </div>
</div>
