import { createFeatureSelector } from '@ngrx/store';
import { MaintenanceInformation } from '@rims/lib';

export interface MetaState {
  appVersion: string;
  buildTime: string;
  commitHash: string;
  loading: boolean;
  maintenance: MaintenanceInformation;
}

export const META_INITIAL_STATE: MetaState = {
  appVersion: null,
  buildTime: null,
  commitHash: null,
  loading: false,
  maintenance: null
};

export const selectMeta = createFeatureSelector<MetaState>('meta');
