import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { UpdatePermissionLevelDialogComponent } from './components/update-permission-level-dialog/update-permission-level-dialog.component';
import { RolesRoutingModule } from './roles-routing.module';
import { RoleDetailComponent } from './views/role-detail/role-detail.component';

@NgModule({
  declarations: [RoleDetailComponent, UpdatePermissionLevelDialogComponent],
  imports: [CommonModule, SharedModule, MaterialModule, ReactiveFormsModule, RolesRoutingModule]
})
export class RolesModule {}
