import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { routeHasOwnModule } from 'src/app/app-routing.module';
import { AppState } from '../../store/store.state';

@Pipe({
  name: 'viewExists'
})
export class ViewExistsPipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(relationName: string): Observable<boolean> {
    return this.store.pipe(
      select(state => state.metadata.views),
      map(views => {
        const view = Object.keys(views)
          .map(id => views[id])
          .find(view => view.route.endsWith(relationName));

        return !!view && routeHasOwnModule(relationName);
      })
    );
  }
}
