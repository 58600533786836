import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { getData } from '../../shared/store/data/data.actions';
import { DataStateEntry } from '../../shared/store/data/data.state';
import { AppState } from '../../store/store.state';

@Injectable({
  providedIn: 'root'
})
export class CreateItemResolver implements Resolve<Record<string, DataStateEntry>> {
  private readonly specialDeviceTypeKey = 'special_device_type';
  private readonly systemProcedurePackTypeKey = 'system_procedure_pack_type';
  private readonly multiComponentTypeKey = 'multi_component_type';

  constructor(readonly store: Store<AppState>) {}

  resolve(): Observable<Record<string, DataStateEntry>> {
    return this.store.pipe(
      select(state => state.data.entries),
      take(1),
      switchMap(data => {
        this.store.dispatch(
          getData({
            url: 'specialdevicetype',
            key: this.specialDeviceTypeKey,
            pageSize: Number.MAX_SAFE_INTEGER
          })
        );
        this.store.dispatch(
          getData({
            url: 'systemprocedurepacktype',
            key: this.systemProcedurePackTypeKey,
            pageSize: Number.MAX_SAFE_INTEGER
          })
        );
        this.store.dispatch(
          getData({
            url: 'multicomponenttype',
            key: this.multiComponentTypeKey,
            pageSize: Number.MAX_SAFE_INTEGER
          })
        );
        return this.store.pipe(
          select(state => state.data.entries),
          filter(entries => {
            let done = true;
            done =
              done &&
              !!entries[this.specialDeviceTypeKey] &&
              !entries[this.specialDeviceTypeKey].loading &&
              !!entries[this.systemProcedurePackTypeKey] &&
              !entries[this.systemProcedurePackTypeKey].loading &&
              !!entries[this.multiComponentTypeKey] &&
              !entries[this.multiComponentTypeKey].loading;
            return done;
          }),
          take(1)
        );
      })
    );
  }
}
