import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { EqualsFilter } from '@rims/lib';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class RiskClassValidator {
  constructor(private readonly data: DataService) {}

  duplicateRiskClassValidator(
    item: string,
    riskClass: number,
    legislation: number
  ): Observable<ValidationErrors | null> {
    if (!(item && riskClass)) {
      return of({
        canNotDetermineUniqueness: true
      });
    }

    return this.data
      .getAll('itemriskclasses', undefined, undefined, undefined, undefined, undefined, [
        new EqualsFilter('riskClass', riskClass),
        new EqualsFilter('item', item)
      ])
      .pipe(
        switchMap(page => {
          if (page?.resultsSize > 0) {
            return of(page);
          }
          return this.data.getAll('itemriskclasses', ['riskClass2'], undefined, undefined, undefined, undefined, [
            new EqualsFilter('item', item),
            new EqualsFilter('riskClass2.legislation', legislation)
          ]);
        }),
        map(page => {
          if (page.resultsSize < 1) {
            return null;
          }

          // if 'legislation' is not part of the filter then the first request already found at least one itemriskclass
          if (!(page as any).filter.includes('legislation')) {
            return {
              duplicate: true
            };
          }

          // else we found at least one itemriskclass with the same legislation
          return {
            legislationAlreadyClassified: true
          };
        })
      );
  }
}
