import { Injectable } from '@angular/core';
import { UpdatePayloadBudi } from '@rims/sap-interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from '../../shared/services/request-builder/request-builder.service';

@Injectable({
  providedIn: 'root'
})
export class SyncPreviewService {
  constructor(private readonly requestBuilder: RequestBuilder) {}

  getBudiSyncPreview(productGroupId: number): Observable<UpdatePayloadBudi> {
    return this.requestBuilder
      .request(environment.backendUrl, 'productgroups', `${productGroupId}`, 'sap-sync-payload')
      .get();
  }

  getItemSyncPreview(item: string): Observable<UpdatePayloadBudi> {
    return this.requestBuilder.request(environment.backendUrl, 'items', `${item}`, 'sap-sync-payload').get();
  }
}
