import { ApplicationTier } from '@rims/lib';
import { Environment } from './_environment.model';

export const environment: Environment = {
  tier: ApplicationTier.PRD,
  production: true,
  oauth2: {
    issuer: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/v2.0',
    redirectUri: 'https://rims.bbraun.cloud/auth/callback',
    clientId: 'a108f914-c9e3-4668-981b-df9dc03a6aa1',
    scope: 'api://5e935111-0591-4ae6-999e-3b9a938e0efe/Backend.Access'
  },
  backendUrl: 'https://api.rims.bbraun.cloud'
};
