<span *ngIf="nonEmptyResultSetMessage && (hasRecords | async)" class="color-grey d-block mb-3" [innerText]="nonEmptyResultSetMessage"> </span>
<div class="d-flex justify-content-between actions" [ngStyle]="{ 'min-height': (hasRecords | async) ? '55px' : '0px' }">
  <div class="d-flex flex-row">
    <ng-content select="rims-table-action"></ng-content>
  </div>
  <div class="d-flex flex-column flex-sm-row" *ngIf="!loader.force">
    <rims-table-search-query
      #searchQuery
      [hidden]="!showQueryInput"
      (onChange)="onSortAndPageChange(undefined, undefined, undefined, $event)"
      (onQueryBlur)="onQueryBlur()"
    ></rims-table-search-query>
    <div class="d-flex flex-row">
      <rims-table-action
        *ngIf="shouldDisplaySearchInput && (hasRecords | async)"
        [hidden]="showQueryInput"
        [attr.e2e-id]="'query-button'"
        (click)="showQueryInput = !showQueryInput"
      >
        <mat-icon>search</mat-icon>
        Search
      </rims-table-action>
      <rims-table-action *ngIf="!hideColumnDialog && (hasRecords | async)" [attr.e2e-id]="'column-dialog-button'" (click)="openColumnSortDialog(viewId)">
        <mat-icon>view_column</mat-icon>
        Columns
      </rims-table-action>
      <rims-table-action [attr.e2e-id]="'filter-menu-button'" [matMenuTriggerFor]="filterMatMenu" *ngIf="(hasRecords | async) && !hideFiltersAndFilterMenu && !!filterMenus">
        <mat-icon>filter_list</mat-icon>
        Filter
      </rims-table-action>
      <rims-table-action *ngIf="shouldDisplayExportDialog && (hasRecords | async)" [attr.e2e-id]="'export-button'" (click)="openExportDialog()">
        <mat-icon>save_alt</mat-icon>
        Export
      </rims-table-action>
      <div class="actions-min-height"></div>
    </div>
  </div>
</div>

<mat-menu #filterMatMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngIf="!!filterMenus">
      <ng-container *ngFor="let filterDef of filterMenus" [ngSwitch]="filterDef.isGroup">
        <ng-container *ngSwitchCase="true">
          <button mat-menu-item [mat-menu-trigger-for]="subMenu">{{ filterDef.name }}</button>
          <mat-menu #subMenu="matMenu">
            <ng-container *ngFor="let filter of filterDef.filterDefinitions">
              <!-- using *ngTemplateOutlet within matMenu causes buggy behavior for sub-menus, therefore we can not put the (redundant) filter buttons in an ng-template -->
              <button mat-menu-item (click)="addFilter(filter)" class="filter-btn d-flex align-items-center justify-content-between">
                <span [style.padding-right]="0">
                  {{ filter.displayName }}
                </span>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <!-- same button as above -->
          <button mat-menu-item (click)="addFilter(filterDef)" class="filter-btn d-flex align-items-center justify-content-between">
            <span [style.padding-right]="0">
              {{ filterDef.displayName }}
            </span>
          </button>
        </ng-container>
      </ng-container>
      <mat-divider></mat-divider>
      <button [disabled]="clearFilterDisabled | async" (click)="clearFilters()" mat-menu-item><mat-icon>clear</mat-icon>Clear All Filters</button>
    </ng-container>
  </ng-template>
</mat-menu>

<div
  *ngIf="!loader.force"
  class="d-flex"
  [ngClass]="hideFiltersAndFilterMenu ? 'justify-content-end' : 'justify-content-between'"
  [style.margin-top]="hideColumnDialog && hideFiltersAndFilterMenu && !shouldDisplaySearchInput ? '-2rem' : null"
>
  <rims-table-filters [hidden]="hideFiltersAndFilterMenu" [viewId]="viewId"></rims-table-filters>
  <rims-table-scroll-indicator
    [ngClass]="hideFiltersAndFilterMenu ? 'adjust-indicator-position' : null"
    [table]="table"
    [columns]="columnIds | async"
  ></rims-table-scroll-indicator>
</div>

<div class="scrollable-table">
  <table
    #table
    class="w-100"
    [attr.e2e-id]="'rims-table-view-' + viewId"
    mat-table
    matSort
    cdkDropList
    cdkDropListLockAxis="x"
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="dropListDropped($event)"
    [matSortActive]="sortEvent?.active"
    [matSortDirection]="sortEvent?.direction"
    [matSortStart]="'asc'"
    (matSortChange)="onSortAndPageChange(pageEvent, $event)"
    [dataSource]="(data | async)?.page?.results"
    [hidden]="(data | async)?.page?.resultsSize === 0 && !loader.force"
    [attr.aria-label]="label"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="checkbox-padding">
        <mat-checkbox
          [color]="(checkboxColor | async) || 'primary'"
          [checked]="allRowsSelected"
          (change)="$event ? selectAllRows() : null"
          [indeterminate]="someRowsSelected"
          [matTooltip]="selectAllTooltip()"
          matTooltipPosition="above"
          matTooltipShowDelay="500"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="checkbox-padding">
        <mat-checkbox
          [color]="(checkboxColor | async) || 'primary'"
          (click)="$event.stopPropagation()"
          (change)="$event ? updateSelection(row) : null"
          [checked]="isSelected(row | propertyAccess : checkBoxesProperty) | async"
          [matBadge]="(row | propertyAccess : checkBoxesProperty) ? '' : '!'"
          [matTooltip]="
            (row | propertyAccess : checkBoxesProperty)
              ? ''
              : 'Row can not be selected because the property ' +
                checkBoxesProperty +
                ' is not available on the record. Your administrator can change this by correctly setting the checkBoxesProperty.'
          "
          [disabled]="(store | async)?.metadata?.loading || !(row | propertyAccess : checkBoxesProperty) || loader.force"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container *ngFor="let col of columnIds | async | onlyNumericalStrings; let i = index" [matColumnDef]="'' + col">
      <ng-container *ngIf="col | columnById : (view | async)?.columns as resolvedColumn">
        <!-- HEADER CELLS -->
        <ng-container *matHeaderCellDef [ngSwitch]="shouldEnableSort(resolvedColumn)">
          <th
            *ngSwitchCase="true"
            mat-header-cell
            mat-sort-header
            cdkDrag
            [cdkDragDisabled]="hideColumnDialog"
            (cdkDragStarted)="dragStarted($event)"
            [cdkDragData]="{ columnId: resolvedColumn?.id, viewId: (view | async)?.id, previousIndex: i }"
            [class.cursor-drag]="!hideColumnDialog"
            [ngStyle]="{ 'min-width.px': resolvedColumn?.width }"
            [attr.e2e-id]="resolvedColumn?.field2?.displayName"
          >
            <ng-container *ngTemplateOutlet="headerCellTemplate; context: { col: resolvedColumn }"></ng-container>
          </th>
          <th
            *ngSwitchDefault
            mat-header-cell
            cdkDrag
            [cdkDragDisabled]="hideColumnDialog"
            (cdkDragStarted)="dragStarted($event)"
            [cdkDragData]="{ columnId: resolvedColumn?.id, viewId: (view | async)?.id, previousIndex: i }"
            [class.cursor-drag]="!hideColumnDialog"
            [ngStyle]="{ 'min-width.px': resolvedColumn?.width }"
            [attr.e2e-id]="resolvedColumn?.field2?.displayName"
          >
            <ng-container *ngTemplateOutlet="headerCellTemplate; context: { col: resolvedColumn }"></ng-container>
          </th>
          <!-- END HEADER CELLS -->
        </ng-container>
        <ng-template #headerCellTemplate let-col="col">
          <span class="d-flex">
            {{ col?.field2?.displayName }}
            <mat-icon
              *ngIf="col.field2?.title"
              matTooltip="The values in this column contain additional information. Hover over the values to see it."
              matTooltipPosition="above"
              matTooltipShowDelay="150"
              class="ml-1"
            >
              highlight_alt
            </mat-icon>
          </span>
        </ng-template>

        <!-- DATA CELLS -->
        <td
          mat-cell
          *matCellDef="let element"
          #cellValue="templateVariable"
          [templateVariable]="getValueByPropertyTree(element, resolvedColumn?.field2?.field, resolvedColumn?.field2?.title, resolvedColumn?.field2?.type?.name)"
        >
          <div class="d-flex align-items-center">
            <ng-container *ngIf="(resolvedColumn.field2?.relation | viewExists | async) && !sameAsView(resolvedColumn.field2?.relation); else valueOnly">
              <a (click)="onDetailLinkClicked(element, resolvedColumn)">
                <ng-container *ngTemplateOutlet="valueOnly"></ng-container>
              </a>
            </ng-container>
            <ng-template #valueOnly>
              <span
                [matTooltip]="cellValue.templateVariable.title"
                [matTooltipShowDelay]="100"
                [innerHtml]="isSearchColumn(resolvedColumn) ? (cellValue.templateVariable.value | highlight : query) : cellValue.templateVariable.value"
              ></span>
            </ng-template>
          </div>
        </td>
        <!-- END DATA CELLS -->
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex justify-content-end">
          <a mat-icon-button (click)="onDetailLinkClicked(element)">
            <mat-icon class="color-purple">arrow_forward</mat-icon>
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnIds | async" class="drag-drop-boundary"></tr>
    <tr mat-row *matRowDef="let row; columns: columnIds | async"></tr>
  </table>
  <ngx-skeleton-loader
    *ngIf="(withinDetailView() && !(data | async)?.page) || loader.force"
    [count]="(loaderCount | async) ?? 5"
    [theme]="{ height: '39px', 'background-color': '#0000000d', 'margin-bottom': '-3px' }"
    animation="progress-dark"
  ></ngx-skeleton-loader>
</div>
<div class="mt-2" *ngIf="(data | async)?.page?.resultsSize === 0">
  <span class="color-grey" *ngIf="!loader.force">{{ emptyResultSetMessage }}</span>
</div>

<mat-paginator
  [hidden]="(data | async)?.page?.resultsSize === 0 || loader.force"
  [pageSizeOptions]="config?.pageSizeOptions"
  [pageSize]="pageEvent?.pageSize"
  [pageIndex]="pageEvent?.pageIndex"
  [length]="(data | async)?.page?.totalResultsSize"
  (page)="onSortAndPageChange($event, sortEvent)"
  showFirstLastButtons
></mat-paginator>
<div class="d-flex justify-content-end my-2 text-right" *ngIf="showDebugInfo">
  <span class="text-danger" style="font-size: 80%">
    <code>view: {{ viewId }}</code>
    <br />
    <code>param: {{ updatePipeline | async | json }}</code>
  </span>
</div>
