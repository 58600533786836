<rims-header>
  <rims-header-title>Create Item</rims-header-title>
</rims-header>

<form [formGroup]="form">
  <div class="container my-5">
    <div class="row mb-4">
      <div class="col-12 col-md-6">
        <p>Please start by entering a material number from SAP.</p>
        <mat-progress-bar mode="indeterminate" class="position-absolute" style="width: calc(100% - 30px)" *ngIf="itemIsLoading"></mat-progress-bar>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Item Number</mat-label>
          <input matInput [formControlName]="formFieldName.ITEM_NUMBER" type="text" autocomplete="off" #itemNumberInput />
          <mat-icon
            [ngSwitch]="form.controls[formFieldName.NO_DUPLICATE_ITEM].value"
            [color]="form.controls[formFieldName.NO_DUPLICATE_ITEM].value === true ? 'primary' : 'warn'"
            [style.opacity]="form.controls[formFieldName.ITEM_NUMBER].valid || form.controls[formFieldName.SECTION_ONE_FINISHED].value ? '1' : '0'"
            matSuffix
          >
            <ng-container *ngSwitchCase="true">done</ng-container>
            <ng-container *ngSwitchCase="false">warning</ng-container>
          </mat-icon>
          <mat-error *ngIf="form.controls[formFieldName.ITEM_NUMBER].hasError('required')">Item Number is required</mat-error>
          <mat-error *ngIf="form.controls[formFieldName.ITEM_NUMBER].hasError('maxlength')">Item Number must not be longer than 18 characters.</mat-error>
          <mat-error *ngIf="form.controls[formFieldName.ITEM_NUMBER].hasError('pattern')">Item Number has no valid format. Maybe leading zeros?</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="form.controls[formFieldName.NO_DUPLICATE_ITEM].value === false">
        <p class="m-0">
          The item already exists:
          <br />
          <a class="unstyled" [routerLink]="['..', form.controls[formFieldName.ITEM_NUMBER].value]">
            <strong>{{ form.controls[formFieldName.ITEM_NUMBER].value }} / {{ (existingExtSapItemResult | async)?.results[0]?.name }}</strong>
          </a>
        </p>
      </div>
    </div>
    <div class="row mb-4" *ngIf="form.controls[formFieldName.SECTION_ONE_FINISHED].value">
      <div class="col-12 col-md-6 d-flex flex-column">
        <h2>SAP Material Information</h2>
        <ng-container
          *ngIf="
            !form.controls[formFieldName.EXT_SAP_ITEM].value &&
            !form.controls[formFieldName.EXT_SAP_ITEM_NOT_FOUND].value &&
            !form.controls[formFieldName.NO_DUPLICATE_EXT_SAP_ITEM].value
          "
        >
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Synchronization</mat-label>
            <input matInput disabled [value]="'Material ' + form.controls[formFieldName.ITEM_NUMBER].value + ' is in sync with RIMS'" />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_ITEM_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_ITEM_NOT_FOUND].value">
          <p class="text-danger">The material could not be found in SAP. Please check the source system and try again.</p>
        </ng-container>
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_ITEM_UNDEFINED_ERROR].value">
          <p class="text-danger">An error occurred while communicating with SAP.</p>
          <small *ngIf="form.value[formFieldName.EXT_SAP_ITEM_ERROR_MESSAGE]">{{ form.value[formFieldName.EXT_SAP_ITEM_ERROR_MESSAGE] }}</small>
        </ng-container>
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_ITEM_STATUS_ERROR].value">
          <p class="text-danger">Item has material status M4 (end-of-life), therefore it can not be created in RIMS.</p>
        </ng-container>
        <ng-container *ngIf="form.controls[formFieldName.EXT_SAP_ITEM].value">
          <p>The following information has been fetched from SAP. Please make sure that they are correct before continuing.</p>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Material Number</mat-label>
            <input matInput disabled [value]="form.controls[formFieldName.EXT_SAP_ITEM]?.value?.materialNumber" />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_ITEM_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput disabled [value]="form.controls[formFieldName.EXT_SAP_ITEM]?.value?.materialDescription" />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_ITEM_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Status</mat-label>
            <input
              matInput
              disabled
              [value]="form.controls[formFieldName.EXT_SAP_ITEM]?.value?.materialStatus + ' / ' + form.controls[formFieldName.EXT_SAP_ITEM]?.value?.distributionStatus"
            />
            <mat-icon color="primary" [style.opacity]="form.controls[formFieldName.EXT_SAP_ITEM_CHECKED].value ? '1' : '0'" matSuffix>done</mat-icon>
          </mat-form-field>
          <mat-checkbox [formControlName]="formFieldName.EXT_SAP_ITEM_CHECKED">The information is correct and I want to sync the material data to RIMS</mat-checkbox>
        </ng-container>
      </div>
    </div>
    <div class="row" *ngIf="form.controls[formFieldName.EXT_SAP_ITEM_CREATION_PENDING].value">
      <div class="col-12 col-md-6">
        <mat-progress-bar mode="indeterminate" class="position-absolute" style="width: calc(100% - 30px)"></mat-progress-bar>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Please wait a moment while we sync data from SAP...</mat-label>
          <input matInput disabled />
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.controls[formFieldName.SECTION_TWO_FINISHED].value">
      <div class="col-12">
        <h2>Product</h2>
        <p>You can also make this assignment after the item has been created.</p>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <mat-progress-bar
          mode="indeterminate"
          class="position-absolute"
          style="width: calc(100% - 30px)"
          *ngIf="form.controls[formFieldName.PRODUCT_QUERY].pending"
        ></mat-progress-bar>
        <mat-form-field class="w-100" appearance="fill" *ngIf="{ options: filteredProducts | async } as observable">
          <mat-label>Product Name</mat-label>
          <input type="text" matInput [formControlName]="formFieldName.PRODUCT_QUERY" [matAutocomplete]="auto" placeholder="Start typing..." />
          <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="onProductSelect($event)" (closed)="onProductAutocompleteClosed()">
            <mat-option *ngFor="let option of observable.options" [value]="option">
              {{ option?.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint class="warn" *ngIf="form.get(formFieldName.PRODUCT_QUERY).hasError('closedWithoutSelection')">
            Please make sure to select the product by clicking the desired entry in the dropdown menu.
          </mat-hint>
          <mat-hint *ngIf="showNoSelectionInfo">Since you did not make a selection in the dropdown list, no product will be assigned to the new item.</mat-hint>
          <mat-hint *ngIf="observable.options?.length === 0 && !form.controls[formFieldName.PRODUCT].value">
            Did not find the product? Click
            <a href="/view/products" target="_blank" class="unstyled font-weight-bold">here</a>
            to browse all products or create a new one.
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-4 pb-5">
      <div class="col">
        <button
          mat-button
          type="button"
          *ngIf="!form.controls[formFieldName.SECTION_ONE_FINISHED].value"
          [disabled]="!form.controls[formFieldName.SECTION_ONE_VALID].value"
          (click)="nextStage()"
        >
          Next
        </button>
        <button mat-button *ngIf="form.controls[formFieldName.EXT_SAP_ITEM_NOT_FOUND].value || form.controls[formFieldName.EXT_SAP_ITEM_UNDEFINED_ERROR].value" (click)="retry()">
          Retry
        </button>
        <button
          mat-button
          type="button"
          *ngIf="
            !form.controls[formFieldName.SECTION_TWO_FINISHED].value && form.controls[formFieldName.SECTION_ONE_FINISHED].value && form.controls[formFieldName.EXT_SAP_ITEM].value
          "
          [disabled]="!form.controls[formFieldName.EXT_SAP_ITEM_CHECKED].value || form.controls[formFieldName.EXT_SAP_ITEM_CREATION_PENDING].value"
          (click)="nextStage()"
        >
          Next
        </button>
        <button mat-button type="button" *ngIf="form.controls[formFieldName.SECTION_TWO_FINISHED].value" [disabled]="form.invalid" (click)="nextStage()">Create Item</button>
      </div>
    </div>
  </div>
</form>
