<h1 mat-dialog-title>Change Container Type</h1>
<div mat-dialog-content>
  <p [ngSwitch]="data.newIsAdvancedValue">
    <ng-container *ngSwitchCase="true">
      You are in the process of changing making this container
      <strong>advanced</strong>
      .
      <br />
      Please select the appropriate type. Afterwards, you can edit the aditional advanced properties.
    </ng-container>
    <ng-container *ngSwitchCase="false">
      You are in the process of changing making this container
      <strong>non-advanced</strong>
      .
      <br />
      This means that you have to select a new type and that any advanced configurations will be removed. Please confirm that you want to proceed.
    </ng-container>
  </p>
  <form [formGroup]="form">
    <mat-form-field class="w-100 mb-2" appearance="fill">
      <mat-label>Please select a new type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of data.availableTypes" [value]="type?.id">{{ type?.name }} ({{ type?.shortName }})</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('type').hasError('required')">Selection of a type is required</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="confirmChange">I confirm that I want to proceed with the changes.</mat-checkbox>
  </form>
</div>
<div mat-dialog-actions class="mb-0 p-0" align="end">
  <button mat-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
</div>
