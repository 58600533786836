import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ContainersModule } from '../containers/containers.module';
import { MaterialModule } from '../material/material.module';
import { QueueModule } from '../queue/queue.module';
import { SapModule } from '../sap/sap.module';
import { SharedModule } from '../shared/shared.module';
import { AddContainersToProductGroupDialogComponent } from './components/add-containers-to-product-group-dialog/add-containers-to-product-group-dialog.component';
import { BudiMaterialsDialogComponent } from './components/budi-materials-dialog/budi-materials-dialog.component';
import { ChangeBudiLifecycleDialogComponent } from './components/change-budi-lifecycle-dialog/change-budi-lifecycle-dialog.component';
import { ProductGroupsRoutingModule } from './product-groups-routing.module';
import { CreateProductGroupComponent } from './views/create-product-group/create-product-group.component';
import { ProductGroupDetailComponent } from './views/product-group-detail/product-group-detail.component';

@NgModule({
  declarations: [
    ProductGroupDetailComponent,
    CreateProductGroupComponent,
    BudiMaterialsDialogComponent,
    ChangeBudiLifecycleDialogComponent,
    AddContainersToProductGroupDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    ProductGroupsRoutingModule,
    SapModule,
    QueueModule,
    ContainersModule,
    NgxSkeletonLoaderModule
  ]
})
export class ProductGroupsModule {}
