import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from 'src/app/app.constants';
import * as urlJoin from 'url-join';
import { SortExt } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class RequestBuilder {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG)
    private readonly config: AppConfig
  ) {}

  static array(values: string[]) {
    return values.join(',');
  }

  request(...segments: string[]): InternalRequestBuilder {
    return new InternalRequestBuilder(this.httpClient, segments, this.config.pageSizeOptions);
  }
}

export class InternalRequestBuilder {
  private _headers: HttpHeaders;
  private _params: HttpParams;
  private _body: any;
  private _fullResponse = false;
  private _textResponse = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly segments: string[],
    private readonly pageSizeOptions: number[]
  ) {
    this._headers = new HttpHeaders();
    this._headers.append('Content-Type', 'application/json; charset=utf-8');
  }

  /**
   * Executes the constructed request as a GET request.
   */
  get<T = any>(): Observable<T> {
    return this.httpClient.get(urlJoin(this.segments), this.createOptions()).pipe(map(data => data as T));
  }

  /**
   * Executes the constructed request as a POST request.
   */
  post(): Observable<any> {
    return this.httpClient
      .post(urlJoin(this.segments), this._body, this.createOptions())
      .pipe(map(data => data as any));
  }

  /**
   * Executes the constructed request as a POST request.
   */
  fileExport(): Observable<any> {
    return this.httpClient
      .post(urlJoin(this.segments), this._body, this.createFileOptions())
      .pipe(map(data => data as any));
  }

  /**
   * Executes the constructed request as a PUT request.
   */
  put(): Observable<any> {
    return this.httpClient.put(urlJoin(this.segments), this._body, this.createOptions()).pipe(map(data => data as any));
  }

  /**
   * Executes the constructed request as a PATCH request.
   */
  patch(): Observable<any> {
    return this.httpClient
      .patch(urlJoin(this.segments), this._body, this.createOptions())
      .pipe(map(data => data as any));
  }

  /**
   * Executes the constructed request as a DELETE request.
   */
  delete(): Observable<any> {
    return this.httpClient.delete(urlJoin(this.segments), this.createOptions()).pipe(map(data => data as any));
  }

  /**
   * Sets the HTTP headers. Calling this method replaces the default headers.
   */
  headers(headers: HttpHeaders): InternalRequestBuilder {
    this._headers = headers;
    return this;
  }

  /**
   * Sets the paging query parameters. If used this method must be called before any other methods that manipulate
   * query parameters.
   */
  paging(pageable: Pageable): InternalRequestBuilder {
    const p = Pageable.applyDefaults(pageable, {
      limit: this.pageSizeOptions[0]
    });
    return this.params(p.apply());
  }

  /**
   * Sets the query parameters. If used this method must be called before any other methods that manipulate
   * query parameters.
   */
  params(params: HttpParams): InternalRequestBuilder {
    if (!this._params) {
      this._params = params;
      return this;
    } else {
      throw new Error('HttpParams already set (use param(string, string) to append)');
    }
  }

  /**
   * Appends a query parameter. Does not set the parameter if the value is considered empty.
   */
  param(name: string, value: string): InternalRequestBuilder {
    if (!value) {
      return this;
    }

    if (!this._params) {
      return this.params(new HttpParams().set(name, value));
    } else {
      this._params = this._params.append(name, value);
      return this;
    }
  }

  /**
   * Sets the body that should be passed as a payload.
   *
   * @param body payload
   */
  body(body: any): InternalRequestBuilder {
    if (!this._body) {
      this._body = body;
      return this;
    } else {
      throw new Error('Body already set');
    }
  }

  /**
   * Flag request the full response information instead of just the body. Required for example when the caller needs to access the
   * HTTP status code.
   */
  withFullResponse(): InternalRequestBuilder {
    this._fullResponse = true;
    return this;
  }

  /**
   * Manipulates the responseType property in the http call. Required when the call needs to have a text response.
   */
  withTextOptions(): InternalRequestBuilder {
    this._textResponse = true;
    return this;
  }

  private createOptions(): RequestOptions {
    return {
      headers: this._headers,
      params: this._params,
      observe: this._fullResponse ? 'response' : 'body',
      responseType: this._textResponse ? 'text' : 'json'
    };
  }

  private createFileOptions(): RequestOptions {
    return {
      headers: this._headers,
      params: this._params,
      observe: this._fullResponse ? 'response' : 'body',
      responseType: 'blob'
    };
  }
}

/**
 * Helper class to generate the pagination parameters as required by a Spring endpoint with
 * a Pageable parameter.
 */
export class Pageable {
  private static defaults: {
    page?: number;
    limit?: number;
    sort?: SortExt;
  } = {
    page: 0,
    limit: 0,
    sort: undefined
  };

  static applyDefaults(
    p: Pageable,
    defaults: {
      page?: number;
      limit?: number;
      sort?: SortExt;
    }
  ): Pageable {
    return new Pageable(
      p['page'] || defaults.page || Pageable.defaults.page,
      p['limit'] || defaults.limit || Pageable.defaults.limit,
      p['sort'] || defaults.sort || Pageable.defaults.sort
    );
  }

  /**
   * Creates a Pageable object. All parameters are optional because the REST endpoints will
   * set reasonable defaults for missing parameters at the server side.
   */
  constructor(
    private readonly page = Pageable.defaults.page,
    private readonly limit = Pageable.defaults.limit,
    private readonly sort?: SortExt
  ) {}

  /**
   * Applies the pagination parameters and returns an HttpParams instance. The basic HttpParams
   * can be supplied as a parameter. If missing, the method will create an empty instance first.
   */
  public apply(params = new HttpParams()): HttpParams {
    params = params.append('limit', String(this.limit));

    params = params.append('page', String(this.page));

    // Only apply sorting when direction is set (MatTable also triggers a sort event with only the sort field set when the user resets the
    // sorting (3rd click on a sort header).
    if (this.sort?.direction) {
      params = params.append('orderBy', this.sort.active + ':' + this.sort.direction.toUpperCase());
    }

    return params;
  }
}

/**
 * Explicit interface for the request options. Required to work around a TypeScript compiler typing 'bug' (maybe not a really a bug).
 */
interface RequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}
