import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { BudiItemSyncStatus } from '@rims/lib';

@Component({
  selector: 'pending-snackbar',
  templateUrl: 'pending-snackbar.component.html',
  styleUrls: ['./pending-snackbar.component.scss']
})
export class PendingSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  getProgress(status: BudiItemSyncStatus) {
    const { items, sapItems, desired } = status;

    const actualSum = items + sapItems;
    const desiredSum = desired * 2;

    return Math.min(actualSum / desiredSum, 1) * 100;
  }
}
