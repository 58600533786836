import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(values: any[], direction: '' | 'asc' | 'desc' = '', property = ''): any[] {
    if (!values || direction === '' || !direction || values.length === 0) {
      return values;
    }
    if (!property || property === '') {
      return direction === 'asc' ? values.sort() : values.sort().reverse();
    }
    return orderBy(values, [property], [direction]);
  }
}
