import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Division } from '@rims/database';
import { SapBudiDivision, sapBudiDivisionToDivisionShortName } from '@rims/sap-interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../store/store.state';

@Pipe({
  name: 'sapDivisionToRimsDivision'
})
export class SapDivisionToRimsDivisionPipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(value: SapBudiDivision, storeKey = 'division'): Observable<Division> {
    return this.store.pipe(
      select(state => state.data.entries[storeKey]?.page?.results),
      map(divisions => {
        const shortName = sapBudiDivisionToDivisionShortName(value);
        const fallback: Partial<Division> = {
          name: value,
          shortName: value
        };

        return divisions.find(division => division?.shortName === shortName) || fallback;
      })
    );
  }
}
